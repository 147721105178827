const portalTranslation = {
  Portal: {
    Header: {
      alt: {
        figLogo: 'Fig logo',
        userIcon: 'User icon',
        languageDropdown: 'Toggle dropdown',
      },
      label: {
        languageDropdown: 'Language',
        profileSettings: 'Profile settings',
        logout: 'Log out',
      },
    },
    Menu: {
      links: {
        referral: `https://fig.ca/en/referral/`,
        terms: `https://fig.ca/en/legal/terms-of-use/`,
        privacy: `https://fig.ca/en/legal/privacy-statement/`,
        helpCenter: `https://support.fig.ca/en/support/home`,
      },
      alt: {
        homeIcon: 'Home icon',
        paymentsIcon: 'Payments icon',
        giftIcon: 'Gift icon',
        documentsIcon: 'Documents icon',
        moreIcon: 'More icon',
        paymentProtectionIcon: 'Payment protection icon',
        loanIncreaseIcon: 'Loan increase icon',
        helpCenterIcon: 'Help center icon',
        termsIcon: 'Terms of use icon',
        notificationIcon: 'Notification icon',
        dividerIcon: 'Menu divider',
      },
      label: {
        home: 'Home',
        payments: 'Payments',
        documents: 'Documents',
        managePayments: 'Manage payments',
        viewDocuments: 'View documents',
        more: 'More',
        paymentProtection: 'Payment protection',
        loanIncrease: 'Request a loan amount increase',
        helpCenter: 'Help center',
        terms: 'Terms of use',
      },
    },
    Home: {
      label: {
        tab: {
          summary: 'Summary',
          transactions: 'Transactions',
          upcomingPayments: 'Upcoming Payments',
        },
        summary: {
          title: 'Summary',
          loanStatus: {
            good: 'Good standing',
            overdue: 'Payment overdue',
            closed: 'Closed',
          },
          remainingBalance: 'Remaining balance',
          upcomingPaymentAmount: 'Upcoming payment amount',
          upcomingPaymentDate: 'Upcoming payment date',
          dateFormat: 'MMM d, yyyy',
          automaticPaymentsOn: 'Automatic payments: On',
          automaticPaymentsOff: 'Automatic payments: Off',
          paymentProtectionActive: 'Payment protection: Active',
          loanDetails: 'Loan details',
          loanId: 'Loan ID',
          loanAmount: 'Loan amount',
          interestRate: 'Interest rate',
          loanTerm: 'Loan term',
          loanTermInMonths: '{{count}} months',
          loanStartDate: 'Loan start date',
        },
        transactions: {
          title: `Transactions`,
          description: `Below, you can see all payments you’ve made, including scheduled payments and one time payments, as well as additional fees on your account.`,
          status: {
            processing: 'Processing',
            failed: 'Failed',
          },
          message: {
            processing: `Please ensure sufficient funds are available in your account until the payment is processed within 2–3 business days.`,
          },
        },
        upcomingPayments: {
          title: `Upcoming Payments`,
          description: `If you have made a lump sum payment, your monthly payment amount won’t be reduced, but your loan term will be shortened.`,
          descriptionWithPaymentProtection: `Your Payment Protection charges are included only in your next upcoming payment. For all other payments listed below, the charges will be added after the previous payment has been processed.`,
          remainingBalance: `Remaining balance:`,
        },
        greeting: {
          morning: 'Good morning',
          afternoon: 'Good afternoon',
          evening: 'Good evening',
        },
      },
    },
    Payments: {
      label: {
        title: `Manage your payments`,
        description: `Choose an option below`,
        options: {
          manualPayment: {
            title: `Make a manual payment`,
            description:
              'Want to pay off your loan faster? You can make an extra payment, or pay off your loan without penalties.',
          },
          overduePaymentDescription: `Choose to pay outstanding amount, pay a different amount or pay off your loan without penalties.`,
          manualPaymentUserDescription: `Choose to pay next payment, make an extra payment, or pay off your loan without penalties.`,
          delayNextPayment: {
            title: `Delay next payment`,
            description:
              'Need more time to make your next payment? You can delay your next payment by up to 1 month, once every 12 months.',
          },
          changePaymentSchedule: {
            title: `Change payment schedule`,
            description: `Choose the payment frequency and date that suits you, for regular payments.`,
          },
          changePaymentMethod: {
            title: `Change payment method`,
            description: `Update your bank account to process future payments.`,
          },
        },
        overdueMessage: `Your payment is overdue. Please pay your outstanding amount as soon as possible to avoid additional interest and negative impact on your credit score.`,
      },
    },
    Components: {
      alt: {
        infoCircleIcon: 'Information icon',
        questionCircleIcon: 'Question mark icon',
        statusIcon: 'Status icon',
        chevronIcon: 'Chevron icon',
        checkmarkIcon: 'Checkmark icon',
      },
      label: {
        viewDetails: 'View details',
        manage: 'Manage',
        learnMore: 'Learn more',
        showDetails: 'Show details',
        hideDetails: 'Hide details',
      },
      tooltip: {
        title: {
          accountStatus: 'Account status',
          remainingBalance: 'Remaining balance',
          loanId: 'Loan ID',
          loanTerm: 'Loan term',
          paymentDetails: 'Payment details',
        },
        content: {
          accountStatus: `<typography>Your account status will show either:<br/><br/><strong>Good standing</strong> - All payments up to your last due date have been received<br/><br/><strong>Payment overdue</strong> - One or more expected payments have been missed<br/><br/><strong>Closed</strong> - Your loan has been paid off<br/><br/>If you see a "Your payment is overdue" message, please pay your outstanding amount as soon as possible or contact our support team to arrange for a payment to return your account to good standing.</typography>`,
          remainingBalance: `<typography>Your loan balance will increase as daily interest is calculated and decrease as payments are applied. If you are looking to pay off your loan, this is the amount due as of today.</typography>`,
          loanId: `<typography>Use this number as your account number when you try to make a bill payment through your bank.</typography>`,
          loanTerm: `<typography>If you make a lump sum payment or have a repayment schedule more frequent than once a month, your actual repayment term may be shorter than originally planned. However, if you miss or delay any payments, this may extend your repayment term beyond the initially scheduled end date.</typography>`,
        },
        label: {
          principalRemaining: 'Principal remaining',
          interestAccrued: 'Interest accrued since last payment',
          feesAccrued: 'Fees accrued since last payment',
          total: 'Total',
          principal: 'Principal',
          creditCharges: 'Credit charges',
          paymentProtectionPremium: 'Payment protection premium',
          paymentProtectionPremiumTax: 'Payment protection Premium Tax',
          nsfFees: 'NSF fees',
          totalAmount: 'Total amount',
        },
        link: {
          remiaingBalance: 'https://fig.ca',
        },
      },
      modal: {
        automaticPayments: {
          on: {
            description: `Your next automatic payment is scheduled for {{date}}`,
            status: 'On',
            linkText: null,
          },
          off: {
            description: `Don't want to miss your payment? Turn on automatic payment now!`,
            status: 'Off',
            linkText: 'Turn on',
          },
          title: {
            paymentAccount: 'Payment account',
            paymentSchedule: 'Payment schedule',
          },
          linkText: {
            change: 'Change',
            delay: 'Delay',
          },
        },
      },
    },
  },
}
export default portalTranslation
