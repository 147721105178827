import { styled } from '@mui/material/styles'

import {
  bmoCircleLogo,
  scotiaCircleLogo,
  tdCircleLogo,
  rbcCircleLogo,
  bmoSquareLogo,
  scotiaSquareLogo,
  tdSquareLogo,
  rbcSquareLogo,
  cibcSquareLogo,
  nationalBankSquareLogo,
  defaultBankLogo,
} from 'src/images'

interface BankLogoProps {
  name: string
  geometry?: string
  style?: React.CSSProperties
  marginRight?: React.CSSProperties['marginRight']
}

interface ImgStyleProps {
  isSquare?: boolean
  marginRight?: React.CSSProperties['marginRight']
}

const BankImgStyle = styled('img')<ImgStyleProps>(({ isSquare, marginRight }) => ({
  width: isSquare ? '16px' : '22px',
  height: isSquare ? 'auto' : '22px',
  objectFit: 'cover',
  borderRadius: isSquare ? 'initial' : '50%',
  marginRight: marginRight || 0,
}))

/**
 *
 */
export const BankLogo = ({ name, geometry, style, marginRight }: BankLogoProps) => {
  if (!name) {
    return null
  }

  const isSquare = geometry === 'square'
  let src
  switch (name.toLowerCase()) {
    case 'td': {
      src = isSquare ? tdSquareLogo : tdCircleLogo
      break
    }
    case 'rbc': {
      src = isSquare ? rbcSquareLogo : rbcCircleLogo
      break
    }
    case 'bmo': {
      src = isSquare ? bmoSquareLogo : bmoCircleLogo
      break
    }
    case 'scotia': {
      src = isSquare ? scotiaSquareLogo : scotiaCircleLogo
      break
    }
    case 'cibc': {
      src = cibcSquareLogo
      break
    }
    case 'national': {
      src = nationalBankSquareLogo
      break
    }
    default: {
      src = defaultBankLogo
      break
    }
  }

  return src ? (
    <BankImgStyle
      src={src}
      alt={name}
      isSquare={isSquare}
      style={style}
      marginRight={marginRight}
    />
  ) : null
}
