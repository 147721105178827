import { Box, Typography, useMediaQuery, useTheme } from '@mui/material'
import Grid from '@mui/material/Grid2'
import { useTranslation } from 'react-i18next'

import { SectionContainer } from 'src/components/EndToEnd/landing/Walmart/components/SectionContainer'
import { ImgStyle } from 'src/components/EndToEnd/landing/Walmart/styles'
import { circleOneIcon, circleThreeIcon, circleTwoIcon, walmart35Kpromo } from 'src/images'

export const HowSection = () => {
  const { t } = useTranslation()
  const theme = useTheme()
  const iconStyle = { display: 'inline', verticalAlign: 'middle', paddingRight: '16px' }
  const headingStyle = { display: 'inline', verticalAlign: 'middle', fontSize: '18px' }
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  return (
    <SectionContainer>
      <Grid
        size={{ xs: 12, md: 6 }}
        sx={{ order: { xs: 1, md: 1 }, textAlign: isMobile ? '-webkit-center' : '' }}
      >
        <Typography
          component="h5"
          variant="sectionHeading"
          sx={{ textAlign: { xs: 'center', md: 'left' } }}
        >
          {t('e2e.Landing.walmart.howSection.title')}
        </Typography>
        <ImgStyle
          src={walmart35Kpromo}
          sx={{ marginTop: '64px', marginBottom: '32px', width: '100%' }}
        />
      </Grid>
      <Grid
        size={{ xs: 12, md: 6 }}
        sx={{
          order: { xs: 2, md: 2 },
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'end',
          paddingLeft: { md: '50px' },
        }}
      >
        <Box sx={{ paddingY: { xs: '0px', md: '16px' } }}>
          <ImgStyle style={iconStyle} src={circleOneIcon} />
          <Typography style={headingStyle} component="h6" variant="subheading">
            {t('e2e.Landing.walmart.howSection.first.title')}
          </Typography>
          <Typography variant="body" component="p" pl="50px" pt="8px">
            {t('e2e.Landing.walmart.howSection.first.content')}
          </Typography>
        </Box>
        <Box sx={{ paddingY: { xs: '8px', md: '16px' } }}>
          <ImgStyle style={iconStyle} src={circleTwoIcon} />
          <Typography style={headingStyle} component="h6" variant="subheading">
            {t('e2e.Landing.walmart.howSection.second.title')}
          </Typography>
          <Typography variant="body" component="p" pl="50px">
            {t('e2e.Landing.walmart.howSection.second.content')}
          </Typography>
        </Box>
        <Box sx={{ paddingY: { xs: '8px', md: '16px' } }}>
          <ImgStyle style={iconStyle} src={circleThreeIcon} />
          <Typography
            style={{ display: 'inline', verticalAlign: 'middle' }}
            component="h6"
            variant="subheading"
          >
            {t('e2e.Landing.walmart.howSection.third.title')}
          </Typography>
          <Typography variant="body" component="p" pl="50px">
            {t('e2e.Landing.walmart.howSection.third.content')}
          </Typography>
        </Box>
      </Grid>
    </SectionContainer>
  )
}
