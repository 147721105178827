import { Typography, useMediaQuery, useTheme } from '@mui/material'
import Grid from '@mui/material/Grid2'
import { useTranslation } from 'react-i18next'

import { TrustpilotMicroCombo } from 'src/components/Trustpilot'
import { ImgStyle, MobileDivider } from 'src/components/EndToEnd/landing/Walmart/styles'
import { reviewAvatar } from 'src/images'

export const TrustpilotSection = () => {
  const theme = useTheme()
  const { t } = useTranslation()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  return (
    <Grid
      container
      sx={{ backgroundColor: theme.palette.info.main, py: '64px', px: isMobile ? '32px' : 0 }}
      alignItems={'center'}
      justifyContent={'center'}
      width={'100%'}
    >
      <Grid container size={{ xs: 12 }} display={'contents'}>
        <Grid container>
          <Grid size={{ xs: 3, md: 3 }} textAlign={'right'}>
            <ImgStyle
              src={reviewAvatar}
              sx={isMobile ? { marginRight: 0 } : { marginLeft: '45%' }}
            />
          </Grid>
          <Grid size={{ xs: 9, md: 9 }}>
            <Typography
              component="h5"
              variant="sectionHeading"
              fontStyle={'italic'}
              sx={{ fontSize: isMobile ? '16px' : '24px', lineHeight: '24px' }}
            >
              {t('e2e.Landing.walmart.trustpilotSection.reviewer.text')}
            </Typography>
            <Typography component="h6" variant="subheading" fontStyle={'italic'} fontWeight={400}>
              {t('e2e.Landing.walmart.trustpilotSection.reviewer.name')}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid size={{ xs: 12 }} alignItems={'center'} justifyContent={'center'}>
        <TrustpilotMicroCombo />
      </Grid>
      <MobileDivider />
    </Grid>
  )
}
