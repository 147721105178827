import { useState } from 'react'
import Grid from '@mui/material/Grid'
import { Link, Typography } from '@mui/material'

import { DivRoot } from 'src/components/common/DivRoot'
import { OnfidoTokenRequest } from 'src/types/api'
import { FigDropdown, FigMenuItem } from 'src/components/common/FigDropdown'
import { PrimaryCTA, SecondaryCTA } from 'src/components/common/Buttons'

/**
 * This component enables us to send an override first name and last name to Onfido. These can be used to force specific scenarios.
 * Moreover, even without sending override first and last names, by clicking override we send $is_test_mode, which lets us use the
 * workflow specific for testing, which is less strict than the production one and will allow things like using a Presto card for scanning.
 *
 * By clicking pass-through, you will be using the same workflow as in production.
 *
 * See https://documentation.onfido.com/#pre-determined-responses for more.
 */
export const OnfidoTestMode = ({
  setApplicantOverride,
}: {
  setApplicantOverride: (override: OnfidoTokenRequest) => void
}) => {
  const [firstName, setFirstName] = useState<string | undefined>()
  const [lastName, setLastName] = useState<string | undefined>()

  const firstNameLabel = 'Override first name'
  const firstNames = [
    'Image Integrity - Supported Document',
    'Image Integrity - Image Quality',
    'Visual Authenticity - Fonts',
    'Visual Authenticity - Security Features',
    'Visual Authenticity - Face Detection',
    'Data Validation - Document Numbers',
    'Data Consistency - Document Type',
    'Visual Authenticity - Spoofing Detection',
    'Face Comparison - Face Match',
    'Image Integrity - Source Integrity',
    'Image Integrity - Face Detected',
  ]

  const lastNameLabel = 'Override last name'
  const lastNames = [
    'Consider',
    'clear',
    'rejected',
    'suspected',
    'caution',
    'CA DL 2018',
    'CA DL 2018 front only',
    'NY DL 2017',
    'NY DL 2017 front only',
    'Ontario ID 2010',
    'FRA ID 1994',
    'FRA ID 1994 front only',
  ]
  const lastNamesTolabel: { [index: string]: string } = {
    rejected: '[DECLINED CASE] rejected',
  }

  return (
    <DivRoot>
      <Grid sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
        <Typography variant="h1" textAlign="center">
          Onfido Test Mode
        </Typography>
        <FigDropdown
          onChange={e => setFirstName(e.target.value as string)}
          value={firstName}
          label={firstNameLabel}
          menuItems={firstNames.map(name => (
            <FigMenuItem key={name} value={name}>
              {name}
            </FigMenuItem>
          ))}
        />
        <FigDropdown
          onChange={e => setLastName(e.target.value as string)}
          value={lastName}
          label={lastNameLabel}
          menuItems={lastNames.map(name => (
            <FigMenuItem key={name} value={name}>
              {lastNamesTolabel[name] || name}
            </FigMenuItem>
          ))}
        />
        <Typography>
          Select no overrides for a success case. See the{' '}
          <Link href="https://documentation.onfido.com/#pre-determined-responses" target="_blank">
            Onfido documentation
          </Link>{' '}
          for more info about pre-determined responses.
        </Typography>
        <PrimaryCTA
          buttonText="Override"
          onClick={() =>
            setApplicantOverride({
              override_first_name: firstName,
              override_last_name: lastName,
              is_test_mode: true,
            })
          }
        />
        <SecondaryCTA buttonText="Pass through" onClick={() => setApplicantOverride({})} />
      </Grid>
    </DivRoot>
  )
}
