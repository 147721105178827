import { Box, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { ImgStyle } from 'src/components/EndToEnd/landing/Walmart/styles'
import { lockOutlinedIcon } from 'src/images'

export const RateDisclaimer = () => {
  const { t } = useTranslation()
  return (
    <Box mt="14px">
      <ImgStyle src={lockOutlinedIcon} sx={{ display: 'inline', verticalAlign: 'middle' }} />
      <Typography
        component="p"
        variant="body"
        sx={{ display: 'inline', verticalAlign: 'middle' }}
        ml="5px"
      >
        {t('e2e.Landing.walmart.rateDisclaimer')}
      </Typography>
    </Box>
  )
}
