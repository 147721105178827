import { Grid2, Typography } from '@mui/material'
import { t } from 'i18next'

import { portalStretchDivider } from 'src/images'
import { PortalLink } from 'src/portal/components/PortalLink'
import PortalTheme from 'src/themes/portal'

interface InfoActionRowProps {
  title: string
  content: JSX.Element | string
  linkText?: string | null
  onClickHandler?: () => void | null
}

/**
 * InfoActionRow component renders a row with a title, content, and an action link.
 *
 * @param {string} title - The title to display in the row.
 * @param {React.ReactNode} content - The content to display below the title.
 * @param {string} linkText (optional) - The text to display in the action link.
 * @param {function} onClickHandler (optional) - The handler function to call when the action link is clicked.
 *
 * @returns {JSX.Element} The rendered InfoActionRow component.
 */
export const InfoActionRow = ({ title, content, linkText, onClickHandler }: InfoActionRowProps) => {
  return (
    <Grid2 container>
      <Grid2 size={9}>
        <Typography variant="body2" fontWeight={500} color={PortalTheme.color.grey10}>
          {title}
        </Typography>
        {content}
      </Grid2>
      <Grid2 size={3} container alignItems="center" justifyContent={'flex-end'}>
        {linkText && onClickHandler && (
          <PortalLink text={linkText} onClickHandler={onClickHandler} />
        )}
      </Grid2>
      <img
        src={portalStretchDivider}
        style={{ width: '100%', paddingTop: '12px', paddingBottom: '12px' }}
        alt={t('Portal.Menu.alt.dividerIcon')}
      />
    </Grid2>
  )
}
