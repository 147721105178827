import { addMonths, parseISO } from 'date-fns'

import { useApi } from 'src/hooks'
import {
  BootstrapInfoType,
  CachedApplications,
  MonthlyScheduleEnum,
  PaymentScheduleEnum,
  RepaymentScheduleRequestType,
  SavedRepaymentScheduleType,
} from 'src/types'
import { copy } from 'src/utils'

interface Props {
  applicationId: string
  setAuthorizePaymentStartError: (value: boolean) => void
  bootstrapInfo: BootstrapInfoType
  cachedApplications: CachedApplications
  setCachedApplications: (value: CachedApplications) => void
}

export const useScheduleHelper = ({
  bootstrapInfo,
  cachedApplications,
  applicationId,
  setCachedApplications,
  setAuthorizePaymentStartError,
}: Props) => {
  const cachedSchedule = cachedApplications[applicationId]?.saved_repayment_schedule

  const { generateRepaymentSchedule } = useApi()

  const saveSchedule = ({
    schedule,
    monthly_choice,
    withdraw_amount,
    total_estimated_interest,
    first_payment_date,
    payment_cycle_due_date,
  }: SavedRepaymentScheduleType) => {
    const newCachedApplications = copy(cachedApplications) as CachedApplications
    newCachedApplications[applicationId].saved_repayment_schedule = {
      withdraw_amount,
      total_estimated_interest,
      first_payment_date,
      monthly_choice,
      schedule,
      payment_cycle_due_date,
    }
    setCachedApplications(newCachedApplications)
  }

  const generateAndSaveSchedule = async (scheduleOptions?: RepaymentScheduleRequestType) => {
    const defaultOptions = {
      schedule: PaymentScheduleEnum.ONCE_A_MONTH,
      monthly_choice: MonthlyScheduleEnum.OTHER,
      first_payment_date: addMonths(parseISO(bootstrapInfo!.system_date!), 1).toISOString(),
    }
    setAuthorizePaymentStartError(false)
    const result = await generateRepaymentSchedule(scheduleOptions || defaultOptions)
    saveSchedule(result.data)
  }

  return {
    saveSchedule,
    generateAndSaveSchedule,
    cachedSchedule,
  }
}
