import { saveAs } from 'file-saver'

function b64toFile(base64String: string, filename: string, contentType: string) {
  const sliceSize = 512
  const byteCharacters = atob(base64String)
  const byteArrays = []

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize)
    const byteNumbers = new Array(slice.length)

    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i)
    }
    const byteArray = new Uint8Array(byteNumbers)
    byteArrays.push(byteArray)
  }
  const file = new File(byteArrays, filename, { type: contentType })
  return file
}

/*
 *
 */
export const pdfFileDownload = (base64String: string, title: string) => {
  const file = b64toFile(base64String, title, 'data:application/pdf;base64')
  saveAs(file)
}
