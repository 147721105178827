import { SxProps, Theme } from '@mui/material/styles'
import { createBrowserHistory } from 'history'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { ReactNode, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Outlet, useLocation, useParams, useNavigate } from 'react-router-dom'

import { LoadingAnimation } from 'src/components/common/animations/Loading'
import FigHeader from 'src/components/common/FigHeader'
import { MaintenanceBanner } from 'src/components/common/MaintenanceBanner'
import {
  ContainerStyle,
  FigAppBar,
  FigChildrenContainer,
  FigContentContainer,
  ImgStyle,
  LanguageToggle,
  StyledDiv,
} from 'src/components/common/RootLayout'
import { ConnectivityToast } from 'src/components/ConnectivityToast/ConnectivityToast'
import { AboveContainerContent } from 'src/components/CreditRenewal/components/AboveContainerContent'
import MaintenanceMode from 'src/components/Error/MaintenanceMode'
import CreditRenewalProgressBar from 'src/components/ProgressBar/CreditRenewal'
import NetworkProvider from 'src/contexts/network'
import { useCreditRenewalContext } from 'src/hooks'
import useFreshChatWidget from 'src/hooks/useFreshChatWidget'
import { SupportedLanguage } from 'src/locales'
import {
  ApplicationStatus,
  CreditRenewalStepsEnum,
  FeatureFlags,
  HeaderType,
  IconType,
  LogoType,
  CreditRenewalRouteBase,
} from 'src/types'
import { copy, decodeBase64 } from 'src/utils'
import {
  EnvEnum,
  ENVIRONMENT,
  FRESHCHAT_HOST,
  FRESHCHAT_SCRIPT_URL,
  FRESHCHAT_TOKEN,
  FRESHCHAT_WIDGET_UUID,
} from 'src/utils/constants'

interface LayoutInputType {
  bootstrapIsLoading: boolean
  bootstrapIsSuccess: boolean
  logo: LogoType
}

function RootLayout({
  children,
  logo,
  header,
  contentStyles,
}: {
  children: ReactNode
  logo: LogoType
  header?: HeaderType
  iconConfig?: IconType
  contentStyles?: SxProps<Theme>
  showProgressBar?: boolean
}) {
  const { i18n } = useTranslation()
  const language = i18n.resolvedLanguage as SupportedLanguage
  const { isMaintenanceBanner } = useFlags<FeatureFlags>()

  return (
    <StyledDiv id="fig-root-container">
      {isMaintenanceBanner ? <MaintenanceBanner /> : null}
      <FigAppBar>
        <ContainerStyle maxWidth={false}>
          <a href={`https://fig.ca/${language}/`} target="_blank" rel="noreferrer">
            {logo ? <ImgStyle src={logo.src} alt={logo.alt} data-testid="partnerLogo" /> : null}
          </a>
        </ContainerStyle>
        <LanguageToggle />
      </FigAppBar>
      <CreditRenewalProgressBar />
      {header ? <FigHeader {...header} /> : null}
      <NetworkProvider>
        <ConnectivityToast />
        <FigContentContainer sx={contentStyles}>
          <AboveContainerContent />
          <FigChildrenContainer id="fig-content-container">{children}</FigChildrenContainer>
        </FigContentContainer>
      </NetworkProvider>
    </StyledDiv>
  )
}

/**
 *
 */
export default function Layout({ bootstrapIsLoading, bootstrapIsSuccess, logo }: LayoutInputType) {
  const params = useParams()
  const history = createBrowserHistory()
  const location = useLocation()
  const { t } = useTranslation()
  const { isMaintenance } = useFlags<FeatureFlags>()
  const navigate = useNavigate()
  const {
    borrowerId,
    setBorrowerId,
    applicationId,
    setApplicationId,
    cachedApplications,
    setCachedApplications,
    jwtApiKey,
    setJwtApiKey,
    isApplicationError,
    setApplicationError,
    setApplicationSettled,
    isApplicationExpired,
    isApplicationDeclined,
    bootstrapInfo,
    setGenericErrorPageError,
    setCRStep,
  } = useCreditRenewalContext()

  // This useEffect is used to listen to browser back and forward buttons
  // To prevent the user from navigating the app
  // after a loan has been approved
  useEffect(() => {
    const unlisten = history.listen(({ action }) => {
      if (
        bootstrapInfo?.application?.status === ApplicationStatus.SETTLED ||
        bootstrapInfo?.application?.status === ApplicationStatus.AUTHORIZED
      ) {
        if (action === 'POP' || action === 'PUSH') {
          setApplicationSettled(true)
        }
      }
    })
    return unlisten
  }, [bootstrapInfo?.application?.status])

  // Trigger a warning on refresh (refreshing on page is not currently supported).
  useEffect(() => {
    window.onbeforeunload = function (event) {
      event.returnValue = 'Your application will restart if you leave. Are you sure?'
    }
  }, [])

  const urlSuffix = location.pathname.split('/').at(-1)
  useEffect(() => {
    // If user refreshes the page on a step other than the eligibility step or the confirm information step
    // then redirect them to the eligibility step
    if (applicationId) {
      return
    }

    if (urlSuffix === CreditRenewalStepsEnum.ELIGIBILITY) {
      return
    }

    if (urlSuffix === CreditRenewalStepsEnum.CONFIRM_INFORMATION) {
      return
    }

    navigate(`/${CreditRenewalRouteBase}/${params.jwtApiKey}/${CreditRenewalStepsEnum.ELIGIBILITY}`)
  }, [params.jwtApiKey, applicationId])

  useEffect(() => {
    if (!params.jwtApiKey) {
      return
    }

    if (jwtApiKey !== params.jwtApiKey) {
      setJwtApiKey(params.jwtApiKey)
    }

    const data = decodeBase64<{ application_id?: string; borrower_id?: string }>(params.jwtApiKey)

    if (!data?.application_id) {
      setApplicationError(true)
      return
    }

    if (data.application_id !== applicationId) {
      setApplicationId(data.application_id)
      setBorrowerId(data.borrower_id ?? '')
      const updatedCachedApplications = copy(cachedApplications)
      updatedCachedApplications[data.application_id] =
        updatedCachedApplications[data.application_id] || {}
      setCachedApplications(updatedCachedApplications)
    }
  }, [params.jwtApiKey])

  useFreshChatWidget({
    scriptSrc: FRESHCHAT_SCRIPT_URL,
    shouldShow: !!applicationId && !bootstrapIsLoading,
    freshChatCustomParams: {
      cf_application_id: applicationId,
      cf_borrower_id: borrowerId,
      cf_environment: ENVIRONMENT === EnvEnum.local ? 'development' : ENVIRONMENT,
      cf_partner_name: bootstrapInfo?.partner?.name ?? '',
    },
    freshChatInitParams: {
      token: FRESHCHAT_TOKEN,
      externalId: borrowerId,
      host: FRESHCHAT_HOST,
      widgetUuid: FRESHCHAT_WIDGET_UUID,
    },
    freshChatContactParams: {
      email: bootstrapInfo?.borrower?.borrower_email ?? '',
      phone: bootstrapInfo?.borrower?.borrower_mobile ?? '',
      firstName: bootstrapInfo?.borrower?.borrower_first_name ?? '',
      lastName: bootstrapInfo?.borrower?.borrower_last_name ?? '',
    },
  })

  useEffect(() => {
    if (params.jwtApiKey && jwtApiKey) {
      if (bootstrapIsLoading) {
        return
      }

      if (isApplicationError || !bootstrapIsSuccess) {
        setGenericErrorPageError(
          new Error(
            `isApplicationError: ${isApplicationError} bootstrapIsSuccess: ${bootstrapIsSuccess}`,
          ),
        )
        setCRStep(CreditRenewalStepsEnum.ERROR, jwtApiKey)
      }
    }
  }, [
    location.pathname,
    params.jwtApiKey,
    jwtApiKey,
    bootstrapIsLoading,
    bootstrapIsSuccess,
    isApplicationError,
    isApplicationExpired,
    isApplicationDeclined,
  ])

  if (isMaintenance) {
    return (
      <RootLayout logo={logo}>
        <MaintenanceMode />
      </RootLayout>
    )
  }

  if (bootstrapIsLoading) {
    return (
      <RootLayout logo={logo}>
        <LoadingAnimation subtitle={t('init.loading')} />
      </RootLayout>
    )
  }

  return (
    <RootLayout logo={logo}>
      <Outlet />
    </RootLayout>
  )
}
