import { useTranslation, Trans } from 'react-i18next'

import { verificationFailImg } from 'src/images'
import { StepsEnum } from 'src/types'
import { kycIdFailContactFigClicked, kycIdFailLiveChatClicked } from 'src/utils'
import { PrimaryCTA } from 'src/components/common/Buttons'
import { PleaseReachOut, openChat } from 'src/components/Error/shared/PleaseReachOut'
import ErrorPage from 'src/components/Error/shared/ErrorPage'

export default KYCIdFail
/**
 *
 */
function KYCIdFail() {
  const { t } = useTranslation()
  const screen = StepsEnum.KYC_ID_FAIL
  const onContactClick = () => {
    kycIdFailLiveChatClicked(t('KYCIdFail.liveChatLabel'), screen)
    openChat()
  }

  const content = (
    <Trans i18nKey="KYCIdFail.content">
      There were some issues verifying your documents. If you think there was a mistake,{' '}
      <PleaseReachOut
        screen={screen}
        liveChatAnalytics={kycIdFailLiveChatClicked}
        contactFigAnalytics={kycIdFailContactFigClicked}
      />
    </Trans>
  )
  const liveChatButton = (
    <PrimaryCTA onClick={onContactClick} buttonText={t('KYCIdFail.liveChatLabel')} />
  )
  return (
    <ErrorPage
      image={{ src: verificationFailImg, alt: t('KYCIdFail.alt') }}
      title={t('KYCIdFail.title')}
      content={content}
      buttons={[liveChatButton]}
    />
  )
}
