import { useEffect, useState } from 'react'
import DoneIcon from '@mui/icons-material/Done'
import { useTranslation } from 'react-i18next'

import { AlertColor, FigAlert, LoadingIcon } from 'src/components/common/FigAlert'
import { useNetworkContext } from 'src/hooks'

const RECONNECT_TOAST_DISPLAY_TIME_MS = 5 * 1000

enum NetworkState {
  DISCONNECTED = 'disconnected',
  RECONNECTED = 'reconnected',
  CONNECTED = 'connected',
}

/**
 *
 */
export function ConnectivityToast() {
  const { t } = useTranslation()
  const { isOnline } = useNetworkContext()

  const [networkState, setNetworkState] = useState<NetworkState | null>(null)
  // let null represent the uninitialized state

  useEffect(() => {
    if (!isOnline) {
      setNetworkState(NetworkState.DISCONNECTED)
      return
    }
    if (networkState === null) {
      // no need to show the reconnected toast for the initial render
      setNetworkState(NetworkState.CONNECTED)
      return
    }
    setNetworkState(NetworkState.RECONNECTED)
  }, [isOnline])

  switch (networkState) {
    case NetworkState.DISCONNECTED:
      return (
        <FigAlert
          icon={<LoadingIcon />}
          title={t('ConnectivityToast.Disconnected.title')}
          content={t('ConnectivityToast.Disconnected.content')}
          color={AlertColor.WARNING}
        />
      )
    case NetworkState.RECONNECTED:
      return (
        <FigAlert
          icon={<DoneIcon />}
          title={t('ConnectivityToast.Reconnected.title')}
          content={t('ConnectivityToast.Reconnected.content')}
          color={AlertColor.SUCCESS}
          fade={{
            lifespanMs: RECONNECT_TOAST_DISPLAY_TIME_MS,
            callback: () => setNetworkState(NetworkState.CONNECTED),
          }}
        />
      )
    case NetworkState.CONNECTED:
    default:
      return null
  }
}
