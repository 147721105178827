import { Box, Grid, ImageList, ImageListItem, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation } from 'react-query'

import { getRetryQueryOptionsWithErrorStep } from 'src/api/api'
import { BackButton, PrimaryCTA } from 'src/components/common/Buttons'
import { DivRoot } from 'src/components/common/DivRoot'
import FigTextField from 'src/components/common/FigTextField'
import { useApi, useOriginationContext } from 'src/hooks'
import {
  autoIcon,
  autoSelectedIcon,
  billsIcon,
  billsSelectedIcon,
  debtIcon,
  debtSelectedIcon,
  educationIcon,
  educationSelectedIcon,
  emergencyIcon,
  emergencySelectedIcon,
  homeIcon,
  homeSelectedIcon,
  investmentIcon,
  investmentSelectedIcon,
  otherIcon,
  otherSelectedIcon,
} from 'src/images'
import theme from 'src/themes'
import { CachedApplication, StepsEnum } from 'src/types'
import { purposeOfLoanBackClicked, purposeProvided } from 'src/utils/analytics'

import { InvestmentScamWarning } from './InvestmentScamWarning'

const PurposeImageListItem = styled(ImageListItem)({
  border: 'solid 1px',
  borderRadius: '3px',
  height: '80px',
  ':hover': {
    borderColor: theme.color.brand1,
  },
})

const IconStyle = styled('img')({
  width: '30px',
  height: '30px',
  marginTop: '15px',
  marginLeft: 'auto',
  marginRight: 'auto',
  marginBottom: '5px',
})

const AutoIconStyle = styled('img')({
  width: '45px',
  height: '25px',
  marginTop: '20px',
  marginLeft: 'auto',
  marginRight: 'auto',
  marginBottom: '5px',
})

const IconTypography = styled(Typography)({
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '13px',
  lineHeight: '14px',
  padding: '4px',
  paddingBottom: '0px',
})

interface Props {
  previousStep: StepsEnum
  nextStep: StepsEnum
}

export default PurposeOfLoan
/**
 *
 */
function PurposeOfLoan({ previousStep, nextStep }: Props) {
  const {
    setStep,
    cachedApplication,
    updateCachedApplication,
    updateAsyncRequestStatusCallback,
    setGenericErrorPageError,
  } = useOriginationContext()
  const { savePurposeOfLoan } = useApi()
  const { t } = useTranslation()
  const loanPurposeOtherReason = cachedApplication.loan_purpose_other_reason || ''
  const loanPurposeCategory = cachedApplication.loan_purpose_category || ''

  const [incompleteError, setIncompleteError] = useState(false)
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const otherCategory = 'Other'
  const purposeData = [
    {
      id: 'debt',
      value: 'Debt Consolidation',
      displayName: t('PurposeOfLoan.displayNames.debt'),
      icon: debtIcon,
      selectedIcon: debtSelectedIcon,
      alt: 'Money flowing graphic',
    },
    {
      id: 'bills',
      value: 'Expenses',
      displayName: t('PurposeOfLoan.displayNames.bills'),
      icon: billsIcon,
      selectedIcon: billsSelectedIcon,
      alt: 'Hand holding bill',
    },
    {
      id: 'home',
      value: 'Home Improvement',
      displayName: t('PurposeOfLoan.displayNames.home'),
      icon: homeIcon,
      selectedIcon: homeSelectedIcon,
      alt: 'House',
    },
    {
      id: 'emergency',
      value: 'Emergency',
      displayName: t('PurposeOfLoan.displayNames.emergency'),
      icon: emergencyIcon,
      selectedIcon: emergencySelectedIcon,
      alt: 'Warning ! icon',
    },
    {
      id: 'auto',
      value: 'Automobile Financing / Repair',
      displayName: t('PurposeOfLoan.displayNames.auto'),
      icon: autoIcon,
      selectedIcon: autoSelectedIcon,
      alt: 'Car',
    },
    {
      id: 'education',
      value: 'Education',
      displayName: t('PurposeOfLoan.displayNames.education'),
      icon: educationIcon,
      selectedIcon: educationSelectedIcon,
      alt: 'Graduation hat on books',
    },
    {
      id: 'investment',
      value: 'Personal Investment',
      displayName: t('PurposeOfLoan.displayNames.personalInvestment'),
      icon: investmentIcon,
      selectedIcon: investmentSelectedIcon,
      alt: 'Hand holding money and graph',
    },
    {
      id: 'other',
      value: otherCategory,
      displayName: t('PurposeOfLoan.displayNames.other'),
      icon: otherIcon,
      selectedIcon: otherSelectedIcon,
      alt: 'Ellipsis',
    },
  ]
  const isContinueDisabled: boolean =
    !loanPurposeCategory.length ||
    (loanPurposeCategory === otherCategory && loanPurposeOtherReason.length === 0)

  const { mutateAsync: savePurposeOfLoanMutation } = useMutation(savePurposeOfLoan, {
    ...getRetryQueryOptionsWithErrorStep({
      queryName: 'savePurposeOfLoan',
      setGenericErrorPageError,
      setStep,
    }),
  })

  const scrollToIncomplete = () => {
    const errorElement = document.getElementById('errorWrapper')
    errorElement?.scrollIntoView({ behavior: 'smooth' })
  }

  useEffect(() => {
    scrollToIncomplete()
  }, [incompleteError])

  const handleContinue = () => {
    if (isContinueDisabled) {
      setIncompleteError(true)
      return
    }
    purposeProvided(loanPurposeCategory, loanPurposeOtherReason, StepsEnum.LOAN_PURPOSE)
    updateAsyncRequestStatusCallback('savePurposeOfLoan', () =>
      savePurposeOfLoanMutation({
        purpose_of_loan: loanPurposeCategory,
        other_reason: loanPurposeOtherReason,
      }),
    )

    setStep(nextStep)
  }

  const handleTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const purposeOtherReason = e.target.value
    if (purposeOtherReason) {
      setIncompleteError(false)
    }
    updateCachedApplication({ loan_purpose_other_reason: purposeOtherReason })
  }

  const setPurposeCategory = (category: string) => {
    const override: Partial<CachedApplication> = {
      loan_purpose_category: category,
    }
    if (category !== otherCategory) {
      setIncompleteError(false)
      override.loan_purpose_other_reason = ''
    }
    updateCachedApplication(override)
  }

  const purposeItems = purposeData.map(purpose => {
    const isSelected = loanPurposeCategory === purpose.value
    return (
      <PurposeImageListItem
        key={purpose.id}
        data-testid="purposeOfLoanItem"
        onClick={() => setPurposeCategory(purpose.value)}
        data-selected={isSelected}
        sx={{
          borderColor: isSelected ? theme.color.brand1 : theme.color.grey3,
        }}
      >
        {purpose.id == 'auto' ? (
          <AutoIconStyle
            src={loanPurposeCategory == purpose.value ? purpose.selectedIcon : purpose.icon}
            alt={purpose.alt}
          />
        ) : (
          <IconStyle
            src={loanPurposeCategory == purpose.value ? purpose.selectedIcon : purpose.icon}
            alt={purpose.alt}
          />
        )}
        <IconTypography
          color={loanPurposeCategory == purpose.value ? theme.color.brand1 : theme.color.grey9}
          textAlign="center"
        >
          {purpose.displayName}
        </IconTypography>
      </PurposeImageListItem>
    )
  })

  return (
    <DivRoot>
      <Grid container>
        <Grid item xs={12} marginBottom="16px">
          <Typography textAlign="center" variant="h1" marginTop="32px">
            {t('PurposeOfLoan.title')}
          </Typography>
        </Grid>
      </Grid>
      <ImageList cols={2} rowHeight={isMobile ? 88 : 85} gap={16}>
        {purposeItems}
      </ImageList>
      <Grid container marginTop="32px">
        {loanPurposeCategory == 'Personal Investment' && <InvestmentScamWarning />}
        {loanPurposeCategory == otherCategory && (
          <Grid item xs={12} marginBottom="16px">
            <FigTextField
              value={loanPurposeOtherReason}
              onChange={handleTextChange}
              label={t('PurposeOfLoan.otherReasonLabel')}
            />
          </Grid>
        )}
        {incompleteError && (
          <Box id="errorWrapper">
            <Typography variant="body2" color={theme.color.error} marginBottom="16px">
              {t('PurposeOfLoan.completeAllFields')}
            </Typography>
          </Box>
        )}
        <Grid item xs={12} marginTop="32px">
          <PrimaryCTA
            buttonText={t('common.continueLabel')}
            onClick={handleContinue}
            disabled={isContinueDisabled}
          />
        </Grid>
        <Grid item xs={12} display="flex" justifyContent="center" marginTop="16px">
          <BackButton
            onClick={() => {
              purposeOfLoanBackClicked(StepsEnum.LOAN_PURPOSE)
              setStep(previousStep)
            }}
          />
        </Grid>
      </Grid>
    </DivRoot>
  )
}
