import Lottie from 'lottie-react'
import { Grid, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

import loadingFile from 'src/data/lottie/loading-animation.json'

import { DivRoot } from '../DivRoot'

interface LoadingAnimationProps {
  subtitle?: string
}

export const LoadingAnimation = (props: LoadingAnimationProps) => {
  const { subtitle } = props
  const { t } = useTranslation()
  return (
    <DivRoot style={{ minHeight: '500px', display: 'flex', alignItems: 'center' }}>
      <Grid container spacing={0}>
        <Grid
          item
          xs={12}
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
        >
          <Lottie autoplay loop animationData={loadingFile} style={{ width: '300px' }} />

          <Typography textAlign="center" variant="body2" marginTop="24px" display="block">
            {subtitle || t('common.loading')}
          </Typography>
        </Grid>
      </Grid>
    </DivRoot>
  )
}
