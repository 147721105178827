import { styled } from '@mui/material/styles'
import Grid from '@mui/material/Grid'

/**
 *
 */
export const GridImage = styled(Grid)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginBottom: '32px',
})
