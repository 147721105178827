import CloseIcon from '@mui/icons-material/Close'
import { Grid, styled } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { PrimaryCTA } from 'src/components/common/Buttons'
import { DivRoot } from 'src/components/common/DivRoot'
import {
  ErrorContent,
  ErrorImage,
  ErrorImageGrid,
  ErrorTitle,
} from 'src/components/Error/shared/Error'
import { loanExistsImg } from 'src/images'
import { StepsEnum } from 'src/types'
import { existingCustomerLoginClicked } from 'src/utils'
import { Q2_PORTAL_URL } from 'src/utils/constants'

interface RequestLoanAmountIncreaseModalProps {
  handleClose: () => void
  isMobile: boolean
}

const DivStyle = styled(DivRoot)<{ isMobile?: boolean }>(({ isMobile }) => ({
  maxHeight: isMobile ? '100vh' : '90vh',
  overflowX: 'hidden',
  overflowY: 'scroll',
  scrollBehavior: 'smooth',
}))

const ModalCloseButton = styled(Grid)<{ isMobile?: boolean }>(({ isMobile }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  position: 'absolute',
  right: isMobile ? '1.5%' : '3%',
  top: '2%',
  zIndex: '1',
  cursor: 'pointer',
}))

export const RequestLoanAmountIncreaseModal = ({
  handleClose,
  isMobile,
}: RequestLoanAmountIncreaseModalProps) => {
  const { t } = useTranslation()
  return (
    <DivStyle isMobile={isMobile}>
      <Grid container spacing={0}>
        <ModalCloseButton item xs={12} isMobile>
          <CloseIcon onClick={handleClose} />
        </ModalCloseButton>
        <Grid container>
          <Grid item xs={12}>
            <ErrorImageGrid item xs={12}>
              <ErrorImage src={loanExistsImg} alt={t('ApplicationAlreadySettled.alt')} />
            </ErrorImageGrid>

            <Grid item xs={12}>
              <ErrorTitle data-testid="errorTitle">
                {t('CreditRenewals.requestButtonLabel')}
              </ErrorTitle>
            </Grid>

            <Grid item xs={12}>
              <ErrorContent data-testid="errorContent">
                {t('CreditRenewals.requestLoanIncreaseDescription')}
              </ErrorContent>
            </Grid>
            <PrimaryCTA
              href={Q2_PORTAL_URL}
              onClick={() => {
                existingCustomerLoginClicked(
                  t('ApplicationAlreadySettled.loginLabel'),
                  StepsEnum.SETTLED,
                )
              }}
              buttonText={t('ApplicationAlreadySettled.loginLabel')}
            />
          </Grid>
        </Grid>
      </Grid>
    </DivStyle>
  )
}
