import { Box } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { activeCircleIcon, alertCircleIcon, inactiveCircleIcon } from 'src/images'
import { StatusIconEnum } from 'src/portal/components/types'

const iconMap = {
  [StatusIconEnum.ACTIVE]: activeCircleIcon,
  [StatusIconEnum.ALERT]: alertCircleIcon,
  [StatusIconEnum.INACTIVE]: inactiveCircleIcon,
}

/**
 * StatusIcon component displays an icon based on the provided variant.
 * ACTIVE: green circle
 * ALERT: red circle
 * INACTIVE: grey circle
 *
 * @param {StatusIconEnum} variant - The variant of the status icon to display.
 * @returns {JSX.Element} The JSX element representing the status icon.
 */
export const StatusIcon = ({ variant }: { variant: StatusIconEnum }) => {
  const { t } = useTranslation()
  return (
    <Box display={'inline'} mr="4px">
      <img
        src={iconMap[variant]}
        alt={t('Portal.Components.alt.statusIcon')}
        style={{ verticalAlign: 'middle' }}
        width="8px"
        height="8px"
      />
    </Box>
  )
}
