import { debounce } from 'lodash'
import { FC, ReactNode, createContext, useEffect, useRef, useState } from 'react'
import { useMutation } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { captureMessage } from '@sentry/react'
import { addMonths, parseISO } from 'date-fns'

import useLocalStorage from 'src/hooks/useLocalStorage'
import { getRetryQueryOptionsWithErrorStep } from 'src/api/api'
import { useApi } from 'src/hooks'
import {
  BootstrapInfoType,
  CachedApplication,
  CachedApplications,
  CreditRenewalRouteBase,
  StorageKeys,
  OfferItemType,
  AsyncRequestsInProgressType,
  FeatureFlags,
  CreditRenewalDecisionType,
  BankingType,
  RepaymentScheduleRequestType,
  MonthlyScheduleEnum,
  PaymentScheduleEnum,
  SavedRepaymentScheduleType,
} from 'src/types'
import { generateOffers } from 'src/utils/amortization'
// import { DEFAULT_MIN_LOAN_INCREASE_AMOUNT } from 'src/utils/constants'
import { iOS, useThrottledTrackAmountChanged } from 'src/utils/loanAmount'
import useAuthJwtContext from 'src/hooks/useAuthJWTContext'

interface Props {
  children: ReactNode
}

type State = {
  jwtApiKey: string
  isBootstrapSuccess: boolean
  borrowerId: string
  applicationId: string
  bootstrapInfo: BootstrapInfoType
  maxLoanAmount: number
  minLoanAmount: number
  minLoanIncreaseAmount: number
  maxLoanIncreaseAmount: number
  totalLoanAmount: number
  selectedLoanIncreaseAmount: number
  isCRApplicationSettled: boolean
  existingLoanAmount: number
  cachedApplications: CachedApplications
  cachedApplication: CachedApplication
  genericErrorPageError: any
  offerList: OfferItemType[]
  asyncRequestsInProgress: AsyncRequestsInProgressType
  isApplicationExpired: boolean
  isApplicationError: boolean
  isApplicationSettled: boolean
  isApplicationDeclined: boolean
  isAuthorizePaymentStartError: boolean
  isLoanAmountChangeError: boolean
  occupationData: string[]
  creditRenewalDecision: CreditRenewalDecisionType
  bankAccounts: BankingType[]
  flinksLoginId: string
}

type DispatchType = {
  setJwtApiKey: (value: string) => void
  setBootstrapSuccess: (value: boolean) => void
  setBorrowerId: (value: string) => void
  setApplicationId: (value: string) => void
  setBootstrapInfo: (value: BootstrapInfoType) => void
  setStep: (value: string) => void
  setCRStep: (value: string, jwt: string) => void
  setMaxLoanAmount: (value: number) => void
  setMinLoanAmount: (value: number) => void
  setMaxLoanIncreaseAmount: (value: number) => void
  setMinLoanIncreaseAmount: (value: number) => void
  setTotalLoanAmount: (value: number) => void
  handleLoanIncreaseAmountChange: (
    event: Event,
    selectedLoanIncreaseAmount: number | number[],
  ) => void
  setSelectedLoanIncreaseAmount: (value: number) => void
  saveOfferTermMutation: (data: {
    term: number
    loan_amount: number
    estimated_due_amount: number
  }) => void
  generateAndSaveScheduleCR: () => void
  setExistingLoanAmount: (value: number) => void
  fetchData: () => void
  setCachedApplications: (value: CachedApplications) => void
  setCachedApplication: (value: CachedApplication) => void
  setGenericErrorPageError: (value: any) => void
  setOfferList: (value: OfferItemType[]) => void
  updateAsyncRequestStatus: (value: keyof AsyncRequestsInProgressType, inProgress: boolean) => void
  updateAsyncRequestStatusCallback: (
    value: keyof AsyncRequestsInProgressType,
    asyncFunc: () => Promise<any>,
  ) => void
  setApplicationError: (value: boolean) => void
  setApplicationSettled: (value: boolean) => void
  setApplicationDeclined: (value: boolean) => void
  setApplicationExpired: (value: boolean) => void
  setAuthorizePaymentStartError: (value: boolean) => void
  setLoanAmountChangeError: (value: boolean) => void
  updateCachedApplication: (override: CachedApplication) => void
  setOccupationData: (value: string[]) => void
  setCreditRenewalDecision: (value: CreditRenewalDecisionType) => void
  setBankAccounts: (value: BankingType[]) => void
  setFlinksLoginId: (value: string) => void
  clearCachedApplications: () => void
}

export type CreditRenewalContextProps = State & DispatchType

const CreditRenewalContext = createContext<CreditRenewalContextProps>(
  {} as CreditRenewalContextProps,
)

// Create a provider component
const CreditRenewalProvider: FC<Props> = ({ children }) => {
  const { jwtApiKey, setJwtApiKey } = useAuthJwtContext()
  const { generateRepaymentSchedule, saveOfferTerm } = useApi()
  const navigate = useNavigate()
  const { isMaintenance } = useFlags<FeatureFlags>()

  const [isBootstrapSuccess, setBootstrapSuccess] = useState(false)
  const [isApplicationError, setApplicationError] = useState(false)
  const [isApplicationSettled, setApplicationSettled] = useState(false)
  const [isApplicationDeclined, setApplicationDeclined] = useState(false)
  const [isApplicationExpired, setApplicationExpired] = useState(false)
  const [bootstrapInfo, setBootstrapInfo] = useState<BootstrapInfoType>({})
  const [maxLoanAmount, setMaxLoanAmount] = useState(0)
  const [minLoanAmount, setMinLoanAmount] = useState(0)
  const [minLoanIncreaseAmount, setMinLoanIncreaseAmount] = useState(0)
  const [maxLoanIncreaseAmount, setMaxLoanIncreaseAmount] = useState(0)
  const [totalLoanAmount, setTotalLoanAmount] = useState(0)
  const [selectedLoanIncreaseAmount, setSelectedLoanIncreaseAmount] = useState(0)
  const [isCRApplicationSettled, setIsCRApplicationSettled] = useState(false)
  const [borrowerId, setBorrowerId] = useState('')
  const [applicationId, setApplicationId] = useState('')
  const [offerList, setOfferList] = useState<OfferItemType[]>([])
  const throttledTrackAmountChanged = useThrottledTrackAmountChanged()
  const [existingLoanAmount, setExistingLoanAmount] = useState(
    (bootstrapInfo?.active_loan?.original_loan_amount ?? 0) -
      (bootstrapInfo?.active_loan?.repayment_amount ?? 0),
  )
  const [cachedApplications, setCachedApplications, clearCachedApplications] =
    useLocalStorage<CachedApplications>(StorageKeys.CACHED_APPLICATIONS, {})
  const [genericErrorPageError, setGenericErrorPageError] = useState(
    new Error('Error: User navigated to the Generic Error page'),
  )
  const [asyncRequestsInProgress, setAsyncRequestsInProgress] =
    useState<AsyncRequestsInProgressType>({
      getOccupation: false,
      getRepaymentSchedule: false,
      saveOffer: false,
      getOnfidoStatus: false,
      saveOccupation: false,
      savePurposeOfLoan: false,
      savePhoneNumber: false,
      paymentProtectionSelection: false,
    })
  const [isAuthorizePaymentStartError, setAuthorizePaymentStartError] = useState(false)
  const [isLoanAmountChangeError, setLoanAmountChangeError] = useState(false)
  const [occupationData, setOccupationData] = useState<string[]>([])
  const [bankAccounts, setBankAccounts] = useState<BankingType[]>([])
  const [creditRenewalDecision, setCreditRenewalDecision] = useState<CreditRenewalDecisionType>({
    eligible_for_renewal: false,
    decline_reasons: [],
  })
  const [flinksLoginId, setFlinksLoginId] = useState<string>('')

  const repaymentAmount = bootstrapInfo?.active_loan?.repayment_amount || 0

  const cachedApplication: CachedApplication = cachedApplications[applicationId] ?? {}
  const setCachedApplication = (newCachedApp: CachedApplication) => {
    setCachedApplications(prevCachedApps => ({ ...prevCachedApps, [applicationId]: newCachedApp }))
  }
  const updateCachedApplication = (cachedAppOverride: CachedApplication) => {
    setCachedApplications(prevCachedApps => ({
      ...prevCachedApps,
      [applicationId]: { ...prevCachedApps[applicationId], ...cachedAppOverride },
    }))
  }

  useEffect(() => {
    if (!maxLoanIncreaseAmount) {
      return
    }

    if (!totalLoanAmount) {
      // initialize total loan amount and pre-populate offers
      setTotalLoanAmount(existingLoanAmount + maxLoanIncreaseAmount)
      const offers = generateOffers(
        existingLoanAmount + maxLoanIncreaseAmount,
        bootstrapInfo?.application?.apr as number,
      )

      setOfferList(offers)
    }
  }, [existingLoanAmount, maxLoanIncreaseAmount, repaymentAmount])

  const debounceDelayInMilliseconds = 500

  const debouncedSaveOfferTermMutation = useRef(
    debounce((selectedOfferTerm, changeAmount, estimatedDueAmount) => {
      saveOfferTermMutation({
        term: selectedOfferTerm,
        loan_amount: changeAmount,
        estimated_due_amount: estimatedDueAmount,
      })
    }, debounceDelayInMilliseconds),
  )

  const handleLoanIncreaseAmountChange = (event: Event, changeAmount: number | number[]) => {
    const isIOS = iOS()
    const selectedOfferTerm = cachedApplication.selected_offer_term as number

    // Mouse events and touch events seem to both fire for some mobile browsers.
    if (isIOS && event.type === 'mousedown') {
      return
    }

    if (!bootstrapInfo.active_loan) {
      return
    }

    if (typeof changeAmount === 'number') {
      setSelectedLoanIncreaseAmount(changeAmount)

      updateCachedApplication({
        selected_loan_increase_amount: changeAmount,
        saved_repayment_schedule: null, // Reset repayment schedule to monthly as the amount/offers are now changed.
      })
      setTotalLoanAmount(existingLoanAmount + changeAmount)

      // set new offers
      const offers = generateOffers(
        existingLoanAmount + changeAmount,
        bootstrapInfo?.application?.apr as number,
      )

      const estimated_due_amount = offers.filter(o => o.term_length === selectedOfferTerm)[0]
        ?.payment_amount

      setOfferList(offers)

      // Debounce the save offer term mutation
      debouncedSaveOfferTermMutation.current(selectedOfferTerm, changeAmount, estimated_due_amount)

      throttledTrackAmountChanged.current(changeAmount)
    }
  }

  const setCRStep = async (path: string, jwtKey: string) => {
    navigate(`/${CreditRenewalRouteBase}/${jwtKey}/${path}`)
  }

  const setStep = async (path: string) => {
    if (isMaintenance) {
      captureMessage(
        `Application navigation for CreditRenewals disabled when in maintenance mode - attempted: ${path}`,
        'warning',
      )
      return
    }

    navigate(`/${CreditRenewalRouteBase}/${jwtApiKey}/${path}`)
  }

  const generateAndSaveScheduleCR = async (scheduleOptions?: RepaymentScheduleRequestType) => {
    const defaultOptions = {
      schedule: PaymentScheduleEnum.ONCE_A_MONTH,
      monthly_choice: MonthlyScheduleEnum.OTHER,
      first_payment_date: addMonths(parseISO(bootstrapInfo!.system_date!), 1).toISOString(),
    }
    setAuthorizePaymentStartError(false)
    const result = await generateRepaymentSchedule(scheduleOptions || defaultOptions)
    saveScheduleCR(result.data)
  }

  const saveScheduleCR = ({
    schedule,
    monthly_choice,
    withdraw_amount,
    total_estimated_interest,
    first_payment_date,
    payment_cycle_due_date,
  }: SavedRepaymentScheduleType) => {
    updateCachedApplication({
      saved_repayment_schedule: {
        withdraw_amount,
        total_estimated_interest,
        first_payment_date,
        monthly_choice,
        schedule,
        payment_cycle_due_date,
      },
    })
  }

  const { mutate: saveOfferTermMutation } = useMutation(saveOfferTerm, {
    onMutate: () => {
      updateAsyncRequestStatus('saveOffer', true)
    },
    onSuccess: data => {
      updateCachedApplication({
        selected_offer_id: data?.data?.data?.offer_id,
      })
      updateAsyncRequestStatusCallback('getRepaymentSchedule', () =>
        generateAndSaveScheduleCR(
          cachedApplication.saved_repayment_schedule?.schedule &&
            cachedApplication.saved_repayment_schedule?.first_payment_date
            ? {
                schedule: cachedApplication.saved_repayment_schedule?.schedule,
                monthly_choice: MonthlyScheduleEnum.OTHER,
                first_payment_date: cachedApplication.saved_repayment_schedule?.first_payment_date,
              }
            : undefined,
        ),
      )
      updateAsyncRequestStatus('saveOffer', false)
    },
    ...getRetryQueryOptionsWithErrorStep({
      queryName: 'saveOfferTerm',
      setGenericErrorPageError,
      setStep: (step: string) => setCRStep(step, jwtApiKey),
    }),
  })

  const fetchData = async () => {
    await generateAndSaveScheduleCR()
  }

  const updateAsyncRequestStatus = (
    key: keyof AsyncRequestsInProgressType,
    inProgress: boolean,
  ) => {
    setAsyncRequestsInProgress(prevState => ({ ...prevState, [key]: inProgress }))
  }

  const updateAsyncRequestStatusCallback = (
    key: keyof AsyncRequestsInProgressType,
    asyncFunc: () => Promise<any>,
  ) => {
    updateAsyncRequestStatus(key, true)
    asyncFunc()
      .catch(() => {
        // pass, let async func handle its own errors
      })
      .finally(() => updateAsyncRequestStatus(key, false))
  }

  useEffect(() => {
    if (
      bootstrapInfo?.active_loan?.credit_limit &&
      (bootstrapInfo.application?.status === 'SETTLED' ||
        bootstrapInfo.application?.status === 'AUTHORIZED')
    ) {
      setIsCRApplicationSettled(true)
    }
  }, [bootstrapInfo])

  const value: CreditRenewalContextProps = {
    setStep,
    jwtApiKey,
    setJwtApiKey,
    isBootstrapSuccess,
    setBootstrapSuccess,
    bootstrapInfo,
    setBootstrapInfo,
    fetchData,
    setCRStep,
    maxLoanAmount,
    setMaxLoanAmount,
    minLoanAmount,
    setMinLoanAmount,
    minLoanIncreaseAmount,
    setMinLoanIncreaseAmount,
    maxLoanIncreaseAmount,
    setMaxLoanIncreaseAmount,
    totalLoanAmount,
    setTotalLoanAmount,
    handleLoanIncreaseAmountChange,
    selectedLoanIncreaseAmount,
    setSelectedLoanIncreaseAmount,
    saveOfferTermMutation,
    generateAndSaveScheduleCR,
    isCRApplicationSettled,
    existingLoanAmount,
    setExistingLoanAmount,
    borrowerId,
    setBorrowerId,
    applicationId,
    setApplicationId,
    cachedApplication,
    cachedApplications,
    setCachedApplication,
    setCachedApplications,
    updateCachedApplication,
    genericErrorPageError,
    setGenericErrorPageError,
    offerList,
    setOfferList,
    asyncRequestsInProgress,
    updateAsyncRequestStatus,
    updateAsyncRequestStatusCallback,
    isApplicationError,
    setApplicationError,
    isApplicationSettled,
    setApplicationSettled,
    isApplicationExpired,
    setApplicationExpired,
    isApplicationDeclined,
    setApplicationDeclined,
    setAuthorizePaymentStartError,
    isAuthorizePaymentStartError,
    isLoanAmountChangeError,
    setLoanAmountChangeError,
    occupationData,
    setOccupationData,
    creditRenewalDecision,
    setCreditRenewalDecision,
    bankAccounts,
    setBankAccounts,
    flinksLoginId,
    setFlinksLoginId,
    clearCachedApplications,
  }
  return <CreditRenewalContext.Provider value={value}>{children}</CreditRenewalContext.Provider>
}

export { CreditRenewalContext, CreditRenewalProvider }
export default CreditRenewalProvider
