import Grid from '@mui/material/Grid2'

import { LocaleSwitcher } from 'src/components/EndToEnd/landing/Walmart/components/LocaleSwitcher'
import { ImgStyle } from 'src/components/EndToEnd/landing/Walmart/styles'
import { logoImgSvg } from 'src/images'

export const Header = () => {
  return (
    <Grid
      size={{ xs: 12 }}
      height={'44px'}
      container
      m={'20px'}
      justifyContent={'space-between'}
      alignItems={'center'}
      width={'100%'}
    >
      <Grid>
        <ImgStyle
          src={logoImgSvg}
          alt="Fig Financial Logo"
          height="31px"
          data-testid="headerLogo2"
        />
      </Grid>
      <Grid>
        <LocaleSwitcher />
      </Grid>
    </Grid>
  )
}
