import { Grid2, Typography } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { useLocalizedFormatters } from 'src/hooks/useLocalizedFormatters'
import { SpacedRow } from 'src/portal/components/SpacedRow'
import { TooltipContainer } from 'src/portal/components/tooltips/TooltipContainer'
import { SummaryTitle } from 'src/portal/pages/home/summary/content/Titles'
import { RemainingBalanceContent } from 'src/portal/pages/home/summary/content/TooltipContent'

interface TotalAmountContainerProps {
  totalAmount: number
  feesAccrued: number
  interestAccrued: number
  principalRemaining: number
}

export const TotalAmountContainer: React.FC<TotalAmountContainerProps> = ({
  totalAmount,
  feesAccrued,
  interestAccrued,
  principalRemaining,
}) => {
  const { t } = useTranslation()
  const { currencyFormat } = useLocalizedFormatters()

  return (
    <SpacedRow>
      <SummaryTitle />

      <Grid2 display="flex" alignItems="center">
        <Typography variant="h1">{currencyFormat(totalAmount)}</Typography>
        <TooltipContainer
          title={t('Portal.Components.tooltip.title.remainingBalance')}
          content={
            <RemainingBalanceContent
              {...{ feesAccrued, interestAccrued, principalRemaining, totalRemaining: totalAmount }}
            />
          }
        />
      </Grid2>
    </SpacedRow>
  )
}
