import { Trans, useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import { PrimaryCTA } from 'src/components/common/Buttons'
import ErrorPage from 'src/components/Error/shared/ErrorPage'
import { PleaseReachOut } from 'src/components/Error/shared/PleaseReachOut'
import { useCreditRenewalContext } from 'src/hooks'
import { genericErrorImg } from 'src/images'
import { CreditRenewalStepsEnum } from 'src/types'
import {
  bankErrorConnectBankAccountClicked,
  bankErrorContactFigClicked,
  bankErrorLiveChatClicked,
} from 'src/utils'

export default BankConnectError
/**
 *
 */
function BankConnectError() {
  const { t } = useTranslation()
  const screen = CreditRenewalStepsEnum.BANK_CONNECT_ERROR
  const { setCRStep } = useCreditRenewalContext()
  const { jwtApiKey } = useParams()
  const content = (
    <Trans i18nKey="BankConnectError.content">
      We weren’t able to connect to your bank account. Click the ‘Try again’ button, or{' '}
      <PleaseReachOut
        screen={screen}
        liveChatAnalytics={bankErrorLiveChatClicked}
        contactFigAnalytics={bankErrorContactFigClicked}
      />
    </Trans>
  )
  const tryAgainButton = (
    <PrimaryCTA
      onClick={() => {
        bankErrorConnectBankAccountClicked(t('common.retryLabel'), screen)
        setCRStep(CreditRenewalStepsEnum.BANK_AUTO_CONNECT, jwtApiKey!)
      }}
      buttonText={t('common.retryLabel')}
    />
  )
  return (
    <ErrorPage
      image={{ src: genericErrorImg, alt: t('BankConnectError.alt') }}
      title={t('BankConnectError.title')}
      content={content}
      buttons={[tryAgainButton]}
    />
  )
}
