import { useEffect } from 'react'
import Grid from '@mui/material/Grid'
import { addBusinessDays } from 'date-fns'
import { useTranslation } from 'react-i18next'

import { useLocalizedFormatters } from 'src/hooks/useLocalizedFormatters'

import { RepaymentStartHeading } from '../shared/RepaymentStartHeading'
import { RepaymentScheduleList } from '../shared/RepaymentScheduleList'

/**
 *
 */
export function EveryWeekScheduleOptions({
  earliestDate,
  selectedFirstPaymentDate,
  setSelectedFirstPaymentDate,
  setScheduleReady,
}: {
  earliestDate: Date
  selectedFirstPaymentDate: Date | null
  setSelectedFirstPaymentDate: (d: Date) => void
  setScheduleReady: () => void
}) {
  const { t } = useTranslation()
  const { dateFormatCustom } = useLocalizedFormatters()

  const formatWeekday = (date: Date | null) =>
    date ? dateFormatCustom(date, t('PaymentSchedule.EveryWeekScheduleOptions.dateFormat')) : ''

  const formattedSelectedFirstPaymentDate = formatWeekday(selectedFirstPaymentDate)

  const upcomingWeekdays: Date[] = Array.from(Array(5)).map((_, i) =>
    addBusinessDays(earliestDate, i),
  )

  useEffect(() => {
    if (selectedFirstPaymentDate != null) {
      setScheduleReady()
    }
  }, [selectedFirstPaymentDate])

  const weekOptions = upcomingWeekdays.map(date => {
    const formattedDate = formatWeekday(date)
    return {
      onClick: () => setSelectedFirstPaymentDate(date),
      isSelected: formattedDate === formattedSelectedFirstPaymentDate,
      primaryText: formattedDate,
    }
  })

  return (
    <Grid container marginTop="24px" id="startWeekWrapper">
      <RepaymentStartHeading />
      <Grid container spacing="0px" marginBottom="16px">
        <Grid item xs={12}>
          <RepaymentScheduleList options={weekOptions} />
        </Grid>
      </Grid>
    </Grid>
  )
}
