import { useFlags } from 'launchdarkly-react-client-sdk'
import { useTranslation } from 'react-i18next'

import { LoadingAnimation } from 'src/components/common/animations/Loading'
import { useOriginationContext } from 'src/hooks'
import {
  FeatureFlags,
  OnFidoDecisionStatusEnum,
  OnfidoReviewReasonsEnum,
  PIIMismatchReasonsEnum,
  StepsEnum,
} from 'src/types'

/**
 * This component is used to wait for a response from Onfido.
 * It will show a loading spinner until a response is received.
 * It will redirect to approved, review, or declined pages based on the response.
 * [SC-15250]: ALLOW USER TO CONTINUE IF ONFIDO RETURNS PII MISMATCH (retain current behaviour)
 * Flow details: https://www.figma.com/file/fLvJFubP1xOXhejVVyebRk/Onfido-optimization
 */
function KYCDecisionLoader() {
  const { enablePiiRetry } = useFlags<FeatureFlags>()

  const { t } = useTranslation()
  const { onfidoDecision, setStep } = useOriginationContext()
  const { status, isApplicationDeclined, reviewReason, attemptCount, piiMismatchReason } =
    onfidoDecision

  if (isApplicationDeclined) {
    setStep(StepsEnum.DECLINED)
  }

  if (status) {
    switch (status) {
      case OnFidoDecisionStatusEnum.APPROVED:
        setStep(StepsEnum.PAYMENT_METHOD_SELECT)
        break
      case OnFidoDecisionStatusEnum.REVIEW:
        if (attemptCount == 3) {
          // wait for decline screen to show
          return <LoadingAnimation subtitle={t('KYCLoading.title')} />
        }
        if (
          reviewReason === OnfidoReviewReasonsEnum.PII_MISMATCH &&
          piiMismatchReason === PIIMismatchReasonsEnum.NOT_REVIEWED
        ) {
          setStep(StepsEnum.KYC_PII_RETRY)
          break
        }
        if (
          attemptCount == 2 &&
          (!reviewReason ||
            (reviewReason === OnfidoReviewReasonsEnum.PII_MISMATCH &&
              piiMismatchReason !== PIIMismatchReasonsEnum.NOT_REVIEWED))
        ) {
          // wait for reviewReason to return for 2nd attempt since PII mismatch only gets two tries
          return <LoadingAnimation subtitle={t('KYCLoading.title')} />
        }
        setStep(StepsEnum.KYC_VERIFY)
        break
      case OnFidoDecisionStatusEnum.DECLINED:
        setStep(StepsEnum.DECLINED)
        break
    }
  } else if (!enablePiiRetry) {
    if (attemptCount === 1) {
      if (reviewReason === OnfidoReviewReasonsEnum.PII_MISMATCH) {
        // [SC-15250]: ALLOW USER TO CONTINUE IF ONFIDO RETURNS PII MISMATCH (retain current behaviour)
        setStep(StepsEnum.ORDER_FINISH)
      } else {
        return <LoadingAnimation subtitle={t('KYCLoading.finalStepTitle')} />
      }
    } else {
      if (reviewReason === OnfidoReviewReasonsEnum.PII_MISMATCH) {
        // [SC-15250]: Keep user in a loading state indefinitely (retain current behaviour)
        return <LoadingAnimation subtitle={t('Finish.loading')} />
      }
    }
  }

  return <LoadingAnimation subtitle={t('KYCLoading.title')} />
}

export default KYCDecisionLoader
