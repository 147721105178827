import { Box, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { useCreditRenewalContext } from 'src/hooks'
import { useLocalizedFormatters } from 'src/hooks/useLocalizedFormatters'
import { lockIcon } from 'src/images'
import { figColors } from 'src/themes'
import { CreditRenewalStepsEnum } from 'src/types'
import { parseUrl } from 'src/utils'

interface LoanAmountIncreaseRequestHeadingProps {
  title: string
  subtitle: string
}

const LoanAmountIncreaseRequestHeading = ({
  title,
  subtitle,
}: LoanAmountIncreaseRequestHeadingProps) => {
  return (
    <Box textAlign="center">
      <Typography variant="title">{title}</Typography>
      <Box mt="16px" mb="48px">
        <img
          src={lockIcon}
          alt="Lock Icon"
          style={{ display: 'inline', verticalAlign: 'middle' }}
          width={'20px'}
          height={'20px'}
        />
        <Typography
          variant="body1"
          color={figColors.black}
          display={'inline'}
          style={{ verticalAlign: 'middle', marginLeft: '8px' }}
        >
          {subtitle}
        </Typography>
      </Box>
    </Box>
  )
}

interface CongratulationsHeadingProps {
  text: string
  amount: string
}

const CongratulationsHeading = ({ text, amount }: CongratulationsHeadingProps) => {
  return (
    <Box textAlign="center">
      <Box mt="16px" mb="48px">
        <Typography variant="h1" display={'inline'}>
          {text}
        </Typography>
        <Typography variant="h1" display={'inline'} fontWeight="bold">
          {amount}!
        </Typography>
      </Box>
    </Box>
  )
}

export const AboveContainerContent = () => {
  const { currentStep } = parseUrl()
  const { t } = useTranslation()
  const { bootstrapInfo } = useCreditRenewalContext()
  const { currencyFormat } = useLocalizedFormatters()
  if (
    currentStep.includes(CreditRenewalStepsEnum.CONFIRM_INFORMATION) ||
    currentStep.includes(CreditRenewalStepsEnum.LOAN_DETAILS)
  ) {
    const loanIncreaseAmountLimit = bootstrapInfo?.borrower?.available_credit
    const displayAmount = currencyFormat(loanIncreaseAmountLimit, 2)
    return (
      <>
        <LoanAmountIncreaseRequestHeading
          title={t('CreditRenewals.Eligibility.title')}
          subtitle={t('CreditRenewals.Eligibility.subtitle')}
        />
        <CongratulationsHeading
          text={t('CreditRenewals.ConfirmInformation.aboveContainerContent')}
          amount={displayAmount}
        />
      </>
    )
  }
  return (
    <LoanAmountIncreaseRequestHeading
      title={t('CreditRenewals.Eligibility.title')}
      subtitle={t('CreditRenewals.Eligibility.subtitle')}
    />
  )
}
