/**
 * Generated by orval v7.2.0 🍺
 * Do not edit manually.
 * NinjaAPI
 * OpenAPI spec version: 1.0.0
 */
import type { AxiosRequestConfig, AxiosResponse } from 'axios'
import axios from 'axios'

import type {
  BorrowerDetailSchema,
  DisbursalHistoryResponseSchema,
  LoanQueryResponseSchema,
  PrequalificationAffliateResponse,
  PrequalificationEmbeddedResponse,
  PrequalificationRequest,
  RepaymentScheduleResponseSchema,
  TransactionHistoryResponseSchema,
} from './api.schemas'

export const getNinjaAPI = () => {
  /**
   * @summary Get Borrower Detail
   */
  const portalApiGetBorrowerDetail = <TData = AxiosResponse<BorrowerDetailSchema>>(
    options?: AxiosRequestConfig,
  ): Promise<TData> => {
    return axios.get(`/v1/portal/borrower`, options)
  }

  /**
 * Retrieves loan information for a given borrower from Salesforce by performing a SOQL query.

Args:
    request: The request object containing authentication and borrower information.

Returns:
    A tuple containing the HTTP status code and a dictionary with loan information.

Raises:
    NinjaApiError: If the borrower is not found, there is a loan query error, no record is found, or there is a parse error.
 * @summary Get Loan Detail
 */
  const portalApiGetLoanDetail = <TData = AxiosResponse<LoanQueryResponseSchema>>(
    options?: AxiosRequestConfig,
  ): Promise<TData> => {
    return axios.get(`/v1/portal/loan`, options)
  }

  /**
   * @summary Get Loan Repayment Schedule
   */
  const portalApiGetLoanRepaymentSchedule = <
    TData = AxiosResponse<RepaymentScheduleResponseSchema[]>,
  >(
    loanId: string,
    options?: AxiosRequestConfig,
  ): Promise<TData> => {
    return axios.get(`/v1/portal/loan/${loanId}/repayment_schedule`, options)
  }

  /**
   * @summary Get Loan Transaction History
   */
  const portalApiGetLoanTransactionHistory = <
    TData = AxiosResponse<TransactionHistoryResponseSchema[]>,
  >(
    loanId: string,
    options?: AxiosRequestConfig,
  ): Promise<TData> => {
    return axios.get(`/v1/portal/loan/${loanId}/transaction_history`, options)
  }

  /**
   * @summary Get Disbursal History
   */
  const portalApiGetDisbursalHistory = <TData = AxiosResponse<DisbursalHistoryResponseSchema[]>>(
    loanId: string,
    options?: AxiosRequestConfig,
  ): Promise<TData> => {
    return axios.get(`/v1/portal/loan/${loanId}/disbursal_history`, options)
  }

  /**
   * @summary Create Prequalification Request
   */
  const personalLoansV2ApiCreatePrequalificationRequest = <
    TData = AxiosResponse<PrequalificationEmbeddedResponse>,
  >(
    prequalificationRequest: PrequalificationRequest,
    options?: AxiosRequestConfig,
  ): Promise<TData> => {
    return axios.post(`/v1/personal_loans_v2/prequal`, prequalificationRequest, options)
  }

  /**
   * @summary Create Affliate Prequalification Request
   */
  const personalLoansV2ApiCreateAffliatePrequalificationRequest = <
    TData = AxiosResponse<PrequalificationAffliateResponse>,
  >(
    prequalificationRequest: PrequalificationRequest,
    options?: AxiosRequestConfig,
  ): Promise<TData> => {
    return axios.post(`/v1/personal_loans_v2/e2e/prequal`, prequalificationRequest, options)
  }

  return {
    portalApiGetBorrowerDetail,
    portalApiGetLoanDetail,
    portalApiGetLoanRepaymentSchedule,
    portalApiGetLoanTransactionHistory,
    portalApiGetDisbursalHistory,
    personalLoansV2ApiCreatePrequalificationRequest,
    personalLoansV2ApiCreateAffliatePrequalificationRequest,
  }
}
export type PortalApiGetBorrowerDetailResult = AxiosResponse<BorrowerDetailSchema>
export type PortalApiGetLoanDetailResult = AxiosResponse<LoanQueryResponseSchema>
export type PortalApiGetLoanRepaymentScheduleResult = AxiosResponse<
  RepaymentScheduleResponseSchema[]
>
export type PortalApiGetLoanTransactionHistoryResult = AxiosResponse<
  TransactionHistoryResponseSchema[]
>
export type PortalApiGetDisbursalHistoryResult = AxiosResponse<DisbursalHistoryResponseSchema[]>
export type PersonalLoansV2ApiCreatePrequalificationRequestResult =
  AxiosResponse<PrequalificationEmbeddedResponse>
export type PersonalLoansV2ApiCreateAffliatePrequalificationRequestResult =
  AxiosResponse<PrequalificationAffliateResponse>
