import { Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { styled } from '@mui/material/styles'
import { Tooltip } from 'react-tooltip'

import { useOriginationContext } from 'src/hooks'
import theme from 'src/themes'
import { ReactComponent as infoIconSvg } from 'src/images/info.svg'

const InfoIcon = styled(infoIconSvg)({
  width: 14,
  height: 14,
  position: 'relative',
  top: '-4px',
  fontWeight: 'bold',
})

export default function PaymentProtectionInfo({ amount }: { amount: string }) {
  const { bootstrapInfo } = useOriginationContext()
  const { t } = useTranslation()
  if (!bootstrapInfo?.application?.payment_protection_applied) {
    return amount
  }

  return (
    <>
      <span data-tooltip-id="paymentProtectionInfo">
        {amount}
        <InfoIcon />
      </span>
      <Tooltip
        style={{
          padding: 16,
          background: theme.color.white,
          color: theme.color.grey7,
          borderRadius: 10,
          boxShadow: theme.shadows[2],
          maxWidth: 335,
        }}
        id="paymentProtectionInfo"
        place="bottom"
        noArrow
        opacity={1}
        offset={3}
      >
        <Typography
          sx={{
            fontSize: 14,
          }}
          textAlign="left"
        >
          {t('PaymentSchedule.PaymentScheduleCalendar.tooltip')}
        </Typography>
      </Tooltip>
    </>
  )
}
