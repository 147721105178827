import { useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'

export function TrustpilotMicroCombo() {
  const ref = useRef(null)
  useEffect(() => {
    if ((window as any).Trustpilot) {
      ;(window as any).Trustpilot.loadFromElement(ref.current, true)
    }
  }, [])
  const {
    i18n: { language },
  } = useTranslation()
  const langCode = language === 'fr' ? 'fr-CA' : 'en-US'

  return (
    <div
      ref={ref} // We need a reference to this element to load the TrustBox in the effect.
      className="trustpilot-widget" // Renamed this to className.
      data-locale={langCode}
      data-template-id="5419b6ffb0d04a076446a9af"
      data-businessunit-id="62d05fc7beb77e5c5fc0238b"
      data-style-height="20px"
      data-style-width="100%"
      data-theme="light"
    >
      <a href="https://www.trustpilot.com/review/fig.ca" target="_blank" rel="noopener noreferrer">
        Trustpilot
      </a>
    </div>
  )
}
