import { useTranslation } from 'react-i18next'

import {
  ProgressStep,
  ProgressStepHint,
  ProgressStepsContainer,
  StepTab,
} from 'src/components/ProgressBar/styles'
import { useOriginationContext } from 'src/hooks'
import { CreditRenewalProgressEnum, CreditRenewalStepsEnum, StepsEnum } from 'src/types'
import { getAnalyticsCallback, parseUrl } from 'src/utils'

const stepToProgressEnum: {
  [key in CreditRenewalStepsEnum]: CreditRenewalProgressEnum | null
} = {
  [CreditRenewalStepsEnum.ELIGIBILITY]: CreditRenewalProgressEnum.ELIGIBILITY,
  [CreditRenewalStepsEnum.DECLINED]: null,
  [CreditRenewalStepsEnum.CONFIRM_INFORMATION]: CreditRenewalProgressEnum.CONFIRM_INFORMATION,
  [CreditRenewalStepsEnum.LOAN_DETAILS]: CreditRenewalProgressEnum.LOAN_DETAILS,
  [CreditRenewalStepsEnum.PAYMENT_PROTECTION]: CreditRenewalProgressEnum.PAYMENT_PROTECTION,
  [CreditRenewalStepsEnum.REPAYMENT_SCHEDULE]: null,
  [CreditRenewalStepsEnum.REVIEW]: CreditRenewalProgressEnum.REVIEW,
  [CreditRenewalStepsEnum.FINISH]: null,
  [CreditRenewalStepsEnum.ERROR]: null,
  [CreditRenewalStepsEnum.BANK_CONNECT_ERROR]: null,
  [CreditRenewalStepsEnum.BANK_AUTO_CONNECT]: null,
  [CreditRenewalStepsEnum.PAYMENT_METHOD_SELECT]: null,
  [CreditRenewalStepsEnum.FLINKS_PAYMENT_CHECK]: null,
  [CreditRenewalStepsEnum.BANK_SUCCESS]: null,
}
/**
 *
 */
export default function ProgressBar() {
  const { setStep, bootstrapInfo } = useOriginationContext()
  const { t } = useTranslation()
  const isBootstrapSet = Object.keys(bootstrapInfo ?? {}).length !== 0
  if (!isBootstrapSet) {
    return <ProgressStepsContainer />
  }

  const { currentStep } = parseUrl()

  const progressStep: CreditRenewalProgressEnum | null =
    stepToProgressEnum[currentStep.split('/')[1] as CreditRenewalStepsEnum]

  const CREDIT_RENEWAL_STEPS: CreditRenewalProgressEnum[] = [
    CreditRenewalProgressEnum.CONFIRM_INFORMATION,
    CreditRenewalProgressEnum.LOAN_DETAILS,
    CreditRenewalProgressEnum.PAYMENT_PROTECTION,
    CreditRenewalProgressEnum.REVIEW,
  ]

  const orderedSteps: CreditRenewalProgressEnum[] = CREDIT_RENEWAL_STEPS.filter(
    step => step !== null,
  ) as CreditRenewalProgressEnum[]

  const stepIndex = progressStep ? orderedSteps.indexOf(progressStep) : -1

  const progressEnumInfo: {
    [key in CreditRenewalProgressEnum]: { displayName: string; step: string }
  } = {
    [CreditRenewalProgressEnum.ELIGIBILITY]: {
      displayName: t('ProgressBar.eligibility'),
      step: CreditRenewalStepsEnum.ELIGIBILITY,
    },
    [CreditRenewalProgressEnum.CONFIRM_INFORMATION]: {
      displayName: t('ProgressBar.confirmInformation'),
      step: CreditRenewalStepsEnum.CONFIRM_INFORMATION,
    },
    [CreditRenewalProgressEnum.REVIEW]: {
      displayName: t('ProgressBar.review'),
      step: CreditRenewalStepsEnum.REVIEW,
    },
    [CreditRenewalProgressEnum.LOAN_DETAILS]: {
      displayName: t('ProgressBar.loanDetails'),
      step: CreditRenewalStepsEnum.LOAN_DETAILS,
    },
    [CreditRenewalProgressEnum.REPAYMENT_SCHEDULE]: {
      displayName: t('ProgressBar.repaymentSchedule'),
      step: CreditRenewalStepsEnum.REPAYMENT_SCHEDULE,
    },
    [CreditRenewalProgressEnum.PAYMENT_PROTECTION]: {
      displayName: t('ProgressBar.paymentProtectionCR'),
      step: CreditRenewalStepsEnum.PAYMENT_PROTECTION,
    },
  }
  return (
    <ProgressStepsContainer>
      {progressStep &&
        orderedSteps.map((progEnum, i) => {
          const { displayName, step } = progressEnumInfo[progEnum]
          const isTodo = stepIndex < i
          return (
            <ProgressStep
              key={step}
              data-testid="progressStep"
              data-step-todo={isTodo}
              onClick={() => {
                if (!isTodo && i !== stepIndex) {
                  getAnalyticsCallback(`ProgressBar / Navigating to ${displayName}`)(
                    displayName,
                    currentStep as StepsEnum,
                  )
                  setStep(step)
                }
              }}
            >
              <StepTab className="stepTab" />
              <ProgressStepHint className="stepHint">{`${i + 1}. ${displayName}`}</ProgressStepHint>
            </ProgressStep>
          )
        })}
    </ProgressStepsContainer>
  )
}
