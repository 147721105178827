import Grid from '@mui/material/Grid'
import { styled } from '@mui/material/styles'
import { Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { DivRoot } from 'src/components/common/DivRoot'
import { GridImage } from 'src/components/common/GridImage'
import { verificationSuccessImg } from 'src/images'
import { useOriginationContext } from 'src/hooks'
import { StepsEnum } from 'src/types'
import { kycSuccessContinueClicked } from 'src/utils'
import { PrimaryCTA } from 'src/components/common/Buttons'

const ImgStyle = styled('img')({
  width: '80px',
  height: '80px',
})

export interface KYCSuccessProps {
  nextStepOnSuccess: StepsEnum
}

export default KYCSuccess
/**
 *
 */
function KYCSuccess({ nextStepOnSuccess }: KYCSuccessProps) {
  const { t } = useTranslation()
  const { setStep } = useOriginationContext()

  return (
    <DivRoot>
      <Grid container>
        <Grid item xs={12}>
          <GridImage item xs={12}>
            <ImgStyle src={verificationSuccessImg} alt={t('KYCSuccess.alt')} />
          </GridImage>

          <Grid item xs={12}>
            <Typography variant="h1" textAlign="center" width="370px" margin="auto">
              {t('KYCSuccess.content')}
            </Typography>
            <Grid item xs={12} marginTop="32px">
              <PrimaryCTA
                onClick={() => {
                  kycSuccessContinueClicked(t('common.continueLabel'), StepsEnum.KYC_SUCCESS)
                  setStep(nextStepOnSuccess)
                }}
                buttonText={t('common.continueLabel')}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </DivRoot>
  )
}
