import { createFilterOptions } from '@mui/material'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useCreditRenewalContext } from 'src/hooks'
import i18n from 'src/locales'
import enTransalation from 'src/locales/en/translation'
import { EmploymentStatus, UseOccupationInputProps } from 'src/types'
import { isQCResident } from 'src/utils/borrower'

export const useOccupationInput = ({
  employmentData,
  borrower,
  handleOccupationEmploymentStatus,
  handleOccupationEmployerName,
  handleOccupationInput,
  handleOccupationIncomeSource,
  handleOccupationStartDate,
}: UseOccupationInputProps) => {
  const { occupationData } = useCreditRenewalContext()

  const [occupation, setOccupation] = useState(employmentData.occupation)
  const [occupationLanguage, setOccupationLanguage] = useState(employmentData.occupation_language)
  const [employmentStatus, setEmploymentStatus] = useState<EmploymentStatus>(
    employmentData.employment_status,
  )
  const [employer, setEmployer] = useState(employmentData.employer || '')
  const [incomeSource, setIncomeSource] = useState(employmentData.income_source || '')
  const [employmentStartDate, setEmploymentStartDate] = useState<string>(
    employmentData?.employment_start_date || '',
  )

  const { t } = useTranslation()
  const getIsEmployed = (employmentStatus: string) =>
    [EmploymentStatus.EMPLOYED, EmploymentStatus.SELF_EMPLOYED].some(
      status => status === employmentStatus,
    )

  const isEmployed = getIsEmployed(employmentStatus)
  const isEmployedQC = isEmployed && isQCResident(borrower)
  const isFromQC = isQCResident(borrower)
  const employmentStatuses = [
    EmploymentStatus.EMPLOYED,
    EmploymentStatus.SELF_EMPLOYED,
    EmploymentStatus.UNEMPLOYED,
    EmploymentStatus.RETIRED,
    EmploymentStatus.STUDENT,
    EmploymentStatus.DISABILITY,
  ]

  const filter = createFilterOptions<OccupationOptionType>()

  type OccupationOptionType = {
    inputValue: string
    title?: string
  }
  const employmentStatusToTransKey: {
    [key in EmploymentStatus]: keyof (typeof enTransalation)['Occupation']['employmentStatuses']
  } = {
    [EmploymentStatus.EMPLOYED]: 'employed',
    [EmploymentStatus.SELF_EMPLOYED]: 'selfEmployed',
    [EmploymentStatus.UNEMPLOYED]: 'unemployed',
    [EmploymentStatus.RETIRED]: 'retired',
    [EmploymentStatus.STUDENT]: 'student',
    [EmploymentStatus.DISABILITY]: 'disability',
  }

  const handleSetEmploymentStatus = (newEmploymentStatus: EmploymentStatus) => {
    setEmploymentStatus(newEmploymentStatus)
    const isNowEmployed = getIsEmployed(newEmploymentStatus)

    // employment status changed if we didn't have an employment status previously or if isEmployed has been updated
    const isEmploymentStatusChanged =
      !employmentStatus || isEmployed !== isNowEmployed || newEmploymentStatus !== employmentStatus

    if (isEmploymentStatusChanged) {
      if (isNowEmployed) {
        setOccupation('')
        setOccupationLanguage('')
        setIncomeSource('')
      } else {
        setOccupation(newEmploymentStatus) // set occupation to employment status for unemployed
        setEmployer('')
        setEmploymentStartDate('')
      }
    }
  }

  const getEmploymentStatus = (status: EmploymentStatus) => {
    return t(`Occupation.employmentStatuses.${employmentStatusToTransKey[status]}`)
  }

  useEffect(
    function resetOccupationAfterLanguageChange() {
      if (occupationLanguage && i18n.resolvedLanguage !== occupationLanguage) {
        setOccupation('')
        setOccupationLanguage('')
      }
    },
    [i18n.resolvedLanguage],
  )

  // update applicantData when occupation fields change
  useEffect(() => {
    handleOccupationInput(occupation)
  }, [occupation])
  useEffect(() => {
    handleOccupationEmploymentStatus(employmentStatus)
  }, [employmentStatus])
  useEffect(() => {
    handleOccupationEmployerName(employer)
  }, [employer])
  useEffect(() => {
    handleOccupationIncomeSource(incomeSource)
  }, [incomeSource])
  useEffect(() => {
    handleOccupationStartDate(employmentStartDate)
  }, [employmentStartDate])

  const occupationStatusProps = {
    employmentStatus,
    employmentStatuses,
    getEmploymentStatus,
    handleSetEmploymentStatus,
  }

  const occupationEmployerProps = {
    isEmployed,
    occupation,
    occupationData,
    setOccupation,
    filter,
    employer,
    setEmployer,
    isEmployedQC,
    employmentStartDate,
    setEmploymentStartDate,
  }

  const occupationIncomeSourceProps = {
    employmentStatus,
    isEmployed,
    isFromQC,
    incomeSource,
    setIncomeSource,
  }

  return { occupationStatusProps, occupationEmployerProps, occupationIncomeSourceProps }
}
