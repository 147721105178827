import { FigAutocompleteAddress } from 'src/types'

/*
 *
 */
export function parseGoogleAddressParts(
  place?: google.maps.places.PlaceResult,
): FigAutocompleteAddress {
  const defaultAddress: FigAutocompleteAddress = {
    formattedAddress: '',
    address_1: '',
    address_2: '',
    postal_code: '',
    province_code: '',
    city: '',
    country_code: '',
  }
  if (!place || !place.address_components) {
    return { ...defaultAddress }
  }
  const response: FigAutocompleteAddress = {
    ...defaultAddress,
    formattedAddress: place.formatted_address ?? '',
  }

  const address_components = place.address_components as google.maps.GeocoderAddressComponent[]

  // See https://developers.google.com/maps/documentation/places/web-service/details#Place for place interface context
  for (const component of address_components) {
    const componentType = component.types[0]

    switch (componentType) {
      // Address number e.g. <123> Main Street
      case 'street_number': {
        response.address_1 = `${component.long_name} ${response.address_1 ?? ''}`
        break
      }

      // Address street name e.g. 123 <Main Street>
      case 'route': {
        response.address_1 += component.long_name
        break
      }

      case 'subpremise': {
        response.address_2 += component.long_name
        break
      }

      case 'postal_code': {
        response.postal_code = `${component.long_name}${response.postal_code ?? ''}`
        break
      }

      case 'postal_code_prefix': {
        response.postal_code = `${component.long_name}`
        break
      }

      case 'postal_code_suffix': {
        if (component.long_name) {
          response.postal_code = `${response.postal_code}-${component.long_name}`
        }
        break
      }

      // County name
      case 'administrative_area_level_2': {
        if (address_components.some(a => a.types.includes('locality'))) {
          // If we have a city in the address components, skip county
          break
        }
        response.city = component.long_name
        break
      }

      // City name
      case 'locality':
        response.city = component.long_name
        break

      // Province code
      case 'administrative_area_level_1': {
        response.province_code = component.short_name
        break
      }

      case 'country':
        response.country_code = component.short_name
        break
    }
  }

  return response
}
