import { useTranslation } from 'react-i18next'

import { SupportedLanguage } from 'src/locales'
import { BankingType } from 'src/types'
import {
  dateISOFormat,
  dateFormatCustom as dateFormatCustomWithLanguage,
  currencyFormatFromCents as currencyFormatFromCentsWithLanguage,
  currencyFormatFromStr as currencyFormatFromStrWithLanguage,
  currencyFormat as currencyFormatWithLanguage,
  percentFormat as percentFormatWithLanguage,
} from 'src/utils/format'

/*
 * make sure this does not include OriginationContext / PrequalificationContext logic,
 * this used in both origination and e2e
 */
export const useLocalizedFormatters = () => {
  const { t, i18n } = useTranslation()
  const language = i18n.resolvedLanguage as SupportedLanguage

  return {
    dateFormat: dateISOFormat,

    dateFormatCustom: (date: Date | null, formatStr: string) =>
      dateFormatCustomWithLanguage({ date, formatStr, language }),

    currencyFormatFromCents: (amountInCents: number | undefined, minFractionDigits = 2) =>
      currencyFormatFromCentsWithLanguage(amountInCents, minFractionDigits, language),

    currencyFormatFromStr: (amount: string | undefined, minFractionDigits = 2) =>
      currencyFormatFromStrWithLanguage(amount, minFractionDigits, language),

    currencyFormat: (amount: number | undefined, minFractionDigits = 2) =>
      currencyFormatWithLanguage(amount, minFractionDigits, language),

    percentFormat: (amount: number | undefined, minFractionDigits = 2) =>
      percentFormatWithLanguage(amount, minFractionDigits, language),

    bankFormat: (bank: BankingType) =>
      `${bank.institution_name} ${t(`common.${bank.account_type.toLowerCase()}`, '')} ${
        bank.account_number
      }`,
  }
}
