import { useState } from 'react'
import { useQuery } from 'react-query'
import * as Sentry from '@sentry/react'
import { useTranslation } from 'react-i18next'

import { LoadingAnimation } from 'src/components/common/animations/Loading'
import { useOriginationContext, useApi } from 'src/hooks'
import { FlinksPaymentCheckResponseDataType } from 'src/types/api'
import { StepsEnum } from 'src/types'
import { ENVIRONMENT } from 'src/utils/constants'

export default FlinksPaymentCheck
/**
 *
 */
function FlinksPaymentCheck() {
  const { flinksLoginId, setStep } = useOriginationContext()
  const { t } = useTranslation()
  const [failureCount, setFailureCount] = useState(0)
  const { flinksPaymentCheck } = useApi()
  // Poll for 5 minutes to see if the flinks webhook, and q2 payment saving
  // and linking are done
  const maxFailures = 60 * 5

  useQuery<FlinksPaymentCheckResponseDataType>(
    ['flinksPaymentCheck', flinksLoginId],
    () => flinksPaymentCheck(flinksLoginId),
    {
      refetchInterval: failureCount <= maxFailures ? 1000 : false,
      onSuccess: (result: FlinksPaymentCheckResponseDataType): void => {
        if (result.data.is_declined) {
          setStep(StepsEnum.DECLINED)
        } else if (!result.data.is_pii_match) {
          setStep(StepsEnum.KYC_FLINKS_FAIL)
        } else if (!result.data.is_bank_account_valid && result.data.is_failed) {
          setStep(StepsEnum.KYC_FLINKS_INVALID_BANK_ACCOUNT)
        } else if (result.data.is_failed) {
          setStep(StepsEnum.BANK_CONNECT_ERROR)
        } else if (result.data.is_complete) {
          setStep(StepsEnum.BANK_SUCCESS)
        } else {
          if (failureCount < maxFailures) {
            setFailureCount(failureCount + 1)
          } else {
            Sentry.captureMessage(
              `Flinks bank connect did not complete within 5 minutes ${ENVIRONMENT}`,
              'warning',
            )
            setStep(StepsEnum.BANK_CONNECT_ERROR)
          }
        }
      },
    },
  )

  return <LoadingAnimation subtitle={t('FlinksPaymentCheck.loading')} />
}
