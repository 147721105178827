import { Grid, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useContext, useState } from 'react'

import { StepsEnum } from 'src/types'
import { PrequalificationContext } from 'src/contexts'
import { DivRoot } from 'src/components/common/DivRoot'
import {
  BiometricConsent,
  GeneralConsent,
  RightToAccessConsent,
} from 'src/components/QCConsents/QCConsents'
import { PrimaryCTA } from 'src/components/common/Buttons'

export default Consents
/**
 *
 */
function Consents() {
  const { t } = useTranslation()
  const { setStepE2E, setIsQCConsentGiven, isQCConsentGiven } = useContext(PrequalificationContext)

  const [acceptBiometricConsent, setAcceptBiometricConsent] = useState(isQCConsentGiven)

  return (
    <DivRoot>
      <Typography textAlign="center" variant="h1">
        {t('QCConsents.title')}
      </Typography>
      <Grid>
        <BiometricConsent {...{ acceptBiometricConsent, setAcceptBiometricConsent }} />
        <GeneralConsent />
        <RightToAccessConsent />
      </Grid>
      <Grid item xs={12}>
        <PrimaryCTA
          disabled={!acceptBiometricConsent}
          onClick={() => {
            setStepE2E(StepsEnum.PREQUALIFICATION)
            setIsQCConsentGiven(true)
          }}
          buttonText={t('common.continueLabel')}
        />
      </Grid>
    </DivRoot>
  )
}
