import { useEffect } from 'react'
import { useTranslation, Trans } from 'react-i18next'

import { PrimaryCTA } from 'src/components/common/Buttons'
import { PleaseReachOut } from 'src/components/Error/shared/PleaseReachOut'
import { genericErrorImg } from 'src/images'
import {
  captureExceptionHelper,
  pageNotFoundTryAgainClicked,
  pageNotFoundLiveChatClicked,
  pageNotFoundContactFigClicked,
} from 'src/utils'
import { StepsEnum } from 'src/types'
import { useOriginationContext } from 'src/hooks'
import ErrorPage from 'src/components/Error/shared/ErrorPage'

export default PageNotFound
/**
 *
 */
function PageNotFound({
  tryAgainScreen = StepsEnum.LOAN_AMOUNT,
  setStepOverride,
  currentLocation = StepsEnum.PAGE_NOT_FOUND,
}: {
  tryAgainScreen?: StepsEnum
  setStepOverride?: (step: string) => void
  currentLocation?: string
}) {
  const screen = StepsEnum.PAGE_NOT_FOUND
  const { setStep: setStepOrigination } = useOriginationContext()
  const setStep = setStepOverride || setStepOrigination

  const { t } = useTranslation()

  const content = (
    <Trans i18nKey="PageNotFound.content">
      The link you requested was not found. Please return to the loan application or if you have
      further questions,{' '}
      <PleaseReachOut
        screen={screen}
        liveChatAnalytics={pageNotFoundLiveChatClicked}
        contactFigAnalytics={pageNotFoundContactFigClicked}
      />
    </Trans>
  )
  const tryAgainButton = (
    <PrimaryCTA
      onClick={() => {
        pageNotFoundTryAgainClicked(t('PageNotFound.restartAppLabel'), screen)
        setStep(tryAgainScreen)
      }}
      buttonText={t('PageNotFound.restartAppLabel')}
    />
  )

  useEffect(() => {
    captureExceptionHelper(
      `Error: User navigated to the PAGE NOT FOUND page. Path: ${currentLocation}`,
      new Error(`Error: User navigated to the PAGE NOT FOUND page. Path: ${currentLocation}`),
      'warning',
    )
  }, [])

  return (
    <ErrorPage
      image={{ src: genericErrorImg, alt: t('PageNotFound.alt') }}
      title={t('PageNotFound.title')}
      content={content}
      buttons={[tryAgainButton]}
    />
  )
}
