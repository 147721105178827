import { ReactNode, MouseEvent } from 'react'
import { styled } from '@mui/material/styles'
import { Checkbox, Grid } from '@mui/material'

const CheckboxStyled = styled(Checkbox)({
  padding: 0,
  marginRight: '14px',
  ':hover': {
    background: 'none',
  },
})

const PolicyContainerDiv = styled('div')({
  display: 'flex',
  alignItems: 'flex-start',
})

interface PolicyContainerType {
  content: ReactNode
  checked: boolean
  setChecked: (value: boolean) => void
  marginTop?: string
}

/**
 *
 */
export function PolicyContainer({
  content,
  checked,
  setChecked,
  marginTop = '16px',
}: PolicyContainerType) {
  const handleClick = (ev: MouseEvent) => {
    const elementClicked = ev.target as HTMLElement

    // Don't select when clicking on links.
    if (elementClicked.tagName.toLowerCase() !== 'a') {
      setChecked(!checked)
    }
  }

  return (
    <Grid item xs={12} marginTop={marginTop} style={{ cursor: 'pointer' }}>
      <PolicyContainerDiv onClick={handleClick} role="button" data-testid="policy-checkbox">
        <CheckboxStyled disableRipple checked={checked} />
        <span>{content}</span>
      </PolicyContainerDiv>
    </Grid>
  )
}
