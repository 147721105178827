import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { ComponentProps, ReactElement } from 'react'
import { styled } from '@mui/material/styles'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'

import theme from 'src/themes'

export const InformationIconStyled = styled(InfoOutlinedIcon)({
  width: '24px',
  height: '24px',
  marginRight: '8px',
  color: 'black',
})

export const NotificationContent = styled(Typography)({
  ...theme.typography.body2,
  color: theme.color.grey9,
})

const NotificationLiteStyled = styled(Typography)({
  ...theme.typography.subtitle1,
  display: 'flex',
  flexDirection: 'row',
  columnGap: '4px',
})

const NotificationBoldStyled = styled(Typography)({
  ...theme.typography.linkMedium,
  lineHeight: '24px',
  display: 'inline',
  fontWeight: 500,
  color: theme.color.grey10,
})

export const NotificationBold = (props: any) => (
  <NotificationBoldStyled {...props} component="span" />
)

export const NotificationContainer = styled(Box)({
  backgroundColor: theme.color.brand5,
  borderRadius: '10px',
  padding: '16px',
  paddingRight: '32px',
})

type NotificationProps = {
  content: ReactElement | string
  icon: ReactElement
  backgroundColor?: keyof typeof theme.color
  sxTypography?: ComponentProps<typeof Typography>
}

export default Notification
/**
 *
 */
function Notification({ icon, content, backgroundColor }: NotificationProps) {
  return (
    <NotificationContainer style={{ backgroundColor: backgroundColor }}>
      <Box display="flex" flexDirection="row">
        {icon}
        <NotificationContent>{content}</NotificationContent>
      </Box>
    </NotificationContainer>
  )
}

/**
 *
 */
export function NotificationLite({ icon, content, sxTypography }: NotificationProps) {
  return (
    <span>
      <NotificationLiteStyled sx={sxTypography}>
        {icon}
        {content}
      </NotificationLiteStyled>
    </span>
  )
}
