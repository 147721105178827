export const applicantData: { [key: string]: any } = {
  'Sin Valid': {
    firstName: 'Sin',
    lastName: 'Valid',
    birthDate: new Date('August 24, 1973 00:00:00'),
    phoneNumber: '4165551212',
    email: 'Sin.Valid@example.com',
    grossAnnualIncome: 75000,
    address: {
      formattedAddress: '170 Jackson St. E, Hamilton, ON L8N 1L4, Canada',
      address_1: '170 Jackson St. E',
      address_2: '',
      postal_code: 'L8N 1L4',
      province_code: 'ON',
      city: 'Hamilton',
      country_code: 'CA',
    },
    city: 'Hamilton',
    postalCode: 'L8N 1L4',
    monthlyHomePayment: 750,
    homeMoveInDate: new Date('July 21, 2019 00:00:00'),
    homeOwnershipStatus: 'own',
  },
  'Six Tests': {
    firstName: 'Six',
    lastName: 'Tests',
    birthDate: new Date('December 12, 1965 00:00:00'),
    phoneNumber: '9053893030',
    email: 'Six.Tests@example.com',
    grossAnnualIncome: 75000,
    address: {
      formattedAddress: '100 Water St., Hamilton, ON L8N 3K8, Canada',
      address_1: '100 Water St.',
      address_2: '',
      postal_code: 'L8N 3K8',
      province_code: 'ON',
      city: 'Hamilton',
      country_code: 'CA',
    },
    city: 'Hamilton',
    postalCode: 'L8N 3K8',
    monthlyHomePayment: 750,
    homeMoveInDate: new Date('July 21, 2019 00:00:00'),
    homeOwnershipStatus: 'own',
  },
  'Test Abcdefg': {
    firstName: 'Test',
    lastName: 'Abcdefg',
    birthDate: new Date('May 10, 1947 00:00:00'),
    phoneNumber: '9053187458',
    email: 'Test.Abcdefg@example.com',
    grossAnnualIncome: 75000,
    address: {
      formattedAddress: '170 Jackson St. E, Hamilton, ON L8N 1L4, Canada',
      address_1: '170 Jackson St. E',
      address_2: '',
      postal_code: 'L8N 1L4',
      province_code: 'ON',
      city: 'Hamilton',
      country_code: 'CA',
    },
    city: 'Hamilton',
    postalCode: 'L8N 1L4',
    monthlyHomePayment: 750,
    homeMoveInDate: new Date('July 21, 2019 00:00:00'),
    homeOwnershipStatus: 'own',
  },
  'Jill Fermoth': {
    firstName: 'Jill',
    lastName: 'Fermoth',
    birthDate: new Date('Janurary 1, 1959 00:00:00'),
    phoneNumber: '5146275478',
    email: 'Jill.Fermoth@example.com',
    grossAnnualIncome: 75000,
    address: {
      formattedAddress: '200 Place De Matane, Laval, QC H7Y 2A1, Canada',
      address_1: '200 Place De Matane',
      address_2: '',
      postal_code: 'H7Y 2A1',
      province_code: 'QC',
      city: 'Laval',
      country_code: 'CA',
    },
    city: 'Laval',
    postalCode: 'H7Y 2A1',
    monthlyHomePayment: 700,
    homeMoveInDate: new Date('July 21, 2019 00:00:00'),
    homeOwnershipStatus: 'own',
  },
  'Albert Thivey': {
    firstName: 'Albert',
    lastName: 'Thivey',
    birthDate: new Date('June 2, 1945 00:00:00'),
    phoneNumber: '7095798181',
    email: 'Albert.Thivey@example.com',
    grossAnnualIncome: 75000,
    address: {
      formattedAddress: "95 Kenmount Rd., St. John's, NL A1B 3P8, Canada",
      address_1: '95 Kenmount Rd.',
      address_2: '',
      postal_code: 'A1B 3P8',
      province_code: 'NL',
      city: "St. John's",
      country_code: 'CA',
    },
    city: "St. John's",
    postalCode: 'A1B 3P8',
    monthlyHomePayment: 700,
    homeMoveInDate: new Date('July 21, 2019 00:00:00'),
    homeOwnershipStatus: 'own',
  },
}
