import { MenuItem, Select, SelectChangeEvent, SxProps, Theme, styled } from '@mui/material'

import {
  FigFormControl,
  FigFormControlProps,
  getLabelId,
} from 'src/components/common/FigFormControl'

const FigSelect = styled(Select)(({ theme }) => ({
  '& [data-placeholder="true"]': {
    color: theme.color.grey6,
  },
  fontSize: '16px',
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: theme.color.grey4,
  },
  '&:hover .MuiOutlinedInput-notchedOutline': {
    borderColor: theme.color.brand1,
  },
}))

export const FigMenuItem = styled(MenuItem)(({ theme }) => ({
  height: '56px',
  padding: '24px',
  fontSize: '16px',
  color: 'black',
  '&.Mui-selected': {
    backgroundColor: theme.color.brand5,
  },
  '&:hover': {
    backgroundColor: theme.color.grey2,
  },
}))

const menuStyles: SxProps<Theme> = theme => ({
  '& > [tabindex="-1"]': {
    backgroundColor: 'transparent',
    boxShadow: 'none',
    marginTop: '4px',
  },
  '& [role="listbox"]': {
    border: 'solid 2px',
    borderColor: theme.color.brand1,
    borderRadius: '4px',
    marginTop: '4px',
    backgroundColor: 'white',
  },
})

/**
 *
 */
export function FigDropdown({
  placeholder,
  onChange,
  value,
  label,
  menuItems,
  error,
  helperText,
  isFocused,
}: {
  placeholder?: string
  onChange: (e: SelectChangeEvent<unknown>) => void
  value?: any
  label: string
  menuItems: ReturnType<typeof FigMenuItem>[]
} & FigFormControlProps) {
  return (
    <FigFormControl {...{ label, error, helperText, isFocused }}>
      <FigSelect
        aria-labelledby={getLabelId(label)}
        data-testid={'figDropdown'}
        fullWidth
        value={value}
        displayEmpty={true}
        MenuProps={{
          sx: menuStyles,
          disableScrollLock: true,
        }}
        renderValue={(val: any) => {
          return <span data-placeholder={!val}>{val || placeholder}</span>
        }}
        onChange={onChange}
      >
        {menuItems}
      </FigSelect>
    </FigFormControl>
  )
}
