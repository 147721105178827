import { useState } from 'react'

import { LoadingAnimation } from 'src/components/common/animations/Loading'
import { LoanStatusEnum, PortalModalsEnum } from 'src/portal/common'
import { RoundedBox } from 'src/portal/components/RoundedBox'
import { StretchDivider } from 'src/portal/components/StretchDivider'
import { useModals } from 'src/portal/hooks/useActionModals'
import usePortalContext from 'src/portal/hooks/usePortalContext'
import { PayNowButton } from 'src/portal/pages/home/components/PayNowButton'
import { ShowDetailsButton } from 'src/portal/pages/home/components/ShowDetailsButton'
import { AutomaticPaymentsContainer } from 'src/portal/pages/home/summary/containers/AutomaticPaymentsContainer'
import { LoanDetailsContainer } from 'src/portal/pages/home/summary/containers/LoanDetailsContainer'
import { LoanStatusContainer } from 'src/portal/pages/home/summary/containers/LoanStatusContainer'
import { PaymentProtectionContainer } from 'src/portal/pages/home/summary/containers/PaymentProtectionContainer'
import { TotalAmountContainer } from 'src/portal/pages/home/summary/containers/TotalAmountContainer'
import { UpcomingPaymentAmountContainer } from 'src/portal/pages/home/summary/containers/UpcomingPaymentAmountContainer'
import { UpcomingPaymentDateContainer } from 'src/portal/pages/home/summary/containers/UpcomingPaymentDateContainer'

const Summary = () => {
  const { loanDetailData } = usePortalContext()
  const [loanDetailsExpanded, setLoanDetailsExpanded] = useState(false)
  const { openModal, RenderModal } = useModals()

  if (!loanDetailData) {
    return (
      <>
        <LoadingAnimation />
      </>
    )
  }

  const totalAmount = loanDetailData.loan_pay_off_amount
  const loanStatus = loanDetailData.loan_status as LoanStatusEnum
  const upcomingPaymentAmount = loanDetailData.due_amount
  const upcomingPaymentDate = loanDetailData.due_date
  const paymentProtectionActive = loanDetailData.payment_protection_applied!
  const automaticPaymentsOn = !!loanDetailData.automated_payment_setup?.id
  const loanId = loanDetailData.id
  const loanAmount = loanDetailData.loan_amount
  const interestRate = loanDetailData.interest_rate
  const loanTerm = loanDetailData.loan_term
  const feesAccrued = loanDetailData.fees_accrued_since_last_payment
  const interestAccrued = loanDetailData.interest_accrued_since_last_payment
  const principalRemaining =
    loanDetailData.loan_pay_off_amount -
    loanDetailData.fees_accrued_since_last_payment -
    loanDetailData.interest_accrued_since_last_payment
  const loanStartDate = loanDetailData.loan_disbursal_date

  return (
    <>
      <RoundedBox>
        <TotalAmountContainer
          {...{ totalAmount, feesAccrued, interestAccrued, principalRemaining }}
        />
        <LoanStatusContainer loanStatus={loanStatus} />
        <StretchDivider />
        <UpcomingPaymentAmountContainer upcomingPaymentAmount={upcomingPaymentAmount} />
        <UpcomingPaymentDateContainer upcomingPaymentDate={upcomingPaymentDate} />
        <AutomaticPaymentsContainer
          automaticPaymentsOn={automaticPaymentsOn}
          viewDetailsClicked={() => openModal(PortalModalsEnum.AUTOMATIC_PAYMENTS_DETAIL)}
        />
        <PaymentProtectionContainer paymentProtectionActive={paymentProtectionActive} />

        {!automaticPaymentsOn && <PayNowButton onClickHandler={() => {}} />}

        {loanDetailsExpanded && (
          <>
            <StretchDivider />
            <LoanDetailsContainer
              {...{ loanId, loanAmount, loanTerm, interestRate, loanStartDate }}
            />
          </>
        )}

        <RenderModal />
      </RoundedBox>
      <ShowDetailsButton onClickHandler={() => setLoanDetailsExpanded(!loanDetailsExpanded)} />
    </>
  )
}
export default Summary
