import { Box } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { portalStretchDivider } from 'src/images'

/**
 * StretchDivider component renders a centered image inside a Box component.
 *  Similar to the MenuDivider component but stretched to 91% of the parent width.
 *
 * @returns {JSX.Element} The rendered StretchDivider component.
 */
export const StretchDivider = () => {
  const { t } = useTranslation()
  return (
    <Box style={{ display: 'flex', justifyContent: 'center' }}>
      <img
        src={portalStretchDivider}
        style={{ padding: '10px 32px', width: '91%' }}
        alt={t('Portal.Menu.alt.dividerIcon')}
      />
    </Box>
  )
}
