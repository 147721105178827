import styled from '@emotion/styled'
import { t } from 'i18next'
import { useState } from 'react'
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input/input'

import { FigFormControl } from 'src/components/common/FigFormControl'
import theme from 'src/themes'

enum Country {
  CANADA = 'CA',
}

const PhoneInputStyled = styled(PhoneInput)({
  width: '100%',
  borderRadius: 3,
  padding: 12,
  height: '56px',
  background: theme.color.white,
  border: `1px solid ${theme.color.grey4}`,
  fontFamily: "'Poppins',sans-serif",
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '16px',
  lineHeight: '20px',
  color: theme.color.grey6,
  marginTop: 4,
  marginBottom: '16px',
  boxSizing: 'border-box',
  outlineColor: theme.color.brand1,
  ['&[data-error="true"]']: {
    outlineColor: theme.color.error,
  },
})

export const PhoneNumberInput = ({
  defaultPhoneNumber,
  handleInput,
}: {
  defaultPhoneNumber: string
  handleInput: (e: any) => void
}) => {
  const [isError, setIsError] = useState<boolean>(false)
  const [isFocused, setIsFocused] = useState<boolean>(false)
  const phoneInputMaxLength = 14
  const phoneNumberLength = 12

  return (
    <FigFormControl
      label={t('OTP.phoneLabel')}
      error={isError}
      data-error={isError}
      isFocused={isFocused}
      helperText={isError ? t('CreditRenewals.ConfirmInformation.phoneInput.errorMessage') : ''}
    >
      <PhoneInputStyled
        data-testid="confirm-phone-input"
        country="CA"
        international
        withCountryCallingCode={false}
        placeholder={t('OTP.phonePlaceholder')}
        autoComplete="tel"
        useNationalFormatForDefaultCountryValue
        value={defaultPhoneNumber}
        onChange={value => {
          handleInput(value)
          if (value && value?.length === phoneNumberLength) {
            const isValid = isValidPhoneNumber(value as string, Country.CANADA)
            setIsError(!isValid)
          } else {
            setIsError(false)
          }
        }}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        maxLength={phoneInputMaxLength}
        aria-invalid={isError} // Inform screen readers when input is invalid
        aria-describedby={isError ? 'phone-input-error-text' : null}
      />
    </FigFormControl>
  )
}
