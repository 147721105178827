import { Button, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import { useContext } from 'react'
import { useTranslation } from 'react-i18next'

import { ApplyNowButtonProps } from 'src/components/EndToEnd/landing/Walmart/types'
import { PrequalificationContext } from 'src/contexts'
import { StepsEnum } from 'src/types'

export const ApplyNowButton = ({ size }: ApplyNowButtonProps) => {
  const { t } = useTranslation()

  const { setStepE2E } = useContext(PrequalificationContext)

  const CTAButton = styled(Button)(({ theme }) => ({
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.light,
    borderRadius: 100,
    width: size,
    height: '52px',
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
      border: `2px solid ${theme.palette.primary.main}`,
      borderRadius: 100,
      '& .MuiTypography-root': {
        color: theme.palette.primary.main,
      },
    },
  }))

  const handleClick = () => {
    setStepE2E(StepsEnum.CONSENTS)
  }
  return (
    <CTAButton onClick={handleClick}>
      <Typography variant="buttonText">{t('e2e.Landing.buttonLabel')}</Typography>
    </CTAButton>
  )
}
