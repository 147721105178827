import { captureException, captureMessage, SeverityLevel } from '@sentry/react'
import { AxiosError, HttpStatusCode } from 'axios'
import { yearsToMonths } from 'date-fns'
import { jwtDecode } from 'jwt-decode'

import { AxiosValues, PartnersEnum, StepsEnum } from 'src/types'
import { log } from 'src/utils'

/*
 *
 */
export function decodeBase64<T>(value: string): T | null {
  try {
    return jwtDecode(value)
  } catch (error) {
    log.error(error)
    return null
  }
}

/*
 *
 */
export const parseUrl = (url?: URL) => {
  const { hash: pathWithQuery } = url ?? window.location
  let [path, query] = pathWithQuery.split('?') // remove query params
  query = query ? `?${query}` : '' // add question mark to query if exists
  if (path.endsWith('/')) {
    path = path.slice(0, -1) // remove terminating backslash
  }
  if (path.startsWith('#/')) {
    path = path.slice(2) // remove #/ prefix
  }
  const e2ePrefix = 'application/'
  if (path.startsWith(e2ePrefix)) {
    path = path.slice(e2ePrefix.length) // remove #/ prefix
  }
  const [jwt, ...stepArray] = path.split('/')
  return { jwt, currentStep: stepArray.join('/'), query }
}

/*
 *
 */
export const isE2EUrl = () => {
  // structure: #/application/<jwt>/prequalification OR #/<jwt>/kyc/error
  const { hash } = window.location
  return hash.split('/')[1] === StepsEnum.END_TO_END
}

/*
 *
 */
export const isEmpty = (val: any) => [undefined, null, 'None'].includes(val)

/*
 *
 */
export const anyEmpty = (...vals: any[]) => vals.some(isEmpty)

/*
 *
 */
export function copy<T>(obj: T): T {
  return JSON.parse(JSON.stringify(obj))
}

type MobileDeviceType = 'other mobile' | 'apple' | 'android'

type DeviceType = MobileDeviceType | 'desktop'

/*
 *
 */
export const getDeviceType = (): DeviceType => {
  // Adapted from MDN: https://developer.mozilla.org/en-US/docs/Web/HTTP/Browser_detection_using_the_user_agent#mobile_device_detection

  const mobileDeviceToUASubstrs: Record<MobileDeviceType, string[]> = {
    apple: ['iPhone', 'iPad', 'iPod'],
    android: ['Android'],
    ['other mobile']: ['BlackBerry', 'webOS', 'IEMobile', 'Windows Phone', 'BB10', 'Playbook'],
  }

  for (const [mobileDevice, uaSubstrs] of Object.entries(mobileDeviceToUASubstrs)) {
    if (new RegExp(`\\b${uaSubstrs.join('|')}\\b`, 'i').test(navigator.userAgent)) {
      return mobileDevice as MobileDeviceType
    }
  }
  return 'desktop'
}

/*
 *
 */
export const getTotalMonths = (d: Date) => yearsToMonths(d.getFullYear()) + d.getMonth() // month is zero indexed

/*
 *
 */
export const getDuration = (startDate: Date) => {
  const diffMonths = getTotalMonths(new Date()) - getTotalMonths(startDate)
  return parseFloat((diffMonths / 12).toFixed(1))
}

/*
 * Sends a Sentry log for errors.
 */
export const captureExceptionHelper = (
  title: string,
  error: unknown,
  level: SeverityLevel = 'error',
) => {
  if (error instanceof AxiosError) {
    captureMessage(title, {
      extra: getAxiosErrorValues(error),
      level,
    }) // use captureMessage with a custom title so that AxiosErrors aren't all grouped together
  } else {
    captureException(new Error(title, { cause: error })) // Sentry Linked Errors
  }
}

/*
 * Grabs some useful values from an AxiosError.
 */
export const getAxiosErrorValues = (error: unknown): AxiosValues | undefined => {
  if (!(error instanceof AxiosError)) {
    return
  }
  return {
    axiosCode: error.code,
    isConnectionError: error.code === AxiosError.ERR_NETWORK,
    url: error.config?.url,
    method: error.config?.method,
    status: error.response?.status,
    isNotFound: error.response?.status === HttpStatusCode.NotFound,
    response: error.code === AxiosError.ERR_NETWORK ? error : error.response?.data,
  }
}

/**
 * Capitalize the first letter of a string.
 * @param {string} str - The input string.
 * @returns {string} The capitalized string.
 */
export function capitalize(str?: string) {
  if (!str) {
    return str
  }
  return str[0].toUpperCase() + str.slice(1).toLowerCase()
}

export const isPartnerPromoActive = (isFigLandingPromo: boolean, partnerName: string): boolean => {
  return (
    isFigLandingPromo &&
    (partnerName === PartnersEnum.FIG ||
      partnerName === PartnersEnum.THE_BRICK ||
      partnerName === PartnersEnum.NEO ||
      partnerName === PartnersEnum.FINTEL)
  )
}

export const isfigCreditKarmaLandingPromoActive = (
  isFigLandingPromo: boolean,
  partnerName: string,
): boolean => {
  return isFigLandingPromo && partnerName === PartnersEnum.CREDIT_KARMA_PROMO
}

export const isfairstoneLandingPromoActive = (
  isFigLandingPromo: boolean,
  partnerName: string,
): boolean => {
  return isFigLandingPromo && partnerName === PartnersEnum.FAIRSTONE
}
