import { Box, Grid2 } from '@mui/material'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import {
  avatarBlackIcon,
  avatarGreenIcon,
  chevronDownicon,
  chevronUpicon,
  figLogo,
  giftIcon,
  logoutIcon,
} from 'src/images'
import { EnglishLanguageKey, FrenchLanguageKey } from 'src/locales'
import { DropdownMenuEnum, DropdownMenuItemUserEnum, PortalRoutesEnum } from 'src/portal/common'
import Dropdown from 'src/portal/layout/header/Dropdown'
import PortalTheme from 'src/themes/portal'

const Header = ({ signOut }: { signOut: VoidFunction }) => {
  const navigate = useNavigate()
  const { i18n, t } = useTranslation()
  const [openDropdown, setOpenDropdown] = useState<DropdownMenuEnum | null>(null)

  const handleDropdownToggle = (dropdownKey: DropdownMenuEnum) => {
    setOpenDropdown(prevKey => (prevKey === dropdownKey ? null : dropdownKey))
  }

  return (
    <Box
      sx={{
        borderBottom: `1px solid ${PortalTheme.color.grey4}`,
        backgroundColor: PortalTheme.color.white,
      }}
    >
      <Grid2 container sx={{ py: 2, px: 4 }} alignItems="center">
        <Grid2 size={6}>
          <img
            src={figLogo}
            alt={t('Portal.Header.alt.figLogo')}
            onClick={() => navigate(PortalRoutesEnum.PORTAL_INDEX)}
            role="button"
            tabIndex={0}
            aria-label={t('Portal.Header.alt.figLogo')}
          />
        </Grid2>
        <Grid2 size={6}>
          <Grid2 container justifyContent="flex-end" alignItems="center" spacing={2}>
            <Grid2>
              <img
                src={giftIcon}
                alt={t('Portal.Menu.alt.giftIcon')}
                onClick={() => window.open(t('Portal.Menu.links.referral'), '_blank')}
                role="button"
                tabIndex={0}
                aria-label={t('Portal.Menu.alt.giftIcon')}
                style={{ cursor: 'pointer' }}
              />
            </Grid2>
            <Grid2>
              <Dropdown
                options={[
                  {
                    key: DropdownMenuItemUserEnum.PROFILE,
                    label: t('Portal.Header.label.profileSettings'),
                    icon: avatarBlackIcon,
                  },
                  {
                    key: DropdownMenuItemUserEnum.LOGOUT,
                    label: t('Portal.Header.label.logout'),
                    icon: logoutIcon,
                  },
                ]}
                isOpen={openDropdown === DropdownMenuEnum.USER}
                onToggle={() => handleDropdownToggle(DropdownMenuEnum.USER)}
                onSelect={optionKey => {
                  if (optionKey === DropdownMenuItemUserEnum.PROFILE) {
                    navigate(PortalRoutesEnum.PORTAL_PROFILE)
                  } else if (optionKey === DropdownMenuItemUserEnum.LOGOUT) {
                    signOut()
                  }
                }}
                icon={avatarGreenIcon}
                alt={t('Portal.Header.alt.userIcon')}
                label={''}
              />
            </Grid2>
            <Grid2>
              <Dropdown
                options={[
                  { key: EnglishLanguageKey, label: 'English' },
                  { key: FrenchLanguageKey, label: 'Français' },
                ]}
                isOpen={openDropdown === DropdownMenuEnum.LANGUAGE}
                onToggle={() => handleDropdownToggle(DropdownMenuEnum.LANGUAGE)}
                onSelect={languageKey => i18n.changeLanguage(languageKey)}
                label={i18n.resolvedLanguage?.toUpperCase() || ''}
                toggleIcon={{ open: chevronUpicon, closed: chevronDownicon }}
                dropdownStyle={{ marginTop: '16px' }}
              />
            </Grid2>
          </Grid2>
        </Grid2>
      </Grid2>
    </Box>
  )
}

export default Header
