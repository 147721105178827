import { Box, Grid2, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'

import { DesktopMenuItemProps, handleNavigation } from 'src/portal/layout/menu/common'
import PortalTheme from 'src/themes/portal'
import { onKeySelect } from 'src/utils/keyboardHandlers'

export const DesktopMenuItem: React.FC<DesktopMenuItemProps> = ({ item, index, isActive }) => {
  const navigate = useNavigate()

  return (
    <Grid2 key={index} role="listitem">
      <Box
        onClick={() => handleNavigation(item, navigate)}
        onKeyDown={onKeySelect(() => handleNavigation(item, navigate))}
        tabIndex={0}
        role="button"
        aria-current={isActive ? 'page' : undefined}
        aria-label={item.label}
        sx={{
          display: 'flex',
          alignItems: 'center',
          px: 2,
          py: 1.5,
          backgroundColor: isActive ? PortalTheme.color.brand5 : 'transparent',
          borderRadius: '4px',
          cursor: 'pointer',
          overflow: 'hidden',
          '&:hover': {
            backgroundColor: PortalTheme.color.brand5,
          },
        }}
      >
        <img
          src={isActive ? item.iconActive : item.icon}
          alt={`${item.label} icon`}
          style={{ width: '24px', height: '24px', marginRight: '8px', verticalAlign: 'middle' }}
        />
        <Typography
          variant="body2"
          sx={{
            fontWeight: '400',
            color: isActive ? PortalTheme.color.brand1 : PortalTheme.color.grey10,
            maxHeight: '3rem', // Limit to approximately 2 lines to be wrapped
            overflow: 'hidden',
            display: '-webkit-box',
            WebkitBoxOrient: 'vertical',
            WebkitLineClamp: 2, // Allows only up to 2 lines to be wrapped
            lineHeight: 1.5,
          }}
        >
          {item.label}
        </Typography>
      </Box>
    </Grid2>
  )
}
