import { ComponentProps } from 'react'
import { Grid, Typography, styled } from '@mui/material'

const InfoTileContainer = styled('div')(({ theme }) => ({
  background: theme.color.brand7,
  marginLeft: '16px', // Balance grid negative margins
  marginTop: '16px', // Balance grid negative margins
  padding: '16px',
  borderRadius: '8px',
}))

const InfoTileBodyText = styled((props: ComponentProps<typeof Typography>) => (
  <Typography {...props} variant="body3" />
))({
  fontWeight: 400,
})

const TileIconContainer = styled('div')({
  width: '24px',
  height: '24px',
  marginLeft: 'auto',
  marginRight: 'auto',
})

export const InfoTile = ({
  title,
  text,
  icon,
}: {
  title: JSX.Element | string
  text: JSX.Element | string
  icon: JSX.Element
}) => {
  return (
    <InfoTileContainer>
      <Grid xs={12} item container>
        <Grid item xs={12}>
          <TileIconContainer>{icon}</TileIconContainer>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body3" fontWeight={500}>
            {title}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <InfoTileBodyText>{text}</InfoTileBodyText>
        </Grid>
      </Grid>
    </InfoTileContainer>
  )
}
