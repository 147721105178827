import LaunchIcon from '@mui/icons-material/Launch'
import CheckIcon from '@mui/icons-material/Check'
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome'
import * as Sentry from '@sentry/react'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import {
  Accordion,
  AccordionDetails,
  Box,
  Divider,
  Link,
  Modal,
  useMediaQuery,
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { useMutation } from 'react-query'
import { useEffect, useMemo, useState } from 'react'

import { PaymentScheduleEnum } from 'src/types'
import { DivRoot } from 'src/components/common/DivRoot'
import { heartIcon, briefcaseIcon } from 'src/images'
import { PrimaryCTA } from 'src/components/common/Buttons'
import theme from 'src/themes'
import { useApi, useOriginationContext } from 'src/hooks'
import { isQCResident } from 'src/utils/borrower'
import { StepsEnum } from 'src/types/common'
import { getRetryQueryOptionsWithErrorStep } from 'src/api/api'
import { useLocalizedFormatters } from 'src/hooks/useLocalizedFormatters'
import { paymentProtectionAcceptClicked, paymentProtectionDeclineClicked } from 'src/utils'

import TermsPage from './TermsPage'
import FaqPage from './FaqPage'
import {
  IconStyle,
  AccordionHeaderStyle,
  RecommendedSectionHeader,
  RecommendedSectionHeaderContainer,
  RecommendedSectionHeaderText,
  RecommendedSectionContainer,
  RadioStyled,
  RecommendedSectionHeaderSecond,
  ConsentTypography,
  BoxModal,
  MainPaymentProtectionListItem,
  RecommendedSectionContainerOne,
  ModalAccordionSummary,
  ModalAccordionGrid,
} from './styles'
import { PaymentProtectionProps, PaymentProtectionEnum } from './types'

export default function PaymentProtection({ nextStep, previousStep }: PaymentProtectionProps) {
  const {
    setStep,
    bootstrapInfo,
    setBootstrapInfo,
    updateAsyncRequestStatusCallback,
    cachedApplications,
    applicationId,
    setGenericErrorPageError,
  } = useOriginationContext()

  const cachedSchedule = cachedApplications[applicationId]?.saved_repayment_schedule
  const selectedLoanAmount = cachedApplications[applicationId]?.selected_loan_amount as number
  const frequency = cachedSchedule?.schedule as PaymentScheduleEnum | undefined
  const withdrawAmount = cachedSchedule?.withdraw_amount

  const { paymentProtectionSelection } = useApi()

  const { mutateAsync: paymentProtectionMutation } = useMutation(paymentProtectionSelection, {
    ...getRetryQueryOptionsWithErrorStep({
      queryName: 'paymentProtectionMutation',
      setGenericErrorPageError,
      setStep,
    }),
  })
  const { currencyFormat } = useLocalizedFormatters()

  const { t } = useTranslation()
  // only continue if payment protection is selected
  const handleContinue = () => {
    if (acceptChecked === PaymentProtectionEnum.PROTECTION_NOT_SELECTED) {
      return
    }
    handleProtectionChecked(acceptChecked === PaymentProtectionEnum.PROTECTION_ACCEPTED)
    setStep(nextStep)
  }

  const [acceptChecked, setAcceptChecked] = useState<PaymentProtectionEnum>(
    PaymentProtectionEnum.PROTECTION_NOT_SELECTED,
  )

  const handleProtectionChecked = (acceptPaymentProtection: boolean) => {
    updateAsyncRequestStatusCallback('paymentProtectionSelection', () => {
      const res = paymentProtectionMutation({
        payment_protection_applied: acceptPaymentProtection,
      })
      setBootstrapInfo({
        ...bootstrapInfo,
        application: {
          ...bootstrapInfo.application,
          payment_protection_applied: acceptPaymentProtection,
        } as any,
        last_completed_step: StepsEnum.PAYMENT_PROTECTION,
      })
      return res
    })
  }

  useEffect(() => {
    if (bootstrapInfo?.application?.payment_protection_applied) {
      setAcceptChecked(PaymentProtectionEnum.PROTECTION_ACCEPTED)
    } else if (bootstrapInfo?.last_completed_step === StepsEnum.PAYMENT_PROTECTION) {
      setAcceptChecked(PaymentProtectionEnum.PROTECTION_DECLINED)
    }
  }, [bootstrapInfo?.application?.payment_protection_applied])

  const handleCancel = () => {
    setStep(previousStep)
  }

  const frequencyText = () => {
    if (frequency === PaymentScheduleEnum.EVERY_WEEK) {
      return t('PaymentProtection.ScheduleFrequency.weekly')
    } else if (frequency === PaymentScheduleEnum.EVERY_TWO_WEEKS) {
      return t('PaymentProtection.ScheduleFrequency.biweekly')
    } else if (frequency === PaymentScheduleEnum.TWICE_A_MONTH) {
      return t('PaymentProtection.ScheduleFrequency.twiceAMonth')
    } else if (frequency === PaymentScheduleEnum.ONCE_A_MONTH) {
      return t('PaymentProtection.ScheduleFrequency.monthly')
    }
  }

  const insuranceAmount = useMemo(() => {
    const taxRate = bootstrapInfo?.application?.payment_protection_tax_rate ?? 0
    const premiumRate = bootstrapInfo?.application?.payment_protection_premium_rate
    const withdrawlAmount = withdrawAmount ? parseFloat(withdrawAmount) : 0
    if (!(withdrawAmount && premiumRate)) {
      Sentry.captureMessage('withdrawAmount is 0', {
        level: 'error',
        extra: { withdrawalAmount: withdrawAmount, taxRate: taxRate, premiumRate: premiumRate },
      })
      return 0
    } else {
      const premiumAmount = withdrawlAmount * (premiumRate * 0.01)
      const calculatedInsuranceAmount = premiumAmount + premiumAmount * (taxRate * 0.01)
      return calculatedInsuranceAmount
    }
  }, [cachedSchedule?.withdraw_amount])

  const loanAmt = currencyFormat(selectedLoanAmount, 0)
  const isFromQC = isQCResident(bootstrapInfo.borrower)

  const [openFaq, setOpenFaq] = useState(false)
  const [openTerms, setOpenTerms] = useState(false)

  const handleCloseFaq = () => setOpenFaq(false)
  const handleCloseTerms = () => setOpenTerms(false)

  const eligibleForProtection = bootstrapInfo?.application?.applicable_for_protection
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  if (!eligibleForProtection) {
    setStep(nextStep)
    return
  }
  return (
    <DivRoot>
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <Typography variant="h1" textAlign="center">
            {t('PaymentProtection.title')}
          </Typography>
        </Grid>
        <Grid item xs={12} marginTop="32px">
          <Typography
            variant="body2"
            sx={{ color: theme.color.grey9, textAlign: isMobile ? 'center' : 'left' }}
          >
            {t('PaymentProtection.subtitle')}
          </Typography>
        </Grid>
        <Grid item marginTop="32px" sx={{ boxShadow: '0px 4px 16px 0px #00000014' }}>
          <Accordion sx={{ boxShadow: 0 }}>
            <ModalAccordionSummary
              expandIcon={<ExpandMoreIcon />}
              id="FirstAccordion-header"
              aria-controls="panel-content"
              isMobile={isMobile}
            >
              <ModalAccordionGrid isMobile={isMobile}>
                <IconStyle src={briefcaseIcon} alt={t('PaymentProtection.FirstAccordion.title')} />
              </ModalAccordionGrid>
              <AccordionHeaderStyle>
                <Typography variant="body2" fontWeight="bold" sx={{ color: theme.color.grey9 }}>
                  {t('PaymentProtection.FirstAccordion.title')}
                </Typography>
                <Typography
                  variant="body2"
                  sx={{ color: theme.color.grey9, paddingTop: isMobile ? '4px' : '4px' }}
                >
                  {t('PaymentProtection.FirstAccordion.subtitle')}
                </Typography>
              </AccordionHeaderStyle>
            </ModalAccordionSummary>
            <AccordionDetails>
              <MainPaymentProtectionListItem
                text={t('PaymentProtection.FirstAccordion.pointone')}
                isMobile={isMobile}
              />
              <MainPaymentProtectionListItem
                text={t('PaymentProtection.FirstAccordion.pointtwo')}
                isMobile={isMobile}
              />
              <MainPaymentProtectionListItem
                text={t('PaymentProtection.FirstAccordion.pointthree')}
                isMobile={isMobile}
              />
            </AccordionDetails>
          </Accordion>
          <Divider variant="middle" sx={{ borderRadius: '20px' }} />
          <Accordion sx={{ boxShadow: 0, '&:before': { display: 'none' } }}>
            <ModalAccordionSummary
              expandIcon={<ExpandMoreIcon />}
              id="FirstAccordion-header"
              aria-controls="panel-content"
              isMobile={isMobile}
            >
              <ModalAccordionGrid isMobile={isMobile}>
                <IconStyle
                  src={heartIcon}
                  sx={{
                    width: '44px',
                    height: '39.08px',
                  }}
                  alt={t('PaymentProtection.SecondAccordion.title')}
                />
              </ModalAccordionGrid>
              <AccordionHeaderStyle>
                <Typography variant="body2" fontWeight="bold" sx={{ color: theme.color.grey9 }}>
                  {t('PaymentProtection.SecondAccordion.title')}
                </Typography>
                <Typography variant="body2" sx={{ color: theme.color.grey9 }}>
                  {t('PaymentProtection.SecondAccordion.subtitle')}
                </Typography>
              </AccordionHeaderStyle>
            </ModalAccordionSummary>
            <AccordionDetails sx={{ paddingTop: '0px' }}>
              <MainPaymentProtectionListItem
                text={t('PaymentProtection.SecondAccordion.pointone')}
                isMobile={isMobile}
              />
              <MainPaymentProtectionListItem
                text={t('PaymentProtection.SecondAccordion.pointtwo')}
                isMobile={isMobile}
              />
              <MainPaymentProtectionListItem
                text={t('PaymentProtection.SecondAccordion.pointthree')}
                isMobile={isMobile}
              />
              <MainPaymentProtectionListItem
                text={t('PaymentProtection.SecondAccordion.pointfour')}
                isMobile={isMobile}
              />
              <MainPaymentProtectionListItem
                text={t('PaymentProtection.SecondAccordion.pointfive')}
                isMobile={isMobile}
              />
            </AccordionDetails>
          </Accordion>
        </Grid>
        <Grid item xs={12} display="flex" marginTop="32px" justifyContent="space-around">
          <Modal
            open={openTerms}
            onClose={handleCloseTerms}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <BoxModal isMobile={isMobile}>
              <TermsPage handleClose={handleCloseTerms} />
            </BoxModal>
          </Modal>
          <Link variant="linkMedium" onClick={() => setOpenTerms(!openTerms)}>
            {t('PaymentProtection.LinksText.termsLink')}
          </Link>

          {isFromQC && (
            <Link
              target="_blank"
              href={t('PaymentProtection.factSheetUrl')}
              display="flex"
              variant="linkMedium"
              align-items="flex-start"
              text-align="center"
              padding="0px 8px 0px 8px"
            >
              <LaunchIcon />
              {t('PaymentProtection.LinksText.factSheetLink')}
            </Link>
          )}
          <Modal
            open={openFaq}
            onClose={handleCloseFaq}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <BoxModal isMobile={isMobile}>
              <FaqPage handleClose={handleCloseFaq} />
            </BoxModal>
          </Modal>
          <Link variant="linkMedium" onClick={() => setOpenFaq(!openFaq)}>
            {t('PaymentProtection.LinksText.faqLink')}
          </Link>
        </Grid>
        <RecommendedSectionContainerOne
          item
          xs={12}
          marginTop={4}
          checked={acceptChecked === PaymentProtectionEnum.PROTECTION_ACCEPTED}
          onClick={() => {
            setAcceptChecked(PaymentProtectionEnum.PROTECTION_ACCEPTED)
            paymentProtectionAcceptClicked('protection_accepted', StepsEnum.PAYMENT_PROTECTION)
          }}
        >
          <RecommendedSectionHeader item xs={12}>
            <RecommendedSectionHeaderContainer>
              <AutoAwesomeIcon sx={{ color: theme.color.grey9 }} />
              <RecommendedSectionHeaderText>
                {t('PaymentProtection.FirstBox.title')}
              </RecommendedSectionHeaderText>
            </RecommendedSectionHeaderContainer>
          </RecommendedSectionHeader>
          <RecommendedSectionContainer
            item
            xs={12}
            sx={{ borderTop: '1px solid ' + theme.color.grey3 }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <RadioStyled
                sx={{ marginLeft: '5px' }}
                checked={acceptChecked === PaymentProtectionEnum.PROTECTION_ACCEPTED}
                data-testid={'radio-button-accepted'}
                id="test_radio_button"
              />
              <RecommendedSectionHeaderText sx={{ flex: 1, fontSize: '16px' }}>
                {t('PaymentProtection.FirstBox.subtitle', {
                  insuranceAmount: currencyFormat(insuranceAmount, 2),
                  frequency: frequencyText(),
                })}
              </RecommendedSectionHeaderText>
            </Box>
            <Typography
              sx={{
                display: 'flex',
                alignItems: 'center',
                paddingLeft: '34px',
                marginTop: '4px',
                color: theme.color.grey9,
                fontSize: '16px',
              }}
            >
              <CheckIcon
                sx={{
                  marginRight: '4px',
                  color: theme.color.brand8,
                  width: '20px',
                  height: '20px',
                }}
              />
              {t('PaymentProtection.FirstBox.subtitle1')}
            </Typography>
            <Grid>
              {isFromQC ? (
                <ConsentTypography>{t('PaymentProtection.disclaimer.QC')}</ConsentTypography>
              ) : (
                <ConsentTypography>{t('PaymentProtection.disclaimer.ROC')}</ConsentTypography>
              )}
            </Grid>
          </RecommendedSectionContainer>
        </RecommendedSectionContainerOne>

        <RecommendedSectionHeaderSecond
          checked={acceptChecked === PaymentProtectionEnum.PROTECTION_DECLINED}
          item
          xs={12}
          marginTop={2}
          onClick={() => {
            setAcceptChecked(PaymentProtectionEnum.PROTECTION_DECLINED)
            paymentProtectionDeclineClicked('protection_declined', StepsEnum.PAYMENT_PROTECTION)
          }}
        >
          <RecommendedSectionHeaderContainer>
            <RadioStyled
              sx={{ marginLeft: '5px' }}
              checked={acceptChecked === PaymentProtectionEnum.PROTECTION_DECLINED}
            />
            <RecommendedSectionHeaderText sx={{ flex: 1, fontSize: '16px' }}>
              {t('PaymentProtection.SecondBox.title')}
              <span style={{ color: theme.color.brand8 }}>
                {t('PaymentProtection.SecondBox.title1', { loanAmt })}
              </span>
              {t('PaymentProtection.SecondBox.title2')}
            </RecommendedSectionHeaderText>
          </RecommendedSectionHeaderContainer>
        </RecommendedSectionHeaderSecond>

        <Grid item xs={12} marginTop="32px">
          <PrimaryCTA
            disabled={acceptChecked == PaymentProtectionEnum.PROTECTION_NOT_SELECTED}
            onClick={handleContinue}
            buttonText={t('common.continueLabel')}
          />
        </Grid>
        <Grid item xs={12} display="flex" marginTop="32px" justifyContent="space-around">
          <Link variant="linkMedium" onClick={handleCancel}>
            {t('common.backlabel')}
          </Link>
        </Grid>
      </Grid>
    </DivRoot>
  )
}
