import { Box, Link, Typography } from '@mui/material'
import AppBar from '@mui/material/AppBar'
import Container from '@mui/material/Container'
import { SxProps, Theme, styled } from '@mui/material/styles'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'

import { ConnectivityToast } from 'src/components/ConnectivityToast/ConnectivityToast'
import { IconSection } from 'src/components/EndToEnd/landing/IconSection/IconSection'
import { ProgressBar } from 'src/components/ProgressBar/ProgressBar'
import FigHeader from 'src/components/common/FigHeader'
import NetworkProvider from 'src/contexts/network'
import { SupportedLanguage, supportedLanguages } from 'src/locales'
import { HeaderType, IconType, LogoType } from 'src/types'
import { FeatureFlags } from 'src/types/common'
import { MaintenanceBanner } from 'src/components/common/MaintenanceBanner'

export const StyledDiv = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  height: '100%',
  marginLeft: 'auto',
  marginRight: 'auto',
  paddingTop: 0,
  paddingBottom: 0,
  backgroundColor: theme.color.grey100,
  [theme.breakpoints.up('md')]: {
    minHeight: '100vh',
    backgroundColor: theme.color.grey100,
  },
}))

export const FigAppBar = styled(AppBar)({
  height: '80px',
  background: 'transparent',
  display: 'flex',
  position: 'sticky',
  flexDirection: 'row',
  boxShadow: 'none',
  backgroundColor: 'white',
})

export const ContainerStyle = styled(Container)(({ theme }) => ({
  padding: '0',
  [theme.breakpoints.up('md')]: {
    padding: '0 32px',
  },
}))

export const ImgStyle = styled('img')(({ theme }) => ({
  height: '32px',
  width: 'auto',
  display: 'block',
  marginTop: '20px',
  marginBottom: '20px',
  marginLeft: '24px',
  marginRight: '24px',
  [theme.breakpoints.up('md')]: {
    height: '40px',
    marginLeft: '0',
    marginRight: '0',
  },
}))

export const FigContentContainer = styled('div')(({ theme }) => ({
  width: '100%',
  height: '100%',
  position: 'relative',
  [theme.breakpoints.up('md')]: {
    width: 594,
    marginTop: '64px',
    marginBottom: '6px',
  },
}))

export const FigChildrenContainer = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    padding: '0px',
    backgroundColor: 'white',
    border: 'solid 1px',
    borderColor: theme.color.grey4,
    borderRadius: '8px',
  },
}))

const selectedLanguageClass = 'selectedLanguage'

export const LanguageLink = styled(Link)({
  fontWeight: 'normal',
  [`&.${selectedLanguageClass}`]: {
    fontWeight: 'bold',
  },
  ':not(:last-child):after': {
    content: '" | "',
    fontWeight: 'normal',
  },
})

export const LanguageToggle = () => {
  const { i18n } = useTranslation()

  return (
    <Box sx={{ width: '100%', p: 3 }}>
      <Typography textAlign="right">
        {supportedLanguages.map(lang => (
          <LanguageLink
            key={lang}
            className={i18n.resolvedLanguage === lang ? selectedLanguageClass : ''}
            onClick={() => {
              if (lang !== i18n.resolvedLanguage) {
                i18n.changeLanguage(lang)
              }
            }}
          >
            {lang.toUpperCase()}
          </LanguageLink>
        ))}
      </Typography>
    </Box>
  )
}

export default RootLayout
/**
 *
 */
function RootLayout({
  children,
  logo,
  header,
  iconConfig,
  showProgressBar = true,
  contentStyles,
}: {
  children: ReactNode
  logo: LogoType
  header?: HeaderType
  iconConfig?: IconType
  contentStyles?: SxProps<Theme>
  showProgressBar?: boolean
}) {
  const { i18n } = useTranslation()
  const language = i18n.resolvedLanguage as SupportedLanguage
  const { isMaintenanceBanner } = useFlags<FeatureFlags>()

  return (
    <StyledDiv id="fig-root-container">
      {isMaintenanceBanner ? <MaintenanceBanner /> : null}
      <FigAppBar>
        <ContainerStyle maxWidth={false}>
          <a href={`https://fig.ca/${language}/`} target="_blank" rel="noreferrer">
            {logo ? <ImgStyle src={logo.src} alt={logo.alt} data-testid="partnerLogo" /> : null}
          </a>
        </ContainerStyle>
        <LanguageToggle />
      </FigAppBar>
      {showProgressBar && <ProgressBar />}
      {header ? <FigHeader {...header} /> : null}
      {iconConfig && Object.keys(iconConfig).length ? (
        <IconSection mobileIcon={iconConfig.mobileIcon} desktopIcon={iconConfig.desktopIcon} />
      ) : null}
      <NetworkProvider>
        <ConnectivityToast />
        <FigContentContainer sx={contentStyles}>
          <FigChildrenContainer id="fig-content-container">{children}</FigChildrenContainer>
        </FigContentContainer>
      </NetworkProvider>
    </StyledDiv>
  )
}
