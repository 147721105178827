import { Box, Grid2 } from '@mui/material'
import { ReactNode } from 'react'

import PortalTheme from 'src/themes/portal'

export const DesktopMenuContainer = ({ children }: { children: ReactNode }) => {
  return (
    <Box
      sx={{
        maxWidth: '345px',
        height: '100vh',
        paddingTop: '32px',
        backgroundColor: PortalTheme.color.white,
        boxShadow: `0px 4px 16px 0px #00000014`,
        borderLeft: `1px solid ${PortalTheme.color.grey4}`,
        overflow: 'hidden', // Prevents content from spilling
      }}
    >
      <Grid2 container direction="column" sx={{ height: '100%' }}>
        {children}
      </Grid2>
    </Box>
  )
}
