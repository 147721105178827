import { Collapse, Grid, Typography, useMediaQuery } from '@mui/material'
import Alert from '@mui/material/Alert'
import { useState } from 'react'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import { useTranslation } from 'react-i18next'

import theme from 'src/themes'

export const MaintenanceBanner = () => {
  const { t } = useTranslation()
  const [open, setOpen] = useState(true)
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'))
  return (
    <Grid style={{ width: '-webkit-fill-available' }}>
      <Collapse in={open}>
        <Alert
          icon={false}
          severity="warning"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setOpen(false)
              }}
              sx={{
                color: 'black',
                marginTop: isDesktop ? '17px' : '',
                marginRight: isDesktop ? '40px' : '',
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
        >
          <Typography
            textAlign="left"
            variant="body2"
            color="black"
            sx={{
              padding: isMobile ? '0px' : '16px 40px 16px 40px',
              fontSize: isMobile ? '14px' : '16px',
            }}
          >
            {t('common.maintenanceBanner')}
          </Typography>
        </Alert>
      </Collapse>
    </Grid>
  )
}
