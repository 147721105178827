import { styled } from '@mui/material'
import Divider from '@mui/material/Divider'

/**
 *
 */
export const StyledDivider = styled(Divider)(({ theme }) => ({
  width: '100%',
  margin: '36px 0',
  borderColor: theme.color.grey4,
}))
