import { Grid, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { LoanDetailsSummaryProps } from 'src/components/ReviewOrder/types'

export function LoanDetailsSummary({
  selectedLoanAmount,
  formattedTotalAmount,
  formattedInterestAmount,
  isQuebecResident,
}: LoanDetailsSummaryProps) {
  const { t } = useTranslation()

  return (
    <Grid item xs={12} marginTop="16px">
      <Grid container spacing={'8px'}>
        <Grid item xs={7}>
          <Typography variant="body2">{t('ReviewOrder.loanDetails.loanAmountSubtitle')}</Typography>
        </Grid>
        <Grid item xs={3} textAlign={'right'}>
          <Typography variant="label">{selectedLoanAmount}</Typography>
        </Grid>

        <Grid item xs={7}>
          <Typography variant="body2">
            {isQuebecResident
              ? t('ReviewOrder.loanDetails.interestAmtSubtitleQC')
              : t('ReviewOrder.loanDetails.interestAmtSubtitle')}
          </Typography>
        </Grid>
        <Grid item xs={3} textAlign={'right'}>
          <Typography variant="label">{formattedInterestAmount}</Typography>
        </Grid>

        <Grid item xs={7}>
          <Typography variant="body2">
            {t('ReviewOrder.loanDetails.totalRepaymentAmtSubtitle')}
          </Typography>
        </Grid>
        <Grid item xs={3} textAlign={'right'}>
          <Typography variant="label">{formattedTotalAmount}</Typography>
        </Grid>
      </Grid>
    </Grid>
  )
}
