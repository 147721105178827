import { Typography } from '@mui/material'
import { Trans } from 'react-i18next'

import { LoanDetailsTextProps } from 'src/components/ReviewOrder/types'
import theme from 'src/themes'

export function LoanDetailsText({
  isQuebecResident,
  loanAmount,
  loanTerm,
  loanApr,
}: LoanDetailsTextProps) {
  return (
    <Typography component="span" variant="body2" color={theme.color.grey6}>
      <Trans
        i18nKey={
          isQuebecResident ? 'ReviewOrder.loanDetails.summaryQC' : 'ReviewOrder.loanDetails.summary'
        }
        values={{
          loanAmt: loanAmount,
          termLength: loanTerm,
          apr: loanApr,
        }}
      >
        You’re borrowing{' '}
        <Typography component="span" variant="label" color={theme.color.grey6}>
          {loanAmount}
        </Typography>{' '}
        over{' '}
        <Typography component="span" variant="label" color={theme.color.grey6}>
          {loanTerm} months{' '}
        </Typography>
        ({loanApr} APR)
      </Trans>
    </Typography>
  )
}
