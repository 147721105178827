import { LabelText } from 'src/portal/components/LabelText'
import { StatusIcon } from 'src/portal/components/StatusIcon'
import { StatusIconEnum } from 'src/portal/components/types'

interface StatusBadgeProps {
  iconVariant: StatusIconEnum
  label: string
}

export const StatusBadge = ({ iconVariant, label }: StatusBadgeProps) => {
  return (
    <>
      <StatusIcon variant={iconVariant} />
      <LabelText text={label} />
    </>
  )
}
