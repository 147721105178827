import { Typography, useMediaQuery, useTheme } from '@mui/material'
import Grid from '@mui/material/Grid2'
import { useTranslation } from 'react-i18next'

import { ImgStyle } from 'src/components/EndToEnd/landing/Walmart/styles'
import { walmartLogo } from 'src/images'

export const Banner = () => {
  const theme = useTheme()
  const { t } = useTranslation()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  return (
    <Grid
      container
      style={{
        backgroundColor: theme.palette.primary.main,
        flexWrap: 'nowrap',
      }}
      py="12px"
      px="20px"
      alignItems={'center'}
      width={'100%'}
    >
      <Grid sx={{ mr: 2 }} size={{ xs: 4, md: 2 }}>
        <ImgStyle
          src={walmartLogo}
          sx={{ maxWidth: isMobile ? '100%%' : '70%' }} // override walmart logo size
        />
      </Grid>
      <Grid size={{ xs: 8, md: 10 }}>
        <Typography
          component="p"
          variant="body"
          style={{
            verticalAlign: 'middle',
            display: 'inline',
            color: theme.palette.primary.light,
            fontWeight: 'bolder',
          }}
        >
          {t('e2e.Landing.walmart.bannerSection.title')}
        </Typography>
      </Grid>
    </Grid>
  )
}
