import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import CloseIcon from '@mui/icons-material/Close'
import { Grid2 } from '@mui/material'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'

import { ActionModalProps } from 'src/portal/components/types'
import PortalTheme from 'src/themes/portal'

/**
 * ActionModalContainer component renders a modal with an optional back button, a close button, an icon, a description, and additional content.
 *
 * @param {string} icon - The source URL of the icon to display.
 * @param {string} iconAlt - The alt text for the icon image.
 * @param {string} description - The description text to display below the icon.
 * @param {React.ReactNode} content - Additional content to render inside the modal.
 * @param {() => void} [backButtonHandler] - Optional handler function for the back button click event.
 * @param {() => void} closeButtonHandler - Handler function for the close button click event.
 *
 * @returns {JSX.Element} The rendered ActionModalContainer component.
 */
export const ActionModalContainer = ({
  icon,
  iconAlt,
  description,
  content,
  backButtonHandler,
  closeButtonHandler,
}: ActionModalProps) => {
  return (
    <Grid2 container flexDirection="column" p="24px">
      <Grid2 container alignItems="center" pb="16px">
        {backButtonHandler && (
          <IconButton edge="start" color="inherit" onClick={backButtonHandler} aria-label="back">
            <ArrowBackIcon />
          </IconButton>
        )}
        <IconButton
          edge="end"
          color="inherit"
          onClick={closeButtonHandler}
          aria-label="close"
          sx={{ marginLeft: 'auto' }}
        >
          <CloseIcon />
        </IconButton>
      </Grid2>

      <Grid2 size={12} textAlign="center" pb="48px">
        <img src={icon} alt={iconAlt} width="80px" height="80px" />
        <Typography variant="body2" color={PortalTheme.color.grey10}>
          {description}
        </Typography>
      </Grid2>

      {content}
    </Grid2>
  )
}
