import { Box, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'

import { PortalRoutesEnum } from 'src/portal/common'
import PortalTheme from 'src/themes/portal'

interface MenuButtonProps {
  iconActive: string
  icon: string
  iconAlt: string
  label: string
  isActive?: boolean
  route?: PortalRoutesEnum
  onClick?: () => void
}

export const MobileMenuButton = ({
  icon,
  iconActive,
  iconAlt,
  label,
  isActive,

  route,
  onClick,
}: MenuButtonProps) => {
  const navigate = useNavigate()

  const handleClick = () => {
    if (onClick) {
      onClick() //used for modal open / close in mobile menu
    } else if (route) {
      navigate(route)
    }
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        flex: 1,
        color: isActive ? PortalTheme.color.brand1 : PortalTheme.color.grey10,
        cursor: 'pointer',
        position: 'relative',
      }}
      onClick={handleClick}
    >
      <Box sx={{ position: 'relative' }}>
        <img src={isActive ? iconActive : icon} alt={iconAlt} />
      </Box>
      <Typography
        variant="body3"
        color={isActive ? PortalTheme.color.brand1 : PortalTheme.color.grey10}
        sx={{ mt: 0.5, fontWeight: isActive ? 500 : 400, marginTop: 0 }}
      >
        {label}
      </Typography>
    </Box>
  )
}
