import { Grid, Typography, styled } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useContext, useState } from 'react'

import theme from 'src/themes'
import {
  jointPartnerIcon,
  landingDome,
  landingLeaf,
  landingPill,
  landingTwoLeaf,
  logoImgSvg,
} from 'src/images'
import { useLocalizedFormatters } from 'src/hooks/useLocalizedFormatters'
import { InfoTile } from 'src/components/EndToEnd/landing/shared/InfoTile'
import {
  LandingTemplate,
  LandingTemplateDivider,
} from 'src/components/EndToEnd/landing/shared/LandingTemplate'
import { PrequalificationContext } from 'src/contexts'
import { useGenericLandingHeaderConfig } from 'src/components/EndToEnd/landing/shared/GenericLanding'
import { PartnersEnum } from 'src/types'

const ImgStyle = styled('img')(() => ({
  width: 'auto',
  display: 'block',
}))

/*
 * Cobranded logo, looks like <partner logo> X <fig logo>
 */
export const CobrandedLogo = ({
  logoSrc,
  partnerLogoHeight = '22px',
}: {
  logoSrc: string
  partnerLogoHeight?: string
}) => {
  const { partnerConfig } = useContext(PrequalificationContext)
  const [partnerLogoIsLoaded, setPartnerLogoIsLoaded] = useState(false)

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      sx={{
        [theme.breakpoints.up('md')]: { maxWidth: '35%' },
        [theme.breakpoints.down('md')]: { maxWidth: '100%' },
        margin: '0 auto 16px auto',
        opacity: partnerLogoIsLoaded ? '100%' : '0%',
      }}
      spacing="8px"
    >
      <ImgStyle
        src={logoSrc}
        alt={`${partnerConfig?.name} logo`}
        height={partnerLogoHeight}
        data-testid="headerLogo1"
        onLoad={() => setPartnerLogoIsLoaded(true)}
      />
      <ImgStyle
        src={jointPartnerIcon}
        alt="and"
        data-testid="jointPartnerIcon"
        sx={{ margin: '0 8px' }}
      />
      <ImgStyle src={logoImgSvg} alt="Fig Financial Logo" height="31px" data-testid="headerLogo2" />
    </Grid>
  )
}

/*
 * Generic landing page body.
 */
export const CobrandedLandingBody = () => {
  const { t } = useTranslation()
  const { percentFormat } = useLocalizedFormatters()
  const { partnerConfig } = useContext(PrequalificationContext)

  const getDescription = () => {
    if (partnerConfig?.name == PartnersEnum.FAIRSTONE) {
      return 'e2e.Landing.fairstone.figDescription'
    }
    return 'e2e.Landing.cobranded.figDescription'
  }

  return (
    <>
      <Grid item xs={12}>
        <Typography variant="body2">
          {t(getDescription(), { partner_name: partnerConfig!.name })}
        </Typography>
      </Grid>
      <LandingTemplateDivider />
      <Grid item xs={12}>
        <Typography variant="h3">{t('e2e.Landing.generic.benefits.title')}</Typography>
      </Grid>

      <InfoTile
        title={t('e2e.Landing.generic.benefits.convenienceTitle')}
        text={t('e2e.Landing.generic.benefits.convenienceText', { pct: percentFormat(100, 0) })}
        icon={<img src={landingTwoLeaf} alt="" />}
      />
      <InfoTile
        title={t('e2e.Landing.generic.benefits.feesTitle')}
        text={t('e2e.Landing.generic.benefits.feesText')}
        icon={<img src={landingLeaf} alt="" />}
      />
      <InfoTile
        title={t('e2e.Landing.generic.benefits.seamlessTitle')}
        text={t('e2e.Landing.generic.benefits.seamlessText')}
        icon={<img src={landingDome} alt="" />}
      />
      <InfoTile
        title={t('e2e.Landing.generic.benefits.trustTitle')}
        text={t('e2e.Landing.generic.benefits.trustText')}
        icon={<img src={landingPill} alt="" />}
      />
    </>
  )
}

/*
 * Co-branded landing page, modelled off of the original Neo landing page designs.
 */
export const CobrandedLanding = ({
  logoSrc,
  partnerLogoHeight,
}: {
  logoSrc: string
  partnerLogoHeight?: string
}) => {
  const genericLandingHeaderConfig = useGenericLandingHeaderConfig()
  return (
    <LandingTemplate
      headerConfig={{
        ...genericLandingHeaderConfig,
        icon: <CobrandedLogo logoSrc={logoSrc} partnerLogoHeight={partnerLogoHeight} />,
      }}
      body={<CobrandedLandingBody />}
    />
  )
}
