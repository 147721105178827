import { Box, Typography } from '@mui/material'
import { t } from 'i18next'
import React from 'react'

import { chevronRightGreenIcon } from 'src/images'
import PortalTheme from 'src/themes/portal'

interface LinkProps {
  text: string
  onClickHandler: () => void
}

/**
 * A functional component that renders a clickable link with text and an arrow icon.
 *
 * @param {string} text - The text to display inside the link.
 * @param {function} onClickHandler - The function to call when the link is clicked.
 *
 * @returns {JSX.Element} The rendered link component.
 */
export const PortalLink: React.FC<LinkProps> = ({ text, onClickHandler }) => {
  return (
    <Box onClick={onClickHandler} alignItems="center" sx={{ cursor: 'pointer' }}>
      <Typography
        display="inline"
        variant="body3"
        fontWeight={500}
        color={PortalTheme.color.brand1}
      >
        {text}
      </Typography>
      <img
        src={chevronRightGreenIcon}
        alt={t('Portal.Components.alt.chevronIcon')}
        style={{
          marginLeft: '4px',
          verticalAlign: 'middle',
          display: 'inline',
          width: '16px',
          height: '16px',
        }}
      />
    </Box>
  )
}
