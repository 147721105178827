import LightbulbOutlined from '@mui/icons-material/LightbulbOutlined'
import { useTranslation } from 'react-i18next'

import { NotificationLite } from './Notification'

export const NoEarlyRepayFeesMessage = () => {
  const { t } = useTranslation()
  return (
    <NotificationLite
      content={t('common.earlyRepaymentLabel')}
      icon={<LightbulbOutlined sx={{ fontSize: '16px' }} />}
      sxTypography={{ justifyContent: 'center' }}
    />
  )
}
