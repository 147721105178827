import { useQuery } from 'react-query'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { GetOccupationsResponseDataType } from 'src/types'
import useApi from 'src/hooks/useApi'
import { useOriginationContext } from 'src/hooks'
import { getRetryQueryOptionsWithErrorStep } from 'src/api/api'

/**
 * Fetches the occupations from the API and stores them in the origination context.
 * Called in useOrigination hook during bootstrap.
 */
export const useOccupations = () => {
  const { i18n } = useTranslation()
  const {
    setOccupationData,
    setStep,
    isBootstrapSuccess,
    updateAsyncRequestStatus,
    setGenericErrorPageError,
  } = useOriginationContext()
  const { getOccupations } = useApi()
  const { isFetching: isOccupationDataLoading } = useQuery<GetOccupationsResponseDataType>(
    ['getOccupations', i18n.resolvedLanguage],
    ({ queryKey }) => {
      const [, language] = queryKey as [string, string]
      return getOccupations({ language })
    },
    {
      enabled: isBootstrapSuccess,
      refetchOnMount: true,
      onSuccess: (result: GetOccupationsResponseDataType) => {
        const { occupations } = result.data
        const formattedOccupations = occupations.map(o => o.replace(/&amp;/, '&')) // Format "&"" character correctly
        setOccupationData(formattedOccupations)
      },
      ...getRetryQueryOptionsWithErrorStep({
        queryName: 'Get Occupations',
        setGenericErrorPageError,
        setStep,
      }),
    },
  )
  useEffect(() => {
    updateAsyncRequestStatus('getOccupation', isOccupationDataLoading) // Update loading state in origination context
  }, [isOccupationDataLoading])
}
