import { useState } from 'react'
import { Value as E164Number } from 'react-phone-number-input/input'

import { constants, log } from 'src/utils'
import { useOriginationContext, useApi } from 'src/hooks'
import { Verify, Send } from 'src/components/OTP'
import { StepsEnum } from 'src/types'

export enum ViewEnum {
  Send = 'Send',
  Verify = 'Verify',
}

enum StatusEnum {
  PENDING = 'pending',
  APPROVED = 'approved',
}

enum ModeEnum {
  SMS = 'sms',
  CALL = 'call',
}

enum ErrorEnum {
  DEFAULT = 'Something went wrong',
}

interface OTPContainerProps {
  nextStepOnSuccess: StepsEnum
  initialView?: ViewEnum
  bootstrapRefetch: () => void
}

export default OTPContainer
/**
 *
 */
function OTPContainer({
  nextStepOnSuccess,
  initialView = ViewEnum.Send,
  bootstrapRefetch,
}: OTPContainerProps) {
  const [view, setView] = useState<ViewEnum>(initialView)
  const [phone, setPhone] = useState<E164Number | undefined>('' as E164Number)
  const [codeList, setCodeList] = useState<string[]>(['', '', '', '', '', ''])
  const [sendError, setSendError] = useState<string | null>(null)
  const [verifyError, setVerifyError] = useState<string | null>(null)
  const [isVerifying, setIsVerifying] = useState(false)
  const [isResend, setResend] = useState<boolean>(false)
  const [isCalled, setCalled] = useState<boolean>(false)
  const [mode, setMode] = useState<ModeEnum>(ModeEnum.SMS)
  const { setStep } = useOriginationContext()
  const { axiosPost } = useApi()

  async function send(_mode: ModeEnum = ModeEnum.SMS) {
    try {
      const response = await axiosPost(`${constants.API_URL}integrations/twilio/generate-otp/`, {
        mobile_number: phone,
        channel: _mode,
      })

      if (response.data?.data?.status === StatusEnum.PENDING) {
        setView(ViewEnum.Verify)
      }
    } catch (err: any) {
      log.exception(err)
      setSendError(err?.response?.data?.data?.error || ErrorEnum.DEFAULT)
    }
  }

  async function verify(code: string) {
    try {
      setIsVerifying(true)
      setVerifyError(null)
      const response = await axiosPost(`${constants.API_URL}integrations/twilio/verify-otp/`, {
        code,
        mobile_number: phone,
        channel: mode,
      })

      setIsVerifying(false)
      if (response.data?.data?.status === StatusEnum.APPROVED) {
        bootstrapRefetch()
        setStep(nextStepOnSuccess)
      } else {
        setCodeList(['', '', '', '', '', ''])
        const firstCodeField = document.getElementById('input-0')
        if (firstCodeField !== null) {
          firstCodeField.focus()
        }
        setVerifyError(ErrorEnum.DEFAULT)
      }
    } catch (err: any) {
      log.error(err)
      setVerifyError(err?.response?.data?.data?.error || ErrorEnum.DEFAULT)
      setIsVerifying(false)
    }
  }

  return view === ViewEnum.Send ? (
    <Send
      phone={phone}
      setPhone={setPhone}
      send={() => send()}
      error={sendError}
      setError={setSendError}
    />
  ) : (
    <Verify
      phone={phone}
      verify={verify}
      setResend={setResend}
      resend={() => {
        setResend(true)
        setVerifyError(null)
        setMode(ModeEnum.SMS)
        send()
      }}
      isResend={isResend}
      call={() => {
        setCalled(true)
        setVerifyError(null)
        setMode(ModeEnum.CALL)
        send(ModeEnum.CALL)
      }}
      setCalled={setCalled}
      isCalled={isCalled}
      error={verifyError}
      setError={setVerifyError}
      codeList={codeList}
      setCodeList={setCodeList}
      isVerifying={isVerifying}
      back={() => {
        setVerifyError(null)
        setView(ViewEnum.Send)
      }}
    />
  )
}
