import enPortalTranslation from 'src/locales/en/portalTranslation'
const portalTranslation: typeof enPortalTranslation = {
  Portal: {
    Header: {
      alt: {
        figLogo: '',
        userIcon: '',
        languageDropdown: '',
      },
      label: {
        languageDropdown: 'Language',
        profileSettings: 'Paramètres du profil',
        logout: 'Se déconnecter',
      },
    },
    Menu: {
      links: {
        referral: `https://fig.ca/fr/referral/`,
        terms: `https://fig.ca/fr/legal/terms-of-service/`,
        privacy: `https://fig.ca/fr/legal/privacy-statement/`,
        helpCenter: `https://support.fig.ca/fr/support/home`,
      },
      alt: {
        homeIcon: '',
        paymentsIcon: '',
        giftIcon: '',
        documentsIcon: '',
        moreIcon: '',
        paymentProtectionIcon: '',
        loanIncreaseIcon: '',
        helpCenterIcon: '',
        termsIcon: '',
        notificationIcon: '',
        dividerIcon: '',
      },
      label: {
        home: 'Accueil',
        payments: 'Paiements',
        documents: 'Gérer les paiements',
        managePayments: 'Gérer les paiements',
        viewDocuments: 'Voir les documents',
        more: 'Plus',
        paymentProtection: 'Protection de paiement',
        loanIncrease: 'Demander une augmentation du montant du prêt',
        helpCenter: `Centre d'aide`,
        terms: `Conditions d'utilisation`,
      },
    },
    Home: {
      label: {
        tab: {
          summary: 'Résumé',
          transactions: 'Transactions',
          upcomingPayments: 'Paiements à venir',
        },
        summary: {
          title: 'Résumé',
          loanStatus: {
            good: 'Bon statut',
            overdue: 'Paiement en retard',
            closed: 'Clôturé',
          },
          remainingBalance: 'Solde restant',
          upcomingPaymentAmount: 'Montant du paiement à venir',
          upcomingPaymentDate: 'Date du paiement à venir',
          dateFormat: 'EEEE dd MMMM yyyy',
          automaticPaymentsOn: 'Paiements automatiques : Activés',
          automaticPaymentsOff: 'Paiements automatiques : Désactivés',
          paymentProtectionActive: 'Protection de paiement : Active',
          loanDetails: 'Voir les détails',
          loanId: 'Identifiant du prêt',
          loanAmount: 'Montant du prêt',
          interestRate: `Taux d'intérêt`,
          loanTerm: `Durée du prêt`,
          loanTermInMonths: 'Durée du prêt',
          loanStartDate: 'Date de début du prêt',
        },
        transactions: {
          title: `Transactions`,
          description:
            'Voici ci-dessous tous les paiements que vous avez effectués, y compris les paiements programmés et les paiements uniques, ainsi que les frais supplémentaires sur votre compte.',
          status: {
            processing: 'Traitement',
            failed: 'Échoué',
          },
          message: {
            processing: `Veuillez vous assurer qu'il y a suffisamment de fonds sur votre compte jusqu'à ce que le paiement soit traité dans dans 2 jours ouvrables`,
          },
        },
        upcomingPayments: {
          title: `Paiements à venir`,
          description: `Si vous avez effectué un paiement forfaitaire, le montant de votre paiement mensuel ne sera pas réduit, mais la durée de votre prêt sera raccourcie.`,
          descriptionWithPaymentProtection: `Des frais de protection de paiement seront appliqués après votre précédent`,
          remainingBalance: `Solde restant`,
        },
        greeting: {
          morning: 'Bonjour',
          afternoon: 'Bon après-midi',
          evening: 'Bonsoir',
        },
      },
    },
    Payments: {
      label: {
        title: ``,
        description: ``,
        options: {
          manualPayment: {
            title: ``,
            description: '',
          },
          overduePaymentDescription: ``,
          manualPaymentUserDescription: ``,
          delayNextPayment: {
            title: ``,
            description: '',
          },
          changePaymentSchedule: {
            title: ``,
            description: ``,
          },
          changePaymentMethod: {
            title: ``,
            description: ``,
          },
        },
        overdueMessage: ``,
      },
    },
    Components: {
      alt: {
        infoCircleIcon: '',
        questionCircleIcon: '',
        statusIcon: '',
        chevronIcon: '',
        checkmarkIcon: '',
      },
      label: {
        viewDetails: 'Voir les détails',
        manage: 'Gérer',
        learnMore: 'En savoir plus',
        showDetails: 'Afficher les détails',
        hideDetails: 'Masquer les détails',
      },
      tooltip: {
        title: {
          accountStatus: 'Statut du compte',
          remainingBalance: 'Solde restant',
          loanId: 'Identifiant du prêt',
          loanTerm: 'Durée du prêt',
          paymentDetails: 'Détail du paiement',
        },
        content: {
          accountStatus: `<typography>Votre statut de compte sera l'un des suivants :<br/><br/><strong>En règle</strong> - Tous les paiements jusqu'à votre dernière date d'échéance ont été reçus.<br/><br/><strong>Paiement en retard</strong> - Un ou plusieurs paiements attendus ont été manqués.<br/><br/><strong>Clôturé</strong> - Votre prêt a été remboursé.<br/><br/>Si vous voyez le message "Votre paiement est en retard", veuillez payer votre montant impayé dès que possible ou contacter notre équipe de support pour planifier un paiement afin de remettre votre compte en règle.</typography>`,
          remainingBalance: `<typography>Le solde de votre prêt augmentera avec l'accumulation des intérêts quotidiens et diminuera à mesure que les paiements seront effectués. Si vous souhaitez rembourser votre prêt, voici le montant dû à ce jour.</typography>`,
          loanId: '',
          loanTerm: '',
        },
        label: {
          principalRemaining: 'Principal restant',
          interestAccrued: 'Intérêts accumulés depuis le dernier paiement',
          feesAccrued: 'Frais accumulés depuis le dernier paiement',
          total: 'Total',
          principal: 'Principal',
          creditCharges: 'Frais de crédit',
          paymentProtectionPremium: 'Prime de protection de paiement',
          paymentProtectionPremiumTax: 'Taxe sur la prime de protection de paiement',
          nsfFees: 'Frais NSF',
          totalAmount: 'Total',
        },
        link: {
          remiaingBalance: '',
        },
      },
      modal: {
        automaticPayments: {
          on: {
            description: 'Votre prochain paiement automatique est prévu pour {{date}}',
            status: 'Activé',
            linkText: null,
          },
          off: {
            description:
              'Vous ne voulez pas manquer votre paiement ? Activez les paiements automatiques maintenant !',
            status: 'Inactive',
            linkText: 'Activer',
          },
          title: {
            paymentAccount: 'Compte de paiement',
            paymentSchedule: 'Calendrier de paiement',
          },
          linkText: {
            delay: 'Reporter',
            change: 'Modifier',
          },
        },
      },
    },
  },
}
export default portalTranslation
