import LocationOnIcon from '@mui/icons-material/LocationOn'
import Autocomplete, { AutocompleteProps } from '@mui/material/Autocomplete'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { captureMessage } from '@sentry/react'
import parse from 'autosuggest-highlight/parse'
import { FieldProps } from 'formik'
import { ComponentProps, SyntheticEvent, useContext, useEffect, useState } from 'react'
import { useDebouncedCallback } from 'use-debounce'

import AddressSearchTextField from 'src/components/AddressSearchTextField'
import FigTextField from 'src/components/common/FigTextField'
import { AutoSaveContext } from 'src/contexts/prequalification'
import { FigAutocompleteAddress } from 'src/types'
import { parseGoogleAddressParts } from 'src/utils/addressParser'
import { GOOGLE_AUTOCOMPLETE_CONFIG, GOOGLE_PLACES_API_KEY } from 'src/utils/constants'
import { loadScriptIfNeeded } from 'src/utils/tagLoader'

export type FigAutocompletePrediction = google.maps.places.AutocompletePrediction
interface FigAddressSearchFieldProps
  extends FieldProps<FigAutocompleteAddress>,
    Omit<AutocompleteProps<FigAutocompletePrediction, false, false, false>, 'ref' | 'options'> {
  label?: string
  manualError: boolean
}

/**
 * Formik version of FigAddressSearch
 */
export function FigAddressSearchField({
  field,
  form: { setFieldValue, isSubmitting },
  ...autoCompleteProps
}: Omit<FigAddressSearchFieldProps, 'inputValue' | 'setInputValue'>) {
  const { selectedAddress, setSelectedAddress } = useContext(AutoSaveContext)
  const [selectedOption, setSelected] = useState<FigAutocompletePrediction | null>(selectedAddress)
  const [inputValue, setInputValue] = useState('')

  return (
    <FigAddressSearch
      {...autoCompleteProps}
      id={'field1'}
      inputValue={inputValue}
      setInputValue={newValue => {
        setInputValue(newValue)
      }}
      value={selectedOption ?? null}
      setValue={(rawNewValue, parsedNewValue) => {
        setSelectedAddress(rawNewValue)
        setSelected(rawNewValue)
        setFieldValue(field.name, parsedNewValue ?? {})
      }}
      disabled={isSubmitting}
    />
  )
}
/**
 * Base FigAddressSearch component
 */
export function FigAddressSearch({
  inputValue,
  setInputValue,
  value,
  setValue,
  inputProps = {},
  ...autocompleteProps
}: {
  inputValue: string
  setInputValue: (newValue: string) => void
  value: FigAutocompletePrediction | null
  setValue: (
    rawNewValue: FigAutocompletePrediction | null,
    parsedNewValue: FigAutocompleteAddress | null,
  ) => void
  inputProps?: ComponentProps<typeof FigTextField>
} & Omit<AutocompleteProps<FigAutocompletePrediction, false, false, false>, 'ref' | 'options'>) {
  const callbackName = 'initGoogleMaps'

  const [autocompleteService, setAutocompleteService] = useState<
    google.maps.places.AutocompleteService | undefined
  >(undefined)
  const [options, setOptions] = useState<readonly FigAutocompletePrediction[]>([])

  const initMap = () => {
    if (!autocompleteService && window?.google?.maps?.places?.AutocompleteService) {
      setAutocompleteService(new window.google.maps.places.AutocompleteService())
    }
  }

  // Call Places API to get autocomplete predictions based on input string
  const fetch = useDebouncedCallback(
    (request: { input: string }, callback: (results: FigAutocompletePrediction[]) => void) => {
      if (!autocompleteService) {
        return callback([])
      }
      autocompleteService.getPlacePredictions(
        {
          ...request,
          types: GOOGLE_AUTOCOMPLETE_CONFIG.autocompleteTypes,
          componentRestrictions: GOOGLE_AUTOCOMPLETE_CONFIG.componentRestrictions,
        },
        predictions => callback(predictions || []),
      )
    },
    400,
  )

  // Initialize google places API with component, re-use if already loaded
  useEffect(() => {
    if (typeof window !== 'undefined' && !autocompleteService) {
      const googleMapsSrc = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_PLACES_API_KEY}&libraries=places&callback=${callbackName}`
      if (!loadScriptIfNeeded({ src: googleMapsSrc, id: 'google-maps' })) {
        // If script already loaded, manually trigger callback for this instance
        initMap()
      }
    }
  }, [])

  useEffect(() => {
    // Callback name must match type definition of `window`
    window[callbackName] = initMap
  }, [initMap])

  useEffect(() => {
    if (!autocompleteService) {
      return
    }
    // Input is cleared
    if (!inputValue) {
      setOptions([])
      setValue(null, null)
      return
    }

    fetch({ input: inputValue }, predictions =>
      value ? setOptions([...predictions, value]) : setOptions(predictions),
    )
  }, [inputValue])

  const onSelectedOptionChange = async (_: any, newValue: FigAutocompletePrediction | null) => {
    // Get specific address parts from autocompleted place_id
    if (!newValue) {
      return
    }
    setOptions([newValue, ...options])
    try {
      const placeDetails = await getDetails({ placeId: newValue.place_id })

      setValue(newValue, parseGoogleAddressParts(placeDetails))
    } catch (e) {
      captureMessage(`Unable to get result for place_id`, {
        level: 'error',
        extra: { status: e, place_id: newValue.place_id, inputValue: inputValue },
      })
      // TODO: Replace silent failure with an error callback if component is unable to get a value
      return
    }
  }

  const onInputValueChange = (_: SyntheticEvent, newInputValue: string) => {
    setInputValue(newInputValue)
  }

  return (
    <Autocomplete
      {...autocompleteProps}
      autoComplete
      includeInputInList
      filterSelectedOptions
      noOptionsText="No locations"
      onChange={onSelectedOptionChange}
      onInputChange={onInputValueChange}
      filterOptions={x => x}
      forcePopupIcon={false}
      options={options}
      value={value}
      getOptionLabel={option => {
        return typeof option === 'string' ? option : option.description
      }}
      isOptionEqualToValue={(option, value) => option.place_id === value.place_id}
      renderInput={props => <AddressSearchTextField {...props} {...inputProps} fullWidth />}
      renderOption={(props, option) => {
        const matches = option.structured_formatting.main_text_matched_substrings || []

        const parts = parse(
          option.structured_formatting.main_text,
          matches.map((match: any) => [match.offset, match.offset + match.length]),
        )

        return (
          <li {...props}>
            <Grid container alignItems="center">
              <Grid item sx={{ display: 'flex', width: 44 }}>
                <LocationOnIcon sx={{ color: 'text.secondary' }} />
              </Grid>
              <Grid item sx={{ width: 'calc(100% - 44px)', wordWrap: 'break-word' }}>
                {parts.map((part, index) => (
                  <Box
                    key={index}
                    component="span"
                    sx={{ fontWeight: part.highlight ? 'bold' : 'regular' }}
                  >
                    {part.text}
                  </Box>
                ))}
                <Typography variant="body2" color="text.secondary">
                  {option.structured_formatting.secondary_text}
                </Typography>
              </Grid>
            </Grid>
          </li>
        )
      }}
    />
  )
}

type GetDetailsArgs = google.maps.places.PlaceDetailsRequest

const getDetails = (args: GetDetailsArgs): Promise<google.maps.places.PlaceResult> => {
  const PlacesService = new window.google.maps.places.PlacesService(document.createElement('div'))

  if (!args.placeId) {
    return Promise.reject('No place_id provided')
  }

  return new Promise((resolve, reject) => {
    PlacesService.getDetails(args, (results, status) => {
      if (status !== 'OK' || !results) {
        reject(status)
        return
      }
      resolve(results)
    })
  })
}
