/**
 * Calculates the number of days between two dates.
 * @param date1 The first date.
 * @param date2 The second date.
 * @returns (number) The number of days between the two dates.
 */
export const getDaysBetween = (date1: Date, date2: Date) => {
  const _MS_PER_DAY = 1000 * 60 * 60 * 24
  const utc1 = Date.UTC(date1.getFullYear(), date1.getMonth(), date1.getDate())
  const utc2 = Date.UTC(date2.getFullYear(), date2.getMonth(), date2.getDate())
  return Math.floor((utc2 - utc1) / _MS_PER_DAY)
}
