import { Paper, SxProps, useTheme } from '@mui/material'

export const PaperComponent = ({ children }: { children: any }) => {
  const theme = useTheme()
  const menuStyles: SxProps = () => ({
    '& > [tabindex="-1"]': {
      backgroundColor: 'transparent',
      boxShadow: 'none',
      marginTop: '4px',
    },
    '& [role="listbox"]': {
      border: 'solid 2px',
      borderColor: theme.color.brand1,
      borderRadius: '4px',
      marginTop: '4px',
      backgroundColor: 'white',
    },
  })
  return <Paper sx={menuStyles}>{children}</Paper>
}
