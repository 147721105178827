import { Box, Typography } from '@mui/material'
import Grid from '@mui/material/Grid'
import { styled } from '@mui/material/styles'
import { useTranslation } from 'react-i18next'

import { PrimaryCTA } from 'src/components/common/Buttons'
import { DivRoot } from 'src/components/common/DivRoot'
import { GridImage } from 'src/components/common/GridImage'
import { useOriginationContext } from 'src/hooks'
import { lightbulbIcon, verificationFailImg } from 'src/images'
import theme from 'src/themes'
import { StepsEnum } from 'src/types'
import { kycRetryImageQualityClicked } from 'src/utils'

const ImgStyle = styled('img')({
  width: '80px',
  height: '80px',
  display: 'block',
  marginLeft: 'auto',
  marginRight: 'auto',
})
const IconStyle = styled('img')({
  width: '32px',
  height: '32px',
  marginLeft: 'auto',
  marginRight: 'auto',
  verticalAlign: 'middle',
  display: 'inline',
})

export default function ImageQualityRetry() {
  const { setStep, setGenericErrorPageError } = useOriginationContext()

  const { t } = useTranslation()

  const handleContinue = async () => {
    try {
      kycRetryImageQualityClicked(
        t('KYCImageQualityRetry.button'),
        StepsEnum.KYC_IMAGE_QUALITY_RETRY,
      )
      setStep(StepsEnum.KYC_VERIFY)
    } catch (error) {
      setGenericErrorPageError(error)
      setStep(StepsEnum.ERROR)
    }
  }

  return (
    <DivRoot>
      <Grid container spacing={0}>
        <GridImage item xs={12}>
          <ImgStyle src={verificationFailImg} alt={t('KYCRetryGeneric.alt')} />
        </GridImage>
        <Grid item xs={12}>
          <Typography variant="h1" textAlign="center">
            {t('KYCImageQualityRetry.title')}
          </Typography>
          <Typography variant="body2" textAlign="center" sx={{ marginTop: '16px' }}>
            {t('KYCImageQualityRetry.subtitle')}
          </Typography>
        </Grid>

        <Grid item xs={12} marginTop="32px" display="flex" flexDirection="column">
          <Box
            sx={{
              backgroundColor: theme.color.brand6,
              textAlign: 'center',
              paddingX: '24px',
              paddingY: '16px',
            }}
          >
            <IconStyle src={lightbulbIcon} sx={{ display: 'inline', verticalAlign: 'middle' }} />
            <Typography variant="h3" textAlign="center" sx={{ display: 'inline' }}>
              {t('KYCImageQualityRetry.tipsTitle')}
            </Typography>
            <Typography variant="body3" sx={{ display: 'block', paddingY: '24px' }}>
              {t('KYCImageQualityRetry.tipsLine1')}
            </Typography>
            <Typography variant="body3" sx={{ display: 'block', paddingBottom: '24px' }}>
              {t('KYCImageQualityRetry.tipsLine2')}
            </Typography>
            <Typography variant="body3" sx={{ display: 'block' }}>
              {t('KYCImageQualityRetry.tipsLine3')}
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={12} marginTop="32px">
          <PrimaryCTA
            type="submit"
            onClick={handleContinue}
            buttonText={t('KYCImageQualityRetry.button')}
          />
        </Grid>
      </Grid>
    </DivRoot>
  )
}
