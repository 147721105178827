import { styled } from '@mui/material'

export const ProgressStepsContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: '6px',
  width: '100%',
  height: '45px',
  [theme.breakpoints.up('md')]: {
    '&:hover .stepHint': {
      opacity: 1,
    },
  },
}))

export const ProgressStep = styled('div')(({ theme }) => ({
  flex: '1 1 0',
  width: 0,
  '&[data-step-todo="false"]': {
    cursor: 'pointer',
    '& .stepTab': {
      backgroundColor: theme.color.brand1,
    },
    '& .stepHint': {
      color: theme.color.brand1,
    },
  },
}))

export const StepTab = styled('div')(({ theme }) => ({
  height: '6px',
  backgroundColor: theme.color.grey3,
}))

export const ProgressStepHint = styled('div')(({ theme }) => ({
  paddingTop: '16px',
  fontSize: '16px',
  width: '100%',
  textAlign: 'center',
  lineHeight: '16px',
  fontWeight: 400,
  color: theme.color.grey6,
  opacity: 0,
  transition: 'opacity 1s',
  userSelect: 'none',
}))
