import { Grid2, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

import PortalTheme from 'src/themes/portal'

export const SummaryTitle = () => {
  const { t } = useTranslation()
  return (
    <Grid2>
      <Typography variant="h3">{t('Portal.Home.label.summary.title')}</Typography>
    </Grid2>
  )
}
export const LoanDetailsTitle = () => {
  const { t } = useTranslation()
  return (
    <Grid2 size={12}>
      <Typography variant="h3">{t('Portal.Home.label.summary.loanDetails')}</Typography>
    </Grid2>
  )
}

export const RemainingBalanceTitle = () => {
  const { t } = useTranslation()
  return (
    <Grid2 style={{ textAlign: 'right' }}>
      <Typography variant="body3" color={PortalTheme.color.grey7}>
        {t('Portal.Home.label.summary.remainingBalance')}
      </Typography>
    </Grid2>
  )
}
