import { useTranslation, Trans } from 'react-i18next'

import { genericErrorImg } from 'src/images'
import { noAppIdHelpCentreClicked, noAppIdContactFigClicked } from 'src/utils'
import { StepsEnum } from 'src/types'
import { ContactFigLink } from 'src/components/Error/shared/PleaseReachOut'
import ErrorPage from 'src/components/Error/shared/ErrorPage'
import { PrimaryCTA } from 'src/components/common/Buttons'

export default PageNotFound
/**
 *
 */
function PageNotFound() {
  const { t } = useTranslation()
  const screen = StepsEnum.NO_APP_ID

  const content = (
    <Trans i18nKey="ApplicationIdNotFound.content">
      The link you requested was not found. Please visit Fig help centre or if you have further
      questions, please{' '}
      <ContactFigLink screen={screen} analyticsCallback={noAppIdContactFigClicked} />.
    </Trans>
  )
  const helpCentreButton = (
    <PrimaryCTA
      href={t('HelpDesk.errorUrl')}
      onClick={() => {
        noAppIdHelpCentreClicked(t('common.helpLabel'), screen)
      }}
      buttonText={t('common.helpLabel')}
    />
  )

  return (
    <ErrorPage
      image={{ src: genericErrorImg, alt: t('ApplicationIdNotFound.alt') }}
      title={t('ApplicationIdNotFound.title')}
      content={content}
      buttons={[helpCentreButton]}
    />
  )
}
