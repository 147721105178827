import { FormControl, FormHelperText, FormLabel, styled } from '@mui/material'

export const FigFormHelperText = styled(FormHelperText)(({ theme }) => ({
  marginLeft: 0,
  color: theme.color.grey6,
  fontSize: '12px',
}))

export const FigFormLabel = styled(FormLabel)(({ theme }) => ({
  fontSize: '16px',
  marginBottom: '4px',
  lineHeight: '24px',
  color: theme.color.grey9,
}))

export const getLabelId = (label?: string) =>
  (label && `figFormControlLabel-${label.replace(/[^\w]/g, '')}`) || undefined

const errorClass = 'FigFormControlError'

export const scrollToFirstError = () => {
  document.querySelector(`.${errorClass}`)?.scrollIntoView({ behavior: 'smooth' })
}

export type FigFormControlProps = {
  label?: string
  error?: boolean
  errorMessage?: string
  helperText?: string
  isFocused?: boolean
}

/**
 *
 */
export const FigFormControl = ({
  label,
  error,
  errorMessage,
  helperText,
  isFocused,
  children,
}: FigFormControlProps & { children: React.ReactElement }) => {
  const helperTextOrErrorMsg = helperText || errorMessage
  return (
    <FormControl
      className={error ? errorClass : ''}
      fullWidth
      error={error || !!errorMessage}
      focused={isFocused}
    >
      {label && <FigFormLabel id={getLabelId(label)}>{label}</FigFormLabel>}
      {children}
      {helperTextOrErrorMsg && <FigFormHelperText>{helperTextOrErrorMsg}</FigFormHelperText>}
    </FormControl>
  )
}
