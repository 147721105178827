import { Divider } from '@mui/material'
import { styled } from '@mui/material/styles'

export const ImgStyle = styled('img')(() => ({
  maxWidth: '100%',
  display: 'block',
}))

export const BannerImage = styled('img')(() => ({
  width: '100%',
  height: '100%',
  display: 'block',
  objectFit: 'cover', // Ensures the image covers the container without stretching
}))

export const alignCenter = {
  justifyContent: 'center',
  alignItems: 'center',
}

export const MobileDivider = styled(Divider)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'none',
  },
  display: 'block',
  marginTop: '64px',
}))
