import { useTranslation, Trans } from 'react-i18next'

import { verificationFailImg } from 'src/images'
import { StepsEnum } from 'src/types'
import {
  flinksFailLiveChatClicked,
  flinksFailContactFigClicked,
  flinksFailTryAgainClicked,
} from 'src/utils'
import { useOriginationContext } from 'src/hooks'
import { PrimaryCTA } from 'src/components/common/Buttons'

import { PleaseReachOut } from '../Error/shared/PleaseReachOut'
import ErrorPage from '../Error/shared/ErrorPage'

export default KYCFlinksFail
/**
 *
 */
function KYCFlinksFail() {
  const { t } = useTranslation()
  const screen = StepsEnum.KYC_FLINKS_FAIL
  const { setStep } = useOriginationContext()
  const onContactClick = () => {
    flinksFailTryAgainClicked(t('common.retryLabel'), screen)
    setStep(StepsEnum.BANK_AUTO_CONNECT)
  }

  const content = (
    <Trans i18nKey="FlinksBankValidationFail.content">
      We could not verify your bank account. Please validate that you are using your primary
      personal bank account. If you have further questions,{' '}
      <PleaseReachOut
        screen={screen}
        liveChatAnalytics={flinksFailLiveChatClicked}
        contactFigAnalytics={flinksFailContactFigClicked}
      />
    </Trans>
  )
  const liveChatButton = <PrimaryCTA onClick={onContactClick} buttonText={t('common.retryLabel')} />
  return (
    <ErrorPage
      image={{ src: verificationFailImg, alt: t('FlinksBankValidationFail.alt') }}
      title={t('FlinksBankValidationFail.title')}
      content={content}
      buttons={[liveChatButton]}
    />
  )
}
