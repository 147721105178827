import { Fade, Grid, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import { MdLockOutline } from 'react-icons/md'
import { Trans, useTranslation } from 'react-i18next'

import { useOriginationContext } from 'src/hooks'
import BankConnectCarousel, { ImgStyle } from 'src/components/PaymentMethod/BankConnectCarousel'
import { connectBankAccountBackClicked, connectBankConnectBankAccountClicked } from 'src/utils'
import { StepsEnum } from 'src/types'
import { DivRoot } from 'src/components/common/DivRoot'
import { BankLogo } from 'src/components/common/Logos'
import { shieldIcon } from 'src/images'
import { BackButton, PrimaryCTA } from 'src/components/common/Buttons'
import theme from 'src/themes'

const TypographySecure = styled(Typography)({
  display: 'flex',
  justifyContent: 'center',
  fontStyle: 'normal',
  fontWeight: 500,
  fontSize: 14,
  lineHeight: '20px',
})

const GreyLockIcon = styled(MdLockOutline)({
  fontSize: 16,
  color: theme.color.grey6,
})

export default SelectMethod
/**
 *
 */
function SelectMethod() {
  const connectBankAccountLabel = 'Connect your bank account'
  const { setStep } = useOriginationContext()
  const { t } = useTranslation()
  const BANK_LOGOS = ['rbc', 'td', 'scotia', 'bmo']

  return (
    <DivRoot>
      <Grid container>
        <Grid item xs={12}>
          <ImgStyle src={shieldIcon} alt="Security icon" />
          <Typography variant="h1" textAlign="center">
            {t('SelectMethod.title')}
          </Typography>
          <Typography variant="body2" textAlign="center" marginTop="16px">
            {t('SelectMethod.subtitle')}
          </Typography>
        </Grid>

        <Grid
          item
          xs={12}
          display="flex"
          justifyContent="center"
          alignItems="center"
          marginTop="16px"
        >
          {BANK_LOGOS.map(name => (
            <BankLogo key={name} name={name} marginRight={4} />
          ))}
          <TypographySecure variant="body1" textAlign="center">
            {t('SelectMethod.logoSubtext', { amt: 150 })}
          </TypographySecure>
        </Grid>

        <Grid
          item
          xs={12}
          display="flex"
          justifyContent="center"
          alignItems="center"
          paddingTop="16px"
          paddingBottom="20px"
        >
          <Fade in={true} timeout={2000}>
            <div style={{ width: '100%', height: '100%' }}>
              <BankConnectCarousel />
            </div>
          </Fade>
        </Grid>

        <Grid item xs={12} marginTop="16px">
          <TypographySecure variant="body1" textAlign="center">
            <GreyLockIcon />
            <Trans i18nKey="SelectMethod.secureLabel">
              All bank connections are encrypted for security <br />
              and privacy.
            </Trans>
          </TypographySecure>
        </Grid>

        <Grid item xs={12} display="flex" justifyContent="center" marginTop="16px">
          <PrimaryCTA
            onClick={() => {
              connectBankConnectBankAccountClicked(
                connectBankAccountLabel,
                StepsEnum.PAYMENT_METHOD_SELECT,
              )
              setStep(StepsEnum.BANK_AUTO_CONNECT)
            }}
            buttonText={t('SelectMethod.connectLabel')}
          />
        </Grid>
        <Grid item xs={12} display="flex" justifyContent="center" marginTop="16px">
          <BackButton
            onClick={() => {
              connectBankAccountBackClicked(
                connectBankAccountLabel,
                StepsEnum.PAYMENT_METHOD_SELECT,
              )
              setStep(StepsEnum.LOAN_PURPOSE)
            }}
          />
        </Grid>
      </Grid>
    </DivRoot>
  )
}
