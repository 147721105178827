import { captureMessage } from '@sentry/react'
import { useEffect } from 'react'
import { useTranslation, Trans } from 'react-i18next'

import { PleaseReachOut } from 'src/components/Error/shared/PleaseReachOut'
import ErrorPage from 'src/components/Error/shared/ErrorPage'
import { expiredImg } from 'src/images'
import {
  anyEmpty,
  offerExpiredContactFigClicked,
  offerExpiredLiveChatClicked,
  startAgainWithPartner,
} from 'src/utils'
import { StepsEnum } from 'src/types'
import { useOriginationContext } from 'src/hooks'
import { PrimaryCTA } from 'src/components/common/Buttons'

export default ApplicationExpired
/**
 *
 */
function ApplicationExpired() {
  const { t } = useTranslation()
  const screen = StepsEnum.EXPIRED

  const { bootstrapInfo } = useOriginationContext()

  const { name: partnerName, loan_page_url: partnerUrl } = bootstrapInfo?.partner ?? {}
  useEffect(() => {
    if (anyEmpty(partnerName, partnerUrl)) {
      captureMessage(`Missing partner info: ${JSON.stringify(bootstrapInfo?.partner)}`, 'warning')
    }
  }, [])

  const content = (
    <Trans i18nKey="ApplicationExpired.content">
      Click below to refresh your application and loan offer. If you have any other questions,{' '}
      <PleaseReachOut
        screen={screen}
        liveChatAnalytics={offerExpiredLiveChatClicked}
        contactFigAnalytics={offerExpiredContactFigClicked}
      />
    </Trans>
  )
  const startAgainButton = (
    <PrimaryCTA
      href={partnerUrl}
      onClick={() => {
        startAgainWithPartner(t('ApplicationExpired.startAgainLabel', { partnerName }), screen)
      }}
      buttonText={t('ApplicationExpired.startAgainLabel', { partnerName })}
    />
  )
  return (
    <ErrorPage
      image={{ src: expiredImg, alt: t('ApplicationExpired.alt') }}
      title={t('ApplicationExpired.title')}
      content={content}
      buttons={[startAgainButton]}
    />
  )
}
