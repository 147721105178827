import { ClerkProvider } from '@clerk/clerk-react'
import { Route, useNavigate } from 'react-router-dom'

import { GenericError } from 'src/components/Error/GenericError'
import RootLayout from 'src/components/common/RootLayout'
import { UnauthenticatedFreshChatWrapper } from 'src/components/common/UnauthenticatedFreshChatWrapper'
import {
  ConfigurationProvider,
  CreditRenewalProvider,
  OriginationProvider,
  PrequalificationProvider,
  AuthJwtProvider,
} from 'src/contexts'
import { AutoSaveProvider } from 'src/contexts/prequalification'
import { logoImgSvg } from 'src/images'
import { PortalRoutesEnum } from 'src/portal/common'
import { PortalProvider } from 'src/portal/contexts/portal'
import AppRouterEndToEnd from 'src/routers/affiliate'
import { SentryRoutes } from 'src/routers/common'
import AppRouterCreditRenewal from 'src/routers/creditrenewals'
import AppRouterOrigination from 'src/routers/origination'
import AppRouterPortal from 'src/routers/portal'
import { CreditRenewalRouteBase, StepsEnum } from 'src/types'
import { log } from 'src/utils'
import { PUBLISHABLE_KEY } from 'src/utils/constants'

export default function AppRouter() {
  const navigate = useNavigate()

  if (!PUBLISHABLE_KEY) {
    log.error('PUBLISHABLE_KEY is not set, GenericError will be shown')
  }

  return (
    <SentryRoutes>
      <Route
        path={`/${StepsEnum.END_TO_END}/*`}
        element={
          <PrequalificationProvider>
            <ConfigurationProvider>
              <AutoSaveProvider>
                <UnauthenticatedFreshChatWrapper shouldShow>
                  <AppRouterEndToEnd />
                </UnauthenticatedFreshChatWrapper>
              </AutoSaveProvider>
            </ConfigurationProvider>
          </PrequalificationProvider>
        }
      />
      <Route
        path={`/${PortalRoutesEnum.PORTAL_BASE}/*`}
        element={
          PUBLISHABLE_KEY ? (
            <ClerkProvider
              routerPush={to => navigate(to)}
              routerReplace={to => navigate(to, { replace: true })}
              publishableKey={PUBLISHABLE_KEY}
              afterSignOutUrl={`/#/${PortalRoutesEnum.PORTAL_BASE}/${PortalRoutesEnum.PORTAL_SIGN_IN}`}
              signInFallbackRedirectUrl={`/#/${PortalRoutesEnum.PORTAL_BASE}/${PortalRoutesEnum.PORTAL_SIGN_IN}`}
            >
              <PortalProvider>
                <AppRouterPortal />
              </PortalProvider>
            </ClerkProvider>
          ) : (
            <RootLayout logo={{ src: logoImgSvg, alt: 'Fig logo' }}>
              <GenericError />
            </RootLayout>
          )
        }
      />
      <Route
        path={`/${CreditRenewalRouteBase}/*`}
        element={
          <ConfigurationProvider>
            <AuthJwtProvider>
              <CreditRenewalProvider>
                <AppRouterCreditRenewal />
              </CreditRenewalProvider>
            </AuthJwtProvider>
          </ConfigurationProvider>
        }
      />
      <Route
        path={`/*`}
        element={
          <ConfigurationProvider>
            <AuthJwtProvider>
              <OriginationProvider>
                <AppRouterOrigination />
              </OriginationProvider>
            </AuthJwtProvider>
          </ConfigurationProvider>
        }
      />
    </SentryRoutes>
  )
}
