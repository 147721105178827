export enum StatusIconEnum {
  ACTIVE = 'active',
  ALERT = 'alert',
  INACTIVE = 'inactive',
}

export interface ActionModalProps {
  icon: string
  iconAlt: string
  description: string
  content: JSX.Element
  backButtonHandler?: () => void // only nested modals have a back button
  closeButtonHandler: () => void
}
