import { Collapse, Grid, Link, styled, Typography } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import { ComponentProps, useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { DivRoot } from 'src/components/common/DivRoot'
import { PolicyContainer } from 'src/components/common/Policy'
import { StepsEnum } from 'src/types'
import { useOriginationContext } from 'src/hooks'
import { DEFAULT_CONSENTS } from 'src/utils/constants'
import { PrimaryCTA } from 'src/components/common/Buttons'
import { LoadingAnimation } from 'src/components/common/animations/Loading'
import { useSaveQCConsents } from 'src/hooks/useSaveQCConsents'

const ConsentToggleGrid = styled(Grid)(({ theme }) => ({
  color: theme.color.brand1,
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  margin: '8px 0',
  cursor: 'pointer',
  userSelect: 'none',
}))

const ConsentToggleTypography = styled(Typography)(({ theme }) => ({
  color: theme.color.brand1,
  fontWeight: 'bold',
  fontSize: '16px',
  lineHeight: '20px',
}))

const ConsentDetailGrid = styled(Grid)(({ theme }) => ({
  backgroundColor: theme.color.brand5,
  color: theme.color.grey8,
  padding: '16px',
  fontSize: '16px',
  lineHeight: '20px',
}))

const ConsentLink = styled(Link)({
  fontWeight: 500,
  fontSize: '16px',
  lineHeight: '20px',
})

const ConsentGrid = styled(Grid)(({ theme }) => ({
  padding: '32px 0',
  ':not(:last-child)': {
    borderBottom: 'solid 1px',
    borderBottomColor: theme.color.border,
  },
}))

const ConsentBodyTypography = styled((props: ComponentProps<typeof Typography>) => (
  <Typography {...props} variant="body2" />
))(({ theme }) => ({ color: theme.color.grey8 }))

const ConsentDetail = ({ isOpen, children }: { isOpen: boolean; children: JSX.Element }) => {
  return <Collapse in={isOpen}>{children}</Collapse>
}

const ConsentGroup = ({ children }: { children: JSX.Element }) => {
  const { t } = useTranslation()
  const [isOpen, setIsOpen] = useState(false)
  const toggleIsOpen = () => {
    setIsOpen(isOpen => {
      return !isOpen
    })
  }
  return (
    <>
      <ConsentToggleGrid onClick={toggleIsOpen}>
        {isOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        <ConsentToggleTypography>
          {isOpen ? t('QCConsents.viewLess') : t('QCConsents.viewMore')}
        </ConsentToggleTypography>
      </ConsentToggleGrid>
      <ConsentDetail isOpen={isOpen}>{children}</ConsentDetail>
    </>
  )
}

const TermsAndConditionsLink = () => {
  const { t } = useTranslation()
  const termsAndConditionsLabel = t('common.termsAndConditionsLabel')

  function openTermsAndConditions() {
    window.open(t('common.termsAndConditionsUrl'), '_blank')
  }

  return <ConsentLink onClick={openTermsAndConditions}>{termsAndConditionsLabel}</ConsentLink>
}
const PrivacyPolicyLink = () => {
  const { t } = useTranslation()
  const privacyPolicyLabel = t('common.privacyPolicyLabel')

  function openPrivacyPolicy() {
    window.open(t('common.privacyPolicyUrl'), '_blank')
  }

  return <ConsentLink onClick={openPrivacyPolicy}>{privacyPolicyLabel}</ConsentLink>
}

/**
 *
 */
export const BiometricConsent = ({
  acceptBiometricConsent,
  setAcceptBiometricConsent,
}: {
  acceptBiometricConsent: boolean
  setAcceptBiometricConsent: (b: boolean) => void
}) => {
  const { t } = useTranslation()
  return (
    <ConsentGrid>
      <PolicyContainer
        checked={acceptBiometricConsent}
        setChecked={(checked: boolean) => {
          setAcceptBiometricConsent(checked)
        }}
        content={
          <ConsentBodyTypography>{t('QCConsents.biometricConsent.content')}</ConsentBodyTypography>
        }
      />
      <ConsentGroup>
        <ConsentDetailGrid>
          {t('QCConsents.biometricConsent.detail1')}
          <br />
          <br />
          {t('QCConsents.biometricConsent.detail2')}
        </ConsentDetailGrid>
      </ConsentGroup>
    </ConsentGrid>
  )
}

/**
 *
 */
export const GeneralConsent = () => {
  const { t } = useTranslation()

  return (
    <ConsentGrid>
      <ConsentBodyTypography>
        <Trans i18nKey="QCConsents.generalConsent.content">
          <TermsAndConditionsLink />
          <PrivacyPolicyLink />
        </Trans>
      </ConsentBodyTypography>
      <ConsentGroup>
        <ConsentDetailGrid>
          {t('QCConsents.generalConsent.detailHeading')}
          <ul>
            <li>{t('QCConsents.generalConsent.detailParagraph1')}</li>
            <br />
            <li>{t('QCConsents.generalConsent.detailParagraph2')}</li>
            <br />
            <li>{t('QCConsents.generalConsent.detailParagraph3')}</li>
            <br />
            <li>{t('QCConsents.generalConsent.detailParagraph4')}</li>
            <br />
            <li>{t('QCConsents.generalConsent.detailParagraph5')}</li>
            <br />
            <li>{t('QCConsents.generalConsent.detailParagraph6')}</li>
          </ul>
        </ConsentDetailGrid>
      </ConsentGroup>
    </ConsentGrid>
  )
}

/**
 *
 */
export const RightToAccessConsent = () => {
  const figEmail = 'privacy@fig.ca'

  const EmailLink = () => (
    <ConsentLink href={`mailto:${figEmail}`} target="_blank">
      {figEmail}
    </ConsentLink>
  )

  return (
    <ConsentGrid>
      <ConsentBodyTypography>
        <Trans i18nKey="QCConsents.rightToAccessConsent.content">
          <PrivacyPolicyLink />
        </Trans>
      </ConsentBodyTypography>
      <ConsentGroup>
        <ConsentDetailGrid>
          <Trans i18nKey="QCConsents.rightToAccessConsent.detail">
            <EmailLink />
          </Trans>
        </ConsentDetailGrid>
      </ConsentGroup>
    </ConsentGrid>
  )
}

/**
 *
 */
export const QCConsents = () => {
  const { t } = useTranslation()
  const {
    setStep,
    cachedApplication,
    updateCachedApplication,
    jwtApiKey: jwtOrigination,
    bootstrapInfo,
  } = useOriginationContext()
  const [acceptBiometricConsent, setAcceptBiometricConsent] = useState(false)
  const { saveQuebecConsentsMutation, isSuccess, isLoading, reset } = useSaveQCConsents({
    jwtOrigination,
  })

  const hasSavedQCConsents =
    cachedApplication.has_saved_qc_consents ||
    (bootstrapInfo?.application?.accept_biometric_consent &&
      bootstrapInfo.application?.accept_right_to_access_consent)

  useEffect(() => {
    if (hasSavedQCConsents) {
      setStep(StepsEnum.LOAN_AMOUNT)
    }
  }, [cachedApplication.has_saved_qc_consents, bootstrapInfo.application])

  if (isSuccess) {
    updateCachedApplication({
      consents: {
        ...(cachedApplication.consents || DEFAULT_CONSENTS),
        accept_policy: true,
      },
      has_saved_qc_consents: true,
      // ^ will trigger the useEffect below to navigate to LOAN_AMOUNT
    })
    reset()
  }

  if (isLoading) {
    return <LoadingAnimation />
  }

  return (
    <DivRoot>
      <Typography textAlign="center" variant="h1">
        {t('QCConsents.title')}
      </Typography>
      <Grid>
        <BiometricConsent {...{ acceptBiometricConsent, setAcceptBiometricConsent }} />
        <GeneralConsent />
        <RightToAccessConsent />
      </Grid>
      <Grid item xs={12}>
        <PrimaryCTA
          disabled={!acceptBiometricConsent}
          onClick={() => saveQuebecConsentsMutation({ jwtOrigination })}
          buttonText={t('common.continueLabel')}
        />
      </Grid>
    </DivRoot>
  )
}
