import * as Sentry from '@sentry/react'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { useContext, useEffect } from 'react'
import { useQuery } from 'react-query'
import { Navigate, Outlet, Route, useLocation, useParams } from 'react-router-dom'

import { getRetryQueryOptionsWithErrorStep } from 'src/api/api'
import Consents from 'src/components/EndToEnd/Consents'
import { Offer } from 'src/components/EndToEnd/Offer'
import { Prequalification } from 'src/components/EndToEnd/Prequalification'
import { SecondLookOffer } from 'src/components/EndToEnd/SecondLookOffer'
import { Landing } from 'src/components/EndToEnd/landing/Landing'
import ApplicationDeclined from 'src/components/Error/ApplicationDeclined'
import { GenericError } from 'src/components/Error/GenericError'
import MaintenanceMode from 'src/components/Error/MaintenanceMode'
import PageNotFound from 'src/components/Error/PageNotFound'
import RootLayout from 'src/components/common/RootLayout'
import { LoadingAnimation } from 'src/components/common/animations/Loading'
import { PrequalificationContext } from 'src/contexts'
import { useConfiguration } from 'src/hooks'
import useApiE2E from 'src/hooks/useApiE2E'
import { logoImgSvg } from 'src/images'
import theme from 'src/themes'
import { ApplicationStatus, FeatureFlags, StepsEnum } from 'src/types'
import { parseUrl } from 'src/utils'
import { SENTRY_TAGS } from 'src/utils/constants'
import { SentryRoutes } from 'src/routers/common'

export default function AppRouterEndToEnd() {
  const {
    prequalResponse,
    jwtApiKey,
    partnerConfig,
    setJwtApiKey,
    setStepE2E,
    updatePartnerTrackingFields,
    setPartnerConfig,
    setE2EHomeStep,
    queryParams,
    setQueryParams,
    setPrequalGenericErrorPageError,
  } = useContext(PrequalificationContext)
  const { loanLimits } = useConfiguration()
  const location = useLocation()
  const { query } = parseUrl()

  const { getPartnerConfig } = useApiE2E()

  const { isLoading: partnerConfigLoading, status: partnerConfigStatus } = useQuery(
    ['getPartnerConfig', jwtApiKey],
    () => getPartnerConfig(),
    {
      enabled: jwtApiKey != null,
      useErrorBoundary: false,
      ...getRetryQueryOptionsWithErrorStep({
        queryName: 'getPartnerConfig',
        setGenericErrorPageError: setPrequalGenericErrorPageError,
        setStep: setStepE2E,
      }),
      onSuccess: data => {
        setPartnerConfig(data)
        setStepE2E(StepsEnum.LANDING)
        setE2EHomeStep(StepsEnum.LANDING)
        Sentry.setTag(SENTRY_TAGS.partnerId, data.partner_id)
      },
    },
  )

  const JWTWrapperE2E = () => {
    const { jwtApiKey: jwtApiKeyUrl } = useParams()
    const { isMaintenance } = useFlags<FeatureFlags>()

    useEffect(() => {
      if (jwtApiKeyUrl == null) {
        Sentry.captureMessage('JWT not found in url', 'error')
        setStepE2E(StepsEnum.PAGE_NOT_FOUND)
        return
      }
      const queryParams = new URLSearchParams(query)
      setQueryParams(query)
      updatePartnerTrackingFields({
        partnerSessionId: queryParams.get('partner_session_id'),
        campaignId: queryParams.get('campaign_id'),
        publisherId: queryParams.get('publisher_id'),
      })
      setJwtApiKey(jwtApiKeyUrl)
    }, [jwtApiKeyUrl])

    const isReady =
      partnerConfigStatus == 'error' ||
      (!partnerConfigLoading && !!partnerConfig && !loanLimits.isLoading)

    if (isMaintenance) {
      return (
        <RootLayout logo={{ src: logoImgSvg, alt: 'Fig logo' }}>
          <MaintenanceMode />
        </RootLayout>
      )
    }

    if (isReady) {
      return <Outlet />
    }

    return <LoadingAnimation />
  }

  return (
    <SentryRoutes>
      <Route path=":jwtApiKey" element={<JWTWrapperE2E />}>
        <Route path={StepsEnum.LANDING} element={<Landing />} />
        <Route
          path={StepsEnum.CONSENTS}
          element={
            <RootLayout logo={{ src: logoImgSvg, alt: 'Fig logo' }}>
              <Consents />
            </RootLayout>
          }
        />
        <Route index element={<Navigate to={StepsEnum.PREQUALIFICATION + queryParams} />} />
        <Route path={StepsEnum.PREQUALIFICATION} element={<Prequalification />} />
        {prequalResponse?.status === ApplicationStatus.PRE_AUTHORIZED && (
          <Route
            path={StepsEnum.OFFER}
            element={
              <RootLayout
                logo={{ src: logoImgSvg, alt: 'Fig logo' }}
                contentStyles={{ [theme.breakpoints.up('md')]: { width: 800 } }}
              >
                <Offer />
              </RootLayout>
            }
          />
        )}
        {prequalResponse?.status === ApplicationStatus.DECLINED && (
          <Route
            path={StepsEnum.DECLINED}
            element={
              <RootLayout logo={{ src: logoImgSvg, alt: 'Fig logo' }}>
                <ApplicationDeclined />
              </RootLayout>
            }
          />
        )}
        {prequalResponse?.status === ApplicationStatus.DECLINED &&
          prequalResponse?.is_second_look_approved && (
            <Route
              path={StepsEnum.SECOND_LOOK_OFFER}
              element={
                <RootLayout
                  logo={{ src: logoImgSvg, alt: 'Fig logo' }}
                  contentStyles={{ [theme.breakpoints.up('md')]: { width: 800 } }}
                >
                  <SecondLookOffer />
                </RootLayout>
              }
            />
          )}
        <Route
          path={StepsEnum.ERROR}
          element={
            <RootLayout logo={{ src: logoImgSvg, alt: 'Fig logo' }}>
              <GenericError isUncaughtError={false} />
            </RootLayout>
          }
        />
        <Route
          path={StepsEnum.PAGE_NOT_FOUND}
          element={
            <RootLayout logo={{ src: logoImgSvg, alt: 'Fig logo' }}>
              <PageNotFound
                tryAgainScreen={StepsEnum.PREQUALIFICATION}
                setStepOverride={setStepE2E}
                currentLocation={location.pathname}
              />
            </RootLayout>
          }
        />
        <Route
          path="*"
          element={
            <RootLayout logo={{ src: logoImgSvg, alt: 'Fig logo' }}>
              <PageNotFound currentLocation={location.pathname} />
            </RootLayout>
          }
        />
      </Route>
    </SentryRoutes>
  )
}
