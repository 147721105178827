import { useEffect } from 'react'

import { useCreditRenewalContext } from 'src/hooks'
import { CreditRenewalStepsEnum, StepsEnum } from 'src/types'
import { creditRenewalsApplicationDeclinedPageLoaded } from 'src/utils'
import {
  CREDIT_RENEWAL_DECLINE_PAGE_PAYMENT_PENDING,
  CREDIT_RENEWAL_DECLINE_PAGE_REJECTED,
  CREDIT_RENEWAL_DECLINE_REASON_PAYMENT_PENDING,
} from 'src/utils/constants'

/**
 * Custom hook for handling the declined state in the credit renewal process.
 *
 * This hook will track the page load event for the declined screen and returns the reason for the decline.
 *
 * @returns
 * - reason: The reason for the decline.
 * - screen: The current screen.
 */
export const useDeclined = () => {
  const screen = StepsEnum.DECLINED
  const {
    creditRenewalDecision: { decline_reasons },
  } = useCreditRenewalContext()

  // If the only decline reason is pending payment confirmation, show payment pending screen
  const reason =
    decline_reasons.length === 1 &&
    decline_reasons.includes(CREDIT_RENEWAL_DECLINE_REASON_PAYMENT_PENDING)
      ? CREDIT_RENEWAL_DECLINE_PAGE_PAYMENT_PENDING
      : CREDIT_RENEWAL_DECLINE_PAGE_REJECTED

  useEffect(() => {
    creditRenewalsApplicationDeclinedPageLoaded(
      'Application Declined',
      CreditRenewalStepsEnum.DECLINED,
    )
  }, [])

  return { reason, screen }
}
