import React, { ReactNode } from 'react'

import { DetailsIcon } from 'src/portal/components/DetailsIcon'
import { ResponsiveTooltip } from 'src/portal/components/tooltips/ResponsiveTooltip'
import { useTooltip } from 'src/portal/components/tooltips/useTooltip'

interface TooltipContainerProps {
  title: string
  content: ReactNode
}

export const TooltipContainer: React.FC<TooltipContainerProps> = ({ title, content }) => {
  const { isTooltipOpen, anchorEl, handleIconClick, handleCloseTooltip } = useTooltip()

  return (
    <>
      <DetailsIcon onClickHandler={handleIconClick} />
      <ResponsiveTooltip
        isOpen={isTooltipOpen}
        anchorEl={anchorEl}
        onClose={handleCloseTooltip}
        title={title}
        content={content}
      />
    </>
  )
}
