import Grid from '@mui/material/Grid'
import 'react-phone-number-input/style.css'
import { styled } from '@mui/material/styles'
import { TextField, Typography } from '@mui/material'
import { Value as E164Number } from 'react-phone-number-input/input'
import Button from '@mui/material/Button'
import PhoneEnabledOutlinedIcon from '@mui/icons-material/PhoneEnabledOutlined'
import PhoneIphoneOutlinedIcon from '@mui/icons-material/PhoneIphoneOutlined'
import { useTranslation } from 'react-i18next'

import { BackButton } from 'src/components/common/Buttons'
import { DivRoot } from 'src/components/common/DivRoot'
import { formatPhoneNumberSimple, formatPhoneNumberInternational } from 'src/utils/format'

import { AlertColor, FigAlert } from '../common/FigAlert'

const ALERT_COOL_DOWN_TIME_MS = 3 * 1000

const ButtonStyled = styled(Button)(({ theme }) => ({
  border: 0,
  textAlign: 'center',
  lineHeight: 'inherit',
  verticalAlign: 'inherit',
  padding: 0,
  '&:hover': {
    backgroundColor: 'transparent',
    color: 'inherit',
  },
  '&:disabled': {
    color: theme.color.grey4,
    pointerEvents: 'none',
  },
}))

const TextFieldNumberStyled = styled(TextField)({
  width: 48,
  marginLeft: 4,
  marginRight: 4,
  '& input[type=number]': {
    MozAppearance: 'textfield',
  },
  '& input[type=number]::-webkit-outer-spin-button': {
    WebkitAppearance: 'none',
  },
  '& input[type=number]::-webkit-inner-spin-button': {
    WebkitAppearance: 'none',
  },
  '& input': {
    textAlign: 'center',
    fontWeight: 600,
  },
})

interface Props {
  phone: E164Number | undefined
  verify: (code: string) => void
  resend: VoidFunction
  call: VoidFunction
  error: string | null
  setError: (value: string | null) => void
  codeList: string[]
  setCodeList: (value: string[]) => void
  isVerifying: boolean
  back: VoidFunction
  setResend: (value: boolean) => void
  isResend: boolean
  setCalled: (value: boolean) => void
  isCalled: boolean
}

export default VerifyOTP
/**
 *
 */
function VerifyOTP({
  phone,
  verify,
  resend,
  call,
  error,
  setError,
  codeList,
  setCodeList,
  isVerifying,
  back,
  isResend,
  setResend,
  setCalled,
  isCalled,
}: Props) {
  const { t } = useTranslation()

  return (
    <DivRoot>
      <Grid container display="flex" justifyContent="center">
        {isCalled && (
          <FigAlert
            color={AlertColor.SUCCESS}
            icon={<PhoneIphoneOutlinedIcon />}
            title={t('OTP.callAlertTitle')}
            content={t('OTP.callAlertContent', { phoneNumber: formatPhoneNumberSimple(phone) })}
            fade={{ lifespanMs: ALERT_COOL_DOWN_TIME_MS, callback: () => setCalled(false) }}
          />
        )}
        {isResend && (
          <FigAlert
            color={AlertColor.SUCCESS}
            icon={<PhoneEnabledOutlinedIcon />}
            title={t('OTP.textAlertTitle')}
            content={t('OTP.textAlertContent', { phoneNumber: formatPhoneNumberSimple(phone) })}
            fade={{ lifespanMs: ALERT_COOL_DOWN_TIME_MS, callback: () => setResend(false) }}
          />
        )}
      </Grid>
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <Typography variant="h1" textAlign="center">
            {t('OTP.verifyTitle')}
          </Typography>
          <Typography variant="body2" textAlign="center" marginTop="16px">
            {t('OTP.verifySubtitle', { phoneNumber: formatPhoneNumberInternational(phone) })}
          </Typography>
        </Grid>

        <Grid
          item
          xs={12}
          style={{ marginTop: 32, paddingLeft: 7, paddingRight: 7 }}
          display="flex"
          flexDirection="row"
          justifyContent="center"
        >
          {isVerifying && (
            <div style={{ position: 'absolute' }}>
              <Typography variant="body1" textAlign="center" marginTop="16px">
                {t('OTP.verifyingLabel')} ...
              </Typography>
            </div>
          )}
          <div
            style={{
              opacity: isVerifying ? 0.2 : 1,
              pointerEvents: isVerifying ? 'none' : 'auto',
            }}
          >
            {codeList.map((code, index) => {
              const key = `input-${index}`
              return (
                <TextFieldNumberStyled
                  id={key}
                  key={key}
                  value={code}
                  type="tel"
                  inputProps={{ maxLength: 1 }}
                  onChange={event => {
                    const list = [...codeList]
                    list[index] = event.target.value
                    setCodeList(list)
                    setError(null)

                    const complete = list.filter(Boolean).length === 6
                    if (complete) {
                      verify(list.join(''))
                    } else if (event.target.value) {
                      const nextSibiling = document.getElementById(`input-${index + 1}`)
                      if (nextSibiling !== null) {
                        nextSibiling.focus()
                      }
                    }
                  }}
                />
              )
            })}
          </div>
        </Grid>

        {error && (
          <Grid item xs={12} style={{ marginTop: 32 }} display="flex" flexDirection="column">
            <Typography variant="body2" color="error" textAlign="center" marginTop="0px">
              {t('OTP.verifyErrorMessage')}
            </Typography>
            <Typography variant="body2" color="error" textAlign="center" marginTop="0px">
              {t('OTP.verifyErrorRetry')}
            </Typography>
          </Grid>
        )}

        <Grid item xs={12} style={{ marginTop: 32 }}>
          <Typography variant="body2" textAlign="center">
            {t('OTP.sendItAgainQuestion')}{' '}
            <ButtonStyled
              color="primary"
              onClick={() => {
                setCodeList(['', '', '', '', '', ''])
                resend()
              }}
              disabled={isResend || isCalled}
            >
              {t('OTP.sendItAgainLabel')}
            </ButtonStyled>
          </Typography>
        </Grid>

        <Grid item xs={12} style={{ marginTop: '20px' }} textAlign="center">
          <Typography variant="body2" textAlign="center">
            <ButtonStyled color="primary" onClick={call} disabled={isResend || isCalled}>
              {t('OTP.callLabel')}
            </ButtonStyled>
          </Typography>
        </Grid>

        <Grid item xs={12} display="flex" justifyContent="center" marginTop="16px">
          <BackButton variant="textGrey" color="primary" onClick={back} />
        </Grid>
      </Grid>
    </DivRoot>
  )
}
