import { Link } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { getAnalyticsCallback } from 'src/utils'

const HelpLinkGeneric = () => {
  const { t } = useTranslation()
  const helpLabel = t('common.helpLinkLabelGeneric')
  return (
    <Link
      whiteSpace="nowrap"
      href={t('HelpDesk.declinedUrl')}
      target="_blank"
      onClick={() => {
        getAnalyticsCallback(helpLabel)
      }}
    >
      {helpLabel}
    </Link>
  )
}

export default HelpLinkGeneric
