import { useState, useEffect } from 'react'
import { useQuery } from 'react-query'

import { LoadingAnimation } from 'src/components/common/animations/Loading'
import { useOriginationContext, useApi } from 'src/hooks'
import SelectExisting from 'src/components/PaymentMethod/SelectExisting'
import SelectMethod from 'src/components/PaymentMethod/SelectMethod'
import { BankingType, GetPaymentResponseDataType } from 'src/types'
import { copy } from 'src/utils'

export default PaymentMethod
/**
 *
 */
function PaymentMethod() {
  const {
    applicationId,
    setCachedApplications,
    cachedApplications,
    bankAccounts,
    setBankAccounts,
    isCheckingAppStatus,
  } = useOriginationContext()
  const { getPaymentMethods } = useApi()
  const [isLoading, setIsLoading] = useState(true)

  useQuery<GetPaymentResponseDataType>(['getPaymentMethods'], () => getPaymentMethods(), {
    onSuccess: result => {
      const bankResult: BankingType[] = result.data.bank_list
      bankResult.sort((a: BankingType, b: BankingType) => b.updated_at.localeCompare(a.updated_at))
      setBankAccounts(bankResult)
      setIsLoading(false)
    },
  })

  useEffect(() => {
    if (!bankAccounts.length) {
      return
    }

    if (!cachedApplications[applicationId]?.selected_bank_account) {
      const updatedCachedApplications = copy(cachedApplications)
      updatedCachedApplications[applicationId].selected_bank_account = bankAccounts[0].bank_id
      setCachedApplications(updatedCachedApplications)
    }
  }, [bankAccounts])

  if (isLoading || isCheckingAppStatus) {
    return <LoadingAnimation />
  }

  return bankAccounts.length ? <SelectExisting /> : <SelectMethod />
}
