import { useState } from 'react'

interface UseTooltipReturn {
  isTooltipOpen: boolean
  anchorEl: HTMLElement | null
  handleIconClick: (event: React.MouseEvent<HTMLElement>) => void
  handleCloseTooltip: () => void
}

/**
 * Custom hook to manage the state and behavior of a tooltip.
 *
 * This hook is used in TooltipContainer to contain all tooltip open/close logic.
 *
 * @returns {UseTooltipReturn} An object containing the state and handlers for the tooltip.
 * @property {boolean} isTooltipOpen - Indicates whether the tooltip is open.
 * @property {HTMLElement | null} anchorEl - The element that the tooltip is anchored to.
 * @property {function} handleIconClick - Handler to toggle the tooltip open/close state when an icon is clicked.
 * @property {function} handleCloseTooltip - Handler to close the tooltip.
 */
export const useTooltip = (): UseTooltipReturn => {
  const [isTooltipOpen, setTooltipOpen] = useState(false)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const handleIconClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
    setTooltipOpen(prev => !prev)
  }

  const handleCloseTooltip = () => {
    setTooltipOpen(false)
    setAnchorEl(null)
  }

  return { isTooltipOpen, anchorEl, handleIconClick, handleCloseTooltip }
}
