import { styled } from '@mui/material/styles'
import { Grid, Typography } from '@mui/material'

import theme from 'src/themes'
import { GridImage } from 'src/components/common/GridImage'

export const ErrorImage = styled('img')({
  width: '80px',
  height: '80px',
})

export const ErrorImageGrid = styled(GridImage)({
  marginBottom: '32px',
})

export const ErrorTitle = styled(Typography)({
  ...theme.typography.h1,
  textAlign: 'center',
  marginBottom: '16px',
  whiteSpace: 'pre-line',
})

export const ErrorContent = styled(Typography)({
  ...theme.typography.body2,
  textAlign: 'center',
  lineHeight: '24px',
  marginBottom: '32px',
})

export const ErrorButtonGrid = styled(Grid)({
  gap: '18px',
  display: 'flex',
  flexDirection: 'column',
})
