import { styled } from '@mui/material/styles'
import { Grid, ListItem, ListItemButton } from '@mui/material'

import { figColors } from 'src/themes'

export const SelectOfferListItem = styled(ListItem)(({ theme }) => ({
  borderColor: theme.color.grey3,
  borderWidth: '1px',
  borderStyle: 'solid',
  borderRadius: '3px',
  ['&.Mui-selected']: {
    borderColor: theme.color.brand1,
    borderWidth: '2px',
    background: 'none',
  },
  ':hover': {
    borderColor: theme.color.brand1,
    background: 'none',
  },
}))

export const SelectOfferListItemButton = styled(ListItemButton)({
  paddingTop: '8px',
  paddingBottom: '8px',
  paddingLeft: '0px',
  paddingRight: '0px',
  justifyContent: 'left',
  ':hover': {
    background: 'none',
  },
})

export const APRGuarantee = styled(Grid)({
  backgroundColor: figColors.brand6,
  marginTop: '12px',
  padding: '12px 33px',
  justifyContent: 'center',
  borderRadius: '8px',
  flexDirection: 'column',
  '> img': {
    marginBottom: '4px',
  },
  '> p': {
    lineHeight: '24px',
    color: 'black',
  },
})
