import styled from '@emotion/styled'
import { Box, Divider, Grid, Typography } from '@mui/material'
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md'

import theme from 'src/themes'

export const ImgStyle = styled('img')<{ isMobile?: boolean }>(({ isMobile }) => ({
  width: isMobile ? '64px' : '80px',
  height: isMobile ? '64px' : '80px',
  display: 'block',
  marginLeft: 'auto',
  marginRight: 'auto',
}))

export const TableGridItemHeader = styled(Grid)({
  cursor: 'pointer',
})

export const TableDivider = styled(Divider)({
  marginTop: '16px',
  marginBottom: '16px',
  borderColor: theme.color.border,
})

export const ChangeLink = styled(Typography)({
  fontWeight: 500,
  color: theme.color.brand1,
  textDecoration: 'none',
  cursor: 'pointer',
})

export const ArrowDownIcon = styled(MdKeyboardArrowDown)({
  fontSize: 22,
  color: theme.color.grey6,
})

export const ArrowUpIcon = styled(MdKeyboardArrowUp)({
  fontSize: 22,
  color: theme.color.grey6,
})

export const TypographyDetail = styled(Typography)({
  marginTop: '4px',
  fontWeight: 400,
  fontSize: '14px',
  lineHeight: '20px',
  verticalAlign: 'middle',
  display: 'inline-flex',
})

export const RightBox = styled(Box)({
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'start',
  cursor: 'pointer',
})
