import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { Box } from '@mui/material'
import React from 'react'

import PortalTheme from 'src/themes/portal'
interface DetailsIconProps {
  onClickHandler?: (event: React.MouseEvent<HTMLElement>) => void
}
/**
 * A functional component that renders the tooltip icon with support of an on click handler.
 *
 * @param {() => void} onClickHandler - The function to call when the image is clicked.
 * @returns {JSX.Element} The rendered image element.
 */
export const DetailsIcon: React.FC<DetailsIconProps> = ({ onClickHandler }) => {
  return (
    <Box display="inline-block" ml={'4px'} onClick={onClickHandler}>
      <InfoOutlinedIcon
        sx={{
          color: PortalTheme.color.brand1,
          verticalAlign: 'middle',
          cursor: 'pointer',
          width: '16px',
          height: '16px',
        }}
      />
    </Box>
  )
}
