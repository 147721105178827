import { Box } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { portalMenudivider } from 'src/images'

export const MenuDivider = () => {
  const { t } = useTranslation()
  return (
    <Box style={{ display: 'flex', justifyContent: 'left' }}>
      <img
        src={portalMenudivider}
        style={{
          paddingLeft: '20px',
          paddingTop: '16px',
          paddingBottom: '16px',
          maxWidth: '90%',
        }}
        alt={t('Portal.Menu.alt.dividerIcon')}
      />
    </Box>
  )
}
