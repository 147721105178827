import { useTranslation, Trans } from 'react-i18next'

import { PrimaryCTA } from 'src/components/common/Buttons'
import { PleaseReachOut } from 'src/components/Error/shared/PleaseReachOut'
import { verificationFailImg } from 'src/images'
import {
  orderDeclinedContactFigClicked,
  orderDeclinedHelpCentreClicked,
  orderDeclinedLiveChatClicked,
} from 'src/utils'
import { StepsEnum } from 'src/types'
import ErrorPage from 'src/components/Error/shared/ErrorPage'

/**
 *
 */
export function ApplicationOrderDeclined() {
  const { t } = useTranslation()
  const screen = StepsEnum.ORDER_DECLINED
  const content = (
    <Trans i18nKey="ApplicationOrderDeclined.content">
      We have identified issues with your application, and unfortunately, we are unable to offer you
      a loan at this time. Fig regularly reviews applications similar to yours, and we may contact
      you for additional information. If you would like to contact us directly,{' '}
      <PleaseReachOut
        screen={screen}
        liveChatAnalytics={orderDeclinedLiveChatClicked}
        contactFigAnalytics={orderDeclinedContactFigClicked}
      />
    </Trans>
  )
  const helpCentreButton = (
    <PrimaryCTA
      target="_blank"
      href={t('HelpDesk.declinedUrl')}
      onClick={() => {
        orderDeclinedHelpCentreClicked(t('common.helpLabel'), screen)
      }}
      buttonText={t('common.helpLabel')}
    />
  )
  return (
    <ErrorPage
      image={{ src: verificationFailImg, alt: t('ApplicationOrderDeclined.alt') }}
      title={t('ApplicationOrderDeclined.title')}
      content={content}
      buttons={[helpCentreButton]}
    />
  )
}
