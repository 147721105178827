import Grid from '@mui/material/Grid'
import { addDays, eachDayOfInterval, isWeekend } from 'date-fns'

import { RepaymentStartHeading } from 'src/components/PaymentSchedule/shared/RepaymentStartHeading'
import { getIsInvalidDateFromRange, FigDatePickerDay } from 'src/components/common/FigDatePicker'

/**
 *
 */
export function EveryTwoWeeksScheduleOptions({
  earliestDate,
  selectedFirstPaymentDate,
  setSelectedFirstPaymentDate,
  setScheduleReady,
}: {
  earliestDate: Date
  selectedFirstPaymentDate: Date | null
  setSelectedFirstPaymentDate: (d: Date) => void
  setScheduleReady: () => void
}) {
  const validDates: Date[] = eachDayOfInterval({
    start: earliestDate,
    end: addDays(earliestDate, 13),
  }).filter(day => !isWeekend(day))

  const setCalendarDate = (date: Date) => {
    setSelectedFirstPaymentDate(date)
    setScheduleReady()
  }

  return (
    <Grid container marginTop="24px" id="startTwoWeekWrapper">
      <RepaymentStartHeading />
      <Grid container spacing="0px" marginBottom="24px">
        <Grid item xs={12} id="datePickerTwoWeekWrapper">
          <FigDatePickerDay
            getIsInvalidDate={getIsInvalidDateFromRange(validDates)}
            calendarDate={selectedFirstPaymentDate}
            setCalendarDate={setCalendarDate}
            disableWeekends={true}
          />
        </Grid>
      </Grid>
    </Grid>
  )
}
