import { LDClient, LDSingleKindContext } from 'launchdarkly-react-client-sdk'
import { captureMessage } from '@sentry/react'

/*
 * Use the LDSingleKindContext, adding some of our own fields for segmentation
 */
interface UserLDContext extends LDSingleKindContext {
  borrowerId?: string
  partnerId: string
  partnerName: string
}

export const getUserLDContext = ({
  borrowerId,
  partnerName,
  partnerId,
}: {
  borrowerId: string
  partnerName: string
  partnerId: string
}): UserLDContext => ({
  kind: 'user',
  anonymous: false,
  key: borrowerId,
  borrowerId,
  partnerId,
  partnerName,
})

export const getAnonymousUserLDContext = ({
  ldClient,
  partnerName,
  partnerId,
}: {
  ldClient: LDClient
  partnerName: string
  partnerId: string
}): UserLDContext => ({
  ...(ldClient.getContext() as LDSingleKindContext), // re-use anonymous key
  kind: 'user',
  partnerId,
  partnerName,
})

/*
 * Identify with LaunchDarkly - either using a borrowerId in origination, or the default anonymous key in E2E
 */
export const ldIdentify = async ({
  ldClient,
  borrowerId,
  partnerName,
  partnerId,
}: {
  ldClient: LDClient | undefined
  borrowerId?: string
  partnerName: string
  partnerId: string
}): Promise<void> => {
  if (!ldClient) {
    return
  }
  try {
    await ldClient.waitForInitialization()
    ldClient.on('error', error => {
      captureMessage(JSON.stringify(error), 'warning')
    })

    await ldClient.identify(
      borrowerId
        ? getUserLDContext({ borrowerId, partnerId, partnerName })
        : getAnonymousUserLDContext({ ldClient, partnerId, partnerName }),
    )
  } catch (err) {
    captureMessage(`LD Client did not load due ${err}`, 'warning')
  }
}
