import { Grid2 } from '@mui/material'

/**
 * Renders components within a `Grid2` container with space-between and horizontal padding.
 *
 * @param {Object} props - The component props.
 * @param {React.ReactNode} props.children - The child elements.
 *
 * @returns {JSX.Element} The rendered container.
 */
export const SpacedRow: React.FC<{ children: React.ReactNode }> = ({ children }) => (
  <Grid2 container justifyContent={'space-between'} minHeight="30px">
    {children}
  </Grid2>
)
