import { useTranslation, Trans } from 'react-i18next'

import { PrimaryCTA } from 'src/components/common/Buttons'
import { PleaseReachOut } from 'src/components/Error/shared/PleaseReachOut'
import { genericErrorImg } from 'src/images'
import { StepsEnum } from 'src/types'
import { useOriginationContext } from 'src/hooks'
import {
  bankErrorConnectBankAccountClicked,
  bankErrorContactFigClicked,
  bankErrorLiveChatClicked,
} from 'src/utils'
import ErrorPage from 'src/components/Error/shared/ErrorPage'

export default BankConnectError
/**
 *
 */
function BankConnectError() {
  const { t } = useTranslation()
  const screen = StepsEnum.BANK_CONNECT_ERROR
  const { setStep } = useOriginationContext()

  const content = (
    <Trans i18nKey="BankConnectError.content">
      We weren’t able to connect to your bank account. Click the ‘Try again’ button, or{' '}
      <PleaseReachOut
        screen={screen}
        liveChatAnalytics={bankErrorLiveChatClicked}
        contactFigAnalytics={bankErrorContactFigClicked}
      />
    </Trans>
  )
  const tryAgainButton = (
    <PrimaryCTA
      onClick={() => {
        bankErrorConnectBankAccountClicked(t('common.retryLabel'), screen)
        setStep(StepsEnum.BANK_AUTO_CONNECT)
      }}
      buttonText={t('common.retryLabel')}
    />
  )
  return (
    <ErrorPage
      image={{ src: genericErrorImg, alt: t('BankConnectError.alt') }}
      title={t('BankConnectError.title')}
      content={content}
      buttons={[tryAgainButton]}
    />
  )
}
