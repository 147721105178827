import { useTranslation } from 'react-i18next'

import { expiredImg } from 'src/images'
import { StepsEnum } from 'src/types'
import { useOriginationContext } from 'src/hooks'
import { PrimaryCTA } from 'src/components/common/Buttons'

import ErrorPage from '../Error/shared/ErrorPage'

export default OnfidoTokenExpired
/**
 *
 */
function OnfidoTokenExpired() {
  const { t } = useTranslation()
  const { setStep } = useOriginationContext()
  const onTryAgainClick = () => {
    setStep(StepsEnum.KYC_VERIFY)
  }

  const tryAgainButton = (
    <PrimaryCTA onClick={onTryAgainClick} buttonText={t('Onfido.tokenExpired.button')} />
  )
  return (
    <ErrorPage
      image={{ src: expiredImg, alt: t('Onfido.tokenExpired.alt') }}
      title={t('Onfido.tokenExpired.title')}
      content={t('Onfido.tokenExpired.content')}
      buttons={[tryAgainButton]}
    />
  )
}
