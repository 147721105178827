import log from 'loglevel'

import { ENVIRONMENT } from 'src/utils/constants'

const ENABLE_LOG = ['development', 'local', 'staging', ''].includes(ENVIRONMENT)
if (ENABLE_LOG) {
  log.setLevel('debug')
}

export default {
  info: (...args: any[]) => ENABLE_LOG && log.info(...args),
  error: (...args: any[]) => ENABLE_LOG && log.error(...args),
  exception: (...args: any[]) => ENABLE_LOG && log.error(...args),
}
