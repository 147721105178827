import * as Sentry from '@sentry/react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'

import { LoadingAnimation } from 'src/components/common/animations/Loading'
import { useApi, useCreditRenewalContext } from 'src/hooks'
import { CreditRenewalStepsEnum } from 'src/types'
import { FlinksPaymentCheckResponseDataType } from 'src/types/api'
import { ENVIRONMENT } from 'src/utils/constants'

export default FlinksPaymentCheck
/**
 *
 */
function FlinksPaymentCheck() {
  const { flinksLoginId, setCRStep } = useCreditRenewalContext()
  const { jwtApiKey } = useParams()
  const { t } = useTranslation()
  const [failureCount, setFailureCount] = useState(0)
  const { flinksPaymentCheck } = useApi()
  // Poll for 5 minutes to see if the flinks webhook, and q2 payment saving
  // and linking are done
  const maxFailures = 60 * 5

  useQuery<FlinksPaymentCheckResponseDataType>(
    ['flinksPaymentCheck', flinksLoginId],
    () => flinksPaymentCheck(flinksLoginId),
    {
      refetchInterval: failureCount <= maxFailures ? 1000 : false,
      onSuccess: (result: FlinksPaymentCheckResponseDataType): void => {
        if (result.data.is_declined) {
          setCRStep(CreditRenewalStepsEnum.DECLINED, jwtApiKey!)
        } else if (!result.data.is_pii_match) {
          setCRStep(CreditRenewalStepsEnum.BANK_CONNECT_ERROR, jwtApiKey!)
        } else if (!result.data.is_bank_account_valid && result.data.is_failed) {
          setCRStep(CreditRenewalStepsEnum.BANK_CONNECT_ERROR, jwtApiKey!)
        } else if (result.data.is_failed) {
          setCRStep(CreditRenewalStepsEnum.BANK_CONNECT_ERROR, jwtApiKey!)
        } else if (result.data.is_complete) {
          setCRStep(CreditRenewalStepsEnum.BANK_SUCCESS, jwtApiKey!)
        } else {
          if (failureCount < maxFailures) {
            setFailureCount(failureCount + 1)
          } else {
            Sentry.captureMessage(
              `Flinks bank connect did not complete within 5 minutes ${ENVIRONMENT}`,
              'warning',
            )
            setCRStep(CreditRenewalStepsEnum.BANK_CONNECT_ERROR, jwtApiKey!)
          }
        }
      },
    },
  )

  return <LoadingAnimation subtitle={t('FlinksPaymentCheck.loading')} />
}
