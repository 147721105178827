import { Box, Typography, useMediaQuery, useTheme, styled } from '@mui/material'
import { Trans, useTranslation } from 'react-i18next'
import Grid from '@mui/material/Grid2'

import { ApplyNowButton } from 'src/components/EndToEnd/landing/Walmart/components/ApplyNowButton'
import { RateDisclaimer } from 'src/components/EndToEnd/landing/Walmart/components/RateDisclaimer'
import { SectionContainer } from 'src/components/EndToEnd/landing/Walmart/components/SectionContainer'
import { MobileDivider } from 'src/components/EndToEnd/landing/Walmart/styles'
import { ApplyNowButtonSizes } from 'src/components/EndToEnd/landing/Walmart/types'
import { transferAndSaveEn, transferAndSaveFr } from 'src/images'
import { ImgStyle } from 'src/components/EndToEnd/landing/Walmart/styles'

export const GridColumn = styled(Grid)(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
}))

export const SpanNumber = styled('span')(({ theme }) => ({
  ...theme.typography.subheading,
}))

export const SupSuffix = styled('sup')(() => ({
  fontSize: '12px',
  position: 'absolute',
  top: '-8px',
}))

export const SupPrefix = styled('sup')(() => ({
  fontSize: '12px',
}))

export const SupContainer = styled('sup')(() => ({
  position: 'relative',
}))

export const TransferSection = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  return (
    <SectionContainer>
      <Grid
        size={{ xs: 12, md: 5 }}
        sx={{
          order: { xs: 2, md: 1 },
          textAlign: isMobile ? 'center' : 'left',
          paddingRight: isMobile ? 0 : '90px',
          paddingTop: isMobile ? '24px' : 0,
        }}
      >
        <Typography component="h5" variant="sectionHeading">
          {t('e2e.Landing.walmart.transferSection.left.title')}
        </Typography>
        <Typography component="p" variant="body">
          {t('e2e.Landing.walmart.transferSection.left.content')}
        </Typography>
        <ApplyNowButton size={ApplyNowButtonSizes.LARGE} />
        <Box>
          <RateDisclaimer />
        </Box>
        <MobileDivider />
      </Grid>
      <Grid
        size={{ xs: 12, md: 7 }}
        sx={{
          order: {
            xs: 1,
            md: 2,
            textAlign: isMobile ? 'center' : 'left',
            paddingRight: isMobile ? 0 : '60px',
            paddingTop: isMobile ? '24px' : 0,
          },
        }}
        container
      >
        <ImgStyle src={language === 'fr' ? transferAndSaveFr : transferAndSaveEn} />
        <Grid size={{ xs: 12 }} marginTop="25px">
          <Typography component="p" variant="body">
            <Trans
              i18nKey="e2e.Landing.walmart.transferSection.right.footer"
              components={{ sup: <SupPrefix /> }}
            />
          </Typography>
        </Grid>
      </Grid>
    </SectionContainer>
  )
}
