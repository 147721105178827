import { keyframes } from '@emotion/react'

export const sectionGap = {
  pb: '24px',
}

export const fadeIn = keyframes`
from {
  opacity: 0;
}
to {
  opacity: 1;
}
`

export const fadeOut = keyframes`
from {
  opacity: 1;
}
to {
  opacity: 0;
}
`

export const slideUp = keyframes`
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
`

export const slideDown = keyframes`
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(100%);
  }
`

export const slideLeft = keyframes`
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
`
export const slideRight = keyframes`
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(100%);
  }
`

export const zIndexAboveFreshChat = 2147483601 //must be higher value than freshchat's z-index of 2147483600
