import { Box, Chip, Grid2, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { useLocalizedFormatters } from 'src/hooks/useLocalizedFormatters'
import { TransactionHistoryResponseSchema } from 'src/portal/api/api.schemas'
import { TransactionStatusEnum } from 'src/portal/common'
import { SpacedRow } from 'src/portal/components/SpacedRow'
import { StretchDivider } from 'src/portal/components/StretchDivider'
import { TooltipContainer } from 'src/portal/components/tooltips/TooltipContainer'
import { TransactionRowContent } from 'src/portal/pages/home/summary/content/TooltipContent'
import PortalTheme from 'src/themes/portal'

interface StatusBadgeProps {
  status?: string
}

const StatusBadge = ({ status }: StatusBadgeProps) => {
  const errorStyles = {
    color: PortalTheme.color.error,
    borderColor: PortalTheme.color.error,
    backgroundColor: '#FFF4F4',
  }
  const { t } = useTranslation()
  const standardStyles = { fontWeight: 400, fontSize: '14px', lineHeight: '18px' }
  const isErrorStatus = status === TransactionStatusEnum.FAILED
  const statusLabel = isErrorStatus
    ? t('Portal.Home.label.transactions.status.failed')
    : t('Portal.Home.label.transactions.status.processing')

  if (status === TransactionStatusEnum.SUCCESS) {
    return null // do not show badge for successful transactions
  }

  return (
    <Chip
      label={statusLabel}
      sx={{
        ...standardStyles,
        ...(isErrorStatus && errorStyles),
      }}
    />
  )
}

interface TransactionRowProps {
  transaction: TransactionHistoryResponseSchema
  paymentProtectionApplied: boolean | undefined
}

enum TransactionFeeNamesEnum {
  NSF_FEES = 'NSF Fees',
  PAYMENT_PROTECTION_PREMIUM = 'Premium Fee',
  PAYMENT_PROTECTION_TAX = 'Insurance Tax Fee',
}

export const TransactionsRow = ({ transaction, paymentProtectionApplied }: TransactionRowProps) => {
  const { currencyFormat, dateFormatCustom } = useLocalizedFormatters()
  const { t } = useTranslation()

  const nsfFeeAmount = transaction.fee_payment.find(
    fee => fee.charge?.name === TransactionFeeNamesEnum.NSF_FEES,
  )?.transaction_amount

  let nsfFee = null

  if (paymentProtectionApplied) {
    nsfFee = nsfFeeAmount || 0
  } else {
    nsfFee = nsfFeeAmount || null
  }

  const paymentProtectionPremium = transaction.fee_payment.find(
    fee => fee.charge?.name === TransactionFeeNamesEnum.PAYMENT_PROTECTION_PREMIUM,
  )?.transaction_amount

  const paymentProtectionPremiumTax = transaction.fee_payment.find(
    fee => fee.charge?.name === TransactionFeeNamesEnum.PAYMENT_PROTECTION_TAX,
  )?.transaction_amount

  return (
    <Box mb="8px">
      <SpacedRow>
        <Grid2>
          <Typography variant="body3" color={PortalTheme.color.grey7}>
            {dateFormatCustom(
              new Date(transaction.transaction_creation_date),
              t('Portal.Home.label.summary.dateFormat'),
            )}
          </Typography>
        </Grid2>
      </SpacedRow>

      <SpacedRow>
        <Grid2>
          <Typography variant="body2" color={PortalTheme.color.grey10}>
            {/* TODO: translate payment_mode */}
            {transaction.payment_mode}
          </Typography>
        </Grid2>
        <Grid2 alignItems="flex-start">
          <Typography
            variant="body1"
            color={PortalTheme.color.grey10}
            style={{ display: 'inline' }}
          >
            {currencyFormat(transaction.transaction_amount)}
          </Typography>
          <TooltipContainer
            title={t('Portal.Components.tooltip.title.paymentDetails')}
            content={
              <TransactionRowContent
                principal={transaction.principal}
                creditCharges={transaction.interest}
                // TransactionRowContent will hide pp, nsf fees if they are undefined
                paymentProtectionPremium={paymentProtectionPremium}
                paymentProtectionPremiumTax={paymentProtectionPremiumTax}
                nsfFees={nsfFee}
                totalAmount={transaction.transaction_amount}
              />
            }
          />
        </Grid2>
      </SpacedRow>

      <SpacedRow>
        <Grid2 size={8}>
          {transaction.transaction_status === TransactionStatusEnum.PROCESSING && (
            <Typography variant="body3" color={PortalTheme.color.grey7}>
              {t('Portal.Home.label.transactions.message.processing')}
            </Typography>
          )}
        </Grid2>
        <Grid2 size={4} display="flex" justifyContent="flex-end">
          <StatusBadge status={transaction.transaction_status} />
        </Grid2>
        <StretchDivider />
      </SpacedRow>
    </Box>
  )
}
