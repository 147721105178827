import Axios from 'axios'
import { FC, createContext, ReactNode, useContext } from 'react'
import { useQuery } from 'react-query'

import { API_URL } from 'src/utils/constants'
import { LoanLimits, LoanLimitsApiType } from 'src/types'
import { getRetryQueryOptionsWithErrorStep } from 'src/api/api'
import { PrequalificationContext } from 'src/contexts'

type ConfigurationContextProps = {
  loanLimits: LoanLimits & { isSuccess: boolean; isLoading: boolean; isError: boolean }
}

export const ConfigurationContext = createContext<ConfigurationContextProps>(
  {} as ConfigurationContextProps,
)

interface Props {
  children: ReactNode
}

export const ConfigurationProvider: FC<Props> = ({ children }) => {
  const { partnerId } = useContext(PrequalificationContext)

  const { isSuccess, isLoading, isError, data } = useQuery(
    ['loanLimits', partnerId],
    () => getLoanLimits(partnerId || ''),
    {
      enabled: !!partnerId,
      useErrorBoundary: false,
      ...getRetryQueryOptionsWithErrorStep({ queryName: 'loan_limits' }),
    },
  )

  return (
    <ConfigurationContext.Provider
      value={{
        loanLimits: {
          ...data,
          isSuccess,
          isLoading,
          isError,
        },
      }}
    >
      {children}
    </ConfigurationContext.Provider>
  )
}

async function getLoanLimits(partnerId: string): Promise<LoanLimits> {
  const headers: any = {
    'Content-Type': 'application/json',
  }

  const { data } = await Axios.post<LoanLimitsApiType>(
    `${API_URL}v1/configuration/loan_limits`,
    {
      partner_id: partnerId,
    },
    { headers },
  )

  return {
    minApr: data.min_apr,
    maxApr: data.max_apr,
    minLoanAmount: data.min_amount || 2000, // Retrieve min from BE now but default to 2000 for the 24 hour cache
    maxLoanAmount: data.max_amount,
  }
}
