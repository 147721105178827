import { useEffect } from 'react'
import { useTranslation, Trans } from 'react-i18next'
import { captureMessage } from '@sentry/react'

import { maintenanceImg } from 'src/images'
import ErrorPage from 'src/components/Error/shared/ErrorPage'

export default MaintenanceMode
/**
 *
 */
function MaintenanceMode() {
  const { t } = useTranslation()
  useEffect(() => {
    captureMessage('The Fig App is in maintenance mode', {
      level: 'error',
    })
  }, [])

  const content = <Trans i18nKey="MaintenanceMode.content" components={{ 1: <br /> }} />

  return (
    <ErrorPage
      image={{ src: maintenanceImg, alt: t('MaintenanceMode.alt') }}
      title={t('MaintenanceMode.title')}
      content={content}
      buttons={[]}
    />
  )
}
