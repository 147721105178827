import { styled, Button, CircularProgress } from '@mui/material'
import { ComponentProps } from 'react'
import { useTranslation } from 'react-i18next'

const ButtonSubmit = styled(Button)(({ theme }) => ({
  borderRadius: 100,
  '&:hover': {
    borderRadius: 100,
  },
  width: theme.width.buttonPrimaryFull,
  height: '46px',
  '&.Mui-disabled': {
    color: theme.color.grey4,
    '&.MuiButton-containedPrimary': {
      backgroundColor: theme.color.grey2,
    },
  },
}))

type ButtonCTAType = { buttonText: string; loading?: boolean } & ComponentProps<typeof Button> &
  React.ComponentPropsWithRef<'a'>

/**
 * A wrapper which allows the button to be 'clicked' when disabled.
 */
export function ButtonCTA(props: ButtonCTAType) {
  const { buttonText, target, loading, ...otherProps } = props
  return (
    <ButtonSubmit color="primary" {...otherProps} {...{ target }}>
      {loading ? <CircularProgress sx={{ color: 'currentcolor' }} /> : buttonText}
    </ButtonSubmit>
  )
}

/**
 *
 */
export const PrimaryCTA = (props: ButtonCTAType) => <ButtonCTA variant="contained" {...props} />

/**
 *
 */
export const SecondaryCTA = (props: ButtonCTAType) => <ButtonCTA variant="outlined" {...props} />

/**
 *
 */
export const BasicCTA = (props: ButtonCTAType) => <ButtonCTA variant="text" {...props} />

/**
 *
 */
export const BackButton = (props: Omit<ButtonCTAType, 'buttonText'>) => {
  const { t } = useTranslation()
  const buttonText = t('common.backlabel')
  return <ButtonCTA variant="textGrey" buttonText={buttonText} {...props} />
}
