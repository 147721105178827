import * as React from 'react'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import CloseIcon from '@mui/icons-material/Close'
import { useTranslation } from 'react-i18next'
import { Divider, useMediaQuery } from '@mui/material'
import { useState } from 'react'

import { faqPageIcon } from 'src/images'
import { SecondaryCTA } from 'src/components/common/Buttons'
import theme from 'src/themes'

import { AccordionComponent } from './FAQAccordion'
import {
  ModalCloseButton,
  FAQDivStyle,
  FAQGridImage,
  FAQImgStyle,
  FAQPageListFirstAccordion,
  FAQPageListItem,
  FAQPageSecondQuestionAccordion,
} from './styles'

export default function FaqPage({ handleClose }: { handleClose: VoidFunction }) {
  const { t } = useTranslation()
  const [viewMoreClicked, setViewMoreClicked] = useState(false)
  const [expanded, setExpanded] = React.useState<string>('')
  const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : '')
  }

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const accordionContent = [
    {
      title: t('PaymentProtection.FAQ.question1'),
      panelId: 'FAQ-question1',
      contents: (
        <>
          <Typography variant="h5" textAlign="left" paddingBottom="8px">
            {t('PaymentProtection.FAQ.subtitle1_point1')}
          </Typography>
          <FAQPageListFirstAccordion
            title={t('PaymentProtection.FAQ.subtitle1_point2_title')}
            content={t('PaymentProtection.FAQ.subtitle1_point2')}
          />
          <FAQPageListFirstAccordion
            title={t('PaymentProtection.FAQ.subtitle1_point3_title')}
            content={t('PaymentProtection.FAQ.subtitle1_point3')}
          />
          <FAQPageListFirstAccordion
            title={t('PaymentProtection.FAQ.subtitle1_point4_title')}
            content={t('PaymentProtection.FAQ.subtitle1_point4')}
          />
          <FAQPageListFirstAccordion
            title={t('PaymentProtection.FAQ.subtitle1_point5_title')}
            content={t('PaymentProtection.FAQ.subtitle1_point5')}
          />
          <Typography variant="h5" textAlign="left" padding="16px 16px 16px 0px">
            {t('PaymentProtection.FAQ.subtitle1_point6')}
          </Typography>
          <Typography variant="h5" textAlign="left" padding="0px 16px 16px 0px">
            {t('PaymentProtection.FAQ.subtitle1_point7')}
          </Typography>
        </>
      ),
      hasDivider: true,
    },
    {
      title: t('PaymentProtection.FAQ.question2'),
      panelId: 'FAQ-question2',
      contents: (
        <>
          <Typography variant="h5" fontWeight="bold" textAlign="left" padding="0px 16px 16px 0px">
            {t('PaymentProtection.FAQ.subtitle2_point1')}
          </Typography>
          <FAQPageListItem text={t('PaymentProtection.FAQ.subtitle2_point2')} />
          <FAQPageListItem text={t('PaymentProtection.FAQ.subtitle2_point3')} />
          <FAQPageListItem text={t('PaymentProtection.FAQ.subtitle2_point4')} />
          <FAQPageListItem text={t('PaymentProtection.FAQ.subtitle2_point5')} />
          <Typography variant="h5" fontWeight="bold" textAlign="left" padding="0px 16px 16px 0px">
            {t('PaymentProtection.FAQ.subtitle2_point6')}
          </Typography>
          <FAQPageListItem text={t('PaymentProtection.FAQ.subtitle2_point7')} />
          <FAQPageListItem text={t('PaymentProtection.FAQ.subtitle2_point8')} />
          <FAQPageListItem text={t('PaymentProtection.FAQ.subtitle2_point9')} />
          <Typography variant="h5" fontWeight="bold" textAlign="left" padding="0px 16px 16px 0px">
            {t('PaymentProtection.FAQ.subtitle2_point10')}
          </Typography>
          <FAQPageListItem text={t('PaymentProtection.FAQ.subtitle2_point11')} />
          <FAQPageSecondQuestionAccordion
            title={t('PaymentProtection.FAQ.subtitle2_point12')}
            content1={t('PaymentProtection.FAQ.subtitle2_point12_bold_point1')}
            content2={t('PaymentProtection.FAQ.subtitle2_point12_bold_point2')}
          />
          <FAQPageListItem text={t('PaymentProtection.FAQ.subtitle2_point13')} />
          <FAQPageListItem text={t('PaymentProtection.FAQ.subtitle2_point14')} />
          <Typography variant="h5" fontWeight="bold" textAlign="left" padding="0px 16px 16px 0px">
            {t('PaymentProtection.FAQ.subtitle2_point15')}
          </Typography>
          <FAQPageListItem text={t('PaymentProtection.FAQ.subtitle2_point16')} />
          <FAQPageSecondQuestionAccordion
            title={t('PaymentProtection.FAQ.subtitle2_point17')}
            content1={t('PaymentProtection.FAQ.subtitle2_point17_bold_point1')}
            content2={t('PaymentProtection.FAQ.subtitle2_point17_bold_point2')}
          />
        </>
      ),
      hasDivider: true,
    },
    {
      title: t('PaymentProtection.FAQ.question3'),
      panelId: 'FAQ-question3',
      contents: (
        <Typography variant="h5" textAlign="left" padding="0px">
          {t('PaymentProtection.FAQ.subtitle3')}
        </Typography>
      ),
      hasDivider: true,
    },
    {
      title: t('PaymentProtection.FAQ.question4'),
      panelId: 'FAQ-question4',
      contents: (
        <>
          <Typography variant="h5" textAlign="left" padding="0px 16px 16px 0px">
            {t('PaymentProtection.FAQ.subtitle4_point1')}
          </Typography>
          <FAQPageListItem text={t('PaymentProtection.FAQ.subtitle4_point2')} />
          <FAQPageListItem text={t('PaymentProtection.FAQ.subtitle4_point3')} />
          <FAQPageListItem text={t('PaymentProtection.FAQ.subtitle4_point4')} />
        </>
      ),
      hasDivider: true,
    },
    {
      title: t('PaymentProtection.FAQ.question5'),
      panelId: 'FAQ-question5',
      contents: (
        <>
          <Typography variant="h5" textAlign="left" padding="0px 16px 16px 0px">
            {t('PaymentProtection.FAQ.subtitle5_point1')}
          </Typography>
          <Typography variant="h5" fontWeight="bold" textAlign="left" padding="0px 16px 16px 0px">
            {t('PaymentProtection.FAQ.subtitle5_point2')}
          </Typography>
          <FAQPageListItem text={t('PaymentProtection.FAQ.subtitle5_point3')} />
          <Typography variant="h5" fontWeight="bold" textAlign="left" padding="0px 16px 16px 0px">
            {t('PaymentProtection.FAQ.subtitle5_point4')}
          </Typography>
          <FAQPageListItem text={t('PaymentProtection.FAQ.subtitle5_point5')} />
          <FAQPageListItem text={t('PaymentProtection.FAQ.subtitle5_point6')} />
          <FAQPageListItem text={t('PaymentProtection.FAQ.subtitle5_point7')} />
          <FAQPageListItem text={t('PaymentProtection.FAQ.subtitle5_point8')} />
        </>
      ),
      hasDivider: false,
    },
  ]

  const remainingFAQs = [
    {
      title: t('PaymentProtection.FAQ.question6'),
      panelId: 'FAQ-question6',
      contents: (
        <Typography variant="h5" textAlign="left" padding="0px 16px 16px 0px">
          {t('PaymentProtection.FAQ.subtitle6')}
        </Typography>
      ),
      hasDivider: true,
    },
    {
      title: t('PaymentProtection.FAQ.question7'),
      panelId: 'FAQ-question7',
      contents: (
        <>
          <Typography variant="h5" textAlign="left" padding="0px 16px 16px 0px">
            {t('PaymentProtection.FAQ.subtitle7_point1')}
          </Typography>
          <FAQPageListItem text={t('PaymentProtection.FAQ.subtitle7_point2')} />
          <FAQPageListItem text={t('PaymentProtection.FAQ.subtitle7_point3')} />
          <FAQPageListItem text={t('PaymentProtection.FAQ.subtitle7_point4')} />
        </>
      ),
      hasDivider: true,
    },
    {
      title: t('PaymentProtection.FAQ.question8'),
      panelId: 'FAQ-question8',
      contents: (
        <>
          <FAQPageListItem text={t('PaymentProtection.FAQ.subtitle8_point1')} />
          <FAQPageListItem text={t('PaymentProtection.FAQ.subtitle8_point2')} />
          <FAQPageListItem text={t('PaymentProtection.FAQ.subtitle8_point3')} />
        </>
      ),
      hasDivider: false,
    },
    {
      title: t('PaymentProtection.FAQ.question9'),
      panelId: 'FAQ-question9',
      contents: (
        <>
          <Typography variant="h5" textAlign="left" fontWeight="bold" padding="0px 16px 16px 0px">
            {t('PaymentProtection.FAQ.subtitle9_point1')}
          </Typography>
          <FAQPageListItem text={t('PaymentProtection.FAQ.subtitle9_point2')} />
          <FAQPageListItem text={t('PaymentProtection.FAQ.subtitle9_point3')} />
          <FAQPageListItem text={t('PaymentProtection.FAQ.subtitle9_point4')} />
          <FAQPageListItem text={t('PaymentProtection.FAQ.subtitle9_point5')} />
          <FAQPageListItem text={t('PaymentProtection.FAQ.subtitle9_point6')} />
          <Typography variant="h5" textAlign="left" padding="0px 16px 16px 0px">
            {t('PaymentProtection.FAQ.subtitle9_point7')}
          </Typography>
        </>
      ),
      hasDivider: false,
    },
    {
      title: t('PaymentProtection.FAQ.question15'),
      panelId: 'FAQ-question14',
      contents: (
        <Typography variant="h5" textAlign="left" padding="0px 16px 16px 0px">
          {t('PaymentProtection.FAQ.subtitle15')}
        </Typography>
      ),
      hasDivider: true,
    },
    {
      title: t('PaymentProtection.FAQ.question10'),
      panelId: 'FAQ-question10',
      contents: (
        <Typography variant="h5" textAlign="left" padding="0px 16px 16px 0px">
          {t('PaymentProtection.FAQ.subtitle10')}
        </Typography>
      ),
      hasDivider: true,
    },
    {
      title: t('PaymentProtection.FAQ.question11'),
      panelId: 'FAQ-question11',
      contents: (
        <>
          <Typography variant="h5" textAlign="left" padding="0px 16px 16px 0px">
            {t('PaymentProtection.FAQ.subtitle11_point1')}
          </Typography>
          <FAQPageListItem text={t('PaymentProtection.FAQ.subtitle11_point2')} />
          <FAQPageListItem text={t('PaymentProtection.FAQ.subtitle11_point3')} />
          <FAQPageListItem text={t('PaymentProtection.FAQ.subtitle11_point4')} />
          <FAQPageListItem text={t('PaymentProtection.FAQ.subtitle11_point5')} />
          <FAQPageListItem text={t('PaymentProtection.FAQ.subtitle11_point6')} />
        </>
      ),
      hasDivider: true,
    },
    {
      title: t('PaymentProtection.FAQ.question12'),
      panelId: 'FAQ-question12',
      contents: (
        <Typography variant="h5" textAlign="left" padding="0px 16px 16px 0px">
          {t('PaymentProtection.FAQ.subtitle12')}
        </Typography>
      ),
      hasDivider: true,
    },
    {
      title: t('PaymentProtection.FAQ.question13'),
      panelId: 'FAQ-question13',
      contents: (
        <>
          <Typography variant="h5" textAlign="left" padding="0px 16px 16px 0px">
            {t('PaymentProtection.FAQ.subtitle13_point1')}
          </Typography>
          <FAQPageListItem text={t('PaymentProtection.FAQ.subtitle13_point2')} />
          <FAQPageListItem text={t('PaymentProtection.FAQ.subtitle13_point3')} />
        </>
      ),
      hasDivider: true,
    },
    {
      title: t('PaymentProtection.FAQ.question14'),
      panelId: 'FAQ-question14',
      contents: (
        <Typography
          variant="h5"
          textAlign="left"
          padding="0px 16px 16px 0px"
          sx={{ wordBreak: 'break-word' }}
        >
          {t('PaymentProtection.FAQ.subtitle14')}
        </Typography>
      ),
      hasDivider: false,
    },
  ]

  return (
    <FAQDivStyle isMobile={isMobile}>
      <Grid>
        <ModalCloseButton item xs={12} isMobile>
          <CloseIcon onClick={handleClose} />
        </ModalCloseButton>
        <Grid item xs={12} paddingTop={isMobile ? '48px' : ''}>
          <FAQGridImage item xs={12}>
            <FAQImgStyle src={faqPageIcon} alt={t('PaymentProtection.FAQ.title')} />
          </FAQGridImage>
        </Grid>
        <Grid item xs={12}>
          <Typography variant={isMobile ? 'h2' : 'h1'} textAlign="center" paddingTop="32px">
            {t('PaymentProtection.FAQ.title')}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h1" textAlign="left" marginTop="32px" marginBottom="24px">
            {t('PaymentProtection.FAQ.subtitle')}
          </Typography>
        </Grid>
        <Divider variant="fullWidth" />
        <Grid>
          <>
            {accordionContent.map(x => (
              <React.Fragment key={x.panelId}>
                <AccordionComponent
                  title={x.title}
                  panelId={x.panelId}
                  expanded={expanded === x.panelId}
                  handleChange={handleChange}
                >
                  {x.contents}
                </AccordionComponent>
                {x.hasDivider && <Divider variant="fullWidth" />}
              </React.Fragment>
            ))}
            {viewMoreClicked &&
              remainingFAQs.map(x => (
                <React.Fragment key={x.panelId}>
                  <Divider variant="fullWidth" />
                  <AccordionComponent
                    title={x.title}
                    panelId={x.panelId}
                    expanded={expanded === x.panelId}
                    handleChange={handleChange}
                  >
                    {x.contents}
                  </AccordionComponent>
                </React.Fragment>
              ))}
          </>
        </Grid>
        <Grid item xs={12} marginTop="32px" justifyContent="center" display="flex" maxWidth="100%">
          <SecondaryCTA
            sx={{ width: '40%', minWidth: 'auto', whiteSpace: 'nowrap' }}
            onClick={() => {
              setViewMoreClicked(!viewMoreClicked)
            }}
            buttonText={
              !viewMoreClicked
                ? t('PaymentProtection.FAQ.buttonViewMore')
                : t('PaymentProtection.FAQ.buttonViewLess')
            }
          />
        </Grid>
      </Grid>
    </FAQDivStyle>
  )
}
