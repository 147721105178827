import { useTranslation, Trans } from 'react-i18next'

import { verificationFailImg } from 'src/images'
import { StepsEnum } from 'src/types'
import {
  flinksInvalidBankFailLiveChatClicked,
  flinksInvalidBankFailContactFigClicked,
  flinksInvalidBankFailTryAgainClicked,
} from 'src/utils'
import { useOriginationContext } from 'src/hooks'
import { PleaseReachOut } from 'src/components/Error/shared/PleaseReachOut'
import ErrorPage from 'src/components/Error/shared/ErrorPage'
import { PrimaryCTA } from 'src/components/common/Buttons'

export default KYCFlinksInvalidBankAccount
/**
 *
 */
function KYCFlinksInvalidBankAccount() {
  const { t } = useTranslation()
  const screen = StepsEnum.KYC_FLINKS_INVALID_BANK_ACCOUNT
  const { setStep } = useOriginationContext()
  const onContactClick = () => {
    flinksInvalidBankFailTryAgainClicked(t('common.retryLabel'), screen)
    setStep(StepsEnum.BANK_AUTO_CONNECT)
  }

  const content = (
    <Trans i18nKey="FlinksBankValidationFail.content">
      We could not verify your bank account. Please validate that you are using your primary
      personal bank account. If you have further questions,{' '}
      <PleaseReachOut
        screen={screen}
        liveChatAnalytics={flinksInvalidBankFailLiveChatClicked}
        contactFigAnalytics={flinksInvalidBankFailContactFigClicked}
      />
    </Trans>
  )
  const liveChatButton = <PrimaryCTA onClick={onContactClick} buttonText={t('common.retryLabel')} />
  return (
    <ErrorPage
      image={{ src: verificationFailImg, alt: t('FlinksBankValidationFail.alt') }}
      title={t('FlinksBankValidationFail.title')}
      content={content}
      buttons={[liveChatButton]}
    />
  )
}
