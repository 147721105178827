import { useAuth } from '@clerk/clerk-react'
import { createContext, FC, ReactNode } from 'react'
import { QueryObserverResult, RefetchOptions, RefetchQueryFilters, useQuery } from 'react-query'

import type {
  BorrowerDetailSchema,
  LoanQueryResponseSchema,
  RepaymentScheduleResponseSchema,
  TransactionHistoryResponseSchema,
} from 'src/portal/api/api.schemas'
import usePortalApi from 'src/portal/hooks/usePortalApi'

export type PortalContextProps = {
  portalApiGetBorrowerDetailRefetch: <TPageData>(
    options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined,
  ) => Promise<QueryObserverResult<BorrowerDetailSchema>>
  portalApiGetBorrowerDetailIsSuccess: boolean
  borrowerDetailData?: BorrowerDetailSchema
  portalApiGetLoanDetailRefetch: <TPageData>(
    options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined,
  ) => Promise<QueryObserverResult<LoanQueryResponseSchema>>
  portalApiGetLoanDetailIsSuccess: boolean
  loanDetailData?: LoanQueryResponseSchema
  isPaymentProtectionEnabled: boolean | undefined

  portalApiGetLoanRepaymentScheduleRefetch: <TPageData>(
    options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined,
  ) => Promise<QueryObserverResult<RepaymentScheduleResponseSchema[]>>
  portalApiGetLoanRepaymentScheduleIsSuccess: boolean
  loanRepaymentScheduleData?: RepaymentScheduleResponseSchema[]

  portalApiGetLoanTransactionHistoryRefetch: <TPageData>(
    options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined,
  ) => Promise<QueryObserverResult<TransactionHistoryResponseSchema[]>>
  portalApiGetLoanTransactionHistoryIsSuccess: boolean
  loanTransactionHistoryData?: TransactionHistoryResponseSchema[]
}

export const PortalContext = createContext<PortalContextProps>({} as PortalContextProps)

interface Props {
  children: ReactNode
}

export const PortalProvider: FC<Props> = ({ children }) => {
  const {
    portalApiGetBorrowerDetail,
    portalApiGetLoanDetail,
    portalApiGetLoanRepaymentSchedule,
    portalApiGetLoanTransactionHistory,
  } = usePortalApi()
  const { isSignedIn } = useAuth()

  const {
    refetch: portalApiGetBorrowerDetailRefetch,
    isSuccess: portalApiGetBorrowerDetailIsSuccess,
    data: borrowerDetailData,
  } = useQuery<BorrowerDetailSchema>(
    ['portalApiGetBorrowerDetail'],
    () => portalApiGetBorrowerDetail(),
    {
      enabled: false, // disabled for now until the API has been implemented in the backend
      useErrorBoundary: false,
    },
  )

  const {
    refetch: portalApiGetLoanDetailRefetch,
    isSuccess: portalApiGetLoanDetailIsSuccess,
    data: loanDetailData,
  } = useQuery<LoanQueryResponseSchema>(
    ['portalApiGetLoanDetailRefetch'],
    () => portalApiGetLoanDetail(),
    {
      enabled: !!isSignedIn,
      useErrorBoundary: false,
    },
  )

  const {
    refetch: portalApiGetLoanRepaymentScheduleRefetch,
    isSuccess: portalApiGetLoanRepaymentScheduleIsSuccess,
    data: loanRepaymentScheduleData,
  } = useQuery<RepaymentScheduleResponseSchema[]>(
    ['portalApiGetLoanRepaymentSchedule', loanDetailData?.id],
    () => portalApiGetLoanRepaymentSchedule(loanDetailData?.id || ''),
    {
      enabled: !!loanDetailData?.id,
      useErrorBoundary: false,
    },
  )

  const {
    refetch: portalApiGetLoanTransactionHistoryRefetch,
    isSuccess: portalApiGetLoanTransactionHistoryIsSuccess,
    data: loanTransactionHistoryData,
  } = useQuery<TransactionHistoryResponseSchema[]>(
    ['portalApiGetLoanTransactionHistory', loanDetailData?.id],
    () => portalApiGetLoanTransactionHistory(loanDetailData?.id || ''),
    {
      enabled: !!loanDetailData?.id,
      useErrorBoundary: false,
    },
  )

  const value = {
    borrowerDetailData,
    portalApiGetBorrowerDetailRefetch,
    portalApiGetBorrowerDetailIsSuccess,
    portalApiGetLoanDetailRefetch,
    portalApiGetLoanDetailIsSuccess,
    portalApiGetLoanRepaymentScheduleRefetch,
    portalApiGetLoanRepaymentScheduleIsSuccess,
    loanRepaymentScheduleData,
    loanDetailData,
    isPaymentProtectionEnabled: loanDetailData?.payment_protection_applied,
    portalApiGetLoanTransactionHistoryRefetch,
    portalApiGetLoanTransactionHistoryIsSuccess,
    loanTransactionHistoryData,
  }

  return <PortalContext.Provider value={value}>{children}</PortalContext.Provider>
}

export default PortalProvider
