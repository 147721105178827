import { FC, createContext, ReactNode, useState, useMemo, useEffect } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useLDClient, useFlags } from 'launchdarkly-react-client-sdk'
import { captureMessage } from '@sentry/react'

import { useChangeLanguage } from 'src/hooks/useChangeLanguage'
import {
  ApplicationStatus,
  GetPartnerConfigResponseType,
  PrequalResponseType,
  StepsEnum,
  FeatureFlags,
} from 'src/types'
import { decodeBase64, parseUrl, constants } from 'src/utils'
import {
  PrequalificationForm,
  PrequalificationFormTouched,
} from 'src/components/EndToEnd/Prequalification'
import { FigAutocompletePrediction } from 'src/components/common/FigAddressSearch'
import { useSaveQCConsents } from 'src/hooks/useSaveQCConsents'
import { ldIdentify } from 'src/utils/launchDarkly'

type PrequalificationContextProps = {
  prequalResponse: PrequalResponseType | null
  setPrequalResponse: (value: PrequalResponseType) => void
  firstName: string
  setFirstName: (value: string) => void
  partnerConfig: GetPartnerConfigResponseType | null
  setPartnerConfig: (value: GetPartnerConfigResponseType) => void
  jwtApiKey: string | null
  setJwtApiKey: (k: string) => void
  partnerId: string | null
  setStepE2E: (step: string) => void
  isQCBorrower: boolean
  isQCConsentGiven: boolean
  setIsQCBorrower: (value: boolean) => void
  setIsQCConsentGiven: (value: boolean) => void
  partnerSessionId?: string | null
  campaignId?: string | null
  publisherId?: string | null
  setE2EHomeStep: (step: StepsEnum) => void
  e2eHomeStep: StepsEnum | null
  queryParams: string
  setQueryParams: (value: string) => void
  updatePartnerTrackingFields: (fields: {
    partnerSessionId: string | null
    campaignId: string | null
    publisherId: string | null
  }) => void
  launchDarklyIdentifed: boolean
  useTestForm: boolean
  prequalGenericErrorPageError: any
  setPrequalGenericErrorPageError: (value: any) => void
}

export const PrequalificationContext = createContext<PrequalificationContextProps>(
  {} as PrequalificationContextProps,
)

interface Props {
  children: ReactNode
}

/**
 *
 */
export const PrequalificationProvider: FC<Props> = ({ children }) => {
  const [prequalResponse, setPrequalResponse] = useState<PrequalResponseType | null>(null)
  const [firstName, setFirstName] = useState('')
  const [partnerConfig, setPartnerConfig] = useState<GetPartnerConfigResponseType | null>(null)
  const [jwtApiKey, setJwtApiKey] = useState<string | null>(null)
  const [isQCBorrower, setIsQCBorrower] = useState(false)
  const [isQCConsentGiven, setIsQCConsentGiven] = useState(false)
  const [partnerSessionId, setPartnerSessionId] = useState<undefined | null | string>(undefined)
  const [campaignId, setCampaignId] = useState<undefined | null | string>(undefined)
  const [publisherId, setPublisherId] = useState<undefined | null | string>(undefined)
  const [e2eHomeStep, setE2EHomeStep] = useState<StepsEnum | null>(null)
  const [useTestForm, setUseTestForm] = useState(false)
  const [queryParams, setQueryParams] = useState('')
  const [prequalGenericErrorPageError, setPrequalGenericErrorPageError] = useState(
    new Error('Error: User navigated to the Generic Error page'),
  )
  const { isMaintenance } = useFlags<FeatureFlags>()
  const [searchParams] = useSearchParams()

  // only override query params if present
  const updatePartnerTrackingFields = (fields: {
    partnerSessionId: string | null
    campaignId: string | null
    publisherId: string | null
  }) => {
    setPartnerSessionId(psid => fields.partnerSessionId ?? psid)
    setCampaignId(cid => fields.campaignId ?? cid)
    setPublisherId(pbId => fields.publisherId ?? pbId)
  }

  const navigate = useNavigate()

  const useTestFormValue = searchParams.get('use_test_form')
  useEffect(() => {
    if (useTestFormValue) {
      setUseTestForm(useTestFormValue === 'true')
    }
  }, [useTestFormValue])

  const setStepE2E = (path: string) => {
    if (isMaintenance) {
      captureMessage(
        `Application navigation for E2E disabled when in maintenance mode | attempted: ${path}`,
        'warning',
      )
    } else {
      navigate(`${jwtApiKey}/${path}${queryParams}`)
    }
  }

  const partnerId = useMemo(() => {
    if (!jwtApiKey) {
      return null
    }
    const decodedToken = decodeBase64<{ partner_id?: string }>(jwtApiKey)
    return decodedToken?.partner_id ?? null
  }, [jwtApiKey])

  const jwt = useMemo(() => {
    if (prequalResponse?.status !== ApplicationStatus.PRE_AUTHORIZED) {
      return
    }
    return parseUrl(new URL(prequalResponse.application_url!)).jwt
  }, [prequalResponse])

  const [launchDarklyIdentifed, setLaunchDarklyIdentified] = useState(false)
  const ldClient = useLDClient()

  useEffect(() => {
    if (!partnerConfig) {
      return
    }
    const borrowerId =
      prequalResponse?.status === ApplicationStatus.PRE_AUTHORIZED
        ? prequalResponse.borrower_id
        : undefined

    ldIdentify({
      ldClient,
      partnerId: partnerConfig.partner_id,
      partnerName: partnerConfig.name,
      borrowerId,
    }).finally(() => setLaunchDarklyIdentified(true))
  }, [partnerConfig, prequalResponse])

  useEffect(() => {
    if (partnerConfig?.session_id && constants.TMX_ORG_ID && window?.threatmetrix?.profile) {
      window.threatmetrix.profile('content.fig.ca', constants.TMX_ORG_ID, partnerConfig?.session_id)
    }
  }, [partnerConfig?.session_id])

  useChangeLanguage(jwt)
  useSaveQCConsents({
    jwtOrigination: jwt,
    autoTrigger: true,
  })

  return (
    <PrequalificationContext.Provider
      value={{
        prequalResponse,
        setPrequalResponse,
        firstName,
        setFirstName,
        partnerConfig,
        setPartnerConfig,
        jwtApiKey,
        setJwtApiKey,
        partnerId,
        setStepE2E,
        isQCBorrower,
        setIsQCBorrower,
        isQCConsentGiven,
        setIsQCConsentGiven,
        partnerSessionId,
        campaignId,
        publisherId,
        updatePartnerTrackingFields,
        e2eHomeStep,
        setE2EHomeStep,
        launchDarklyIdentifed,
        useTestForm,
        queryParams,
        setQueryParams,
        prequalGenericErrorPageError,
        setPrequalGenericErrorPageError,
      }}
    >
      {children}
    </PrequalificationContext.Provider>
  )
}

type AutoSaveContextProps = {
  prequalFormInProgress: PrequalificationForm | null
  setPrequalFormInProgress: (value: PrequalificationForm | null) => void
  prequalFormInProgressTouched: PrequalificationFormTouched | null
  setPrequalFormInProgressTouched: (value: PrequalificationFormTouched | null) => void
  selectedAddress: FigAutocompletePrediction | null
  setSelectedAddress: (value: FigAutocompletePrediction | null) => void
  reset: () => void
  isManualAddressError: boolean
  setIsManualAddressError: (value: boolean) => void
}

export const AutoSaveContext = createContext<AutoSaveContextProps>({} as AutoSaveContextProps)

export const AutoSaveProvider: FC<Props> = ({ children }) => {
  const [prequalFormInProgress, setPrequalFormInProgress] = useState<PrequalificationForm | null>(
    null,
  )

  const [prequalFormInProgressTouched, setPrequalFormInProgressTouched] =
    useState<PrequalificationFormTouched | null>(null)

  const [selectedAddress, setSelectedAddress] = useState<FigAutocompletePrediction | null>(null)
  const [isManualAddressError, setIsManualAddressError] = useState<boolean>(false)

  return (
    <AutoSaveContext.Provider
      value={{
        prequalFormInProgressTouched,
        setPrequalFormInProgressTouched,
        prequalFormInProgress,
        setPrequalFormInProgress,
        selectedAddress,
        setSelectedAddress,
        reset: () => {
          setPrequalFormInProgress(null)
          setPrequalFormInProgressTouched(null)
          setIsManualAddressError(false)
          setSelectedAddress(null)
        },
        isManualAddressError,
        setIsManualAddressError,
      }}
    >
      {children}
    </AutoSaveContext.Provider>
  )
}
