import { useEffect } from 'react'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'

import { useApi, useCreditRenewalContext } from 'src/hooks'
import { CreditRenewalStepsEnum } from 'src/types'
import { creditRenewalsEligibilityPageLoaded } from 'src/utils'

import { useDeclined } from './useDeclined'

/**
 * Custom hook for checking eligibility for credit renewal.
 *
 * This hook will:
 * 1. track the page load event for the eligibility screen.
 * 2. fetches data from the API, updates the state creditRenewalDecision
 * 3. redirects to the confirm information screen based on the API response.
 *
 * @returns
 * - data: The fetched data.
 * - isLoading: The loading state.
 * - t: The translation function.
 * - checkEligibility: A function to trigger the API call to check eligibility.
 */
export const useEligibility = () => {
  const {
    applicationId,
    setJwtApiKey,
    creditRenewalDecision,
    setCreditRenewalDecision,
    jwtApiKey: jwt,
    setCRStep,
    clearCachedApplications,
  } = useCreditRenewalContext()
  const { creditRenewalCheckEligibility } = useApi()
  const { jwtApiKey } = useParams()

  const { reason } = useDeclined()

  const {
    data,
    isLoading,
    error,
    refetch: checkEligibility,
  } = useQuery(
    ['eligibility-check', applicationId],
    async () => {
      clearCachedApplications()

      if (creditRenewalDecision?.eligible_for_renewal === true) {
        // re-use existing renewal decision
        setCRStep(CreditRenewalStepsEnum.CONFIRM_INFORMATION, jwtApiKey!)
        return
      }
      const res = await creditRenewalCheckEligibility()
      const data = res.data
      const { eligible_for_renewal, decline_reasons, application_url } = data
      setCreditRenewalDecision({ eligible_for_renewal, decline_reasons })

      if (!eligible_for_renewal || !application_url) {
        setCRStep(CreditRenewalStepsEnum.DECLINED, jwtApiKey!)
      } else {
        // Extract JWT from the application URL using the regex parse below
        const regex = /#\/(?:[^/]+\/)?(eyJ[\w-]+\.[\w-]+\.[\w-]+)/
        const match = application_url.match(regex)
        if (match) {
          const jwt = match[1]
          setJwtApiKey(jwt)
          setCRStep(CreditRenewalStepsEnum.CONFIRM_INFORMATION, jwt)
        } else if (reason === 'PAYMENT_PENDING_CONFIRMATION') {
          setCRStep(CreditRenewalStepsEnum.DECLINED, jwtApiKey!)
        } else {
          setCRStep(CreditRenewalStepsEnum.ERROR, jwtApiKey!)
        }
      }
    },
    {
      enabled: false, // Prevent auto-fetch
      useErrorBoundary: false,
    },
  )

  useEffect(function trackPageLoad() {
    creditRenewalsEligibilityPageLoaded('Page Loaded', CreditRenewalStepsEnum.ELIGIBILITY)
  }, [])

  useEffect(
    function setErrorStep() {
      if (error) {
        setCRStep(CreditRenewalStepsEnum.ERROR, jwt)
      }
    },
    [error],
  )

  return { data, isLoading, checkEligibility }
}
