import { Box, Fade, Grid, List, Radio, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useEffect } from 'react'

import { NoEarlyRepayFeesMessage } from 'src/components/common/NoEarlyRepayFeesMessage'
import { SelectOfferListItem, SelectOfferListItemButton } from 'src/components/CreditRenewal/styles'
import { useOriginationContext } from 'src/hooks'
interface Offer {
  term_length: number
  payment_amount: number
}
interface SelectOfferComponentProps {
  offerList: Offer[]
  selectedOfferTerm: number
  setSelectedOfferIdOnClick: (offer: Offer) => void
  apr: number
  borrower: any // Replace 'any' with the appropriate type
  isQCResident: (borrower: any) => boolean // Replace 'any' with the appropriate type
  currencyFormat: (amount: number, decimals: number) => string
  percentFormat: (value: number) => string
  disabled?: boolean
}
export function SelectOfferComponent({
  offerList,
  selectedOfferTerm,
  setSelectedOfferIdOnClick,
  apr,
  borrower,
  isQCResident,
  currencyFormat,
  percentFormat,
  disabled,
}: SelectOfferComponentProps) {
  const { t } = useTranslation()
  const { bootstrapInfo } = useOriginationContext()
  const defaultOffer = bootstrapInfo?.active_loan?.renewal_default_term

  useEffect(() => {
    if (defaultOffer && offerList.length) {
      const defaultOfferObj = offerList.find(offer => offer.term_length === defaultOffer)
      setSelectedOfferIdOnClick(defaultOfferObj as Offer)
    }
  }, [offerList])

  const offerItems = offerList.map(offer => (
    <List key={offer.term_length}>
      <SelectOfferListItem>
        <SelectOfferListItemButton
          data-testid="offer"
          role="button"
          selected={offer.term_length === selectedOfferTerm}
          aria-checked={offer.term_length === selectedOfferTerm}
          onClick={() => setSelectedOfferIdOnClick(offer)}
          disableRipple
          disabled={disabled}
        >
          <Radio
            checked={offer.term_length === selectedOfferTerm}
            aria-label={t('SelectOffer.termLength', { termLength: offer.term_length })}
          />
          <Box>
            <Typography variant="label">
              {t('SelectOffer.offerAmount', {
                offerAmount: currencyFormat(offer.payment_amount, 2),
              })}
            </Typography>
            <Typography variant="body2">
              {t(isQCResident(borrower) ? 'SelectOffer.aprQC' : 'SelectOffer.apr', {
                apr: percentFormat(apr),
              })}
            </Typography>
          </Box>
          <Box flexGrow={1}>
            <Typography variant="body2" textAlign="right">
              {t('SelectOffer.termLength', { termLength: offer.term_length })}
            </Typography>
          </Box>
        </SelectOfferListItemButton>
      </SelectOfferListItem>
    </List>
  ))
  return (
    <Grid sx={{ paddingTop: '32px' }}>
      <Grid>
        <Typography variant="h1" textAlign="center">
          {t('CreditRenewals.LoanDetails.offerTitle')}
        </Typography>
      </Grid>
      <Fade>
        <>
          <Grid item xs={12} marginTop="32px">
            {offerItems}
          </Grid>
          <Grid item xs={12} marginTop="8px">
            <NoEarlyRepayFeesMessage />
          </Grid>
        </>
      </Fade>
    </Grid>
  )
}
