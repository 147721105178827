import { CSSObject, styled } from '@mui/material'
import Grid from '@mui/material/Grid'
import * as Sentry from '@sentry/react'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation } from 'react-query'
import { Onfido } from 'onfido-sdk-ui'

import { DivRoot } from 'src/components/common/DivRoot'
import { LoadingAnimation } from 'src/components/common/animations/Loading'
import { useApi, useOriginationContext } from 'src/hooks'
import { figLogoOnfidoCobrand } from 'src/images'
import { EnglishLanguageKey, SupportedLanguage } from 'src/locales'
import theme from 'src/themes'
import { StepsEnum } from 'src/types'
import { GetOnfidoTokenResponseDataType, OnfidoTokenRequest } from 'src/types/api'
import { captureExceptionHelper, onfidoEventPosted, parseUrl } from 'src/utils'
import { CANADIAN_PHONE_NUMBER_LENGTH, IS_PREPROD_OR_PROD } from 'src/utils/constants'

import { OnfidoTestMode } from './OnfidoTestMode'

const ONFIDO_CONTAINER_ID = 'onfido-mount'

const OnfidoContainer = styled(Grid)(({ theme }) => ({
  '@media (max-width:480px)': {
    marginLeft: '-16px',
  },
  [theme.breakpoints.up('md')]: {
    marginLeft: '-24px',
  },
  // The onfido-sdk html doesn't display on mobile unless this is set
  height: '600px',
}))

const muiButtonStyle = theme.components?.MuiButton?.styleOverrides?.root as CSSObject
const OnfidoCustomUI = {
  fontFamilyTitle: theme.typography.fontFamily,
  fontFamilySubtitle: theme.typography.fontFamily,
  fontFamilyBody: theme.typography.fontFamily,
  colorBackgroundButtonPrimary: theme.color.brand1,
  colorBackgroundButtonPrimaryHover: theme.color.brand8,
  colorBackgroundButtonPrimaryActive: theme.color.brand8,
  colorBorderButtonPrimary: theme.color.brand1,
  colorBorderButtonPrimaryHover: theme.color.brand8,
  colorBorderButtonPrimaryActive: theme.color.brand8,
  colorBackgroundInfoPill: theme.color.brand8,
  colorBackgroundButtonSecondary: theme.color.brand1,
  colorBackgroundButtonSecondaryHover: theme.color.brand8,
  colorBackgroundButtonSecondaryActive: theme.color.brand8,
  colorBorderButtonSecondary: theme.color.brand1,
  colorBorderButtonSecondaryHover: theme.color.brand8,
  colorBorderButtonSecondaryActive: theme.color.brand8,
  fontWeightTitle: theme.typography.linkMedium.fontWeight,
  borderRadiusButton: muiButtonStyle.borderRadius,
  colorContentSubtitle: theme.color.grey6,
  fontWeightSubtitle: theme.typography.linkMedium.fontWeight,
  colorBorderDocTypeButton: theme.color.brand1,
  colorBackgroundDocTypeButtonHover: theme.color.brand5,
  colorBackgroundDocTypeButtonActive: theme.color.brand5,
  colorBorderDocTypeButtonHover: theme.color.brand1,
  colorBorderDocTypeButtonActive: theme.color.brand1,
  colorContentButtonPrimaryText: theme.color.white,
  colorContentButtonSecondaryText: theme.color.white,
  colorBorderLinkUnderline: theme.color.brand1,
  colorBackgroundLinkHover: theme.color.brand5,
  colorBackgroundLinkActive: theme.color.brand5,
  colorContentLinkTextHover: theme.color.brand1,
  colorIcon: theme.color.brand1,
  colorBorderSurfaceModal: theme.color.white,
  colorBackgroundSurfaceModal: theme.color.white,
  colorContentAlertInfo: theme.color.warning1,
  colorBackgroundAlertInfo: theme.color.warning2,
  colorBackgroundAlertInfoLinkHover: theme.color.warning2,
}

/**
 *
 */
export function KYCVerifyOnfido() {
  const { startOnfidoPolling, setGenericErrorPageError, setStep, bootstrapInfo, onfidoDecision } =
    useOriginationContext()
  const { i18n } = useTranslation()
  const { sessionVerify, setOnfidoUserActionComplete, getOnfidoToken } = useApi()
  const { currentStep } = parseUrl()
  const [applicantOverride, setApplicantOverride] = useState<null | OnfidoTokenRequest>(
    IS_PREPROD_OR_PROD ? {} : null,
  )
  const { t } = useTranslation()

  const inTestMode = applicantOverride === null

  // Verify if the user has already completed onfido and if so then redirect to payment method page.
  // Currently we only do this check between page transition.
  // If a user directly hits this page or use brower back button then this is skipped.
  useEffect(() => {
    if (
      !onfidoDecision?.reviewReason &&
      (onfidoDecision?.attemptCount === 0 || onfidoDecision?.attemptCount === 1)
    ) {
      if (
        onfidoDecision?.isUserActionComplete ||
        bootstrapInfo?.application?.is_onfido_user_action_complete
      ) {
        setStep(StepsEnum.PAYMENT_METHOD_SELECT)
        return
      }
    }
  }, [])

  useEffect(() => {
    if (bootstrapInfo?.config?.session_id && !bootstrapInfo?.config?.session_complete) {
      sessionVerify(currentStep)
    }
  }, [])

  useEffect(() => {
    const listener = ((event: CustomEvent<{ eventName: string; properties: any }>) => {
      onfidoEventPosted(event.detail.eventName, StepsEnum.KYC_VERIFY, 'Event Fired', {
        properties: event.detail.properties,
      })
    }) as (e: Event) => void

    const onfidoEventName = 'userAnalyticsEvent'
    window.addEventListener(onfidoEventName, listener)
    return () => window.removeEventListener(onfidoEventName, listener)
  }, []) // send onfido events to segment

  const timeoutRef = useRef<NodeJS.Timeout | undefined>()
  const onfidoObj = useRef<any>()
  useEffect(() => {
    return () => {
      clearTimeout(timeoutRef.current)
      onfidoObj?.current?.tearDown?.()
    }
  }, []) // tear down onfidoObj, remove timeout on component unmount

  const {
    mutate: getOnfidoTokenMutation,
    data: getTokenData,
    isLoading: isGetTokenLoading,
    isSuccess: isGetTokenSuccess,
  } = useMutation<GetOnfidoTokenResponseDataType, any, NonNullable<typeof applicantOverride>>(
    getOnfidoToken,
    {
      useErrorBoundary: false,
      onError: e => {
        captureExceptionHelper('Error getting Onfido token', e)
        setGenericErrorPageError(new Error('Error getting Onfido token'))
        setStep(StepsEnum.ERROR)
      },
    },
  )
  const { sdk_token, workflow_run_id } = getTokenData?.data ?? {}

  const {
    mutateAsync: setOnfidoUserActionCompleteMutation,
    isLoading: isSettingOnfidoUserActionComplete,
  } = useMutation(setOnfidoUserActionComplete, {
    useErrorBoundary: false,
    onError: e => {
      captureExceptionHelper('Error setting Onfido user action complete', e)
    },
  })

  const phrases = {
    'crossdevice.mobile_intro.title': t('Onfido.crossDeviceTitle'),
    'crossdevice.mobile_intro.info_1': t('Onfido.crossDeviceInfo1'),
    'crossdevice.mobile_intro.info_2': t('Onfido.crossDeviceInfo2'),
    'crossdevice.mobile_intro.btn': t('Onfido.crossDeviceBtn'),
  }

  useEffect(() => {
    if (isGetTokenSuccess) {
      if (!sdk_token) {
        Sentry.captureMessage('getOnfidoToken response missing sdk_token', 'error')
        setGenericErrorPageError(new Error('getOnfidoToken response missing sdk_token'))
        setStep(StepsEnum.ERROR)
        return
      }

      onfidoObj.current = Onfido.init({
        // For some reason, onfido remove the ability to pin version after 14.29.0
        // Currently hacking the type so that we can force a version since from their codebase it is still allowed....
        // Reach out to Onfido to see why this is happening
        // @ts-ignore: Hacking version for Onfido init...
        version: '14.37.1',
        token: sdk_token,
        containerId: ONFIDO_CONTAINER_ID,
        workflowRunId: workflow_run_id || '',
        smsNumberCountryCode: 'CA',
        userDetails: {
          // grab last 10 digits to ignore any prior formatting
          smsNumber: `+1${bootstrapInfo?.borrower?.borrower_mobile?.slice(-CANADIAN_PHONE_NUMBER_LENGTH)}`,
        },
        language: {
          locale: (i18n.resolvedLanguage as SupportedLanguage) || EnglishLanguageKey,
          phrases,
          mobilePhrases: phrases,
        },
        customUI: OnfidoCustomUI,
        enterpriseFeatures: {
          logoCobrand: { lightLogoSrc: figLogoOnfidoCobrand, darkLogoSrc: figLogoOnfidoCobrand },
        },
        onComplete: () => {
          setOnfidoUserActionCompleteMutation()
          if (startOnfidoPolling == null) {
            Sentry.captureMessage(
              'VerifyOnfido - expected startOnfidoPolling to be defined but is null',
              'error',
            )
            setGenericErrorPageError(
              new Error('VerifyOnfido - expected startOnfidoPolling to be defined but is null'),
            )
            setStep(StepsEnum.ERROR)
            return
          }

          startOnfidoPolling() // start onfido polling

          setTimeout(() => {
            setStep(StepsEnum.PAYMENT_METHOD_SELECT)
          }, 2000)
        },
        onError: (e: { type: string; message: string; exception?: Error | unknown }) => {
          if (e.type === 'expired_token') {
            setStep(StepsEnum.KYC_TOKEN_EXPIRED)
          } else if (e.type === 'permissions_unavailable') {
            setStep(StepsEnum.KYC_PERMISSIONS_ONFIDO_ERROR)
          } else {
            captureExceptionHelper(
              `Error initializing onfido with ${e.type} and ${e.message}`,
              e.exception,
            )
            setGenericErrorPageError(
              new Error(`Error initializing onfido with ${e.type} and ${e.message}`),
            )
            setStep(StepsEnum.ERROR)
          }
        },
      })
    }
  }, [isGetTokenSuccess])

  useEffect(() => {
    if (!inTestMode) {
      getOnfidoTokenMutation(applicantOverride!)
    }
  }, [inTestMode])

  if (isGetTokenLoading || isSettingOnfidoUserActionComplete) {
    return <LoadingAnimation />
  }
  if (inTestMode) {
    return <OnfidoTestMode {...{ applicantOverride, setApplicantOverride }} />
  }
  return (
    <DivRoot>
      <Grid>
        <Grid item xs={12}>
          <OnfidoContainer id={ONFIDO_CONTAINER_ID}></OnfidoContainer>
        </Grid>
      </Grid>
    </DivRoot>
  )
}
