import Grid from '@mui/material/Grid'
import 'react-phone-number-input/style.css'
import { styled } from '@mui/material/styles'
import PhoneInput, { Value as E164Number } from 'react-phone-number-input/input'
import { Typography } from '@mui/material'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { DivRoot } from 'src/components/common/DivRoot'
import { StepsEnum } from 'src/types'
import { useOriginationContext } from 'src/hooks'
import { PrimaryCTA, BackButton } from 'src/components/common/Buttons'

import { FigFormControl } from '../common/FigFormControl'

const PhoneInputStyled = styled(PhoneInput)(({ theme }) => ({
  width: '100%',
  borderRadius: 3,
  padding: 12,
  height: '56px',
  background: theme.color.white,
  border: `1px solid ${theme.color.grey4}`,
  fontFamily: "'Poppins',sans-serif",
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '16px',
  lineHeight: '20px',
  color: theme.color.grey6,
  marginTop: 4,
  marginBottom: 4,
  boxSizing: 'border-box',
  outlineColor: theme.color.brand1,
  ['&[data-error="true"]']: {
    outlineColor: theme.color.error,
  },
}))

interface Props {
  phone: E164Number | undefined
  setPhone: (value: E164Number | undefined) => void
  error: string | null
  setError: (value: string | null) => void
  send: VoidFunction
}

export default SendOTP
/**
 *
 */
function SendOTP({ send, phone, setPhone, error, setError }: Props) {
  const { setStep } = useOriginationContext()
  const { t } = useTranslation()

  const phoneDataLength = 12

  // Includes bracket auto-formatting
  const phoneInputLength = 14

  const isDisabled = phone?.length != phoneDataLength || !!error

  const handleKeyDown = (e: KeyboardEvent) => {
    if (e.key === 'Enter' && !isDisabled) {
      send()
    }
  }

  const [isFocused, setIsFocused] = useState(false)

  return (
    <DivRoot>
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <Typography variant="h1" textAlign="center">
            {t('OTP.title')}
          </Typography>
          <Typography textAlign="center" variant="body2" marginTop="16px">
            {t('OTP.subtitle')}
          </Typography>
        </Grid>

        <Grid item xs={12} marginTop="32px" display="flex" flexDirection="column">
          <FigFormControl
            label={t('OTP.phoneLabel')}
            error={!!error}
            helperText={error || t('OTP.phoneHelperText')}
            isFocused={isFocused}
          >
            <PhoneInputStyled
              country="CA"
              placeholder={t('OTP.phonePlaceholder')}
              value={phone}
              data-error={!!error}
              onChange={value => {
                setPhone(value)
                setError(null)
              }}
              onFocus={() => setIsFocused(true)}
              onBlur={() => setIsFocused(false)}
              onKeyDown={handleKeyDown}
              maxLength={phoneInputLength}
            />
          </FigFormControl>
        </Grid>

        <Grid item xs={12} marginTop="32px">
          <PrimaryCTA
            type="submit"
            onClick={send}
            disabled={isDisabled}
            buttonText={t('common.continueLabel')}
          />
        </Grid>

        <Grid item xs={12} display="flex" justifyContent="center" marginTop="16px">
          <BackButton
            onClick={() => {
              setStep(StepsEnum.SELECT_OFFER)
            }}
          />
        </Grid>
      </Grid>
    </DivRoot>
  )
}
