/**
 * Add a script to the document
 */
export function loadScript({
  src,
  id,
  onload,
  extra = {},
}: {
  src: string
  id: string
  onload?: () => void
  extra?: { [key: string]: any }
}) {
  const script = document.createElement('script')
  script.async = true
  script.id = id
  script.src = src
  if (onload) {
    script.onload = onload
  }
  Object.entries(extra).forEach(kvp => {
    const [key, value] = kvp
    script.setAttribute(key, value)
  })
  document.head.appendChild(script)
}

/**
 * Try to load a script, return false if already exists
 */
export function loadScriptIfNeeded(args: {
  src: string
  id: string
  onload?: () => void
  extra?: { [key: string]: any }
}) {
  if (document.getElementById(args.id)) {
    return false
  }
  loadScript(args)
  return true
}

/**
 * Add a stylesheet to the document
 */
export function loadStylesheet({
  href,
  id,
  extra = {},
}: {
  href: string
  id: string
  extra?: { [key: string]: any }
}) {
  const link = document.createElement('link')
  link.id = id
  link.href = href
  link.rel = 'stylesheet'
  Object.entries(extra).forEach(kvp => {
    const [key, value] = kvp
    link.setAttribute(key, value)
  })
  document.head.appendChild(link)
}
