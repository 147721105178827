import Axios from 'axios'
import { useAuth } from '@clerk/clerk-react'

import { getNinjaAPI } from 'src/portal/api/api'
import { API_URL } from 'src/utils/constants'

// Setting axios defaults to send cookies
Axios.defaults.withCredentials = true
Axios.defaults.baseURL = API_URL

const parseHeaders = (token: string | null) => {
  return {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  }
}

export default function usePortalApi() {
  const { getToken } = useAuth()
  const api = getNinjaAPI()

  return {
    portalApiGetBorrowerDetail: async () => {
      const token = await getToken()
      const { data } = await api.portalApiGetBorrowerDetail(parseHeaders(token))
      return data
    },
    portalApiGetLoanDetail: async () => {
      const token = await getToken()
      const { data } = await api.portalApiGetLoanDetail(parseHeaders(token))
      return data
    },
    portalApiGetLoanRepaymentSchedule: async (loan_id: string) => {
      const token = await getToken()
      const { data } = await api.portalApiGetLoanRepaymentSchedule(loan_id, parseHeaders(token))
      return data
    },
    portalApiGetLoanTransactionHistory: async (loan_id: string) => {
      const token = await getToken()
      const { data } = await api.portalApiGetLoanTransactionHistory(loan_id, parseHeaders(token))
      return data
    },
  }
}
