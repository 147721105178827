import { Link } from '@mui/material'
import { useEffect } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import ErrorPage from 'src/components/Error/shared/ErrorPage'
import { PrimaryCTA } from 'src/components/common/Buttons'
import { useOriginationContext } from 'src/hooks'
import { verificationFailImg } from 'src/images'
import { StepsEnum } from 'src/types'
import { declinedUserHelpCentreClicked, kycApplicationDeclined } from 'src/utils'

export const HelpLink = ({
  screen,
  analyticsCallback,
}: {
  screen: StepsEnum
  analyticsCallback: (object: string, page_name: StepsEnum) => void
}) => {
  const { t } = useTranslation()
  const helpLabel = t('common.helpLinkLabel')
  return (
    <Link
      whiteSpace="nowrap"
      href={t('HelpDesk.declinedUrl')}
      target="_blank"
      onClick={() => {
        analyticsCallback(helpLabel, screen)
      }}
    >
      {helpLabel}
    </Link>
  )
}

export default ApplicationDeclined
/**
 *
 */
function ApplicationDeclined() {
  const { t } = useTranslation()
  const { onfidoDecision } = useOriginationContext()
  const screen = StepsEnum.DECLINED

  useEffect(() => {
    if (onfidoDecision?.applicationDeclinedReason) {
      kycApplicationDeclined(onfidoDecision.applicationDeclinedReason, StepsEnum.DECLINED)
    }
  }, [onfidoDecision])

  const content = (
    <Trans i18nKey="ApplicationDeclined.content">
      To learn more about why you may not have been approved, you can visit our help centre. For any
      other questions and concerns,{' '}
      <HelpLink screen={screen} analyticsCallback={declinedUserHelpCentreClicked} />
    </Trans>
  )
  const helpCentreButton = (
    <PrimaryCTA
      target="_blank"
      href={t('HelpDesk.declinedUrl')}
      onClick={() => {
        declinedUserHelpCentreClicked(t('common.helpLabel'), screen)
      }}
      buttonText={t('common.helpLabel')}
    />
  )
  return (
    <ErrorPage
      image={{ src: verificationFailImg, alt: t('ApplicationDeclined.alt') }}
      title={t('ApplicationDeclined.title')}
      content={content}
      buttons={[helpCentreButton]}
    />
  )
}
