import { useState } from 'react'

import { PortalModalsEnum } from 'src/portal/common'
import { AutomaticPaymentsDetailModal } from 'src/portal/components/modals/AutomaticPayments/AutomaticPaymentsDetail'

/**
 * Reusable hook to manage shared modals in the portal:
 * Automatic Payments Detail
 *
 * @returns {object} An object containing methods to open, close, and render modals.
 * @returns {function} openModal - Function to open a modal by its name.
 * @returns {function} closeModal - Function to close the currently active modal.
 * @returns {function} renderModal - JSX element to render the currently active modal.
 */
export const useModals = () => {
  const [activeModal, setActiveModal] = useState<keyof typeof modals | null>(null)

  const modals = {
    [PortalModalsEnum.AUTOMATIC_PAYMENTS_DETAIL]: (
      <AutomaticPaymentsDetailModal closeModal={() => setActiveModal(null)} />
    ),
  }

  const openModal = (modalName: keyof typeof modals) => {
    if (modals[modalName]) {
      setActiveModal(modalName)
    }
  }

  const closeModal = () => setActiveModal(null)

  const RenderModal = () => {
    return activeModal ? modals[activeModal] : null
  }
  return { openModal, closeModal, RenderModal }
}
