/**
 * This file is part of the connect a bank account flow.
 * It displays the flinks iframe which is used to retrieve the users
 * banking information.
 */
import { useState } from 'react'
import { styled } from '@mui/material/styles'
import Fade from '@mui/material/Fade'

import { LoadingAnimation } from 'src/components/common/animations/Loading'
import { DivRoot } from 'src/components/common/DivRoot'
import { FlinksProvider } from 'src/contexts'
import { useConfiguredFlinksUrl } from 'src/hooks/useConfiguredFlinksUrl'

const IframeStyled = styled('iframe')(({ theme }) => ({
  border: 'none',
  [theme.breakpoints.down('sm')]: {
    paddingBottom: 25,
  },
}))

export default ConnectBankAccount
/**
 *
 */
function ConnectBankAccount() {
  const [isLoading, setIsLoading] = useState(true)

  const flinksUrl: string = useConfiguredFlinksUrl()

  // The Flinks iFrame takes longer than the onLoad call to render, this adds
  // an additional delay to help ensure it's rendered before revealing it.
  const iframeLoadDelay = 2000
  const iframeLoaded = () => {
    setTimeout(function () {
      setIsLoading(false)
    }, iframeLoadDelay)
  }

  return (
    <>
      {isLoading && <LoadingAnimation />}
      <FlinksProvider>
        <Fade in={!isLoading} timeout={500}>
          <DivRoot sx={{ display: isLoading ? 'none' : 'inherit' }}>
            <IframeStyled
              id="purple-iframe"
              title="connect to bank"
              className="flinksconnect"
              height="640"
              width="100%"
              onLoad={iframeLoaded}
              src={flinksUrl}
              style={{
                display: 'block',
              }}
            ></IframeStyled>
          </DivRoot>
        </Fade>
      </FlinksProvider>
    </>
  )
}
