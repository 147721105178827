import { useEffect, useState } from 'react'
import { useMutation } from 'react-query'
import { useParams } from 'react-router-dom'

import { useApi, useCreditRenewalContext } from 'src/hooks'
import {
  CreditRenewalStepsEnum,
  EmploymentStatus,
  FigAutocompleteAddress,
  GetApplicantDataResponseType,
  ProvinceCodes,
} from 'src/types'
import { creditRenewalsConfirmInformationPageLoaded, log } from 'src/utils'

export const useConfirmInformation = () => {
  const { bootstrapInfo, setCRStep } = useCreditRenewalContext()
  const { creditRenewalUpdateApplicantData } = useApi()
  const { jwtApiKey } = useParams()

  const { mutate: handleConfirm, isLoading } = useMutation(
    async (applicantData: GetApplicantDataResponseType) => {
      const response = await creditRenewalUpdateApplicantData({
        full_name: applicantData.fullName,
        date_of_birth: applicantData.dateOfBirth,
        phone_number: applicantData.phoneNumber,
        employment_data: applicantData.employmentData,
        borrower: applicantData.borrower,
        purpose_of_loan: applicantData.purposeOfLoan,
      })
      return response.data
    },
    {
      onSuccess: (data, variables) => {
        if (data.borrower_id === variables?.borrower?.borrower_id) {
          setCRStep(CreditRenewalStepsEnum.LOAN_DETAILS, jwtApiKey!)
        } else {
          setCRStep(CreditRenewalStepsEnum.ERROR, jwtApiKey!)
        }
      },
      onError: error => {
        log.error('Error updating applicant data', error)
        setCRStep(CreditRenewalStepsEnum.ERROR, jwtApiKey!)
      },
    },
  )

  const [isConfirmDisabled, setIsConfirmDisabled] = useState<boolean>(true)
  const [applicantData, setApplicantData] = useState<GetApplicantDataResponseType>({
    fullName: `${bootstrapInfo.borrower?.borrower_first_name ?? ''} ${bootstrapInfo.borrower?.borrower_last_name ?? ''}`,
    dateOfBirth: bootstrapInfo.borrower?.borrower_dob || '',
    phoneNumber: `${bootstrapInfo.borrower?.borrower_mobile ?? ''}`,
    employmentData: {
      occupation: bootstrapInfo.application?.occupation || '',
      employment_status: bootstrapInfo.application?.occupation
        ? EmploymentStatus.EMPLOYED
        : EmploymentStatus.UNEMPLOYED,
      occupation_language: bootstrapInfo.borrower?.language_preference ?? '',
      employment_start_date: '',
    },
    borrower: {
      borrower_id: bootstrapInfo.borrower?.borrower_id ?? '',
      borrower_first_name: bootstrapInfo.borrower?.borrower_first_name ?? '',
      borrower_last_name: bootstrapInfo.borrower?.borrower_last_name ?? '',
      borrower_email: bootstrapInfo.borrower?.borrower_email ?? '',
      borrower_mobile: bootstrapInfo.borrower?.borrower_mobile ?? '',
      available_credit: bootstrapInfo.borrower?.available_credit ?? 0,
      borrower_address_1: bootstrapInfo.borrower?.borrower_address_1 || '',
      borrower_address_2: bootstrapInfo.borrower?.borrower_address_2 || '',
      borrower_province: bootstrapInfo.borrower?.borrower_province ?? '',
      borrower_city: bootstrapInfo.borrower?.borrower_city || '',
      borrower_country: bootstrapInfo.borrower?.borrower_country || '',
      postal_code: bootstrapInfo.borrower?.postal_code || '',
    },
    purposeOfLoan: '',
  })

  const handlePhoneInput = (inputString: string) => {
    setApplicantData({
      ...applicantData,
      phoneNumber: inputString,
    })
  }

  const handlePurposeOfLoanInput = (inputOption: string) => {
    setApplicantData({
      ...applicantData,
      purposeOfLoan: inputOption,
    })
  }

  const handleOccupationEmploymentStatus = (inputEmploymentStatus: EmploymentStatus) => {
    setApplicantData({
      ...applicantData,
      employmentData: {
        ...applicantData.employmentData,
        employment_status: inputEmploymentStatus,
      },
    })
  }

  const handleOccupationEmployerName = (inputEmployerName: string) => {
    setApplicantData({
      ...applicantData,
      employmentData: {
        ...applicantData.employmentData,
        employer: inputEmployerName,
      },
    })
  }

  const handleOccupationStartDate = (inputStartDate: string) => {
    setApplicantData({
      ...applicantData,
      employmentData: {
        ...applicantData.employmentData,
        employment_start_date: inputStartDate,
      },
    })
  }

  const handleOccupationInput = (inputOccupation: string) => {
    setApplicantData({
      ...applicantData,
      employmentData: {
        ...applicantData.employmentData,
        occupation: inputOccupation,
      },
    })
  }

  const handleOccupationIncomeSource = (inputIncomeSource: string) => {
    setApplicantData({
      ...applicantData,
      employmentData: {
        ...applicantData.employmentData,
        income_source: inputIncomeSource,
      },
    })
  }

  const handleAddressInput = (addressObj: FigAutocompleteAddress) => {
    setApplicantData({
      ...applicantData,
      borrower: {
        ...applicantData.borrower,
        borrower_address_1: addressObj.address_1,
        borrower_address_2: addressObj.address_2,
        borrower_province: addressObj.province_code,
        borrower_city: addressObj.city,
        borrower_country: addressObj.country_code,
        postal_code: addressObj.postal_code,
      },
    })
  }

  useEffect(function trackPageLoadEvent() {
    creditRenewalsConfirmInformationPageLoaded(
      'Confirm Information',
      CreditRenewalStepsEnum.CONFIRM_INFORMATION,
    )
  }, [])

  useEffect(
    function enableConfirmButton() {
      const isEmployedAndOccupationFilled =
        applicantData?.employmentData?.employment_status === EmploymentStatus.EMPLOYED &&
        applicantData?.employmentData?.occupation !== ''

      const isFromQc = applicantData?.borrower?.borrower_province === ProvinceCodes.QUEBEC
      const isOccupationStartDateSet = !!applicantData?.employmentData?.employment_start_date
      const isNotEmployed =
        applicantData?.employmentData?.employment_status !== EmploymentStatus.EMPLOYED

      const isPurposeOfLoanFilled =
        !!applicantData?.purposeOfLoan && applicantData?.purposeOfLoan !== 'Choose an option'

      const isEmploymentValid = isEmployedAndOccupationFilled || isNotEmployed
      const isQcConditionsMet = !isFromQc || isOccupationStartDateSet

      if (isEmploymentValid && isQcConditionsMet && isPurposeOfLoanFilled) {
        setIsConfirmDisabled(false)
      }
    },
    [applicantData],
  )

  return {
    applicantData,
    handlePhoneInput,
    handleConfirm,
    isLoading,
    handlePurposeOfLoanInput,
    handleOccupationEmploymentStatus,
    handleOccupationEmployerName,
    handleOccupationStartDate,
    handleOccupationInput,
    handleOccupationIncomeSource,
    handleAddressInput,
    isConfirmDisabled,
  }
}
