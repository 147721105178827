import { format } from 'date-fns'
import { enCA, frCA } from 'date-fns/locale'

import { SupportedLanguage } from 'src/locales'

export const centsInDollar = 100

/*
 *
 */
export const getLocale = (language: SupportedLanguage) => {
  return language === 'fr' ? frCA : enCA
}

/*
 *
 */
export const currencyFormatFromCents = (
  amountInCents: number | undefined,
  minFractionDigits = 2,
  language: SupportedLanguage,
) =>
  currencyFormat(
    amountInCents ? amountInCents / centsInDollar : undefined,
    minFractionDigits,
    language,
  )

/*
 *
 */
export const currencyFormatFromStr = (
  amount: string | undefined,
  minFractionDigits = 2,
  language: SupportedLanguage,
) => currencyFormat(amount ? parseFloat(amount) : undefined, minFractionDigits, language)

/*
 *
 */
export const currencyFormat = (
  amount: number | undefined,
  minFractionDigits = 2,
  language: SupportedLanguage,
) => {
  return new Intl.NumberFormat(`${language}-CA`, {
    // without -CA (ie en-CA, fr-CA), it will specify Canadian dollars ie '123 CA$'
    style: 'currency',
    currency: 'CAD',
    minimumFractionDigits: minFractionDigits,
  }).format(amount || 0)
}

/*
 *
 */
export const percentFormat = (
  amount: number | undefined,
  minFractionDigits = 2,
  language: SupportedLanguage,
) => {
  return amount
    ? new Intl.NumberFormat(language, {
        style: 'percent',
        minimumFractionDigits: minFractionDigits,
      }).format(amount / 100)
    : ''
}

/*
 *
 */
export const dateISOFormat = (date: Date | null) => (date ? format(date, 'yyyy-MM-dd') : '')

/*
 *
 */
export const dateFormatCustom = ({
  date,
  formatStr,
  language,
}: {
  date: Date | null
  formatStr: string
  language: SupportedLanguage
}) => {
  return date ? format(date, formatStr, { locale: getLocale(language) }) : ''
}

/*
 * formats to 666-777-6777
 */

export const formatPhoneNumberSimple = (phoneNumber: string | undefined): string => {
  if (!phoneNumber) {
    return ''
  }

  const cleaned = phoneNumber.replace(/\D/g, '')
  const match = cleaned.match(/^1?(\d{3})(\d{3})(\d{4})$/)
  if (match) {
    return `${match[1]}-${match[2]}-${match[3]}`
  }

  return phoneNumber
}

/*
 * formats to +1 (666) 777-6777
 */
export const formatPhoneNumberInternational = (phoneNumber: string | undefined): string => {
  if (!phoneNumber) {
    return ''
  }

  const cleaned = phoneNumber.replace(/\D/g, '')
  const match = cleaned.match(/^(\d)(\d{3})(\d{3})(\d{4})$/)
  if (match) {
    return `+${match[1]} (${match[2]}) ${match[3]}-${match[4]}`
  }

  return phoneNumber
}

/*
 * formats a valid Canadian SIN
 */
export const formatSIN = (sin?: string) =>
  sin ? `${sin.slice(0, 3)}-${sin.slice(3, 6)}-${sin.slice(6)}` : undefined

/*
 * formats a valid Canadian postal code
 */
export const formatPostalCode = (postalCode: string) => {
  const formattedPostalCode = postalCode.replaceAll(' ', '').toUpperCase()
  return `${formattedPostalCode.slice(0, 3)} ${formattedPostalCode.slice(3, 6)}`
}
