import { Box, Typography, useTheme } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { ImgStyle } from 'src/components/PaymentMethod/BankConnectCarousel'
import { flagIcon } from 'src/images'
import { EnglishLanguageKey, FrenchLanguageKey } from 'src/locales'

export const LocaleSwitcher = () => {
  const { i18n } = useTranslation()
  const theme = useTheme()

  const displayLanguage = i18n.resolvedLanguage === EnglishLanguageKey ? 'FR' : 'EN'
  const handleLanguageToggle = () => {
    if (i18n.resolvedLanguage === EnglishLanguageKey) {
      i18n.changeLanguage(FrenchLanguageKey)
    } else {
      i18n.changeLanguage(EnglishLanguageKey)
    }
  }

  return (
    <Box onClick={handleLanguageToggle} sx={{ cursor: 'pointer' }}>
      <ImgStyle
        src={flagIcon}
        alt="Flag icon for the locale switcher"
        height="24px"
        data-testid="flagIcon"
        style={{ display: 'inline' }}
      />
      <Typography
        style={{
          display: 'inline',
          verticalAlign: 'top',
          fontWeight: 'bold',
          color: theme.palette.secondary.main,
        }}
      >
        {displayLanguage.toLocaleUpperCase()}
      </Typography>
    </Box>
  )
}
