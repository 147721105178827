import { createContext, FC, ReactNode, useState } from 'react'

type State = {
  jwtApiKey: string
}

type DispatchType = {
  setJwtApiKey: (value: string) => void
}

export type AuthJwtContextProps = State & DispatchType

const AuthJwtContext = createContext<AuthJwtContextProps>({} as AuthJwtContextProps)

interface Props {
  children: ReactNode
}

const AuthJwtProvider: FC<Props> = ({ children }) => {
  const [jwtApiKey, setJwtApiKey] = useState('')

  return (
    <AuthJwtContext.Provider value={{ jwtApiKey, setJwtApiKey }}>
      {children}
    </AuthJwtContext.Provider>
  )
}

export { AuthJwtContext, AuthJwtProvider }
export default AuthJwtProvider
