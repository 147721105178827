import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined'
import ReportOutlinedIcon from '@mui/icons-material/ReportOutlined'
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined'
import { Box, Grid, styled, Typography } from '@mui/material'
import { captureMessage } from '@sentry/react'
import { useContext, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation } from 'react-query'

import { BasicCTA, PrimaryCTA } from 'src/components/common/Buttons'
import { AlertColor, FigAlert } from 'src/components/common/FigAlert'
import { PrequalificationContext } from 'src/contexts'
import { AutoSaveContext } from 'src/contexts/prequalification'
import useApiE2E from 'src/hooks/useApiE2E'
import { useLocalizedFormatters } from 'src/hooks/useLocalizedFormatters'
import { ReactComponent as LockIcon } from 'src/images/lock.svg'
import theme from 'src/themes'
import {
  ApplyLaterRequestType,
  ApplyLaterResponseDataType,
  ApplyLaterStatus,
  PrequalOfferType,
  PrequalSuccessResponseType,
  StepsEnum,
} from 'src/types'
import { captureExceptionHelper } from 'src/utils'

const TypographyHeader = styled(Typography)(() => ({
  fontSize: '32px',
  lineHeight: '48px',
  fontWeight: 600,
}))

const offerAlertTimeoutMs = 5000

/**
 *
 */
export function OfferAlert({
  applyLaterStatus,
  setApplyLaterStatus,
}: {
  applyLaterStatus: ApplyLaterStatus | null
  setApplyLaterStatus: (status: ApplyLaterStatus | null) => void
}) {
  const { t, i18n } = useTranslation()
  const clearApplyLaterStatus = () => setApplyLaterStatus(null)

  const alertConfig = useMemo(() => {
    switch (applyLaterStatus) {
      case ApplyLaterStatus.NEW:
        return {
          icon: <EmailOutlinedIcon />,
          title: t('e2e.Offer.applyLater.alert.new.title'),
          content: t('e2e.Offer.applyLater.alert.new.content'),
          color: AlertColor.SUCCESS,
        }
      case ApplyLaterStatus.EXISTING:
        return {
          icon: <WarningAmberOutlinedIcon />,
          title: t('e2e.Offer.applyLater.alert.existing.title'),
          content: t('e2e.Offer.applyLater.alert.existing.content'),
          color: AlertColor.WARNING,
        }
      case ApplyLaterStatus.ERROR:
        return {
          icon: <ReportOutlinedIcon />,
          title: t('e2e.Offer.applyLater.alert.error.title'),
          content: t('e2e.Offer.applyLater.alert.error.content'),
          color: AlertColor.ERROR,
        }
      case null:
      default:
        return null
    }
  }, [applyLaterStatus, i18n.resolvedLanguage])

  if (!alertConfig) {
    return <></>
  }

  return (
    <FigAlert
      {...alertConfig}
      fade={{ lifespanMs: offerAlertTimeoutMs, callback: clearApplyLaterStatus }}
    />
  )
}

export function Offer() {
  const { prequalResponse, firstName, setStepE2E, isQCBorrower, setPrequalGenericErrorPageError } =
    useContext(PrequalificationContext)
  const { reset: autosaveReset } = useContext(AutoSaveContext)
  const { currencyFormat, percentFormat } = useLocalizedFormatters()
  const { max_loan_amount, offers, application_url, application_id } =
    prequalResponse as PrequalSuccessResponseType
  const { applyLater } = useApiE2E()
  const { t } = useTranslation()

  const [applyLaterStatus, setApplyLaterStatus] = useState<ApplyLaterStatus | null>(null)

  useEffect(() => {
    if (offers === undefined) {
      captureMessage(
        `Missing offer info in prequalification: ${JSON.stringify(prequalResponse)}`,
        'warning',
      )
      setPrequalGenericErrorPageError(
        new Error(`Missing offer info in prequalification: ${JSON.stringify(prequalResponse)}`),
      )
      setStepE2E(StepsEnum.ERROR)
    } else {
      // Reset any tracked form state when valid offer is presented
      autosaveReset()
    }
  }, [offers])

  const { mutate: applyLaterMutate, isLoading } = useMutation<
    ApplyLaterResponseDataType,
    any,
    ApplyLaterRequestType
  >(
    request => {
      setApplyLaterStatus(null)
      return applyLater(request)
    },
    {
      useErrorBoundary: false,
      onSuccess: (result: ApplyLaterResponseDataType) => {
        const newApplyLaterStatus = result.data.status.toLowerCase() as ApplyLaterStatus
        // status comes back as capitalized
        setApplyLaterStatus(newApplyLaterStatus)
      },
      onError: error => {
        captureExceptionHelper('Error applying later', error)
        setApplyLaterStatus(ApplyLaterStatus.ERROR)
      },
    },
  )

  const offersByDescendingTerm = offers
    ? offers.sort(
        (a: PrequalOfferType, b: PrequalOfferType) =>
          b.term_duration_months - a.term_duration_months,
      )
    : []

  const rate = percentFormat(offersByDescendingTerm[0].apr)
  const maximum_term_years = offersByDescendingTerm[0]?.term_duration_months / 12
  const minimum_monthly_payment = offersByDescendingTerm[0]?.payment_amount

  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <OfferAlert {...{ applyLaterStatus, setApplyLaterStatus, isLoading }} />
      <Grid
        container
        style={{
          textAlign: 'center',
        }}
        direction="column"
        paddingX={{ xs: 1 }}
        paddingY={{ md: 10 }}
        rowSpacing={{ xs: 3, md: 4 }}
      >
        <Grid item xs={12}>
          <img height={100} src={t('e2e.Offer.logoUrl')} alt="Fig. A Fairstone Bank Company" />
        </Grid>
        <Grid item xs={12}>
          <TypographyHeader variant="h1">{t('e2e.Offer.congrats', { firstName })}</TypographyHeader>
          <TypographyHeader variant="h1">
            {t('e2e.Offer.approvedAmt', { amt: currencyFormat(max_loan_amount, 0) })}
          </TypographyHeader>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h2" sx={{ fontSize: '32px' }}>
            {rate} <LockIcon height="24px" width="24px" />
          </Typography>
          <Typography variant="body3">
            {t(isQCBorrower ? 'e2e.Offer.aprQC' : 'e2e.Offer.apr')}
          </Typography>
        </Grid>
        <Grid item xs={12} container rowSpacing={{ xs: '24px', md: 1 }} alignItems={'flex-end'}>
          <Grid item md={4} xs={12}>
            <Typography variant="h1">
              {t('e2e.Offer.monthlyPayment.value', {
                amt: currencyFormat(minimum_monthly_payment, 0),
              })}
            </Typography>
            <Typography variant="body3">{t('e2e.Offer.monthlyPayment.label')}</Typography>
          </Grid>
          <Grid item md={4} xs={12}>
            <Typography variant="h1">{t('e2e.Offer.loanType.value')}</Typography>
            <Typography variant="body3">{t('e2e.Offer.loanType.label')}</Typography>
          </Grid>
          <Grid item md={4} xs={12}>
            <Typography variant="h1">
              {t('e2e.Offer.term.value', { maximum_term_years })}
            </Typography>
            <Typography variant="body3">{t('e2e.Offer.term.label')}</Typography>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <PrimaryCTA
            buttonText={t('e2e.Offer.getLoan')}
            href={application_url}
            sx={{ width: theme.width.buttonPrimaryLg }}
          />
        </Grid>
        <Grid item xs={12}>
          <BasicCTA
            buttonText={t('e2e.Offer.applyLater.buttonLabel')}
            sx={{ width: theme.width.buttonPrimaryLg }}
            onClick={() => applyLaterMutate({ application_id: application_id! })}
            disabled={isLoading}
            loading={isLoading}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body2">{t('e2e.Offer.guarantee')}</Typography>
        </Grid>
      </Grid>
    </Box>
  )
}
