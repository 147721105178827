export enum PortalRoutesEnum {
  PORTAL_BASE = 'portal',
  PORTAL_INDEX = '',
  PORTAL_ERROR = 'error',
  PORTAL_PROFILE = 'profile',
  PORTAL_SIGN_IN = 'signin',
  PORTAL_PAYMENTS = 'payments',
  PORTAL_DOCUMENTS = 'documents',
  PORTAL_PAYMENT_PROTECTION = 'payment-protection',
  PORTAL_LOAN_INCREASE = 'loan-increase',
  PORTAL_HELP = 'help',
  PORTAL_TERMS = 'terms',
}

export enum PortalModalsEnum {
  AUTOMATIC_PAYMENTS_DETAIL = 'automaticPaymentsDetail',
}

export enum LoanStatusEnum {
  GOOD = 'Active - Good Standing',
  OVERDUE = 'Active - Bad Standing',
  CLOSED = 'Closed - Obligations met',
}

export enum TransactionStatusEnum {
  SUCCESS = 'success',
  PROCESSING = 'processing',
  FAILED = 'failed',
}

export enum PaymentMethodEnum {
  MANUAL_PAYMENT = 'Manual payment',
  BILL_PAYMENT = 'Bill payment',
  AUTOMATIC_PAYMENT = 'Automatic payment',
  INTERAC_PAYMENT = 'Interac payment',
  DISBURSAL = 'Disbursal',
  TBD = 'TBD',
}

export enum DropdownMenuEnum {
  USER = 'user',
  LANGUAGE = 'language',
}

export enum DropdownMenuItemUserEnum {
  PROFILE = 'profile',
  LOGOUT = 'logout',
}

export const TABLET_BREAKPOINT = 768
