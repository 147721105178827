import { Box, Grid, styled, useMediaQuery } from '@mui/material'

import theme from 'src/themes'
import { IconType } from 'src/types'

export const IconSection = ({ mobileIcon, desktopIcon }: IconType) => {
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const IconSectionContainer = styled('div')(({ theme }) => ({
    width: '100%',
    height: '100%',
    position: 'relative',
    [theme.breakpoints.up('md')]: {
      marginTop: '20px',
      width: 594,
    },
  }))

  return (
    <IconSectionContainer>
      <Grid item xs={12}>
        {isMobile ? (
          <Box pt="5%" px="16px">
            <img src={mobileIcon} alt="" width={'100%'} />
          </Box>
        ) : (
          <img src={desktopIcon} alt="" width={'100%'} />
        )}
      </Grid>
    </IconSectionContainer>
  )
}
