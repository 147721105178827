import useFreshChatWidget from 'src/hooks/useFreshChatWidget'
import {
  FRESHCHAT_SCRIPT_URL,
  ENVIRONMENT,
  EnvEnum,
  FRESHCHAT_TOKEN,
  FRESHCHAT_HOST,
} from 'src/utils/constants'

/**
 *
 */
export const UnauthenticatedFreshChatWrapper = ({
  shouldShow,
  children,
}: {
  shouldShow: boolean
  children: React.ReactElement
}) => {
  useFreshChatWidget({
    scriptSrc: FRESHCHAT_SCRIPT_URL,
    shouldShow: shouldShow,
    freshChatCustomParams: {
      cf_environment: ENVIRONMENT === EnvEnum.local ? 'development' : ENVIRONMENT,
    },
    freshChatInitParams: {
      token: FRESHCHAT_TOKEN,
      host: FRESHCHAT_HOST,
    },
  })

  return children
}
