import { FC, createContext, useEffect, ReactNode, useRef } from 'react'
import { captureMessage } from '@sentry/react'
import { useMutation } from 'react-query'

import { captureExceptionHelper, connectBankFlinksEventPosted } from 'src/utils'
import { useOriginationContext, useApi } from 'src/hooks'
import { StepsEnum } from 'src/types'

type State = Record<string, unknown>

export type FlinksContextProps = State

export const FlinksContext = createContext<FlinksContextProps>({} as FlinksContextProps)

const ALERT_EVENTS = [
  'MAXIMUM_RETRY_REACHED',
  'SESSION_NONEXISTENT',
  'SESSION_EXPIRED',
  'APP_OFFLINE',
  'APP_ONLINE',
]

const REDIRECT_EVENT = 'REDIRECT'
const COMPONENT_CLOSE_SESSION = 'COMPONENT_CLOSE_SESSION'
interface Props {
  children: ReactNode
}

/**
 *
 */
const FlinksProvider: FC<Props> = ({ children }) => {
  const { setStep, applicationId, bootstrapInfo, setFlinksLoginId, setGenericErrorPageError } =
    useOriginationContext()
  const { flinksSetAccount, flinksTrack } = useApi()
  const borrowerId = bootstrapInfo.borrower?.borrower_id

  const { mutate: trackFlinksMutation } = useMutation(['trackFlinks', applicationId], flinksTrack, {
    useErrorBoundary: false,
    onError: (e: any) => {
      captureMessage('Error calling flinks track event', { level: 'warning', extra: { e } })
    },
  })

  const { mutate: setFlinksAccountMutation } = useMutation(
    ['setFlinksAccount', applicationId],
    flinksSetAccount,
    {
      useErrorBoundary: false,
      onSuccess: () => {
        setStep(StepsEnum.FLINKS_PAYMENT_CHECK)
      },
      onError: (e: any) => {
        captureExceptionHelper('Error setting flinks account', e)
        setGenericErrorPageError(new Error('Error setting flinks account'))
        setStep(StepsEnum.ERROR)
      },
    },
  )

  const lastFlinksEvent = useRef('')

  useEffect(() => {
    const listener = function (
      e: MessageEvent<{ step: string; loginId?: string; accountId?: string }>,
    ) {
      const currentEvent = e.data.step
      if (ALERT_EVENTS.includes(currentEvent)) {
        captureMessage(`Flinks event received: ${currentEvent}`, 'warning')
      }
      if (currentEvent && lastFlinksEvent.current !== currentEvent) {
        // ^ remove multiple duplicate messages in a row
        connectBankFlinksEventPosted(currentEvent, StepsEnum.BANK_AUTO_CONNECT, 'Event Fired')
        trackFlinksMutation({ eventName: currentEvent, borrowerId: borrowerId! })
      }
      lastFlinksEvent.current = currentEvent

      if (currentEvent === REDIRECT_EVENT) {
        const { loginId, accountId } = e.data
        setFlinksLoginId(loginId!)
        setFlinksAccountMutation({
          loginId: loginId!,
          accountId: accountId!,
          borrowerId: borrowerId!,
        })
      }

      if (currentEvent === COMPONENT_CLOSE_SESSION) {
        setStep(StepsEnum.PAYMENT_METHOD_SELECT)
      }
    }
    window.addEventListener('message', listener)
    return () => window.removeEventListener('message', listener)
  }, [])

  const value: FlinksContextProps = {}
  return <FlinksContext.Provider value={value}>{children}</FlinksContext.Provider>
}

export default FlinksProvider
