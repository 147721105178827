import { Grid2 } from '@mui/material'
import { t } from 'i18next'
import { useEffect } from 'react'

import { LoadingAnimation } from 'src/components/common/animations/Loading'
import usePortalContext from 'src/portal/hooks/usePortalContext'
import { SectionHeading } from 'src/portal/pages/home/components/SectionHeading'
import { UpcomingPaymentsRow } from 'src/portal/pages/home/upcomingPayments/UpcomingPaymentsRow'

export const UpcomingPayments = () => {
  const { loanDetailData, loanRepaymentScheduleData, portalApiGetLoanRepaymentScheduleRefetch } =
    usePortalContext()

  useEffect(() => {
    portalApiGetLoanRepaymentScheduleRefetch()
  }, [])

  if (!loanRepaymentScheduleData || !loanDetailData) {
    return (
      <div>
        <LoadingAnimation />
      </div>
    )
  }

  const paymentProtectionApplied = loanDetailData.payment_protection_applied

  return (
    <Grid2 container flexDirection="column">
      <SectionHeading
        title={t('Portal.Home.label.upcomingPayments.title')}
        description={t('Portal.Home.label.upcomingPayments.description')}
        extraDescription={
          paymentProtectionApplied
            ? t('Portal.Home.label.upcomingPayments.descriptionWithPaymentProtection')
            : undefined
        }
      />
      {loanRepaymentScheduleData.map(transaction => (
        <UpcomingPaymentsRow key={transaction.id} {...{ transaction }} />
      ))}
    </Grid2>
  )
}
