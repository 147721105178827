import { Grid, Typography } from '@mui/material'

interface LineItemProps {
  title: string
  content: string
}
export const LineItem = ({ title, content }: LineItemProps) => {
  return (
    <>
      <Grid item xs={7}>
        <Typography variant="body2">{title}</Typography>
      </Grid>
      <Grid item xs={3} textAlign={'right'} justifyContent={'flex-end'}>
        <Typography variant="label">{content}</Typography>
      </Grid>
    </>
  )
}
