import { useClerk } from '@clerk/clerk-react'
import { Box, GlobalStyles, Grid2, useMediaQuery } from '@mui/material'
import { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'

import useFreshChatWidget from 'src/hooks/useFreshChatWidget'
import {
  desktopMenuHandIcon,
  desktopMenuHandIconActive,
  desktopMenuShieldIcon,
  desktopMenuShieldIconActive,
  loanIncreaseIcon,
  paymentProtectionIcon,
} from 'src/images'
import { PortalRoutesEnum } from 'src/portal/common'
import usePortalContext from 'src/portal/hooks/usePortalContext'
import Header from 'src/portal/layout/header/Header'
import { MenuButtonItem } from 'src/portal/layout/menu/common'
import DesktopMenu from 'src/portal/layout/menu/desktop/DesktopMenu'
import MobileMenu from 'src/portal/layout/menu/mobile/MobileMenu'
import PortalTheme from 'src/themes/portal'
import {
  EnvEnum,
  ENVIRONMENT,
  FRESHCHAT_HOST,
  FRESHCHAT_SCRIPT_URL,
  FRESHCHAT_TOKEN,
  FRESHCHAT_WIDGET_UUID,
} from 'src/utils/constants'

interface LayoutProps {
  children: ReactNode
}

export default function Layout({ children }: LayoutProps) {
  const isMobile = useMediaQuery(PortalTheme.breakpoints.down('sm'))
  const { signOut } = useClerk()
  useFreshChatWidget({
    scriptSrc: FRESHCHAT_SCRIPT_URL,
    shouldShow: true,
    freshChatCustomParams: {
      cf_environment: ENVIRONMENT === EnvEnum.local ? 'development' : ENVIRONMENT,
    },
    freshChatInitParams: {
      token: FRESHCHAT_TOKEN,
      // externalId: borrowerId, TODO: plug in borrowerId from portal context
      externalId: 'borrowerId',
      host: FRESHCHAT_HOST,
      widgetUuid: FRESHCHAT_WIDGET_UUID,
    },
    //TODO: plug in data for freshChatContactParams
    // freshChatContactParams: {
    // email: bootstrapInfo?.borrower?.borrower_email ?? '',
    // phone: bootstrapInfo?.borrower?.borrower_mobile ?? '',
    // firstName: bootstrapInfo?.borrower?.borrower_first_name ?? '',
    // lastName: bootstrapInfo?.borrower?.borrower_last_name ?? '',
    // },
  })

  const { isPaymentProtectionEnabled } = usePortalContext()
  const { t } = useTranslation()

  const mobileOptionalItemList: MenuButtonItem[] = [
    {
      icon: paymentProtectionIcon,
      label: t('Portal.Menu.label.paymentProtection'),
      iconAlt: t('Portal.Menu.alt.paymentProtectionIcon'),
      route: PortalRoutesEnum.PORTAL_PAYMENT_PROTECTION,
      isHidden: !isPaymentProtectionEnabled,
    },
    {
      icon: loanIncreaseIcon,
      label: t('Portal.Menu.label.loanIncrease'),
      iconAlt: t('Portal.Menu.alt.loanIncreaseIcon'),
      route: PortalRoutesEnum.PORTAL_LOAN_INCREASE,
      isHidden: false, // TODO: need data and logic for this
    },
  ]

  const desktopOptionalItemList = [
    {
      icon: desktopMenuShieldIcon,
      iconActive: desktopMenuShieldIconActive,
      iconAlt: t('Portal.Menu.alt.paymentProtectionIcon'),
      label: t('Portal.Menu.label.paymentProtection'),
      isHidden: !isPaymentProtectionEnabled,
      route: PortalRoutesEnum.PORTAL_PAYMENT_PROTECTION,
    },
    {
      icon: desktopMenuHandIcon,
      iconActive: desktopMenuHandIconActive,
      iconAlt: t('Portal.Menu.alt.loanIncreaseIcon'),
      label: t('Portal.Menu.label.loanIncrease'),
      isHidden: false, // TODO: need data and logic for this
      route: PortalRoutesEnum.PORTAL_LOAN_INCREASE,
    },
  ]

  return (
    <Box sx={{ backgroundColor: PortalTheme.color.grey1, height: '100%' }}>
      {/* style the freshchat widget for portal layout */}
      <GlobalStyles
        styles={{
          [PortalTheme.breakpoints.up('md')]: {
            '.fc-widget-normal': { bottom: '48px !important', right: '48px !important' },
          },
          [PortalTheme.breakpoints.down('md')]: {
            '.fc-widget-normal': { bottom: '120px !important', right: '24px !important' },
          },
        }}
      />
      <Header signOut={() => signOut({ redirectUrl: '/portal' })} />
      <Grid2 container sx={isMobile ? { flex: 1, overflowY: 'auto', paddingBottom: '100px' } : {}}>
        {isMobile ? (
          <MobileMenu optionalItemList={mobileOptionalItemList} />
        ) : (
          <Grid2 size={{ sm: 3, md: 2 }}>
            <DesktopMenu optionalItemList={desktopOptionalItemList} />
          </Grid2>
        )}
        <Grid2 size={{ xs: 12, sm: 9, md: 10 }} p={isMobile ? '24px' : '48px'}>
          {children}
        </Grid2>
      </Grid2>
    </Box>
  )
}
