import { useTranslation } from 'react-i18next'
import { useFlags } from 'launchdarkly-react-client-sdk'

import { useOriginationContext } from 'src/hooks'
import { PartnersEnum, FeatureFlags } from 'src/types'
import { ALTERNA_FLINKS_IFRAME_SRC, FLINKS_IFRAME_SRC, IS_PRODUCTION } from 'src/utils/constants'

const FLINKS_DAYS_365 = 'Days365'
const FLINKS_DAYS_90 = 'Days90'

// URL params need to be string values
interface FlinksUrlParams {
  consentEnable: string
  customerName: string
  closeEnable: string
  accountSelectorEnable: string
  accountSelectorCurrency: string
  windowContext: string
  eftEligibleRatio: string
  maximumRetry: string
  backEnable: string
  tag: string
  demo: string
}

const defaultFlinksParams: FlinksUrlParams = {
  consentEnable: 'true',
  customerName: 'Fig Financial Inc.',
  closeEnable: 'true',
  accountSelectorEnable: 'true',
  accountSelectorCurrency: 'cad',
  windowContext: 'parent',
  eftEligibleRatio: '0.8',
  maximumRetry: '3',
  backEnable: 'true',
  tag: '',
  demo: !IS_PRODUCTION + '',
}

/*
 *
 */
export const useConfiguredFlinksUrl = (): string => {
  const { i18n } = useTranslation()
  const { applicationId, bootstrapInfo } = useOriginationContext()
  const { flinksDaysOfTransaction } = useFlags<FeatureFlags>()

  let iframeSrc: string = FLINKS_IFRAME_SRC

  if (bootstrapInfo?.partner?.name === PartnersEnum.ALTERNA) {
    iframeSrc = ALTERNA_FLINKS_IFRAME_SRC
  }

  const daysOfTransactions: string = flinksDaysOfTransaction ? FLINKS_DAYS_365 : FLINKS_DAYS_90

  const urlParams: string = new URLSearchParams({
    ...defaultFlinksParams,
    tag: applicationId,
    language: i18n.resolvedLanguage ?? i18n.language,
    daysOfTransactions: daysOfTransactions,
  }).toString()

  return `${iframeSrc}?${urlParams}`
}
