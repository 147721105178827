import { useEffect, useState } from 'react'
import {
  Autocomplete,
  createFilterOptions,
  Grid,
  MenuItem,
  Paper,
  styled,
  SxProps,
  Theme,
  Typography,
} from '@mui/material'
import { useMutation } from 'react-query'
import { useTranslation } from 'react-i18next'
import { parseISO } from 'date-fns'

import { LoadingAnimation } from 'src/components/common/animations/Loading'
import { DivRoot } from 'src/components/common/DivRoot'
import { EmploymentStatus, StepsEnum } from 'src/types'
import { BackButton, PrimaryCTA } from 'src/components/common/Buttons'
import { occupationBackClicked, occupationProvided } from 'src/utils/analytics'
import { useApi, useOriginationContext } from 'src/hooks'
import { getDuration } from 'src/utils'
import { FigDropdown } from 'src/components/common/FigDropdown'
import FigTextField from 'src/components/common/FigTextField'
import enTransalation from 'src/locales/en/translation'
import { FigDatePickerMonth, getIsDateInFuture } from 'src/components/common/FigDatePicker'
import { isQCResident } from 'src/utils/borrower'
import { getRetryQueryOptionsWithErrorStep } from 'src/api/api'
import { dateISOFormat } from 'src/utils/format'

const filter = createFilterOptions<OccupationOptionType>()

export const FigMenuItem = styled(MenuItem)(({ theme }) => ({
  color: 'black',
  padding: '12px 24px !important',
  fontSize: '16px',
  overflow: 'hidden',

  '&.MuiAutocomplete-option[aria-selected="true"]': {
    backgroundColor: theme.color.brand5,
    '&.Mui-focused': {
      backgroundColor: theme.color.brand5,
    },
  },
  '&.Mui-selected': {
    backgroundColor: theme.color.brand5,
  },
  '&:hover': {
    backgroundColor: theme.color.grey2,
  },
}))

const menuStyles: SxProps<Theme> = theme => ({
  '& > [tabindex="-1"]': {
    backgroundColor: 'transparent',
    boxShadow: 'none',
    marginTop: '4px',
  },
  '& [role="listbox"]': {
    border: 'solid 2px',
    borderColor: theme.color.brand1,
    borderRadius: '4px',
    marginTop: '4px',
    backgroundColor: 'white',
  },
})

const employmentStatuses = [
  EmploymentStatus.EMPLOYED,
  EmploymentStatus.SELF_EMPLOYED,
  EmploymentStatus.UNEMPLOYED,
  EmploymentStatus.RETIRED,
  EmploymentStatus.STUDENT,
  EmploymentStatus.DISABILITY,
]

const PaperComponent = ({ children }: { children: any }) => (
  <Paper sx={menuStyles}>{children}</Paper>
)

type OccupationOptionType = {
  inputValue: string
  title?: string
}

const employmentStatusToTransKey: {
  [key in EmploymentStatus]: keyof (typeof enTransalation)['Occupation']['employmentStatuses']
} = {
  [EmploymentStatus.EMPLOYED]: 'employed',
  [EmploymentStatus.SELF_EMPLOYED]: 'selfEmployed',
  [EmploymentStatus.UNEMPLOYED]: 'unemployed',
  [EmploymentStatus.RETIRED]: 'retired',
  [EmploymentStatus.STUDENT]: 'student',
  [EmploymentStatus.DISABILITY]: 'disability',
}

const getIsContinueDisabled = ({
  occupation,
  employer,
  employmentStatus,
  employmentStartDate,
  incomeSource,
  isEmployed,
  isEmployedQC,
  isFromQC,
}: {
  occupation: string
  employer: string
  employmentStatus: string
  employmentStartDate: string
  incomeSource: string
  isEmployed: boolean
  isEmployedQC: boolean
  isFromQC: boolean
}) => {
  const employmentStatusValid = !!employmentStatus
  const occupationValid = 3 <= occupation.length || !isEmployed
  const employerValid = !!employer || !isEmployedQC
  const employmentStartDateValid = !!employmentStartDate || !isEmployedQC
  const incomeSourceValid = isEmployed || !isFromQC || !!incomeSource

  return !(
    employmentStatusValid &&
    occupationValid &&
    employerValid &&
    employmentStartDateValid &&
    incomeSourceValid
  )
}

interface Props {
  previousStep: StepsEnum
  nextStep: StepsEnum
}

const getIsEmployed = (employmentStatus: string) =>
  [EmploymentStatus.EMPLOYED, EmploymentStatus.SELF_EMPLOYED].some(
    status => status === employmentStatus,
  )

export default Occupation
/**
 *
 */
function Occupation({ previousStep, nextStep }: Props) {
  const {
    setStep,
    cachedApplication,
    updateCachedApplication,
    bootstrapInfo,
    occupationData,
    asyncRequestsInProgress,
    updateAsyncRequestStatusCallback,
    setGenericErrorPageError,
  } = useOriginationContext()
  const { saveOccupation } = useApi()
  const { t, i18n } = useTranslation()

  const getEmploymentStatus = (status: EmploymentStatus) => {
    return t(`Occupation.employmentStatuses.${employmentStatusToTransKey[status]}`)
  }

  const [occupation, setOccupation] = useState(cachedApplication.occupation || '')
  const [occupationLanguage, setOccupationLanguage] = useState(
    cachedApplication.occupation_language || '',
  )
  const [employmentStatus, setEmploymentStatus] = useState<EmploymentStatus | ''>(
    cachedApplication.employment_status || '',
  )
  const [employer, setEmployer] = useState(cachedApplication.employer || '')
  const [incomeSource, setIncomeSource] = useState(cachedApplication.income_source || '')
  const [employmentStartDate, setEmploymentStartDate] = useState<string>(
    cachedApplication?.employment_start_date || '',
  )

  const isEmployed = getIsEmployed(employmentStatus)
  const isEmployedQC = isEmployed && isQCResident(bootstrapInfo.borrower)
  const isFromQC = isQCResident(bootstrapInfo.borrower)

  const isContinueDisabled = getIsContinueDisabled({
    occupation,
    employer,
    employmentStatus,
    employmentStartDate,
    incomeSource,
    isEmployed,
    isEmployedQC,
    isFromQC,
  })

  const { mutateAsync: occupationMutation } = useMutation(
    ['saveOccupation', occupation],
    saveOccupation,
    {
      ...getRetryQueryOptionsWithErrorStep({
        queryName: 'Save Occupation',
        setGenericErrorPageError,
        setStep,
      }),
    },
  )

  useEffect(() => {
    if (occupationLanguage && i18n.resolvedLanguage !== occupationLanguage) {
      setOccupation('')
    }
  }, [i18n.resolvedLanguage])

  const handleSetEmploymentStatus = (newEmploymentStatus: EmploymentStatus) => {
    // set our state
    setEmploymentStatus(newEmploymentStatus)

    const isNowEmployed = getIsEmployed(newEmploymentStatus)

    const isEmploymentStatusChanged =
      !employmentStatus || isEmployed !== isNowEmployed || newEmploymentStatus !== employmentStatus
    // employment status changed if we didn't have an employment status previously or if isEmployed has been updated

    if (isEmploymentStatusChanged) {
      if (isNowEmployed) {
        setOccupation('')
        setOccupationLanguage('')
        setIncomeSource('')
      } else {
        setOccupation(newEmploymentStatus) // set occupation to employment status for unemployed
        setEmployer('')
        setEmploymentStartDate('')
      }
    }
  }

  const handleContinue = () => {
    const defaultIncomeSource = 'employment'
    const duration = employmentStartDate ? getDuration(parseISO(employmentStartDate)) : undefined
    occupationProvided(
      {
        occupation,
        employment_status: employmentStatus,
        employer,
        length_of_employment: duration?.toString() || '',
        source_of_income: incomeSource || defaultIncomeSource,
      },
      StepsEnum.OCCUPATION,
    )
    updateAsyncRequestStatusCallback('saveOccupation', () =>
      occupationMutation({
        employmentStatus,
        occupation,
        employer,
        duration,
        incomeSource,
      }),
    )

    updateCachedApplication({
      occupation,
      occupation_language: i18n.resolvedLanguage,
      employment_status: employmentStatus ? employmentStatus : undefined,
      employer,
      employment_start_date: employmentStartDate,
      income_source: incomeSource,
    })
    setStep(nextStep)
  }

  useEffect(() => {
    if (occupationLanguage && i18n.resolvedLanguage !== occupationLanguage) {
      setOccupation('')
      setOccupationLanguage('')
    }
  }, [i18n.resolvedLanguage])

  if (asyncRequestsInProgress.getOccupation) {
    return <LoadingAnimation />
  }
  return (
    <DivRoot>
      <Grid container>
        <Grid item xs={12} marginBottom={'16px'}>
          <Typography textAlign="center" variant="h1">
            {t('Occupation.employmentStatus.title')}
          </Typography>
        </Grid>
        <Grid item xs={12} marginBottom="16px">
          <FigDropdown
            placeholder={t('Occupation.employmentStatus.placeholder')}
            onChange={e => handleSetEmploymentStatus(e.target.value as EmploymentStatus)}
            value={employmentStatus}
            label={t('Occupation.employmentStatus.label')}
            menuItems={employmentStatuses.map(status => (
              <FigMenuItem key={status} value={status}>
                {getEmploymentStatus(status)}
              </FigMenuItem>
            ))}
          />
        </Grid>
        {isEmployed && (
          <Grid container>
            <Grid item xs={12} marginBottom={'16px'}>
              <Autocomplete
                freeSolo
                disableClearable={!occupation?.length}
                options={occupationData}
                onChange={(_, newValue: any) => {
                  setOccupation(newValue?.inputValue ?? newValue ?? '')
                }}
                filterOptions={(options, params) => {
                  const filtered = filter(options, params)
                  if (params.inputValue !== '') {
                    filtered.push({
                      inputValue: params.inputValue,
                      title: t('Occupation.occupation.add', { occupationName: params.inputValue }),
                    })
                  }

                  return filtered
                }}
                value={occupation}
                getOptionLabel={option => {
                  // e.g value selected with enter, right from the input
                  if (typeof option === 'string') {
                    return option
                  }
                  if (option.inputValue) {
                    return option.inputValue
                  }
                  return option.title
                }}
                componentsProps={{
                  popper: {
                    modifiers: [
                      {
                        name: 'flip',
                        enabled: false,
                      },
                      {
                        name: 'preventOverflow',
                        enabled: false,
                      },
                    ],
                  },
                }}
                PaperComponent={PaperComponent as any}
                renderOption={(props: object, option: any, _state: any, _ownerState: object) => (
                  <FigMenuItem {...props}>
                    <p style={{ whiteSpace: 'pre-wrap', margin: 0 }}>{option?.title ?? option}</p>
                  </FigMenuItem>
                )}
                renderInput={params => (
                  <FigTextField
                    {...params}
                    onChange={e => {
                      setOccupation(e.target.value)
                    }}
                    label={t('Occupation.occupation.label')}
                    placeholder={t('Occupation.occupation.placeholder')}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} marginBottom={'16px'}>
              <FigTextField
                onChange={e => {
                  setEmployer(e.target.value)
                }}
                label={t(
                  isEmployedQC ? 'Occupation.employer.labelQC' : 'Occupation.employer.label',
                )}
                placeholder={t('Occupation.employer.placeholder')}
                value={employer}
              />
            </Grid>
            {isEmployedQC && (
              <Grid item xs={12} marginBottom={'16px'}>
                <FigDatePickerMonth
                  label={t('Occupation.duration.label')}
                  getIsInvalidDate={getIsDateInFuture}
                  setCalendarDate={(d: Date) => setEmploymentStartDate(dateISOFormat(d))}
                  calendarDate={employmentStartDate ? parseISO(employmentStartDate) : null}
                  disableWeekends={false}
                />
              </Grid>
            )}
          </Grid>
        )}
        {employmentStatus && !isEmployed && (
          <Grid item xs={12} marginBottom={'16px'}>
            <FigTextField
              onChange={e => {
                setIncomeSource(e.target.value)
              }}
              label={
                isFromQC ? t('Occupation.incomeSource.labelQC') : t('Occupation.incomeSource.label')
              }
              placeholder={t('Occupation.incomeSource.placeholder')}
              value={incomeSource}
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <PrimaryCTA
            buttonText={t('common.continueLabel')}
            onClick={handleContinue}
            disabled={isContinueDisabled}
          />
        </Grid>
        <Grid item xs={12} display="flex" justifyContent="center" marginTop="16px">
          <BackButton
            onClick={() => {
              occupationBackClicked(StepsEnum.OCCUPATION)
              setStep(previousStep)
            }}
          />
        </Grid>
      </Grid>
    </DivRoot>
  )
}
