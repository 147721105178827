import { Grid } from '@mui/material'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import { LoadingAnimation } from 'src/components/common/animations/Loading'
import { PrimaryCTA } from 'src/components/common/Buttons'
import { DivRoot } from 'src/components/common/DivRoot'
import { useSelectOffer } from 'src/components/CreditRenewal/hooks/useSelectOffer'
import { CreditRenewalStepsEnum, PaymentScheduleEnum } from 'src/types'
import { useCreditRenewalContext } from 'src/hooks'
import { creditRenewalsLoanDetailsPageCTAClicked } from 'src/utils'
import useCreditRenewal from 'src/hooks/useCreditRenewal'

import { LoanAmountComponent } from './components/LoanDetails/LoanAmountComponent'
import { PaymentCalendarComponent } from './components/LoanDetails/PaymentCalendarComponent'
import { SelectOfferComponent } from './components/LoanDetails/SelectOfferComponent'

interface LoanDetailsProps {
  nextStep: CreditRenewalStepsEnum
}

function LoanDetails({ nextStep }: LoanDetailsProps) {
  const { bootstrapRefetch } = useCreditRenewal()
  const { jwtApiKey } = useParams()
  const { t } = useTranslation()
  const {
    setCRStep,
    handleLoanIncreaseAmountChange,
    minLoanIncreaseAmount,
    maxLoanIncreaseAmount,
    totalLoanAmount,
    selectedLoanIncreaseAmount,
    setSelectedLoanIncreaseAmount,
    fetchData,
    saveOfferTermMutation,
    cachedApplication,
    asyncRequestsInProgress,
    bootstrapInfo,
    offerList,
    setOfferList,
    updateCachedApplication,
    cachedApplications,
    applicationId,
    setStep,
  } = useCreditRenewalContext()
  const cachedSchedule = cachedApplications[applicationId]?.saved_repayment_schedule

  const {
    apr: offerApr,
    borrower,
    selectedOfferTerm,
    setSelectedOfferIdOnClick,
    currencyFormat,
    percentFormat,
    isQCResident,
  } = useSelectOffer()

  useEffect(() => {
    bootstrapRefetch()
  }, [])

  const apr = bootstrapInfo?.application?.apr as number
  const getRepaymentScheduleInProgress = asyncRequestsInProgress?.getRepaymentSchedule
  const saveOfferTermInProgress = asyncRequestsInProgress?.saveOffer

  // Set the selected offer term to the first offer term on the first render
  useEffect(() => {
    if (!offerList.length || !cachedApplication.selected_loan_increase_amount) {
      return
    }

    // skip save offer term call before offer list is loaded
    if (!offerList[0]?.term_length) {
      return
    }

    if (!cachedApplication.selected_offer_term) {
      updateCachedApplication({
        selected_offer_term: offerList[0]?.term_length,
      })
      saveOfferTermMutation({
        term: offerList[0].term_length,
        loan_amount: cachedApplication.selected_loan_increase_amount,
        estimated_due_amount: selectedLoanIncreaseAmount || maxLoanIncreaseAmount, // default to max loan increase amount if this is the first time user is on this screen
      })
    }
  }, [])

  // initialize the selected loan increase amount to the max loan increase amount by default
  useEffect(() => {
    if (maxLoanIncreaseAmount && selectedLoanIncreaseAmount === 0) {
      setSelectedLoanIncreaseAmount(maxLoanIncreaseAmount)
    }
  }, [maxLoanIncreaseAmount])

  // generate and save payment schedule on first page load
  useEffect(function initializePaymentSchedule() {
    if (
      cachedApplication.selected_loan_increase_amount &&
      !cachedApplication.saved_repayment_schedule &&
      bootstrapInfo?.system_date
    ) {
      fetchData()
    }
  }, [])

  useEffect(
    function updateOfferTermWithPaymentScheduleWithdrawAmount() {
      /**
       * Fixes a bug that also exists in origination, where the offer term amount doesn't match the server-calculated withdrawal amount
       * on the payment schedule, This function will update the offer term with the correct amount.
       */
      if (!cachedApplication.saved_repayment_schedule) {
        return
      }

      // do not override the offer term if the schedule is not monthly
      if (
        cachedApplication.saved_repayment_schedule?.schedule !== PaymentScheduleEnum.ONCE_A_MONTH
      ) {
        return
      }

      // skip if the cached application's increase amount has not been updated yet
      if (selectedLoanIncreaseAmount !== cachedApplication.selected_loan_increase_amount) {
        return
      }

      // If the offer list doesn't exist or the payment amount is not set, return
      if (!offerList.length || !offerList[0].payment_amount) {
        return
      }

      const withdrawAmount = parseFloat(cachedApplication.saved_repayment_schedule.withdraw_amount)

      // Find the closest offer to the new withdraw amount
      const closestOfferIndex = offerList.reduce(
        (closestIndex, currentOffer, currentIndex) =>
          Math.abs(currentOffer.payment_amount - withdrawAmount) <
          Math.abs(offerList[closestIndex].payment_amount - withdrawAmount)
            ? currentIndex
            : closestIndex,
        0,
      )

      // Update the withdraw_amount for the closest offer
      const updatedOfferList = offerList.map((offer, index) =>
        index === closestOfferIndex ? { ...offer, payment_amount: withdrawAmount } : offer,
      )

      setOfferList(updatedOfferList)
    },
    [cachedApplication.saved_repayment_schedule, selectedLoanIncreaseAmount],
  )

  if (!cachedApplication.selected_loan_increase_amount) {
    return <LoadingAnimation />
  }
  return (
    <DivRoot>
      <Grid container spacing={0}>
        <LoanAmountComponent
          selectedLoanIncreaseAmount={selectedLoanIncreaseAmount}
          handleLoanIncreaseAmountChange={handleLoanIncreaseAmountChange}
          minLoanIncreaseAmount={minLoanIncreaseAmount}
          maxLoanIncreaseAmount={maxLoanIncreaseAmount}
          totalLoanAmount={totalLoanAmount}
          apr={apr}
          disabled={getRepaymentScheduleInProgress || saveOfferTermInProgress}
        />
        <SelectOfferComponent
          offerList={offerList}
          selectedOfferTerm={selectedOfferTerm}
          setSelectedOfferIdOnClick={setSelectedOfferIdOnClick}
          apr={offerApr}
          borrower={borrower}
          currencyFormat={currencyFormat}
          percentFormat={percentFormat}
          isQCResident={isQCResident}
          disabled={getRepaymentScheduleInProgress || saveOfferTermInProgress}
        />
        <Grid item xs={12} sx={{ paddingTop: '32px' }}>
          <PaymentCalendarComponent
            asyncRequestsInProgress={asyncRequestsInProgress}
            cachedSchedule={cachedSchedule}
            setStep={setStep}
          />
        </Grid>
        <Grid item xs={12} sx={{ paddingTop: '32px' }}>
          <PrimaryCTA
            disabled={getRepaymentScheduleInProgress || saveOfferTermInProgress}
            onClick={() => {
              setCRStep(nextStep, jwtApiKey!)
              creditRenewalsLoanDetailsPageCTAClicked(
                t('CreditRenewals.LoanDetails.buttonLabel'),
                CreditRenewalStepsEnum.LOAN_DETAILS,
              )
            }}
            buttonText={t('CreditRenewals.LoanDetails.buttonLabel')}
            aria-label={t('CreditRenewals.LoanDetails.buttonLabel')}
          />
        </Grid>
      </Grid>
    </DivRoot>
  )
}

export default LoanDetails
