/**
 * Returns an event handler that triggers the callback when 'Enter' or 'Space' is pressed.
 * This is useful for accessibility.
 *
 * @param callback - Function to be called on key press.
 * @returns A keyboard event handler function.
 */
export const onKeySelect = (callback: () => void) => (event: React.KeyboardEvent) => {
  if (isEnterOrSpaceKeyPressed(event)) {
    event.preventDefault()
    callback()
  }
}

const isEnterKeyPressed = (e: React.KeyboardEvent) => e.key === 'Enter'
const isSpaceKeyPressed = (e: React.KeyboardEvent) => e.key === ' '

export const isEnterOrSpaceKeyPressed = (e: React.KeyboardEvent) =>
  isEnterKeyPressed(e) || isSpaceKeyPressed(e)
