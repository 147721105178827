import { Typography } from '@mui/material'

import PortalTheme from 'src/themes/portal'

export const LabelText = ({ text }: { text: string }) => {
  return (
    <Typography variant="body3" color={PortalTheme.color.grey7} py="6px">
      {text}
    </Typography>
  )
}
