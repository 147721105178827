import { Typography } from '@mui/material'
import Grid from '@mui/material/Grid'
import { styled } from '@mui/material/styles'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { DivRoot } from 'src/components/common/DivRoot'
import { useOriginationContext } from 'src/hooks'
import { connectionSuccessImg } from 'src/images'
import theme from 'src/themes'
import { StepsEnum } from 'src/types'

const ImgStyle = styled('img')({
  width: '80px',
  height: '80px',
})

const GridImage = styled(Grid)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginBottom: '16px',
})

const DISPLAY_SECONDS = 4

export default ConnectBankAccountSuccess
/**
 *
 */
function ConnectBankAccountSuccess() {
  const { setStep } = useOriginationContext()

  const { t } = useTranslation()

  useEffect(() => {
    const timer = setTimeout(() => {
      setStep(StepsEnum.REPAYMENT_SCHEDULE_CONFIRM)
    }, 1000 * DISPLAY_SECONDS)
    return () => {
      clearTimeout(timer)
    }
  }, [])

  return (
    <DivRoot>
      <GridImage item xs={12}>
        <ImgStyle src={connectionSuccessImg} alt="Checkmark" />
      </GridImage>
      <Grid item xs={12}>
        <Typography variant="h1" textAlign="center" marginBottom="16px">
          {t('ConnectBankAccountSuccess.title')}
        </Typography>
      </Grid>
      <Grid item xs={12} textAlign="center">
        <Typography variant="body2" color={theme.color.grey6}>
          {t('ConnectBankAccountSuccess.subtitle')}
        </Typography>
      </Grid>
    </DivRoot>
  )
}
