import Grid from '@mui/material/Grid'
import Radio from '@mui/material/Radio'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableRow from '@mui/material/TableRow'
import Typography from '@mui/material/Typography'
import { styled } from '@mui/material/styles'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'

import { BackButton, BasicCTA, PrimaryCTA } from 'src/components/common/Buttons'
import { DivRoot } from 'src/components/common/DivRoot'
import { BankLogo } from 'src/components/common/Logos'
import { LoadingAnimation } from 'src/components/common/animations/Loading'
import { useApi, useOriginationContext } from 'src/hooks'
import { useLocalizedFormatters } from 'src/hooks/useLocalizedFormatters'
import theme from 'src/themes'
import { BankingType, StepsEnum } from 'src/types'
import {
  copy,
  selectExistingAddBankAccountClicked,
  selectExistingBackClicked,
  selectExistingContinueClicked,
} from 'src/utils'

// The issue is that the typescript implementation
// differs from what the documentation says should happen.
// https://stackoverflow.com/a/69422141
type TypographyExtraProps = {
  component: React.ElementType
}

const TableGridBorder = styled(Grid)(({ theme }) => ({
  border: `1px solid ${theme.color.grey3}`,
  borderRadius: '3px',
  marginTop: '8px',
}))

const TypographyAccountDetail = styled(Typography)<TypographyExtraProps>({
  color: theme.color.grey9,
  fontWeight: 400,
  fontSize: '16px',
})

const TableRowStyled = styled(TableRow)({
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
})

const TableCellStyled = styled(TableCell)({
  padding: '16px',
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
})

const RadioStyled = styled(Radio)({
  padding: '0',
  marginRight: 8,
})

interface SavedMethodItemProps {
  info: BankingType
  isChecked: boolean
  setChecked: VoidFunction
}

function SavedMethodItem({ info, isChecked, setChecked }: SavedMethodItemProps) {
  const { bankFormat } = useLocalizedFormatters()
  const description = bankFormat(info)
  return (
    <TableRowStyled>
      <TableCellStyled onClick={setChecked}>
        <RadioStyled checked={isChecked} onChange={setChecked} value="a" name="radio-buttons" />
        <BankLogo name={info.institution_name} geometry="square" style={{ marginRight: 8 }} />
        <TypographyAccountDetail component="span">{description}</TypographyAccountDetail>
      </TableCellStyled>
    </TableRowStyled>
  )
}

const screen = StepsEnum.PAYMENT_METHOD_SELECT

export default SelectExisting
/**
 *
 */
function SelectExisting() {
  const { setStep, bankAccounts, applicationId, setCachedApplications, cachedApplications } =
    useOriginationContext()
  const { t } = useTranslation()
  const { linkPaymentMethod } = useApi()
  const selectedBankAccount = cachedApplications[applicationId]?.selected_bank_account as string

  const { refetch, isFetching } = useQuery(
    ['linkPaymentMethod', selectedBankAccount],
    () => linkPaymentMethod(selectedBankAccount),
    {
      enabled: false,
      onSuccess: () => {
        setStep(StepsEnum.REPAYMENT_SCHEDULE_CONFIRM)
      },
    },
  )

  function handleSelectedBankAccount(info: BankingType) {
    const updatedCachedApplications = copy(cachedApplications)
    updatedCachedApplications[applicationId].selected_bank_account = info.bank_id
    setCachedApplications(updatedCachedApplications)
  }

  if (isFetching) {
    return <LoadingAnimation subtitle={t('SelectExisting.loading')} />
  }

  return (
    <DivRoot>
      <Grid container spacing={0}>
        <Grid item xs={12} marginBottom="16px">
          <Typography variant="h1" textAlign="center">
            {t('SelectExisting.title')}
          </Typography>
        </Grid>

        <TableGridBorder item xs={12} marginTop="16px">
          <TableContainer>
            <Table>
              <TableBody>
                {bankAccounts.map(info => (
                  <SavedMethodItem
                    info={info}
                    key={info.bank_id}
                    isChecked={info.bank_id === selectedBankAccount}
                    setChecked={() => {
                      handleSelectedBankAccount(info)
                    }}
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </TableGridBorder>

        <Grid item xs={12} display="flex" justifyContent="center" marginTop="16px">
          <PrimaryCTA
            onClick={() => {
              selectExistingContinueClicked('Continue', screen)
              refetch()
            }}
            disabled={!selectedBankAccount}
            buttonText={t('common.continueLabel')}
          />
        </Grid>

        <Grid item xs={12} display="flex" justifyContent="center" marginTop="16px">
          <BasicCTA
            onClick={() => {
              selectExistingAddBankAccountClicked('Add a bank account', screen)
              setStep(StepsEnum.BANK_AUTO_CONNECT)
            }}
            buttonText={t('SelectExisting.addAccount')}
          />
        </Grid>

        <Grid item xs={12} display="flex" justifyContent="center" marginTop="16px">
          <BackButton
            onClick={() => {
              selectExistingBackClicked('Back', screen)
              setStep(StepsEnum.LOAN_PURPOSE)
            }}
          />
        </Grid>
      </Grid>
    </DivRoot>
  )
}
