import { Typography } from '@mui/material'
import Grid from '@mui/material/Grid'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import { parseISO } from 'date-fns'

import { PaymentScheduleCalendar } from 'src/components/PaymentSchedule/shared/PaymentScheduleCalendar'
import { BackButton, PrimaryCTA } from 'src/components/common/Buttons'
import { DivRoot } from 'src/components/common/DivRoot'
import { LoadingAnimation } from 'src/components/common/animations/Loading'
import { useOriginationContext } from 'src/hooks'
import { useScheduleHelper } from 'src/hooks/useScheduleHelper'
import {
  PaymentScheduleEnum,
  MonthlyScheduleEnum,
  StepsEnum,
  CachedApplications,
  CreditRenewalStepsEnum,
} from 'src/types/common'
import { confirmPaymentScheduleBackClicked, scheduleContinueClicked } from 'src/utils'
import { getDaysBetween } from 'src/utils/date'
import { BootstrapInfoType, SavedRepaymentScheduleType } from 'src/types'

interface ConfirmPaymentScheduleProps {
  previousStep: StepsEnum
  nextStep: StepsEnum
  bootstrapInfo: BootstrapInfoType
  setStep: (step: StepsEnum | CreditRenewalStepsEnum) => void
  cachedSchedule?: SavedRepaymentScheduleType | null
  applicationId: string
  setAuthorizePaymentStartError: (value: boolean) => void
  cachedApplications: CachedApplications
  setCachedApplications: (value: CachedApplications) => void
}

function ConfirmPaymentSchedule({
  bootstrapInfo,
  setStep,
  nextStep,
  previousStep,
  cachedSchedule,
  cachedApplications,
  applicationId,
  setCachedApplications,
  setAuthorizePaymentStartError,
}: ConfirmPaymentScheduleProps) {
  const { asyncRequestsInProgress, isCheckingAppStatus } = useOriginationContext()

  const { t } = useTranslation()

  const { generateAndSaveSchedule } = useScheduleHelper({
    bootstrapInfo,
    cachedApplications,
    applicationId,
    setCachedApplications,
    setAuthorizePaymentStartError,
  })

  const paymentCycleDueDateStr = cachedSchedule?.payment_cycle_due_date
  const frequency = cachedSchedule?.schedule as PaymentScheduleEnum | undefined
  const monthlyChoice = cachedSchedule?.monthly_choice as MonthlyScheduleEnum | undefined
  const paymentCycleDueDate = paymentCycleDueDateStr ? parseISO(paymentCycleDueDateStr) : null

  // Note: a schedule is stale if system date is less than 7 days from the payment cycle due date
  const isScheduleStale = paymentCycleDueDate
    ? getDaysBetween(new Date(bootstrapInfo!.system_date!), paymentCycleDueDate!) < 7
    : false
  const shouldRefechPaymentSchedule = !cachedSchedule || isScheduleStale

  const continueLabel = t('common.continueLabel')

  const handleContinue = () => {
    scheduleContinueClicked(
      continueLabel,
      StepsEnum.REPAYMENT_SCHEDULE_CONFIRM,
      cachedSchedule!.schedule,
    )
    setStep(nextStep)
  }

  const handleCancel = () => {
    confirmPaymentScheduleBackClicked(StepsEnum.REPAYMENT_SCHEDULE_CONFIRM)
    setStep(previousStep)
  }

  const { isFetching } = useQuery<Promise<void>>(
    ['generatePaymentSchedule'],
    () =>
      generateAndSaveSchedule({
        schedule: frequency,
        monthly_choice: monthlyChoice,
        first_payment_date: paymentCycleDueDateStr!,
      }),
    {
      enabled: shouldRefechPaymentSchedule,
    },
  )

  if (asyncRequestsInProgress.saveOffer || isCheckingAppStatus) {
    return <LoadingAnimation />
  }

  return (
    <DivRoot>
      <Typography variant="h1" textAlign="center" marginBottom="20px">
        {t('PaymentSchedule.ConfirmPaymentSchedule.title')}
      </Typography>
      <PaymentScheduleCalendar
        isLoading={isFetching}
        showChangeButton={true}
        showDisclaimer={true}
        first_payment_date={cachedSchedule?.first_payment_date}
        payment_cycle_due_date={cachedSchedule?.payment_cycle_due_date}
        schedule={cachedSchedule?.schedule}
        monthly_choice={cachedSchedule?.monthly_choice}
        withdraw_amount={cachedSchedule?.withdraw_amount}
        setStep={setStep}
      />
      <Grid item xs={12} display="flex" justifyContent="center" id="continueBtn">
        <PrimaryCTA buttonText={continueLabel} onClick={handleContinue} disabled={isFetching} />
      </Grid>
      <Grid item xs={12} display="flex" justifyContent="center" marginTop="16px">
        <BackButton onClick={handleCancel} />
      </Grid>
    </DivRoot>
  )
}
export default ConfirmPaymentSchedule
