import { Box, Grid, styled, Typography } from '@mui/material'
import { useContext } from 'react'
import { useTranslation } from 'react-i18next'

import { PrimaryCTA } from 'src/components/common/Buttons'
import { PrequalificationContext } from 'src/contexts'
import { useLocalizedFormatters } from 'src/hooks/useLocalizedFormatters'
import { PrequalDeclineResponseType, StepsEnum } from 'src/types'
import { SecondLookAmount } from 'src/types/common'
import { figSecondLookCobrand } from 'src/images'
import theme from 'src/themes'
import { secondLookOfferPageCTAClicked } from 'src/utils'

const TypographyHeader = styled(Typography)(() => ({
  fontSize: '32px',
  lineHeight: '48px',
  fontWeight: 600,
}))

const ConsentTypography = styled(Typography)({
  fontWeight: 400,
  color: theme.color.grey6,
  fontSize: '13px',
  lineHeight: '18px',
  fontStyle: 'italic',
})

export function SecondLookOffer() {
  const { prequalResponse, firstName } = useContext(PrequalificationContext)
  const { currencyFormat } = useLocalizedFormatters()
  const { second_look_url, is_homeowner } = prequalResponse as PrequalDeclineResponseType
  const { t } = useTranslation()
  const amt = is_homeowner ? SecondLookAmount.HOMEOWNER : SecondLookAmount.NON_HOMEOWNER

  const handleClick = () => {
    secondLookOfferPageCTAClicked(
      t(`e2e.SecondLookOffer.button.label`),
      StepsEnum.SECOND_LOOK_OFFER,
    )
  }

  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <Grid
        container
        style={{
          textAlign: 'center',
        }}
        direction="column"
        paddingX={{ xs: 1 }}
        paddingY={{ md: 10 }}
        rowSpacing={{ xs: 3, md: 4 }}
      >
        <Grid item xs={12}>
          <img height={80} src={figSecondLookCobrand} alt="Fig. A Fairstone Bank Company" />
        </Grid>
        <Grid item xs={12}>
          <TypographyHeader variant="h1">
            {t('e2e.SecondLookOffer.title', { firstName, amt: currencyFormat(amt, 0) })}
          </TypographyHeader>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body2" margin="20px 20px 0 20px">
            {t('e2e.SecondLookOffer.body1')}
          </Typography>
          <Typography variant="body2" margin="20px 20px 0 20px">
            {t('e2e.SecondLookOffer.body2')}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <PrimaryCTA
            buttonText={t('e2e.SecondLookOffer.button.label')}
            href={second_look_url}
            onClick={handleClick}
            sx={{ width: '60%' }}
          />
          <Typography variant="body2" margin="20px 20px 0 20px">
            {t('e2e.SecondLookOffer.button.text')}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <ConsentTypography variant="body3">{t('e2e.SecondLookOffer.terms')}</ConsentTypography>
        </Grid>
      </Grid>
    </Box>
  )
}
