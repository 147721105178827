import { Grid, Typography } from '@mui/material'
import { t } from 'i18next'

import { CRLoanDetailsSummary } from 'src/components/CreditRenewal/components/ReviewLoanDetailsSummary'
import { LoanDetailsSummary } from 'src/components/ReviewOrder/components/LoanDetailsSummary'
import { LoanDetailsText } from 'src/components/ReviewOrder/components/LoanDetailsText'
import { DropDownContainer } from 'src/components/ReviewOrder/Containers/DropDownContainer'
import { TableDivider } from 'src/components/ReviewOrder/styles'
import { LoanDetailsContainerReviewOrderProps } from 'src/components/ReviewOrder/types'
import { useCreditRenewalContext } from 'src/hooks'
import { useLocalizedFormatters } from 'src/hooks/useLocalizedFormatters'
import { isQCResident } from 'src/utils/borrower'

export function LoanDetailsContainer({
  startHidden = false,
  changeLink,
  setStep,
  applicationId,
  bootstrapInfo: { borrower, application, active_loan },
  isCreditRenewal,
  cachedApplications,
  setLoanAmountChangeError,
}: LoanDetailsContainerReviewOrderProps) {
  const { currencyFormat, percentFormat } = useLocalizedFormatters()

  const cachedSchedule = cachedApplications[applicationId]?.saved_repayment_schedule
  const totalEstimatedInterestStr = cachedSchedule?.total_estimated_interest
  const totalEstimatedInterest = totalEstimatedInterestStr
    ? parseFloat(cachedSchedule?.total_estimated_interest)
    : undefined

  const { totalLoanAmount, selectedLoanIncreaseAmount } = useCreditRenewalContext()
  let total
  let displayLoanAmount
  // note: totalLoanAmount is used in credit renewals flow. defaults to selected_loan_amount in origination flow
  const selectedTerm = cachedApplications[applicationId]?.selected_offer_term as number
  const selectedLoanAmount = cachedApplications[applicationId]?.selected_loan_amount as number
  const selectedApr = application?.apr as number

  const isQuebecResident = isQCResident(borrower)
  const loanApr = percentFormat(selectedApr)
  const previousPaymentAmount = active_loan?.repayment_amount ?? 0
  const existingLoanAmount = active_loan?.original_loan_amount ?? 0

  if (isCreditRenewal) {
    total = totalEstimatedInterest! + totalLoanAmount
    displayLoanAmount = currencyFormat(totalLoanAmount, 2)
  } else {
    displayLoanAmount = currencyFormat(selectedLoanAmount, 2)
    total = totalEstimatedInterest! + selectedLoanAmount
  }
  const formattedTotalAmount = currencyFormat(total, 2)
  const formattedInterestAmount = currencyFormat(totalEstimatedInterest || 0, 2)
  const formattedExistingLoanAmount = currencyFormat(existingLoanAmount, 2)
  return (
    <Grid container>
      <Grid item xs={12} marginBottom="8px" marginTop="24px">
        <Typography marginBottom="0px" variant="label">
          {t('ReviewOrder.loanDetails.title')}
        </Typography>
      </Grid>
      <DropDownContainer
        startHidden={startHidden}
        title={
          <LoanDetailsText
            isQuebecResident={isQuebecResident}
            loanAmount={displayLoanAmount}
            loanApr={loanApr}
            loanTerm={selectedTerm}
          />
        }
        content={
          isCreditRenewal ? (
            <CRLoanDetailsSummary
              selectedLoanAmount={formattedExistingLoanAmount}
              formattedTotalAmount={formattedTotalAmount}
              formattedInterestAmount={formattedInterestAmount}
              formattedAdditionalAmount={currencyFormat(selectedLoanIncreaseAmount, 2)}
              formattedPreviousPaymentAmount={currencyFormat(previousPaymentAmount, 2)}
              isQuebecResident={isQuebecResident}
            />
          ) : (
            <LoanDetailsSummary
              selectedLoanAmount={displayLoanAmount}
              formattedTotalAmount={formattedTotalAmount}
              formattedInterestAmount={formattedInterestAmount}
              isQuebecResident={isQuebecResident}
            />
          )
        }
        changeLink={changeLink}
        setStep={setStep}
        setLoanAmountChangeError={setLoanAmountChangeError}
      />
      <Grid item xs={12}>
        <TableDivider />
      </Grid>
    </Grid>
  )
}
