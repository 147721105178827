import { useContext } from 'react'

import { WalmartLanding } from 'src/components/EndToEnd/landing/Walmart/WalmartLanding'
import { CobrandedLanding } from 'src/components/EndToEnd/landing/shared/CobrandedLanding'
import { GenericLanding } from 'src/components/EndToEnd/landing/shared/GenericLanding'
import { PrequalificationContext } from 'src/contexts'
import { fairstoneLogo, neoLogo, theBrickLogo } from 'src/images'
import { PartnersEnum } from 'src/types'

/**
 * Returns landing page for the respective partner.
 */
export function Landing() {
  const { partnerConfig } = useContext(PrequalificationContext)
  if (!partnerConfig) {
    return <></>
  }

  const customLandingPages: { [partnerName: string]: JSX.Element } = {
    [PartnersEnum.CREDIT_KARMA]: <GenericLanding />,
    [PartnersEnum.CREDIT_KARMA_PROMO]: <GenericLanding />,
    [PartnersEnum.NEO]: <CobrandedLanding logoSrc={neoLogo} />,
    [PartnersEnum.THE_BRICK]: <CobrandedLanding logoSrc={theBrickLogo} />,
    [PartnersEnum.PERKOPOLIS]: <GenericLanding />,
    [PartnersEnum.WALMART]: <WalmartLanding />,
    [PartnersEnum.BORROWELL]: <GenericLanding />,
    [PartnersEnum.LFLGROUP]: <GenericLanding />,
    [PartnersEnum.FAIRSTONE]: <CobrandedLanding logoSrc={fairstoneLogo} partnerLogoHeight="32px" />,
  }

  return customLandingPages[partnerConfig.name] || <GenericLanding />
}
