import { ReactNode } from 'react'

import { BootstrapInfoType, CachedApplications, CreditRenewalStepsEnum, StepsEnum } from 'src/types'

export interface DropDownContainerProps {
  title: ReactNode
  content: ReactNode
  setStep: (step: StepsEnum | CreditRenewalStepsEnum) => void
  startHidden?: boolean
  changeLink: StepsEnum | CreditRenewalStepsEnum
  setLoanAmountChangeError: (error: boolean) => void
}

export interface PaymentContainerType {
  paymentMethod: string
  changeLink?: StepsEnum | CreditRenewalStepsEnum
  setStep: (step: StepsEnum | CreditRenewalStepsEnum) => void
}

export interface PaymentProtectionPlanContainerType {
  isEnrolled: boolean
  changeLink: StepsEnum | CreditRenewalStepsEnum
  setStep: (step: StepsEnum | CreditRenewalStepsEnum) => void
}

export interface CreditRenewalReviewOrderProps {
  previousStep: CreditRenewalStepsEnum
  nextStep: CreditRenewalStepsEnum
}
export interface ReviewOrderProps {
  previousStep: StepsEnum
  nextStep: StepsEnum
}
export interface LoanDetailsTextProps {
  isQuebecResident: boolean
  loanAmount: string
  loanTerm: number
  loanApr: string
}

export interface LoanDetailsContainerProps {
  isQuebecResident: boolean
  selectedLoanAmount: number
  selectedTerm: number
  selectedApr: number
}

export interface LoanDetailsSummaryProps {
  selectedLoanAmount: string
  formattedTotalAmount: string
  formattedInterestAmount: string
  isQuebecResident: boolean
}

export interface PaymentBreakdownProps {
  loanPaymentAmount: string
  premiumAmount: string
  premiumTaxAmount: string
  totalAmount: string
}

export enum AgreementTypeEnum {
  TOC = 'accept_toc',
  PAD = 'accept_pad',
  FICO = 'check_fico',
  EMAIL = 'consent_email',
}

export interface LoanDetailsContainerReviewOrderProps {
  startHidden?: boolean
  changeLink: StepsEnum | CreditRenewalStepsEnum
  setStep: (step: string) => void
  applicationId: string
  bootstrapInfo: BootstrapInfoType
  cachedApplications: CachedApplications
  setLoanAmountChangeError: (error: boolean) => void
  isCreditRenewal?: boolean
}

export interface RepaymentContainerProps {
  prevFirstPaymentDate: Date | null
  isEnrolled: boolean
  changeLink: StepsEnum | CreditRenewalStepsEnum
  setStep: (step: StepsEnum | CreditRenewalStepsEnum | string) => void
  cachedApplications: CachedApplications
  applicationId: string
  isAuthorizePaymentStartError: boolean
  isLoanAmountChangeError: boolean
  bootstrapInfo: BootstrapInfoType
  setCachedApplications: (value: CachedApplications) => void
  setAuthorizePaymentStartError: (value: boolean) => void
}
