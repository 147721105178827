import { Grid } from '@mui/material'
import { ReactElement } from 'react'

import { DivRoot } from 'src/components/common/DivRoot'
import {
  ErrorButtonGrid,
  ErrorContent,
  ErrorImage,
  ErrorImageGrid,
  ErrorTitle,
} from 'src/components/Error/shared/Error'

type ErrorProps = {
  image: {
    src: string
    alt: string
  }
  title: ReactElement | string
  content: ReactElement | string
  buttons: ReactElement[]
  extraContent?: ReactElement
}

export default ErrorPage
/**
 *
 */
function ErrorPage({ image, title, content, buttons, extraContent }: ErrorProps) {
  return (
    <DivRoot>
      <Grid container>
        <Grid item xs={12}>
          <ErrorImageGrid item xs={12}>
            <ErrorImage src={image.src} alt={image.alt} />
          </ErrorImageGrid>

          <Grid item xs={12}>
            <ErrorTitle data-testid="errorTitle">{title}</ErrorTitle>
          </Grid>

          <Grid item xs={12}>
            <ErrorContent data-testid="errorContent">{content}</ErrorContent>
          </Grid>
          <ErrorButtonGrid item xs={12}>
            {buttons.map((button, i) => (
              <div key={i}>{button}</div>
            ))}
          </ErrorButtonGrid>
          {extraContent}
        </Grid>
      </Grid>
    </DivRoot>
  )
}
