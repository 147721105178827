import LocationOnIcon from '@mui/icons-material/LocationOn'
import { Autocomplete, Box, Grid, Typography } from '@mui/material'
import { captureMessage } from '@sentry/react'
import parse from 'autosuggest-highlight/parse'
import { SyntheticEvent, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDebouncedCallback } from 'use-debounce'

import AddressSearchTextField from 'src/components/AddressSearchTextField'
import { FigAutocompletePrediction } from 'src/components/common/FigAddressSearch'
import { FigFormControl } from 'src/components/common/FigFormControl'
import FigTextField from 'src/components/common/FigTextField'
import { FigAutocompleteAddress } from 'src/types'
import { parseGoogleAddressParts } from 'src/utils/addressParser'
import { GOOGLE_AUTOCOMPLETE_CONFIG, GOOGLE_PLACES_API_KEY } from 'src/utils/constants'
import { loadScriptIfNeeded } from 'src/utils/tagLoader'

interface AddressValue {
  rawNewValue: FigAutocompletePrediction | null | string
  parsedNewValue: FigAutocompleteAddress | null
}

export const AddressInput = ({
  defaultAddress,
  handleAddressInput,
}: {
  defaultAddress: string
  handleAddressInput: (addressObj: FigAutocompleteAddress) => void
}) => {
  const { t } = useTranslation()
  const [value, setValue] = useState<AddressValue>({
    rawNewValue: defaultAddress, // set default to borrower address 1
    parsedNewValue: null,
  })
  const [inputValue, setInputValue] = useState('')
  const [addressLineTwo, setAddressLineTwo] = useState('')
  const callbackName = 'initGoogleMaps'

  const [autocompleteService, setAutocompleteService] = useState<
    google.maps.places.AutocompleteService | undefined
  >(undefined)
  const [options, setOptions] = useState<readonly FigAutocompletePrediction[]>([])

  const initMap = () => {
    if (!autocompleteService && window?.google?.maps?.places?.AutocompleteService) {
      setAutocompleteService(new window.google.maps.places.AutocompleteService())
    }
  }

  const fetch = useDebouncedCallback(
    (request: { input: string }, callback: (results: FigAutocompletePrediction[]) => void) => {
      if (!autocompleteService) {
        return callback([])
      }
      autocompleteService.getPlacePredictions(
        {
          ...request,
          types: GOOGLE_AUTOCOMPLETE_CONFIG.autocompleteTypes,
          componentRestrictions: GOOGLE_AUTOCOMPLETE_CONFIG.componentRestrictions,
        },
        predictions => callback(predictions || []),
      )
    },
    400,
  )

  // Initialize google places API with component, re-use if already loaded
  useEffect(() => {
    if (typeof window !== 'undefined' && !autocompleteService) {
      const googleMapsSrc = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_PLACES_API_KEY}&libraries=places&callback=${callbackName}`
      if (!loadScriptIfNeeded({ src: googleMapsSrc, id: 'google-maps' })) {
        // If script already loaded, manually trigger callback for this instance
        initMap()
      }
    }
  }, [])

  useEffect(() => {
    window[callbackName] = initMap
  }, [initMap])

  useEffect(() => {
    if (!autocompleteService) {
      return
    }
    // Input is cleared
    if (!inputValue) {
      setOptions([])
      setValue({ rawNewValue: null, parsedNewValue: null })
      return
    }

    fetch({ input: inputValue }, predictions => {
      if (typeof value.rawNewValue !== 'string' && value.rawNewValue) {
        setOptions([...predictions, value.rawNewValue!])
      } else {
        setOptions(predictions)
      }
    })
  }, [inputValue])

  const onSelectedOptionChange = async (_: any, newValue: FigAutocompletePrediction | null) => {
    // Get specific address parts from autocompleted place_id
    if (!newValue) {
      return
    }
    setOptions([newValue, ...options])
    try {
      const placeDetails = await getDetails({ placeId: newValue.place_id })

      setValue({ rawNewValue: newValue, parsedNewValue: parseGoogleAddressParts(placeDetails) })
    } catch (e) {
      captureMessage(`Unable to get result for place_id`, {
        level: 'error',
        extra: { status: e, place_id: newValue.place_id, inputValue: inputValue },
      })
      return
    }
  }

  const onInputValueChange = (_: SyntheticEvent, newInputValue: string) => {
    setInputValue(newInputValue)
  }

  useEffect(
    function handleAddressChange() {
      if (value.parsedNewValue) {
        if (!value.parsedNewValue.address_2 && addressLineTwo) {
          // set address_2 to the value of the unit number input field if manually inputted
          value.parsedNewValue.address_2 = addressLineTwo
        }
        setAddressLineTwo(value.parsedNewValue?.address_2)
        handleAddressInput(value.parsedNewValue)
      }
    },
    [value],
  )

  return (
    <FigFormControl label={t('CreditRenewals.ConfirmInformation.addressInput.label')}>
      <Box data-testid="confirm-address-input">
        <Autocomplete
          autoComplete
          includeInputInList
          filterSelectedOptions
          noOptionsText="No locations"
          onChange={onSelectedOptionChange}
          onInputChange={onInputValueChange}
          filterOptions={x => x}
          forcePopupIcon={false}
          options={options}
          // @ts-ignore: allow default address to be in the autocomplete box
          value={value.rawNewValue} // Provide the 'rawNewValue' property of 'value' to the 'value' prop of Autocomplete
          getOptionLabel={option => {
            if (option) {
              return typeof option === 'string' ? option : option.description
            }
            return ''
          }}
          isOptionEqualToValue={(option, value) => option?.place_id === value?.place_id}
          renderInput={props => <AddressSearchTextField {...props} fullWidth />}
          renderOption={(props, option) => {
            const matches = option?.structured_formatting.main_text_matched_substrings || []

            const parts = parse(
              option?.structured_formatting.main_text,
              matches.map((match: any) => [match.offset, match.offset + match.length]),
            )

            return (
              <li {...props}>
                <Grid container alignItems="center">
                  <Grid item sx={{ display: 'flex', width: 44 }}>
                    <LocationOnIcon sx={{ color: 'text.secondary' }} />
                  </Grid>
                  <Grid item sx={{ width: 'calc(100% - 44px)', wordWrap: 'break-word' }}>
                    {parts.map((part, index) => (
                      <Box
                        key={index}
                        component="span"
                        sx={{ fontWeight: part.highlight ? 'bold' : 'regular' }}
                      >
                        {part.text}
                      </Box>
                    ))}
                    <Typography variant="body2" color="text.secondary">
                      {option?.structured_formatting?.secondary_text}
                    </Typography>
                  </Grid>
                </Grid>
              </li>
            )
          }}
        />
        <Grid item xs={12} my={'16px'}>
          <FigTextField
            label={t('e2e.Prequalification.form.address.unit.label')}
            name="unit"
            aria-required="true"
            value={addressLineTwo}
            onChange={e => setAddressLineTwo(e.target.value)}
            autoComplete="address-line1"
          />
        </Grid>
      </Box>
    </FigFormControl>
  )
}

type GetDetailsArgs = google.maps.places.PlaceDetailsRequest

const getDetails = (args: GetDetailsArgs): Promise<google.maps.places.PlaceResult> => {
  const PlacesService = new window.google.maps.places.PlacesService(document.createElement('div'))

  if (!args.placeId) {
    return Promise.reject('No place_id provided')
  }

  return new Promise((resolve, reject) => {
    PlacesService.getDetails(args, (results, status) => {
      if (status !== 'OK' || !results) {
        reject(status)
        return
      }
      resolve(results)
    })
  })
}
