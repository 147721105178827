import { t } from 'i18next'

import { LoanStatusEnum } from 'src/portal/common'
import { StatusIconEnum } from 'src/portal/components/types'
import { StatusBadge } from 'src/portal/pages/home/components/StatusBadge'

export const LoanStatusLabel = ({ loanStatus }: { loanStatus: LoanStatusEnum }) => {
  switch (loanStatus) {
    case LoanStatusEnum.GOOD:
      return (
        <StatusBadge
          iconVariant={StatusIconEnum.ACTIVE}
          label={t('Portal.Home.label.summary.loanStatus.good')}
        />
      )
    case LoanStatusEnum.OVERDUE:
      return (
        <StatusBadge
          iconVariant={StatusIconEnum.ALERT}
          label={t('Portal.Home.label.summary.loanStatus.overdue')}
        />
      )
    case LoanStatusEnum.CLOSED:
      return (
        <StatusBadge
          iconVariant={StatusIconEnum.INACTIVE}
          label={t('Portal.Home.label.summary.loanStatus.closed')}
        />
      )
  }
}
