import { Typography } from '@mui/material'
import Grid from '@mui/material/Grid'
import { styled } from '@mui/material/styles'
import { useState } from 'react'
import { useTranslation, Trans } from 'react-i18next'
import { parseISO } from 'date-fns'

import { verificationFailImg } from 'src/images'
import { dateISOFormat } from 'src/utils/format'
import {
  kycRetryPIIClicked,
  kycRetryPIILiveChatClicked,
  kycRetryPIILContactFigClicked,
} from 'src/utils'
import { DivRoot } from 'src/components/common/DivRoot'
import { useApi, useOriginationContext } from 'src/hooks'
import { StepsEnum, PIIMismatchReasonsEnum } from 'src/types'
import { GridImage } from 'src/components/common/GridImage'
import { LoadingAnimation } from 'src/components/common/animations/Loading'
import { FigDatePickerDay, getIsDateInFuture } from 'src/components/common/FigDatePicker'
import FigTextField from 'src/components/common/FigTextField'
import { PrimaryCTA } from 'src/components/common/Buttons'
import { PleaseReachOut } from 'src/components/Error/shared/PleaseReachOut'
import { ErrorContent } from 'src/components/Error/shared/Error'

const ImgStyle = styled('img')({
  width: '80px',
  height: '80px',
  display: 'block',
  marginLeft: 'auto',
  marginRight: 'auto',
})

export default function PIIRetry() {
  const { onfidoDecision, setStep, setGenericErrorPageError } = useOriginationContext()
  const { piiMismatchReason, piiMismatchInfo } = onfidoDecision

  const [dateOfBirth, setDateOfBirth] = useState(
    piiMismatchInfo?.dob ? parseISO(piiMismatchInfo?.dob) : null,
  )
  const [firstName, setFirstName] = useState(piiMismatchInfo?.firstName)
  const [lastName, setLastName] = useState(piiMismatchInfo?.lastName)
  const [isSavingPiiData, setIsSavingPiiData] = useState(false)
  const { savePiiData } = useApi()

  const { t } = useTranslation()
  const screen = StepsEnum.KYC_PII_RETRY

  const content = (
    <Trans i18nKey="KYCPIIRetry.help">
      If you think there was a mistake,{' '}
      <PleaseReachOut
        screen={screen}
        liveChatAnalytics={kycRetryPIILiveChatClicked}
        contactFigAnalytics={kycRetryPIILContactFigClicked}
      />
    </Trans>
  )

  const isNameMatch =
    piiMismatchReason === PIIMismatchReasonsEnum.NAME_MATCH ||
    piiMismatchReason === PIIMismatchReasonsEnum.ALL_MATCH
  const isDobMatch =
    piiMismatchReason === PIIMismatchReasonsEnum.DOB_MATCH ||
    piiMismatchReason === PIIMismatchReasonsEnum.ALL_MATCH

  const handleContinue = async () => {
    kycRetryPIIClicked(t('KYCPIIRetry.button'), StepsEnum.KYC_PII_RETRY)
    setIsSavingPiiData(true)
    try {
      const response = await savePiiData({
        first_name: isNameMatch ? firstName : null,
        last_name: isNameMatch ? lastName : null,
        date_of_birth: isDobMatch ? dateISOFormat(dateOfBirth) : null,
      })

      if (response.status == 200) {
        setStep(StepsEnum.KYC_VERIFY)
      } else {
        setGenericErrorPageError(
          new Error(`Returned status for save PII Data retry is not 200: ${response.status}`),
        )
        setStep(StepsEnum.ERROR)
      }
    } catch (error) {
      setGenericErrorPageError(error)
      setStep(StepsEnum.ERROR)
    }
    setIsSavingPiiData(false)
  }

  if (isSavingPiiData) {
    return <LoadingAnimation />
  }

  return (
    <DivRoot>
      <Grid container spacing={0}>
        <GridImage item xs={12}>
          <ImgStyle src={verificationFailImg} alt={t('KYCRetryGeneric.alt')} />
        </GridImage>
        <Grid item xs={12}>
          <Typography variant="h1" textAlign="center">
            {t('KYCPIIRetry.title')}
          </Typography>
        </Grid>

        <Grid item xs={12} marginTop="10px">
          <Typography variant="body2" textAlign="center">
            {t('KYCPIIRetry.content')}
          </Typography>
        </Grid>

        <Grid item xs={12} marginTop="32px" display="flex" flexDirection="column">
          {isNameMatch && (
            <Grid container spacing={1} marginTop="12px">
              <Grid item xs={6}>
                <FigTextField
                  name="firstName"
                  label={t('e2e.Prequalification.form.personalDetails.firstName.label')}
                  value={firstName}
                  onChange={e => {
                    setFirstName(e.target.value)
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <FigTextField
                  name="lastName"
                  label={t('e2e.Prequalification.form.personalDetails.lastName.label')}
                  value={lastName}
                  onChange={e => {
                    setLastName(e.target.value)
                  }}
                />
              </Grid>
            </Grid>
          )}
          {isDobMatch && (
            <Grid item xs={12} marginTop="10px">
              <FigDatePickerDay
                label={t('e2e.Prequalification.form.personalDetails.birthDate.label')}
                name="birthDate"
                getIsInvalidDate={getIsDateInFuture}
                placeholder={'DD/MM/YYYY'}
                inputFormat={'dd/MM/yyyy'}
                calendarDate={dateOfBirth}
                setCalendarDate={value => setDateOfBirth(value)}
                disableWeekends={false}
              />
            </Grid>
          )}
        </Grid>

        <Grid item xs={12} marginTop="32px">
          <PrimaryCTA type="submit" onClick={handleContinue} buttonText={t('KYCPIIRetry.button')} />
        </Grid>
        <Grid item xs={12} marginTop="32px">
          <ErrorContent data-testid="piiHelpContent">{content}</ErrorContent>
        </Grid>
      </Grid>
    </DivRoot>
  )
}
