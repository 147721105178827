import { styled } from '@mui/material/styles'
import { Grid, Typography, Fade, CircularProgress } from '@mui/material'
import { ReactElement, useEffect, useRef, useState, ComponentProps } from 'react'

import theme from 'src/themes'

const fadeTimeMs = 500

const LoadingIconUnsized = styled(CircularProgress)({
  color: 'currentcolor',
})

export const LoadingIcon = (props: ComponentProps<typeof LoadingIconUnsized>) => (
  <LoadingIconUnsized {...{ size: 24, ...props }} />
)

export const FigAlertStyles = styled(Grid)({
  ...theme.typography.h1,
  textAlign: 'left',
  borderRadius: '10px',
  width: 'min(400px, 90vw)',
  position: 'fixed',
  zIndex: 1101,
  top: 'min(5vw, 20px)',
  border: '2px solid',
  padding: '16px',
})

export const AlertTitle = styled(Typography)({
  ...theme.typography.h2,
  fontSize: '16px',
  lineHeight: '24px',
  color: 'inherit',
})

export const AlertContent = styled(Typography)({
  ...theme.typography.body2,
  fontSize: '12px',
  lineHeight: '18px',
  maxWidth: '330px',
  color: 'inherit',
  marginTop: '4px',
})

export enum AlertColor {
  WARNING = 'warning',
  SUCCESS = 'success',
  ERROR = 'error',
  INFO = 'info',
}

export const alertColorStyles: {
  [color in AlertColor]: {
    backgroundColor: string
    borderColor: string
    color: string
  }
} = {
  [AlertColor.WARNING]: { backgroundColor: '#FFF4E2', borderColor: '#754900', color: '#754900' },
  [AlertColor.SUCCESS]: { backgroundColor: '#ECFBF0', borderColor: '#005F09', color: '#005F09' },
  [AlertColor.ERROR]: { backgroundColor: '#FFF4F4', borderColor: '#B50000', color: '#B50000' },
  [AlertColor.INFO]: { backgroundColor: '#E5F3FF', borderColor: '#003079', color: '#003079' },
}

/**
 *
 */
export const FigAlert = ({
  color,
  icon,
  title,
  content,
  fade,
  alertSx,
  contentSx,
}: {
  color: AlertColor
  icon: ReactElement
  title: string
  content: string
  fade?: {
    lifespanMs: number
    callback?: () => void
  }
  alertSx?: any
  contentSx?: any
}) => {
  if (fade != null && fade.lifespanMs < fadeTimeMs) {
    throw Error(
      `lifespan is shorter than fadeTime | lifespanMs: ${fade.lifespanMs}, fadeTimeMs: ${fadeTimeMs}`,
    )
  }
  const [isShowing, setIsShowing] = useState(true)
  const timeoutsRef = useRef<NodeJS.Timeout[]>([])

  useEffect(() => {
    // remove existing timeouts
    const cleanup = () => {
      timeoutsRef.current.forEach(t => clearTimeout(t))
      timeoutsRef.current = []
    }
    cleanup()

    setIsShowing(true)

    if (fade) {
      // set timeout to invoke <Fade>
      timeoutsRef.current = [
        setTimeout(() => {
          setIsShowing(false)
        }, fade.lifespanMs - fadeTimeMs),
        // lifespanMs is greater than or equal to fadeTimeMs
      ]

      // set timeout to invoke callback (if exists)
      if (fade.callback) {
        timeoutsRef.current.push(
          setTimeout(() => {
            fade.callback!()
          }, fade.lifespanMs),
        )
      }
    }
    return cleanup
  }, [color, icon, title, content, fade])

  return (
    <Fade in={isShowing} appear={false} timeout={{ enter: 0, exit: fadeTimeMs }}>
      <FigAlertStyles style={{ ...alertColorStyles[color] }} sx={alertSx}>
        <div style={{ display: 'flex', flexDirection: 'row', columnGap: '10px' }}>
          <span>{icon}</span>
          <Grid>
            <AlertTitle>{title}</AlertTitle>
            <AlertContent sx={contentSx}>{content}</AlertContent>
          </Grid>
        </div>
      </FigAlertStyles>
    </Fade>
  )
}
