import { Grid, Typography } from '@mui/material'
import { Trans, useTranslation } from 'react-i18next'
import { parseISO } from 'date-fns'

import { PaymentBreakdown } from 'src/components/ReviewOrder/components/PaymentBreakdown'
import { SystemDateUpdateNotification } from 'src/components/ReviewOrder/components/SystemDateUpdateNotification'
import { useReviewOrder } from 'src/components/ReviewOrder/hooks/useReviewOrder'
import { ChangeLink, RightBox, TableDivider } from 'src/components/ReviewOrder/styles'
import { RepaymentContainerProps } from 'src/components/ReviewOrder/types'
import { useLocalizedFormatters } from 'src/hooks/useLocalizedFormatters'
import theme from 'src/themes'
import { MonthlyScheduleEnum, PaymentScheduleEnum, StepsEnum } from 'src/types'
import { reviewChangePaymentScheduleClicked } from 'src/utils'
import usePaymentScheduleText from 'src/hooks/usePaymentScheduleText'

export function RepaymentContainer({
  prevFirstPaymentDate,
  isEnrolled,
  changeLink,
  setStep,
  cachedApplications,
  applicationId,
  isAuthorizePaymentStartError,
  isLoanAmountChangeError,
  bootstrapInfo,
  setCachedApplications,
  setAuthorizePaymentStartError,
}: RepaymentContainerProps) {
  const { dateFormat } = useLocalizedFormatters()
  const getPaymentScheduleText = usePaymentScheduleText()
  const { t } = useTranslation()

  const cachedSchedule = cachedApplications[applicationId]?.saved_repayment_schedule
  const frequency = cachedSchedule?.schedule as PaymentScheduleEnum | undefined
  const monthlyChoice = cachedSchedule?.monthly_choice as MonthlyScheduleEnum | undefined
  const paymentCycleDueDateStr = cachedSchedule?.payment_cycle_due_date
  const paymentCycleDueDate = paymentCycleDueDateStr ? parseISO(paymentCycleDueDateStr) : null
  const firstPaymentDateStr = cachedSchedule?.first_payment_date
  const firstPaymentDate = firstPaymentDateStr ? parseISO(firstPaymentDateStr) : null

  const { dateFormatCustom } = useLocalizedFormatters()
  const firstPaymentDateFormat = t('ReviewOrder.firstPaymentDate.dateFormat')
  const changeLabel = t('ReviewOrder.change')
  const showSystemDateNotification =
    prevFirstPaymentDate && dateFormat(prevFirstPaymentDate) !== dateFormat(firstPaymentDate)

  const {
    formattedTotalAmount,
    formattedPaymentAmount,
    formattedPremiumAmount,
    formattedPremiumTaxAmount,
  } = useReviewOrder({
    applicationId,
    cachedApplications,
    setCachedApplications,
    setStep,
    isAuthorizePaymentStartError,
    isLoanAmountChangeError,
    bootstrapInfo,
    setAuthorizePaymentStartError,
  })

  return (
    <Grid container marginTop="16px">
      <Grid item xs={12} marginBottom="8px">
        <Typography variant="label" marginBottom="0px">
          {t('ReviewOrder.paymentSchedule.title')}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Grid container>
          <Grid item xs={9}>
            <Grid container direction="column">
              <Grid item>
                <Typography variant="body2">
                  <Trans
                    i18nKey="ReviewOrder.paymentSchedule.withdrawAmount"
                    values={{
                      paymentScheduleText: getPaymentScheduleText({
                        frequency,
                        monthlyChoice,
                        paymentCycleDueDate,
                      }),
                      displayWithdrawAmount: isEnrolled
                        ? formattedTotalAmount
                        : formattedPaymentAmount,
                    }}
                  >
                    You’ll pay{' '}
                    <Typography variant="label" color={theme.color.black}>
                      {formattedPaymentAmount}
                    </Typography>{' '}
                    {getPaymentScheduleText({
                      frequency,
                      monthlyChoice,
                      paymentCycleDueDate,
                    })}
                  </Trans>
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={3} style={{ display: 'grid', placeItems: 'center end' }}>
            <RightBox>
              <ChangeLink
                onClick={() => {
                  setStep(changeLink)
                  reviewChangePaymentScheduleClicked(changeLabel, StepsEnum.ORDER_REVIEW)
                }}
              >
                {t('ReviewOrder.change')}
              </ChangeLink>
            </RightBox>
          </Grid>

          {isEnrolled && (
            <PaymentBreakdown
              loanPaymentAmount={formattedPaymentAmount}
              premiumAmount={formattedPremiumAmount}
              premiumTaxAmount={formattedPremiumTaxAmount}
              totalAmount={formattedTotalAmount}
            />
          )}

          <Grid item xs={12} marginTop="8px" marginBottom="8px">
            <Typography variant="label" marginBottom="0px">
              {t('ReviewOrder.firstPaymentDate.title')}
            </Typography>
          </Grid>
          <Grid item xs={9}>
            <Typography variant="body2" color={theme.color.grey6}>
              {dateFormatCustom(firstPaymentDate!, firstPaymentDateFormat)}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      {showSystemDateNotification && (
        <SystemDateUpdateNotification
          {...{ prevFirstPaymentDate, firstPaymentDate: firstPaymentDate! }}
        />
      )}
      <Grid item xs={12}>
        <TableDivider style={{ marginBottom: 0 }} />
      </Grid>
    </Grid>
  )
}
