import { Grid2, Typography } from '@mui/material'

import { StretchDivider } from 'src/portal/components/StretchDivider'
import PortalTheme from 'src/themes/portal'

interface SectionHeadingProps {
  title: string
  description: string
  extraDescription?: string
}
/**
 * Component for rendering a section heading with a title and description - used in Home - Transactions and Upcoming Payments tabs
 *
 * @param {string} title - The title text to display.
 * @param {string} description - The description text to display.
 * @param {string} extraDescription (optional) -  The extra description text to display below the main description.
 *
 * @returns {JSX.Element} The rendered section heading component.
 */
export const SectionHeading = ({ title, description, extraDescription }: SectionHeadingProps) => {
  return (
    <Grid2 pb="24px">
      <Typography variant="h2" color={PortalTheme.color.black}>
        {title}
      </Typography>
      <Typography variant="body2" color={PortalTheme.color.grey7} pb="24px">
        {description}
      </Typography>
      <Typography variant="body2" color={PortalTheme.color.grey7} pb="24px">
        {extraDescription}
      </Typography>
      <StretchDivider />
    </Grid2>
  )
}
