import { Grid, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { PaymentBreakdownProps } from 'src/components/ReviewOrder/types'
import { shieldCheckMarkOutlinedIcon } from 'src/images'
import theme from 'src/themes'

export const PaymentBreakdown = ({
  loanPaymentAmount,
  premiumAmount,
  premiumTaxAmount,
  totalAmount,
}: PaymentBreakdownProps) => {
  const { t } = useTranslation()
  return (
    <Grid item xs={12} marginTop="16px">
      <Grid container spacing={'8px'}>
        <Grid item xs={7}>
          <Typography variant="body2">
            {t('ReviewOrder.paymentSchedule.loanPaymentTitle')}
          </Typography>
        </Grid>
        <Grid item xs={3} textAlign={'right'}>
          <Typography variant="label" color={theme.color.grey6}>
            {loanPaymentAmount}
          </Typography>
        </Grid>

        {
          <>
            <Grid item xs={7} style={{ display: 'inline-flex', alignItems: 'center' }}>
              <img
                style={{ marginRight: theme.spacing(1) }}
                width={20}
                height={20}
                src={shieldCheckMarkOutlinedIcon}
                alt={t('ReviewOrder.paymentProtectionPlan.title')}
              />
              <Typography variant="body2">
                {t('ReviewOrder.paymentSchedule.paymentProtectionPremiumTitle')}
              </Typography>
            </Grid>
            <Grid item xs={3} textAlign={'right'}>
              <Typography variant="label" color={theme.color.grey6}>
                {premiumAmount}
              </Typography>
            </Grid>
          </>
        }

        <Grid item xs={7} style={{ display: 'inline-flex', alignItems: 'center' }}>
          <img
            style={{ marginRight: theme.spacing(1) }}
            width={20}
            height={20}
            src={shieldCheckMarkOutlinedIcon}
            alt={t('ReviewOrder.paymentProtectionPlan.title')}
          />
          <Typography variant="body2">
            {t('ReviewOrder.paymentSchedule.paymentProtectionPremiumTaxTitle')}
          </Typography>
        </Grid>
        <Grid item xs={3} textAlign={'right'}>
          <Typography variant="label" color={theme.color.grey6}>
            {premiumTaxAmount}
          </Typography>
        </Grid>

        <Grid item xs={7}>
          <Typography variant="body2">
            {t('ReviewOrder.paymentSchedule.totalPaymentTitle')}
          </Typography>
        </Grid>
        <Grid item xs={3} textAlign={'right'}>
          <Typography variant="label" color={theme.color.grey6}>
            {totalAmount}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  )
}
