import { Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

import PortalTheme from 'src/themes/portal'

interface GreetingProps {
  name: string
}

enum TimeOfDayEnum {
  NOON = 12,
  EVENING = 18,
}

export const Greeting = ({ name }: GreetingProps) => {
  const { t } = useTranslation()

  const timeOfDayGreeting: string = (() => {
    const hours = new Date().getHours()
    if (hours < TimeOfDayEnum.NOON) {
      return t('Portal.Home.label.greeting.morning')
    } else if (hours < TimeOfDayEnum.EVENING) {
      return t('Portal.Home.label.greeting.afternoon')
    } else {
      return t('Portal.Home.label.greeting.evening')
    }
  })()

  return (
    <Typography variant="h3" color={PortalTheme.color.black}>
      👋 {timeOfDayGreeting}, {name}
    </Typography>
  )
}
