import { Grid, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

export const RepaymentStartHeading = () => {
  const { t } = useTranslation()
  return (
    <Grid item xs={12}>
      <Typography variant="body2" color="black">
        {t('PaymentSchedule.RepaymentStartHeading.title')}
      </Typography>
    </Grid>
  )
}
