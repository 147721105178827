import { TextField, styled } from '@mui/material'
import { ComponentProps, useState } from 'react'

import { FigFormControl, getLabelId } from 'src/components/common/FigFormControl'

const StyledTextField = styled(TextField)(({ theme }) => ({
  '& .MuiInputBase-root:hover fieldset': {
    borderColor: theme.color.brand1,
  },
  '& *::placeholder, #placeholder, *::-webkit-input-placeholder': {
    opacity: 1,
    color: theme.color.grey6,
  },
  '& fieldset': {
    borderColor: theme.color.grey4,
  },
}))

type FigFormControlType = typeof FigFormControl
type TextFieldType = typeof TextField

export default AddressSearchTextField
/**
 *
 */
function AddressSearchTextField(
  props: Omit<ComponentProps<FigFormControlType>, 'children'> & ComponentProps<TextFieldType>,
) {
  const [isTextFocused, setIsTextFocused] = useState(false)
  const {
    label,
    error,
    errorMessage,
    helperText,
    disabled,
    placeholder,
    inputProps,
    ...otherProps
  } = props
  return (
    <FigFormControl
      {...{ label, error, helperText, errorMessage, isFocused: props.isFocused || isTextFocused }}
    >
      <StyledTextField
        fullWidth
        aria-labelledby={getLabelId(label)}
        {...{ disabled, placeholder, inputProps, ...otherProps }}
        error={error}
        name="search"
        inputProps={{
          autocomplete: 'new-password',
          ['data-testid']: 'figTextField',
          style: {
            fontSize: 16,
            cursor: props ? 'pointer' : 'text',
          },
          ...inputProps,
          /*
                    Manually set focus here because MUI TextField is complicated,
                    it is a <FormControl> itself, but its label is this strange
                          design that overlaps with the border, and is also the placeholder
                          text when the input is empty:
          
                          https://mui.com/material-ui/react-text-field/
          
                          By saving the isFocused state, we can manually pass it to
                          <FigFormControl>, so that our regular fixed label can have
                              focused styling when we click on the input
                              */
          onFocus: e => {
            setIsTextFocused(true)
            inputProps?.onFocus?.(e)
          },
          onBlur: e => {
            setIsTextFocused(false)
            inputProps?.onBlur?.(e)
          },
        }}
      />
    </FigFormControl>
  )
}
