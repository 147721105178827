import Grid from '@mui/material/Grid'
import CloseIcon from '@mui/icons-material/Close'
import Typography from '@mui/material/Typography'
import { useTranslation } from 'react-i18next'
import { useMediaQuery } from '@mui/material'

import { termsPageIcon } from 'src/images'
import theme from 'src/themes'

import { TermsPageListItem, DivStyle, GridImage, TermsImgStyle, ModalCloseButton } from './styles'
import { TermsPageProps } from './types'

const TermsPage: React.FC<TermsPageProps> = ({ handleClose }) => {
  const { t } = useTranslation()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const firstTitlePoints: string[] = [
    t('PaymentProtection.Terms.FirstTitle.point1'),
    t('PaymentProtection.Terms.FirstTitle.point2'),
    t('PaymentProtection.Terms.FirstTitle.point3'),
    t('PaymentProtection.Terms.FirstTitle.point4'),
    t('PaymentProtection.Terms.FirstTitle.point5'),
    t('PaymentProtection.Terms.FirstTitle.point6'),
    t('PaymentProtection.Terms.FirstTitle.point7'),
  ]

  const secondTitlePoints: string[] = [
    t('PaymentProtection.Terms.SecondTitle.point1'),
    t('PaymentProtection.Terms.SecondTitle.point2'),
    t('PaymentProtection.Terms.SecondTitle.point3'),
  ]

  return (
    <DivStyle isMobile={isMobile}>
      <Grid container spacing={0}>
        <ModalCloseButton item xs={12} isMobile>
          <CloseIcon onClick={handleClose} />
        </ModalCloseButton>
        <Grid item xs={12}>
          <GridImage item xs={12} paddingTop="5%">
            <TermsImgStyle
              src={termsPageIcon}
              alt={t('PaymentProtection.Terms.FirstTitle.title')}
            />
          </GridImage>
          <Typography variant="h1" textAlign="center" padding="32px 0">
            {t('PaymentProtection.Terms.FirstTitle.title')}
          </Typography>
        </Grid>
        <Grid item xs={12} textAlign="left">
          {firstTitlePoints.map((point, index) => (
            <TermsPageListItem key={index} text={point} />
          ))}
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h1" textAlign="center" marginTop="32px" marginBottom="32px">
            {t('PaymentProtection.Terms.SecondTitle.title')}
          </Typography>
        </Grid>
        <Grid item xs={12} textAlign="left">
          {secondTitlePoints.map((point, index) => (
            <TermsPageListItem key={index} text={point} />
          ))}
        </Grid>
      </Grid>
    </DivStyle>
  )
}

export default TermsPage
