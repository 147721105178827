import { Box, styled } from '@mui/material'

import theme from 'src/themes'

interface RequestLoanAmountIncreaseBoxModalProps {
  isMobile: boolean
}

export const RequestLoanAmountIncreaseBoxModal = styled(
  Box,
)<RequestLoanAmountIncreaseBoxModalProps>(({ isMobile }) => ({
  [theme.breakpoints.up('md')]: {
    width: 594,
  },
  width: isMobile ? '100%' : '75%',
  height: 'auto',
  paddingTop: isMobile ? '10%' : '0%',
  backgroundColor: 'white',
  border: 'solid 1px',
  borderColor: theme.color.grey4,
  margin: 0,
  position: 'absolute',
  top: isMobile ? '50%' : '34%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
}))
