import { useFormikContext } from 'formik'
import { useContext, useEffect } from 'react'

import {
  PrequalificationForm,
  PrequalificationFormTouched,
} from 'src/components/EndToEnd/Prequalification'
import { AutoSaveContext } from 'src/contexts/prequalification'

/**
 *
 */
export function AutoSave() {
  const { setPrequalFormInProgress, setPrequalFormInProgressTouched } = useContext(AutoSaveContext)
  const { values, touched } = useFormikContext()

  useEffect(() => {
    setPrequalFormInProgressTouched(touched as PrequalificationFormTouched)
  }, [touched])

  useEffect(() => {
    setPrequalFormInProgress(values as PrequalificationForm)
  }, [values])
  return <></>
}
