import { Grid2 } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { PortalLink } from 'src/portal/components/PortalLink'
import { SpacedRow } from 'src/portal/components/SpacedRow'
import { StatusIconEnum } from 'src/portal/components/types'
import { StatusBadge } from 'src/portal/pages/home/components/StatusBadge'

interface AutomaticPaymentsContainerProps {
  automaticPaymentsOn: boolean
  viewDetailsClicked: () => void
}
export const AutomaticPaymentsContainer = ({
  automaticPaymentsOn,
  viewDetailsClicked,
}: AutomaticPaymentsContainerProps) => {
  const { t } = useTranslation()

  if (!automaticPaymentsOn) {
    return (
      <SpacedRow>
        <Grid2>
          <StatusBadge
            iconVariant={StatusIconEnum.ALERT}
            label={t('Portal.Home.label.summary.automaticPaymentsOff')}
          />
        </Grid2>
        <Grid2>
          <PortalLink
            text={t('Portal.Components.label.manage')}
            onClickHandler={viewDetailsClicked}
          />
        </Grid2>
      </SpacedRow>
    )
  }
  return (
    <SpacedRow>
      <Grid2>
        <StatusBadge
          iconVariant={StatusIconEnum.ACTIVE}
          label={t('Portal.Home.label.summary.automaticPaymentsOn')}
        />
      </Grid2>
      <Grid2>
        <PortalLink
          text={t('Portal.Components.label.viewDetails')}
          onClickHandler={viewDetailsClicked}
        />
      </Grid2>
    </SpacedRow>
  )
}
