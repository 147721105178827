import { useFlags } from 'launchdarkly-react-client-sdk'
import { Navigate, Route, useLocation } from 'react-router-dom'

import ApplicationAlreadySettled from 'src/components/Error/ApplicationAlreadySettled'
import ApplicationDeclined from 'src/components/Error/ApplicationDeclined'
import ApplicationExpired from 'src/components/Error/ApplicationExpired'
import ApplicationIdNotFound from 'src/components/Error/ApplicationIdNotFound'
import { ApplicationOrderDeclined } from 'src/components/Error/ApplicationOrderDeclined'
import BankConnectError from 'src/components/Error/BankConnectError'
import { GenericError } from 'src/components/Error/GenericError'
import PageNotFound from 'src/components/Error/PageNotFound'
import Finish from 'src/components/Finish'
import {
  KYCDecisionLoader,
  KYCFlinksFail,
  KYCFlinksInvalidBankAccount,
  KYCOnfidoPermission,
  KYCRetryGeneric,
  KYCSuccess,
  KYCVerify,
  PIIRetry,
  KYCTokenExpired,
} from 'src/components/KYC'
import ImageQualityRetry from 'src/components/KYC/ImageQualityRetry'
import KYCIdFail from 'src/components/KYC/KYCIdFail'
import { LoanAmount } from 'src/components/LoanAmount'
import Occupation from 'src/components/Occupation/Occupation'
import {
  ConnectBankAccount,
  ConnectBankAccountSuccess,
  FlinksPaymentCheck,
  PaymentMethod,
} from 'src/components/PaymentMethod'
import { PaymentProtection } from 'src/components/PaymentProtection'
import ConfirmPaymentSchedule from 'src/components/PaymentSchedule/ConfirmPaymentSchedule'
import EditPaymentSchedule from 'src/components/PaymentSchedule/EditPaymentSchedule'
import PhoneNumber from 'src/components/PhoneNumber/PhoneNumber'
import { PurposeOfLoan } from 'src/components/PurposeOfLoan'
import { QCConsents } from 'src/components/QCConsents/QCConsents'
import ReviewOrder from 'src/components/ReviewOrder/ReviewOrder'
import SelectOffer from 'src/components/SelectOffer/SelectOffer'
import Layout from 'src/components/common/Layout'
import RootLayout from 'src/components/common/RootLayout'
import OTPContainer from 'src/containers/OTP'
import { OriginationProvider } from 'src/contexts'
import { useOriginationContext } from 'src/hooks'
import useOrigination from 'src/hooks/useOrigination'
import { FeatureFlags, StepsEnum } from 'src/types'
import { SentryRoutes } from 'src/routers/common'

export default function AppRouterOrigination() {
  const { logo, isApplicationSettled, bootstrapIsSuccess, bootstrapIsLoading, bootstrapRefetch } =
    useOrigination()

  const {
    setStep,
    cachedApplications,
    applicationId,
    bootstrapInfo,
    asyncRequestsInProgress,
    setCachedApplications,
    setAuthorizePaymentStartError,
  } = useOriginationContext()
  const { skipOtp } = useFlags<FeatureFlags>()
  const location = useLocation()

  return (
    <SentryRoutes>
      {isApplicationSettled ? (
        <Route
          path=":jwtApiKey"
          element={
            <Layout
              bootstrapIsLoading={bootstrapIsLoading}
              bootstrapIsSuccess={bootstrapIsSuccess}
              logo={logo}
            />
          }
        >
          <Route
            path={StepsEnum.ORDER_FINISH}
            element={<Finish bootstrapRefetch={bootstrapRefetch} />}
          />
          <Route path={StepsEnum.SETTLED} element={<ApplicationAlreadySettled />} />
          <Route path="*" element={<Navigate to={StepsEnum.SETTLED} />} />
        </Route>
      ) : (
        <Route
          path=":jwtApiKey"
          element={
            <Layout
              bootstrapIsLoading={bootstrapIsLoading}
              bootstrapIsSuccess={bootstrapIsSuccess}
              logo={logo}
            />
          }
        >
          <Route index element={<Navigate to={StepsEnum.LOAN_AMOUNT} />} />
          <Route path={StepsEnum.LOAN_AMOUNT} element={<LoanAmount />} />
          <Route
            path={StepsEnum.OTP}
            element={
              <OTPContainer
                nextStepOnSuccess={StepsEnum.OCCUPATION}
                bootstrapRefetch={bootstrapRefetch}
              />
            }
          />
          <Route path={StepsEnum.PHONE} element={<PhoneNumber />} />
          <Route path={StepsEnum.QC_CONSENTS} element={<QCConsents />} />
          <Route
            path={StepsEnum.SELECT_OFFER}
            element={
              <SelectOffer
                nextStep={
                  bootstrapInfo?.borrower?.borrower_mobile
                    ? StepsEnum.OCCUPATION
                    : skipOtp
                      ? StepsEnum.PHONE
                      : StepsEnum.OTP
                }
              />
            }
          />
          <Route path={StepsEnum.PAYMENT_METHOD_SELECT} element={<PaymentMethod />} />
          <Route path={StepsEnum.FLINKS_PAYMENT_CHECK} element={<FlinksPaymentCheck />} />
          <Route path={StepsEnum.BANK_AUTO_CONNECT} element={<ConnectBankAccount />} />
          <Route path={StepsEnum.BANK_SUCCESS} element={<ConnectBankAccountSuccess />} />
          <Route
            path={StepsEnum.ORDER_REVIEW}
            element={
              <ReviewOrder
                nextStep={StepsEnum.ORDER_FINISH}
                previousStep={
                  bootstrapInfo?.application?.applicable_for_protection
                    ? StepsEnum.PAYMENT_PROTECTION
                    : StepsEnum.REPAYMENT_SCHEDULE_CONFIRM
                }
              />
            }
          />
          <Route path={StepsEnum.KYC_VERIFY} element={<KYCVerify />} />
          <Route path={StepsEnum.KYC_PERMISSIONS_ONFIDO_ERROR} element={<KYCOnfidoPermission />} />
          <Route path={StepsEnum.KYC_TOKEN_EXPIRED} element={<KYCTokenExpired />} />
          <Route path={StepsEnum.KYC_LOADING} element={<KYCDecisionLoader />} />
          <Route path={StepsEnum.KYC_RETRY_GENERIC} element={<KYCRetryGeneric />} />
          <Route path={StepsEnum.KYC_PII_RETRY} element={<PIIRetry />} />
          <Route path={StepsEnum.KYC_IMAGE_QUALITY_RETRY} element={<ImageQualityRetry />} />
          <Route
            path={StepsEnum.KYC_SUCCESS}
            element={<KYCSuccess nextStepOnSuccess={StepsEnum.PAYMENT_METHOD_SELECT} />}
          />
          <Route path={StepsEnum.KYC_FLINKS_FAIL} element={<KYCFlinksFail />} />
          <Route
            path={StepsEnum.KYC_FLINKS_INVALID_BANK_ACCOUNT}
            element={<KYCFlinksInvalidBankAccount />}
          />
          <Route path={StepsEnum.KYC_ID_FAIL} element={<KYCIdFail />} />
          <Route
            path={StepsEnum.OCCUPATION}
            element={
              <Occupation
                previousStep={
                  bootstrapInfo?.borrower?.borrower_mobile
                    ? StepsEnum.SELECT_OFFER
                    : skipOtp
                      ? StepsEnum.PHONE
                      : StepsEnum.SELECT_OFFER
                }
                nextStep={StepsEnum.LOAN_PURPOSE}
              />
            }
          />
          <Route
            path={StepsEnum.LOAN_PURPOSE}
            element={
              <PurposeOfLoan previousStep={StepsEnum.OCCUPATION} nextStep={StepsEnum.KYC_VERIFY} />
            }
          />
          <Route
            path={StepsEnum.REPAYMENT_SCHEDULE_CONFIRM}
            element={
              <ConfirmPaymentSchedule
                nextStep={
                  bootstrapInfo?.application?.applicable_for_protection
                    ? StepsEnum.PAYMENT_PROTECTION
                    : StepsEnum.ORDER_REVIEW
                }
                previousStep={StepsEnum.PAYMENT_METHOD_SELECT}
                bootstrapInfo={bootstrapInfo}
                setStep={setStep}
                cachedSchedule={cachedApplications[applicationId]?.saved_repayment_schedule}
                cachedApplications={cachedApplications}
                applicationId={applicationId}
                setCachedApplications={setCachedApplications}
                setAuthorizePaymentStartError={setAuthorizePaymentStartError}
              />
            }
          />
          {bootstrapInfo?.application?.applicable_for_protection}?
          <Route
            path={StepsEnum.PAYMENT_PROTECTION}
            element={
              <PaymentProtection
                nextStep={StepsEnum.ORDER_REVIEW}
                previousStep={StepsEnum.REPAYMENT_SCHEDULE_CONFIRM}
              />
            }
          />
          :{' '}
          <Route
            path={StepsEnum.REPAYMENT_SCHEDULE}
            element={
              <EditPaymentSchedule
                applicationId={applicationId}
                cachedApplications={cachedApplications}
                asyncRequestsInProgress={asyncRequestsInProgress}
                setStep={setStep}
                bootstrapInfo={bootstrapInfo}
                setCachedApplications={setCachedApplications}
                setAuthorizePaymentStartError={setAuthorizePaymentStartError}
              />
            }
          />
          <Route path={StepsEnum.ORDER_DECLINED} element={<ApplicationOrderDeclined />} />
          <Route
            path={StepsEnum.ORDER_FINISH}
            element={<Finish bootstrapRefetch={bootstrapRefetch} />}
          />
          <Route path={StepsEnum.ERROR} element={<GenericError isUncaughtError={false} />} />
          <Route path={StepsEnum.DECLINED} element={<ApplicationDeclined />} />
          <Route path={StepsEnum.SETTLED} element={<ApplicationAlreadySettled />} />
          <Route path={StepsEnum.EXPIRED} element={<ApplicationExpired />} />
          <Route path={StepsEnum.BANK_CONNECT_ERROR} element={<BankConnectError />} />
          <Route path="*" element={<PageNotFound currentLocation={location.pathname} />} />
        </Route>
      )}
      <Route
        path="*"
        element={
          <OriginationProvider>
            <RootLayout logo={logo}>
              <ApplicationIdNotFound />
            </RootLayout>
          </OriginationProvider>
        }
      />
    </SentryRoutes>
  )
}
