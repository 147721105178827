import React from 'react'
import { createRoot } from 'react-dom/client'
import { ThemeProvider } from '@mui/material/styles'
import { I18nextProvider } from 'react-i18next'
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk'
import CssBaseline from '@mui/material/CssBaseline'

import '@fontsource/poppins/400.css'
import '@fontsource/poppins/500.css'
import '@fontsource/poppins/600.css'
import 'react-tooltip/dist/react-tooltip.css'
import 'src/components/PaymentMethod/BankConnectCarousel.css'

import theme from 'src/themes'
import { LAUNCH_DARKLY_CLIENT_ID } from 'src/utils/constants'

import App from './App'
import reportWebVitals from './reportWebVitals'
import i18n from './locales'

const root = createRoot(document.getElementById('root') as HTMLElement)
const renderApp = async () => {
  const LDProvider = await asyncWithLDProvider({ clientSideID: LAUNCH_DARKLY_CLIENT_ID as string })

  root.render(
    <React.StrictMode>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <I18nextProvider i18n={i18n}>
          <LDProvider>
            <App />
          </LDProvider>
        </I18nextProvider>
      </ThemeProvider>
    </React.StrictMode>,
  )
}

renderApp()
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
