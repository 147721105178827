import { captureMessage } from '@sentry/react'
import { useTranslation } from 'react-i18next'

import { useLocalizedFormatters } from 'src/hooks/useLocalizedFormatters'
import { MonthlyScheduleEnum, PaymentScheduleEnum } from 'src/types'

export default function usePaymentScheduleText() {
  const { t } = useTranslation()
  const { dateFormatCustom } = useLocalizedFormatters()

  return ({
    frequency,
    monthlyChoice,
    paymentCycleDueDate,
  }: {
    frequency?: PaymentScheduleEnum
    monthlyChoice?: MonthlyScheduleEnum
    paymentCycleDueDate: Date | null
  }): string => {
    switch (frequency) {
      case PaymentScheduleEnum.EVERY_WEEK: {
        const dayOfWeek = dateFormatCustom(paymentCycleDueDate!, 'EEEE')
        return t('PaymentSchedule.scheduleHelpers.everyWeek', { dayOfWeek })
      }
      case PaymentScheduleEnum.EVERY_TWO_WEEKS:
        return t('PaymentSchedule.scheduleHelpers.everyTwoWeeks')
      case PaymentScheduleEnum.TWICE_A_MONTH:
        return t('PaymentSchedule.scheduleHelpers.twiceAMonth')
      case PaymentScheduleEnum.ONCE_A_MONTH:
        switch (monthlyChoice) {
          case MonthlyScheduleEnum.FIRST:
            return t('PaymentSchedule.scheduleHelpers.onceAMonth.first')
          case MonthlyScheduleEnum.LAST:
            return t('PaymentSchedule.scheduleHelpers.onceAMonth.last')
          case MonthlyScheduleEnum.FIFTEENTH:
            return t('PaymentSchedule.scheduleHelpers.onceAMonth.fifteenth')
          case MonthlyScheduleEnum.OTHER: {
            const day = dateFormatCustom(paymentCycleDueDate!, 'do')
            return t('PaymentSchedule.scheduleHelpers.onceAMonth.other', { day })
          }
          default:
            captureMessage(`Error generating first payment date monthly text `, {
              level: 'warning',
              extra: { monthlyChoice, paymentCycleDueDate },
            })
            return ''
        }
      default:
        captureMessage(`Error generating first payment date text with data`, {
          level: 'warning',
          extra: { frequency, paymentCycleDueDate, monthlyChoice },
        })
        return ''
    }
  }
}
