import { useCallback } from 'react'

import { useCreditRenewalContext } from 'src/hooks'
import { useLocalizedFormatters } from 'src/hooks/useLocalizedFormatters'
import { OfferItemType } from 'src/types'
import { selectOfferChanged } from 'src/utils/analytics'
import { isQCResident } from 'src/utils/borrower'

export const useSelectOffer = () => {
  const { currencyFormat, percentFormat } = useLocalizedFormatters()
  const {
    bootstrapInfo,
    offerList,
    cachedApplication,
    updateCachedApplication,
    selectedLoanIncreaseAmount,
    saveOfferTermMutation,
  } = useCreditRenewalContext()
  const apr = bootstrapInfo?.application?.apr as number

  const selectedOfferTerm = cachedApplication.selected_offer_term as number
  const { borrower } = bootstrapInfo

  const changeOffer = useCallback(
    (offer: OfferItemType) => {
      if (!selectedLoanIncreaseAmount) {
        return
      }
      if (offer.term_length !== cachedApplication.selected_offer_term) {
        updateCachedApplication({
          selected_offer_term: offer.term_length,
        })
        saveOfferTermMutation({
          term: offer.term_length,
          loan_amount: selectedLoanIncreaseAmount,
          estimated_due_amount: offerList.filter(o => o.term_length === offer.term_length)[0]
            ?.payment_amount,
        })
      }
    },
    [
      selectedLoanIncreaseAmount,
      cachedApplication.selected_offer_term,
      selectedOfferTerm,
      offerList,
    ],
  )

  const setSelectedOfferIdOnClick = (offer: OfferItemType) => {
    changeOffer(offer)
    selectOfferChanged(offer.term_length.toString())
  }

  return {
    apr,
    borrower,
    offerList,
    selectedOfferTerm,
    setSelectedOfferIdOnClick,
    currencyFormat,
    percentFormat,
    isQCResident,
    saveOfferTermMutation,
  }
}
