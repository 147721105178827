import { useContext } from 'react'

import type { AuthJwtContextProps } from 'src/contexts/authjwt'
import { AuthJwtContext } from 'src/contexts'

export default function useAuthJwtContext(): AuthJwtContextProps {
  const context = useContext(AuthJwtContext)
  if (context === undefined) {
    throw new Error('useAuthJwtContext must be used within a AuthJwtProvider')
  }
  return context
}
