import { OfferItemType } from 'src/types'

/**
 * Calculates the monthly due amount based on the amortization formula
 * @param {number} principal The original loan amount
 * @param {number} term The duration of the loan in months
 * @param {number} apr The interest rate
 * @returns {number} The monthly due amount
 */
export const calculateOffer = (principal: number, term: number, apr: number) => {
  const r = apr / (100 * 12)
  const rPow = Math.pow(1 + r, term)
  const num = r * rPow
  const den = rPow - 1
  return Number(((principal * num) / den).toFixed(2))
}

/**
 * Generates 4 offers to be shown to the user where the user can select one.
 * @param {number}principal The original loan amount
 * @param {number} apr The interest rate
 * @returns {OfferItemType} A list of objects containing the term_length and the monthly payment_amount, sorted by term_length in descending order
 */
export const generateOffers = (principal: number, apr: number): OfferItemType[] => {
  return [60, 48, 36, 24].map(term_length => ({
    term_length,
    payment_amount: calculateOffer(principal, term_length, apr),
  }))
}
