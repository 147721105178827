import { useEffect } from 'react'

/**
 *
 */
export function BiMonthlyScheduleOptions({ setScheduleReady }: { setScheduleReady: () => void }) {
  useEffect(() => {
    setScheduleReady()
  }, [])

  return <></>
}
// No options to render, Q2 knows enough from just the frequency selection TWICE_A_MONTH
