import { useContext } from 'react'

import { ConfigurationContext } from '../contexts/configuration'

export function useConfiguration() {
  const context = useContext(ConfigurationContext)
  if (!context) {
    throw new Error('Context must be used within a provider')
  }

  return context
}
