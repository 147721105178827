import { Typography } from '@mui/material'
import { t } from 'i18next'

import { useLocalizedFormatters } from 'src/hooks/useLocalizedFormatters'
import { LabelText } from 'src/portal/components/LabelText'
import { SpacedRow } from 'src/portal/components/SpacedRow'
import PortalTheme from 'src/themes/portal'

export const UpcomingPaymentDateContainer = ({
  upcomingPaymentDate,
}: {
  upcomingPaymentDate: string
}) => {
  const { dateFormatCustom } = useLocalizedFormatters()

  return (
    <SpacedRow>
      <LabelText text={t('Portal.Home.label.summary.upcomingPaymentDate')} />
      <Typography variant="body2" color={PortalTheme.color.grey9} py="6px">
        {dateFormatCustom(new Date(upcomingPaymentDate), t('Portal.Home.label.summary.dateFormat'))}
      </Typography>
    </SpacedRow>
  )
}
