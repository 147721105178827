import { BorrowerType, ProvinceCodes } from 'src/types'

/*
 *
 */
export const provinceIsQC = (province_code?: string) => province_code === ProvinceCodes.QUEBEC

/*
 *
 */
export const isQCResident = (borrower?: BorrowerType) => {
  return provinceIsQC(borrower?.borrower_province)
}
