import Grid from '@mui/material/Grid'
import { styled } from '@mui/material/styles'
import { Box, Typography } from '@mui/material'

/**
 *
 */
export const PADScheduleHeader = styled(Grid)(({ theme }) => ({
  borderColor: theme.color.grey3,
  padding: '10px',
  borderWidth: '1px',
  borderStyle: 'solid',
  borderBottom: 'none',
  borderRadius: '4px',
  borderBottomWidth: 0,
  borderBottomLeftRadius: 0,
  borderBottomRightRadius: 0,
  backgroundColor: theme.color.brand5,
}))

/**
 *
 */
export const PADHeadingText = styled(Typography)(({ theme }) => ({
  color: theme.color.black,
  paddingLeft: '5px',
  fontWeight: 500,
}))

/**
 *
 */
export const PADHeaderContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
})

/**
 *
 */
export const PADScheduleContainer = styled(Grid)(({ theme }) => ({
  borderColor: theme.color.grey3,
  padding: '5px',
  borderWidth: '1px',
  borderStyle: 'solid',
  borderTop: 'none',
  borderRadius: '4px',
  borderTopLeftRadius: 0,
  borderTopRightRadius: 0,
  width: '100%',
}))
