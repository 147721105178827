import { useContext, useEffect, useRef } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { debounce } from 'throttle-debounce'
import { Link } from '@mui/material'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'

import { PrimaryCTA } from 'src/components/common/Buttons'
import ErrorPage from 'src/components/Error/shared/ErrorPage'
import { PleaseReachOut } from 'src/components/Error/shared/PleaseReachOut'
import { PrequalificationContext } from 'src/contexts'
import { useOriginationContext } from 'src/hooks'
import { genericErrorImg, verificationFailImg } from 'src/images'
import { CreditRenewalRouteBase, CreditRenewalStepsEnum, StepsEnum } from 'src/types'
import {
  captureExceptionHelper,
  errorContactFigClicked,
  errorLiveChatClicked,
  errorTryAgainClicked,
  isE2EUrl,
  parseUrl,
  segmentAnalytics,
} from 'src/utils'

const LocalStorageHelpLink = ({
  screen,
  analyticsCallback,
}: {
  screen: StepsEnum | CreditRenewalStepsEnum
  analyticsCallback: (object: string, page_name: StepsEnum | CreditRenewalStepsEnum) => void
}) => {
  const { t } = useTranslation()
  const contactFigLabel = t('GenericError.LocalStorageError.enableLink')
  return (
    <Link
      href={t('GenericError.LocalStorageError.helpLink')}
      target="_blank"
      variant="linkMedium"
      whiteSpace="nowrap"
      onClick={() => {
        analyticsCallback(contactFigLabel, screen)
      }}
      style={{
        display: 'flex',
        alignItems: 'center',
        gap: '4px',
        justifyContent: 'center',
      }}
    >
      <OpenInNewIcon /> {contactFigLabel}
    </Link>
  )
}

/**
 *
 */
export function GenericError({
  isUncaughtError = true,
  isLocalStorageError = false,
}: {
  isUncaughtError?: boolean
  isLocalStorageError?: boolean
}) {
  const { genericErrorPageError } = useOriginationContext()
  const { prequalGenericErrorPageError } = useContext(PrequalificationContext)
  const { t } = useTranslation()
  const { jwt, query } = parseUrl()
  const { jwtApiKey } = useParams()
  const step = StepsEnum.ERROR

  const debouncedPageView = useRef(
    debounce(1000, (properties: any) => {
      segmentAnalytics.page(properties)
    }),
  )

  useEffect(() => {
    const error = isE2EUrl() ? prequalGenericErrorPageError : genericErrorPageError
    captureExceptionHelper('User navigated to the Generic Error page', error, 'warning')
  }, [prequalGenericErrorPageError, genericErrorPageError])

  useEffect(() => {
    window.onbeforeunload = () => {
      // pass
    }
    // If we have a handler here, the browser will show the dialogue "Reload site?"

    let url
    if (isUncaughtError) {
      if (isE2EUrl()) {
        url = `${window.location.origin}/#/${StepsEnum.END_TO_END}/${jwt}/${step}${query}`
      } else {
        url = `${window.location.origin}/#/${jwt}/${step}`
      }
      debouncedPageView.current({
        path: `/${step}`,
        title: document.title,
        referrer: url,
        url,
      })
    }
  }, [isUncaughtError])

  const tryAgain = () => {
    const { jwt, query } = parseUrl()
    let url

    if (window.location.hash.includes(CreditRenewalRouteBase)) {
      url = `${window.location.origin}/#/${CreditRenewalRouteBase}/${jwtApiKey}/${CreditRenewalStepsEnum.ELIGIBILITY}`
    } else if (isE2EUrl()) {
      url = `${window.location.origin}/#/${StepsEnum.END_TO_END}/${jwt}/${StepsEnum.PREQUALIFICATION}${query}`
    } else {
      url = `${window.location.origin}/#/${jwt}/${StepsEnum.LOAN_AMOUNT}${query}`
    }
    window.location.href = url
    if (isUncaughtError) {
      window.location.reload()
    }
    // If it is an uncaught error, we are not wrapped in the react router and need to reload,
    //  otherwise just changing the href will reroute without needing to reload
  }
  let content = (
    <Trans i18nKey="GenericError.content">
      <PleaseReachOut
        screen={step}
        liveChatAnalytics={errorLiveChatClicked}
        contactFigAnalytics={errorContactFigClicked}
      />
    </Trans>
  )
  const tryAgainButton = (
    <PrimaryCTA
      onClick={() => {
        errorTryAgainClicked(t('common.retryLabel'), step)
        tryAgain()
      }}
      buttonText={t('common.retryLabel')}
    />
  )

  if (isLocalStorageError) {
    content = (
      <Trans i18nKey="GenericError.LocalStorageError.content">
        <LocalStorageHelpLink screen={step} analyticsCallback={errorContactFigClicked} />
      </Trans>
    )
  }

  return (
    <ErrorPage
      image={{
        src: isLocalStorageError ? verificationFailImg : genericErrorImg,
        alt: t('GenericError.alt'),
      }}
      title={
        isLocalStorageError ? t('GenericError.LocalStorageError.title') : t('GenericError.title')
      }
      content={content}
      buttons={[tryAgainButton]}
    />
  )
}
