import Axios from 'axios'
import { useContext } from 'react'

import { API_URL } from 'src/utils/constants'
import {
  PrequalResponseDataType,
  PrequalRequestType,
  GetPartnerConfigResponseType,
  ApplyLaterResponseDataType,
  ApplyLaterRequestType,
} from 'src/types/api'
import { PrequalificationContext } from 'src/contexts'

// Setting axios defaults to send cookies
Axios.defaults.withCredentials = true

interface ApiReturnProps {
  getPartnerConfig: () => Promise<GetPartnerConfigResponseType>
  prequalify: (payload: PrequalRequestType) => Promise<PrequalResponseDataType>
  applyLater: (payload: ApplyLaterRequestType) => Promise<ApplyLaterResponseDataType>
}

export default useApiE2E
/*
 *
 */
function useApiE2E(): ApiReturnProps {
  const { jwtApiKey } = useContext(PrequalificationContext)
  const headers: any = {
    'Content-Type': 'application/json',
    authorization: jwtApiKey,
  }

  return {
    getPartnerConfig: async () => {
      const { data } = await Axios.get<GetPartnerConfigResponseType>(
        `${API_URL}v1/e2e_prequal/get_partner_config`,
        { headers },
      )
      return data
    },
    prequalify: async (payload: PrequalRequestType) => {
      const { data } = await Axios.post<PrequalResponseDataType>(
        `${API_URL}v1/e2e_prequal/prequal`,
        payload,
        { headers },
      )
      return data
    },
    applyLater: async (payload: ApplyLaterRequestType) => {
      const { data } = await Axios.post<ApplyLaterResponseDataType>(
        `${API_URL}v1/e2e_prequal/apply_later`,
        payload,
        { headers },
      )
      return data
    },
  }
}
