import { useFlags } from 'launchdarkly-react-client-sdk'
import { Trans, useTranslation } from 'react-i18next'

import ErrorPage from 'src/components/Error/shared/ErrorPage'
import { PrimaryCTA } from 'src/components/common/Buttons'
import HelpLinkGeneric from 'src/components/common/HelpLinkGeneric'
import { useOriginationContext } from 'src/hooks'
import { verificationFailImg } from 'src/images'
import { FeatureFlags, OnfidoReviewReasonsEnum, PIIMismatchReasonsEnum, StepsEnum } from 'src/types'
import { kycRetryGenericClicked } from 'src/utils'

function KYCRetryGeneric() {
  const { enablePiiRetry } = useFlags<FeatureFlags>()

  const { t } = useTranslation()
  const { setStep, setOnfidoDecision, onfidoDecision } = useOriginationContext()
  const { reviewReason, piiMismatchReason } = onfidoDecision

  const screen = StepsEnum.KYC_RETRY_GENERIC

  const onVerifyAgainClick = () => {
    setOnfidoDecision({
      ...onfidoDecision,
      shouldRetry: true,
    })
    kycRetryGenericClicked(t('KYCRetryGeneric.verifyButtonLabel'), screen)

    if (
      enablePiiRetry &&
      reviewReason === OnfidoReviewReasonsEnum.PII_MISMATCH &&
      piiMismatchReason !== PIIMismatchReasonsEnum.NOT_REVIEWED
    ) {
      setStep(StepsEnum.KYC_PII_RETRY)
    } else {
      setStep(StepsEnum.KYC_VERIFY)
    }
  }

  const content = (
    <Trans i18nKey="KYCRetryGeneric.content">
      <HelpLinkGeneric />
    </Trans>
  )
  const verifyIdButton = (
    <PrimaryCTA onClick={onVerifyAgainClick} buttonText={t('KYCRetryGeneric.verifyButtonLabel')} />
  )
  return (
    <ErrorPage
      image={{ src: verificationFailImg, alt: t('KYCRetryGeneric.alt') }}
      title={t('KYCRetryGeneric.title')}
      content={content}
      buttons={[verifyIdButton]}
    />
  )
}

export default KYCRetryGeneric
