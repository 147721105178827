import { useContext } from 'react'

import type { CreditRenewalContextProps } from 'src/contexts/creditrenewal'
import { CreditRenewalContext } from 'src/contexts'

export default function useCreditRenewalContext(): CreditRenewalContextProps {
  const context = useContext(CreditRenewalContext)
  if (context === undefined) {
    throw new Error('useCreditRenewalContext must be used within a CreditRenewalProvider')
  }
  return context
}
