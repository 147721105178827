import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import {
  addMonths,
  differenceInCalendarMonths,
  eachDayOfInterval,
  lastDayOfMonth,
  setDate,
  startOfMonth,
} from 'date-fns'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import theme from 'src/themes'
import { MonthlyScheduleEnum } from 'src/types/common'
import { RepaymentScheduleList } from 'src/components/PaymentSchedule/shared/RepaymentScheduleList'
import { RepaymentStartHeading } from 'src/components/PaymentSchedule/shared/RepaymentStartHeading'
import { getIsInvalidDateFromRange, FigDatePickerDay } from 'src/components/common/FigDatePicker'

export function MonthlyChoices({
  selectedMonthlyChoice,
  setSelectedMonthlyChoice,
  minimumDate,
  maximumDate,
  currentDate,
}: {
  selectedMonthlyChoice?: MonthlyScheduleEnum
  setSelectedMonthlyChoice: (choice: MonthlyScheduleEnum) => void
  minimumDate: Date
  maximumDate: Date
  currentDate: Date
}) {
  const { t } = useTranslation()
  const maximumMonthSpan = differenceInCalendarMonths(maximumDate, minimumDate) + 1 // add one to calculate inclusive range

  const estimatedPaymentDatesFirst = []
  for (let i = 0; i < maximumMonthSpan + 1; i++) {
    estimatedPaymentDatesFirst.push(startOfMonth(addMonths(currentDate, i)))
  }

  const estimatedPaymentDatesFifteenth = []
  for (let i = 0; i < maximumMonthSpan + 1; i++) {
    estimatedPaymentDatesFifteenth.push(setDate(addMonths(currentDate, i), 15))
  }

  const estimatedPaymentDatesLast = []
  for (let i = 0; i < maximumMonthSpan + 1; i++) {
    estimatedPaymentDatesLast.push(lastDayOfMonth(addMonths(currentDate, i)))
  }

  const options = [
    {
      monthlyChoice: MonthlyScheduleEnum.FIRST,
      primaryText: t('PaymentSchedule.MonthlyScheduleOptions.first'),
      estimatedPaymentDates: estimatedPaymentDatesFirst,
    },
    {
      monthlyChoice: MonthlyScheduleEnum.FIFTEENTH,
      primaryText: t('PaymentSchedule.MonthlyScheduleOptions.fifteenth'),
      estimatedPaymentDates: estimatedPaymentDatesFifteenth,
    },
    {
      monthlyChoice: MonthlyScheduleEnum.LAST,
      primaryText: t('PaymentSchedule.MonthlyScheduleOptions.last'),
      estimatedPaymentDates: estimatedPaymentDatesLast,
    },
    {
      monthlyChoice: MonthlyScheduleEnum.OTHER,
      primaryText: t('PaymentSchedule.MonthlyScheduleOptions.other'),
      estimatedPaymentDates: [minimumDate],
    },
  ]
    // Filter to only options with valid payment dates
    .filter(({ estimatedPaymentDates }) =>
      estimatedPaymentDates.some(
        paymentDate =>
          paymentDate.getTime() >= minimumDate.getTime() &&
          paymentDate.getTime() <= maximumDate.getTime(),
      ),
    )
    .map(({ monthlyChoice, primaryText }) => ({
      onClick: () => setSelectedMonthlyChoice(monthlyChoice),
      isSelected: monthlyChoice === selectedMonthlyChoice,
      primaryText,
    }))

  return (
    <Grid container spacing="0px" marginBottom="24px">
      <Grid item xs={12}>
        <RepaymentScheduleList options={options} />
      </Grid>
    </Grid>
  )
}

/**
 *
 */
export function MonthlyScheduleOptions({
  currentDate,
  earliestDate,
  latestDate,
  selectedMonthlyChoice,
  setSelectedMonthlyChoice,
  selectedFirstPaymentDate,
  setSelectedFirstPaymentDate,
  setScheduleReady,
}: {
  currentDate: Date
  earliestDate: Date
  latestDate: Date
  selectedMonthlyChoice?: MonthlyScheduleEnum
  setSelectedMonthlyChoice: (choice: MonthlyScheduleEnum) => void
  selectedFirstPaymentDate: Date | null
  setSelectedFirstPaymentDate: (d: Date) => void
  setScheduleReady: () => void
}) {
  const { t } = useTranslation()
  const validDates: Date[] = eachDayOfInterval({
    start: earliestDate,
    end: latestDate,
  })

  useEffect(() => {
    if (selectedMonthlyChoice) {
      if (
        selectedMonthlyChoice !== MonthlyScheduleEnum.OTHER ||
        selectedFirstPaymentDate !== null
      ) {
        setScheduleReady()
      }
    }
  }, [selectedMonthlyChoice, selectedFirstPaymentDate])

  return (
    <Grid container marginTop="24px" id="startMonthlyWrapper">
      <RepaymentStartHeading />
      <MonthlyChoices
        {...{
          selectedMonthlyChoice,
          setSelectedMonthlyChoice,
          currentDate,
          minimumDate: earliestDate,
          maximumDate: latestDate,
        }}
      />
      {selectedMonthlyChoice === MonthlyScheduleEnum.OTHER && (
        <Grid container>
          <Grid item xs={12} marginBottom="4px">
            <Typography variant="body2" color={theme.color.black}>
              {t('PaymentSchedule.MonthlyScheduleOptions.firstPaymentDate')}
            </Typography>
          </Grid>
          <Grid item xs={12} marginBottom="16px" id="datePickerMonthlyWrapper">
            <FigDatePickerDay
              getIsInvalidDate={getIsInvalidDateFromRange(validDates)}
              calendarDate={selectedFirstPaymentDate}
              setCalendarDate={setSelectedFirstPaymentDate}
              disableWeekends={true}
            />
          </Grid>
        </Grid>
      )}
    </Grid>
  )
}
