import { useContext } from 'react'

import type { OriginationContextProps } from 'src/contexts/origination'
import { OriginationContext } from 'src/contexts'

export default function useOriginationContext(): OriginationContextProps {
  const context = useContext(OriginationContext)
  if (context === undefined) {
    throw new Error('useOriginationContext must be used within a OriginationProvider')
  }
  return context
}
