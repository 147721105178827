import React, { ComponentProps } from 'react'
import ToggleButton from '@mui/material/ToggleButton'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'
import { styled } from '@mui/material'

const StyledToggleButton = styled(ToggleButton)(({ theme }) => ({
  textTransform: 'inherit',
  lineHeight: '32px',
  fontSize: '16px',
  backgroundColor: 'inherit',
  borderColor: theme.color.brand1,
  color: theme.color.brand1,
  '&.Mui-selected': {
    backgroundColor: theme.color.brand1,
    color: theme.color.white,
  },
  '&.Mui-selected:hover': {
    backgroundColor: theme.color.brand1,
    color: theme.color.white,
  },
  ':hover': {
    backgroundColor: 'inherit',
  },
}))

type ToggleButtonType = { buttonText: string } & ComponentProps<typeof ToggleButton>

interface ToggleButtonGroupProps {
  setValue: (value: string) => void
  value: string
  buttonDetails: { text: string; value: string }[]
}

export function FigToggleButton(props: ToggleButtonType) {
  const { buttonText, ...otherProps } = props
  return (
    <StyledToggleButton color="primary" {...otherProps}>
      {buttonText}
    </StyledToggleButton>
  )
}

export default FigToggleButtonGroup
/**
 *
 */
function FigToggleButtonGroup({ setValue, value, buttonDetails }: ToggleButtonGroupProps) {
  const handleValueChange = (event: React.MouseEvent<HTMLElement>, newValue: string) => {
    setValue(newValue)
  }

  return (
    <ToggleButtonGroup
      value={value}
      exclusive={true}
      onChange={handleValueChange}
      fullWidth={true}
      color="primary"
    >
      {buttonDetails.map(button => (
        <FigToggleButton
          key={button.text}
          disabled={value === button.value}
          value={button.value}
          buttonText={button.text}
        >
          {button.text}
        </FigToggleButton>
      ))}
    </ToggleButtonGroup>
  )
}
