import { FC, createContext, useEffect, ReactNode, useState } from 'react'
import { captureMessage } from '@sentry/react'

export type NetworkContextProps = {
  isOnline: boolean
}

export const NetworkContext = createContext<NetworkContextProps>({} as NetworkContextProps)

interface Props {
  children: ReactNode
}

const setButtons = (isDisabled: boolean) => {
  const btns = Array.from(document.querySelectorAll('button'))
  for (const btn of btns) {
    btn.disabled = isDisabled
  }
}

/**
 *
 */
const NetworkProvider: FC<Props> = ({ children }) => {
  const [isOnline, setIsOnline] = useState(true)

  useEffect(() => setButtons(!isOnline), [isOnline])

  useEffect(() => {
    setIsOnline(window.navigator.onLine)

    const disconnectCallback = () => setIsOnline(false)
    const reconnectCallback = () => {
      captureMessage('User reconnected to internet', 'info')
      setIsOnline(true)
    }

    window.addEventListener('offline', disconnectCallback)
    window.addEventListener('online', reconnectCallback)

    const cleanup = () => {
      window.removeEventListener('offline', disconnectCallback)
      window.removeEventListener('online', reconnectCallback)
    }

    return cleanup
  }, [])

  return <NetworkContext.Provider value={{ isOnline }}>{children}</NetworkContext.Provider>
}

export default NetworkProvider
