import { Typography, Button, Box, Link, MobileStepper } from '@mui/material'
import { Tooltip } from 'react-tooltip'
import SwipeableViews from 'react-swipeable-views'
import { ReactElement, useEffect, useState } from 'react'
import { styled } from '@mui/material/styles'
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft'
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight'
import { debounce } from 'throttle-debounce'
import { useTranslation } from 'react-i18next'

import theme from 'src/themes'
import {
  connectBankGuidanceFAQCarouselViewed,
  bankConnectCarouselLearnMoreClicked,
  bankConnectCarouselTooltipViewed,
} from 'src/utils'
import { StepsEnum } from 'src/types'
import { bankIcon, checkMarkShieldIcon, helpIcon } from 'src/images'
import { ReactComponent as LockIcon } from 'src/images/lock.svg'

import './BankConnectCarousel.css'

export const ImgStyle = styled('img')({
  marginLeft: 'auto',
  marginRight: 'auto',
  marginBottom: '10px',
  display: 'block',
})

const GreenLockIcon = styled(LockIcon)({
  color: theme.color.brand1,
  width: 32,
  height: 32,
  marginLeft: 'auto',
  marginRight: 'auto',
  marginBottom: '10px',
  display: 'block',
})

const CarouselButtonStyle = {
  backgroundColor: theme.color.brand1,
  width: '38px',
  height: '38px',
  borderRadius: '50%',
}

const LearnMoreImg = styled('img')({
  marginLeft: 'auto',
  marginRight: 'auto',
  width: 20,
  height: 20,
})

const DisabledCarouselButtonStyle = { ...CarouselButtonStyle, backgroundColor: theme.color.grey5 }

type CarouselProps = {
  icon: ReactElement
  question: string
  answer: string
  tooltipText: boolean
}

function Item({ icon, question, answer, tooltipText }: CarouselProps) {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      borderRadius="8px"
      paddingX="30px"
      paddingBottom="20px"
    >
      {icon}
      <Typography data-testid="question" variant="label" textAlign="center">
        {question}
      </Typography>
      <Typography
        data-testid="answer"
        variant="body2"
        textAlign="center"
        marginTop="8px"
        color={theme.color.grey10}
      >
        {answer}
        {tooltipText && (
          <LearnMoreImg src={helpIcon} alt="Learn more" data-tooltip-id="flinksLearnMore" />
        )}
      </Typography>
    </Box>
  )
}

export default BankConnectCarousel
/**
 *
 */
function BankConnectCarousel() {
  const [activeStep, setActiveStep] = useState(0)

  const { t } = useTranslation()

  const CAROUSEL_INFO: Array<CarouselProps> = [
    {
      icon: <ImgStyle src={checkMarkShieldIcon} alt={t('BankConnectCarousel.why.alt')} />,
      question: t('BankConnectCarousel.why.question'),
      answer: t('BankConnectCarousel.why.answer'),
      tooltipText: true,
    },
    {
      icon: <GreenLockIcon />,
      question: t('BankConnectCarousel.secure.question'),
      answer: t('BankConnectCarousel.secure.answer'),
      tooltipText: false,
    },
    {
      icon: <ImgStyle src={bankIcon} alt={t('BankConnectCarousel.which.alt')} />,
      question: t('BankConnectCarousel.which.question'),
      answer: t('BankConnectCarousel.which.answer'),
      tooltipText: false,
    },
  ]

  const debouncedConnectBankGuidanceFAQViewed = debounce(2000, () => {
    connectBankGuidanceFAQCarouselViewed(
      `Card with question ${CAROUSEL_INFO[0].question} viewed`,
      StepsEnum.PAYMENT_METHOD_SELECT,
    )
  })

  useEffect(() => {
    debouncedConnectBankGuidanceFAQViewed()
  }, [])

  const maxSteps = CAROUSEL_INFO.length
  const handleNext = () => {
    connectBankGuidanceFAQCarouselViewed(
      `Card with question ${CAROUSEL_INFO[activeStep + 1].question} viewed`,
      StepsEnum.PAYMENT_METHOD_SELECT,
    )
    setActiveStep(prevActiveStep => prevActiveStep + 1)
  }
  const handleBack = () => {
    connectBankGuidanceFAQCarouselViewed(
      `Card with question ${CAROUSEL_INFO[activeStep - 1].question} viewed`,
      StepsEnum.PAYMENT_METHOD_SELECT,
    )
    setActiveStep(prevActiveStep => prevActiveStep - 1)
  }
  const handleStepChange = (step: number) => {
    connectBankGuidanceFAQCarouselViewed(
      `Card with question ${CAROUSEL_INFO[step].question} viewed`,
      StepsEnum.PAYMENT_METHOD_SELECT,
    )
    setActiveStep(step)
  }

  return (
    <Box
      sx={{
        display: 'block',
        background: theme.color.brand6,
        borderRadius: '8px',
        paddingTop: '40px',
      }}
    >
      <SwipeableViews
        springConfig={{
          duration: '0.4s',
          easeFunction: 'ease-out',
          delay: '0s',
        }}
        index={activeStep}
        onChangeIndex={handleStepChange}
        enableMouseEvents
      >
        {CAROUSEL_INFO.map((item, i) => (
          <Item key={i} {...item} />
        ))}
      </SwipeableViews>
      <MobileStepper
        sx={{
          backgroundColor: theme.color.brand6,
          paddingLeft: '30px',
          paddingRight: '30px',
          paddingBottom: '15px',
        }}
        steps={maxSteps}
        position="static"
        activeStep={activeStep}
        nextButton={
          <Button
            data-testid="carouselNextButton"
            size="small"
            onClick={handleNext}
            disabled={activeStep === maxSteps - 1}
            style={activeStep === maxSteps - 1 ? DisabledCarouselButtonStyle : CarouselButtonStyle}
          >
            <KeyboardArrowRight style={{ color: 'white' }} />
          </Button>
        }
        backButton={
          <Button
            data-testid="carouselBackButton"
            size="small"
            onClick={handleBack}
            disabled={activeStep === 0}
            style={activeStep === 0 ? DisabledCarouselButtonStyle : CarouselButtonStyle}
          >
            <KeyboardArrowLeft style={{ color: 'white' }} />
          </Button>
        }
      />
      <Tooltip
        style={{
          padding: 16,
          background: theme.color.white,
          color: theme.color.grey7,
          borderRadius: 10,
          boxShadow: theme.shadows[2],
          maxWidth: 335,
        }}
        id="flinksLearnMore"
        place="bottom"
        clickable
        afterShow={() => {
          bankConnectCarouselTooltipViewed(
            'Carousel Tooltip viewed',
            StepsEnum.PAYMENT_METHOD_SELECT,
          )
        }}
      >
        <Typography
          sx={{
            fontSize: 14,
          }}
        >
          {t('BankConnectCarousel.why.tooltip')}
        </Typography>
        <Link
          sx={{
            display: 'block',
            marginTop: 3,
            fontSize: 14,
          }}
          variant="linkMedium"
          href={t('BankConnectCarousel.why.url')}
          target="_blank"
          onClick={() => {
            bankConnectCarouselLearnMoreClicked(
              t('BankConnectCarousel.why.learnMore'),
              StepsEnum.PAYMENT_METHOD_SELECT,
            )
          }}
        >
          {t('BankConnectCarousel.why.learnMore')}
        </Link>
      </Tooltip>
    </Box>
  )
}
