import { ComponentProps, FocusEventHandler, useState } from 'react'
import { DatePicker, LocalizationProvider, DateView } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3'
import { useTranslation } from 'react-i18next'
import { useMediaQuery } from '@mui/material'

import FigTextField from 'src/components/common/FigTextField'
import { dateISOFormat, getLocale } from 'src/utils/format'
import { FigFormControlProps, getLabelId } from 'src/components/common/FigFormControl'
import theme from 'src/themes'

export const getIsDateInFuture = (d: Date) => new Date().getTime() < d.getTime()

export const getIsInvalidDateFromRange = (dateRange: Date[]) => {
  const validDateStrs = new Set((dateRange ?? []).map((d: Date) => dateISOFormat(d)))
  return (date: Date) => !validDateStrs.has(dateISOFormat(date))
}

type FigDatePickerProps = FigFormControlProps & {
  getIsInvalidDate: (d: Date) => boolean
  minDate?: Date
  placeholder?: string
  readOnly?: boolean
  disabled?: boolean
  inputFormat?: string
  name?: string
  onBlur?: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>
  onFocus?: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>
  calendarDate: Date | null
  setCalendarDate: (date: Date) => void
  views: Array<DateView>
  mask?: string
  disableMaskedInput?: boolean
  openTo?: DateView
  disableWeekends: boolean
}

export const FigDatePickerDay = (props: Omit<FigDatePickerProps, 'views'>) => (
  <FigDatePicker {...props} views={['year', 'month', 'day']} />
)

export const FigDatePickerMonth = (props: Omit<FigDatePickerProps, 'views'>) => (
  <FigDatePicker
    placeholder={'MM/YYYY'}
    inputFormat={'MM/yyyy'}
    mask="__/____"
    disableMaskedInput={false}
    {...props}
    views={['year', 'month']}
  />
)

/**
 * A custom date picker text field component.
 *
 * @param props - The component props.
 * @param props.value - The value of the text field.
 * @param props.inputProps - Additional input props for the text field.
 * @param props.calendarDate - The selected date from the calendar.
 * @returns The rendered FigDatePickerTextField component.
 */
const FigDatePickerTextField = (
  props: ComponentProps<typeof FigTextField> & { calendarDate: Date | null },
) => {
  const { value, inputProps, calendarDate } = props
  return (
    <FigTextField
      {...{
        ...props,
        value: inputProps?.readOnly && !calendarDate ? '' : value,
      }}
    />
  )
}

/**
 *
 */
export function FigDatePicker({
  getIsInvalidDate,
  minDate,
  readOnly = true,
  disabled,
  onFocus,
  inputFormat,
  placeholder,
  calendarDate,
  setCalendarDate,
  name,
  onBlur,
  views,
  label,
  error,
  helperText,
  openTo,
  disableWeekends,
}: FigDatePickerProps) {
  const {
    t,
    i18n: { language },
  } = useTranslation()
  const [open, setOpen] = useState<boolean>(false)
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const shouldDisableDateWithWeekendCheck = (date: Date) => {
    return (
      getIsInvalidDate(date) || (disableWeekends && (date.getDay() === 0 || date.getDay() === 6))
    )
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={getLocale(language as any)}>
      <DatePicker
        open={open}
        referenceDate={minDate ?? new Date()}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        value={calendarDate}
        views={views}
        openTo={openTo}
        disableHighlightToday={true}
        shouldDisableYear={getIsInvalidDate}
        shouldDisableMonth={getIsInvalidDate}
        shouldDisableDate={shouldDisableDateWithWeekendCheck}
        format={inputFormat ? inputFormat : t('common.FigDatePicker.defaultDateFormat')}
        aria-labelledby={getLabelId(label)}
        onChange={date => setCalendarDate(date as Date)}
        slots={{
          textField: FigDatePickerTextField as any,
        }}
        slotProps={{
          textField: {
            helperText: helperText,
            label: label,
            error: error,
            onClick: readOnly || isMobile ? () => setOpen(true) : undefined,
            placeholder: placeholder || t('common.FigDatePicker.placeholder'),
            inputProps: {
              'data-testid': 'date-picker',
              readOnly: readOnly,
              placeholder: placeholder || t('common.FigDatePicker.placeholder'),
            },
            disabled: disabled,
            fullWidth: true,
            onBlur: onBlur,
            onFocus: onFocus,
            name: name,
            isFocused: open,
            calendarDate: calendarDate,
          } as any,
          toolbar: {
            hidden: true,
          },
          popper: {
            placement: 'bottom-start',
          },
        }}
      />
    </LocalizationProvider>
  )
}
