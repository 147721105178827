import { useEffect, useRef } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import Notification, {
  InformationIconStyled,
  NotificationBold,
} from 'src/components/common/Notification'
import { useLocalizedFormatters } from 'src/hooks/useLocalizedFormatters'

export function SystemDateUpdateNotification({
  prevFirstPaymentDate,
  firstPaymentDate,
}: {
  prevFirstPaymentDate: Date
  firstPaymentDate: Date
}) {
  const { dateFormat, dateFormatCustom } = useLocalizedFormatters()
  const elementRef = useRef<HTMLDivElement | null>(null)
  const { t } = useTranslation()
  const firstPaymentDateFormat = t('ReviewOrder.firstPaymentDate.dateFormat')

  useEffect(() => {
    const element = elementRef.current
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' })
    }
  }, [dateFormat(firstPaymentDate)])
  return (
    <div ref={elementRef} style={{ marginTop: '8px' }}>
      <Notification
        content={
          <Trans
            i18nKey="ReviewOrder.dateUpdateNotification"
            values={{
              prevDate: dateFormatCustom(prevFirstPaymentDate, firstPaymentDateFormat),
              currDate: dateFormatCustom(firstPaymentDate, firstPaymentDateFormat),
            }}
          >
            Our system updated your first payment date from{' '}
            <NotificationBold>
              {dateFormatCustom(prevFirstPaymentDate, firstPaymentDateFormat)}
            </NotificationBold>{' '}
            to{' '}
            <NotificationBold>
              {dateFormatCustom(firstPaymentDate, firstPaymentDateFormat)}
            </NotificationBold>{' '}
            to match the payment schedule.
          </Trans>
        }
        icon={<InformationIconStyled />}
      />
    </div>
  )
}
