import CloseIcon from '@mui/icons-material/Close'
import { Box, IconButton, Typography } from '@mui/material'
import { ReactNode, useEffect, useState } from 'react'

import { slideDown, slideUp, zIndexAboveFreshChat } from 'src/portal/styles'
import PortalTheme from 'src/themes/portal'

interface MobileTooltipProps {
  isOpen: boolean
  onClose: () => void
  title: string
  content: ReactNode
}

export const MobileTooltip = ({ isOpen, onClose, title, content }: MobileTooltipProps) => {
  const [isAnimating, setIsAnimating] = useState(false)

  useEffect(() => {
    if (isOpen) {
      setIsAnimating(true)
    } else {
      setTimeout(() => setIsAnimating(false), 300)
    }
  }, [isOpen])

  if (!isOpen && !isAnimating) {
    return null
  }

  return (
    <>
      {/* Overlay */}
      <Box
        onClick={onClose}
        sx={{
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          zIndex: 1299, // Slightly lower than the modal
        }}
      />

      {/* Modal */}
      <Box
        sx={{
          position: 'fixed',
          bottom: 0,
          left: 0,
          right: 0,
          height: 'auto',
          backgroundColor: PortalTheme.color.white,
          boxShadow: '0px -2px 8px rgba(0, 0, 0, 0.2)',
          borderTopLeftRadius: '16px',
          borderTopRightRadius: '16px',
          padding: '24px',
          zIndex: zIndexAboveFreshChat,
          animation: `${isOpen ? slideUp : slideDown} 0.3s ease-out`,
          overflowY: 'auto',
        }}
      >
        <IconButton
          onClick={onClose}
          sx={{
            position: 'absolute',
            top: '16px',
            right: '16px',
          }}
        >
          <CloseIcon />
        </IconButton>

        <Typography
          variant="h1"
          color={PortalTheme.color.black}
          sx={{ fontWeight: 'bold', textAlign: 'center', mb: '32px', mt: '24px' }}
        >
          {title}
        </Typography>

        <Box>{content}</Box>
      </Box>
    </>
  )
}
