import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  useMediaQuery,
} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { ReactNode } from 'react'

import theme from 'src/themes'

export function AccordionComponent({
  title,
  expanded,
  panelId,
  handleChange,
  children,
}: {
  title: string
  expanded: boolean
  panelId: string

  handleChange: (value: string) => (event: React.SyntheticEvent, isExpanded: boolean) => void
  children: ReactNode
}) {
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <Accordion
      expanded={expanded}
      onChange={handleChange(panelId)}
      sx={{
        padding: '0px',
        boxShadow: 0,
        '&:before': { display: 'none' },
        color: theme.color.grey9,
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        id="panel-header"
        aria-controls="panel-content"
        sx={{ padding: '0px' }}
      >
        <Typography
          variant="body2"
          textAlign="left"
          sx={{
            padding: isMobile ? '16px 16px 16px 0px' : '16px 16px 16px 0px',
            color: theme.color.grey9,
          }}
        >
          {title}
        </Typography>
      </AccordionSummary>
      <AccordionDetails sx={{ paddingTop: '0px' }}>{children}</AccordionDetails>
    </Accordion>
  )
}
