import { Typography } from '@mui/material'
import Grid from '@mui/material/Grid'
import { styled } from '@mui/material/styles'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { isValidPhoneNumber } from 'react-phone-number-input'
import PhoneInput from 'react-phone-number-input/input'
import 'react-phone-number-input/style.css'
import { useMutation } from 'react-query'

import { getRetryQueryOptionsWithErrorStep } from 'src/api/api'
import { BackButton, PrimaryCTA } from 'src/components/common/Buttons'
import { DivRoot } from 'src/components/common/DivRoot'
import { useApi, useOriginationContext } from 'src/hooks'
import { StepsEnum } from 'src/types'

import { FigFormControl } from '../common/FigFormControl'

enum Country {
  CANADA = 'CA',
}

const PhoneInputStyled = styled(PhoneInput)(({ theme }) => ({
  width: '100%',
  borderRadius: 3,
  padding: 12,
  height: '56px',
  background: theme.color.white,
  border: `1px solid ${theme.color.grey4}`,
  fontFamily: "'Poppins',sans-serif",
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '16px',
  lineHeight: '20px',
  color: theme.color.grey6,
  marginTop: 4,
  marginBottom: 4,
  boxSizing: 'border-box',
  outlineColor: theme.color.brand1,
  ['&[data-error="true"]']: {
    outlineColor: theme.color.error,
  },
}))

export default function PhoneNumber() {
  const {
    setStep,
    updateAsyncRequestStatusCallback,
    cachedApplication,
    updateCachedApplication,
    setGenericErrorPageError,
  } = useOriginationContext()

  const [isError, setIsError] = useState(false)
  const [phone, setPhone] = useState(cachedApplication?.phone_number)
  const { savePhoneNumber } = useApi()

  const { t } = useTranslation()

  const { mutateAsync: savePhoneNumberMutation } = useMutation(savePhoneNumber, {
    ...getRetryQueryOptionsWithErrorStep({
      queryName: 'savePhoneNumberMutation',
      setGenericErrorPageError,
      setStep,
    }),
  })

  const phoneDataLength = 12
  const phoneInputLengthWithBrackets = 14

  const isDisabled = isError || phone?.length !== phoneDataLength

  const handleContinue = () => {
    updateAsyncRequestStatusCallback('savePhoneNumber', () =>
      savePhoneNumberMutation({
        phone_number: phone as string,
      }),
    )
    setStep(StepsEnum.OCCUPATION)
    updateCachedApplication({
      phone_number: phone,
    })
  }

  const handleKeyDown = (e: KeyboardEvent) => {
    if (e.key === 'Enter' && !isDisabled) {
      handleContinue()
    }
  }

  const [isFocused, setIsFocused] = useState(false)

  return (
    <DivRoot>
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <Typography variant="h1" textAlign="center">
            {t('OTP.title')}
          </Typography>
        </Grid>

        <Grid item xs={12} marginTop="32px" display="flex" flexDirection="column">
          <FigFormControl
            label={t('OTP.phoneLabel')}
            error={isError}
            helperText={t('OTP.phoneHelperText')}
            isFocused={isFocused}
          >
            <PhoneInputStyled
              country="CA"
              placeholder={t('OTP.phonePlaceholder')}
              value={phone}
              data-error={isError}
              onChange={value => {
                setPhone(value)
                if (value && value?.length === phoneDataLength) {
                  const isValid = isValidPhoneNumber(value as string, Country.CANADA)
                  setIsError(!isValid)
                } else {
                  setIsError(false)
                }
              }}
              onFocus={() => setIsFocused(true)}
              onBlur={() => setIsFocused(false)}
              onKeyDown={handleKeyDown}
              maxLength={phoneInputLengthWithBrackets}
            />
          </FigFormControl>
        </Grid>

        <Grid item xs={12} marginTop="32px">
          <PrimaryCTA
            type="submit"
            onClick={handleContinue}
            disabled={isDisabled}
            buttonText={t('common.continueLabel')}
          />
        </Grid>

        <Grid item xs={12} display="flex" justifyContent="center" marginTop="16px">
          <BackButton
            onClick={() => {
              setStep(StepsEnum.SELECT_OFFER)
            }}
          />
        </Grid>
      </Grid>
    </DivRoot>
  )
}
