import { Grid, Link, Typography } from '@mui/material'
import { Trans, useTranslation } from 'react-i18next'

import { LoadingAnimation } from 'src/components/common/animations/Loading'
import { PrimaryCTA } from 'src/components/common/Buttons'
import { DivRoot } from 'src/components/common/DivRoot'
import { useEligibility } from 'src/components/CreditRenewal/hooks/useEligibility'
import { IconStyle } from 'src/components/PaymentProtection/styles'
import { eligibilityIcon1, eligibilityIcon2 } from 'src/images'
import { figColors } from 'src/themes'
import { CreditRenewalStepsEnum } from 'src/types'
import { creditRenewalsEligibilityPageCTAClicked } from 'src/utils'

const CreditRenewalEligibility = () => {
  const { isLoading, checkEligibility } = useEligibility()
  const { t } = useTranslation()

  if (isLoading) {
    return <LoadingAnimation />
  }

  return (
    <DivRoot>
      <Grid container>
        <Grid item xs={12}>
          <Grid item xs={12}>
            <IconStyle src={eligibilityIcon1} alt={t('KYCSuccess.alt')} />
            <Typography variant="h1" mt={'8px'} mb={'24px'}>
              {t('CreditRenewals.Eligibility.heading1')}
            </Typography>
            <Typography variant="body2" textAlign="center" color={figColors.black}>
              {t('CreditRenewals.Eligibility.description1')}
            </Typography>
          </Grid>

          <Grid item xs={12} mt="84px">
            <IconStyle src={eligibilityIcon2} alt={t('KYCSuccess.alt')} width={'84px'} />
            <Typography variant="h1" mt={'8px'} mb={'24px'}>
              {t('CreditRenewals.Eligibility.heading2')}
            </Typography>
            <Typography variant="body2" textAlign="center" color={figColors.black}>
              <Trans
                i18nKey="CreditRenewals.Eligibility.description2"
                components={{
                  learnMoreLink: (
                    <Link
                      href={t('CreditRenewals.LoanDetails.tooltipURL')}
                      target="_blank"
                      rel="noopener noreferrer"
                      aria-label={t('CreditRenewals.LoanDetails.tooltipURL')}
                    />
                  ),
                }}
              />
            </Typography>
          </Grid>

          <Grid item xs={12} marginTop="32px" textAlign={'center'}>
            <PrimaryCTA
              sx={{ marginY: '24px' }}
              onClick={() => {
                creditRenewalsEligibilityPageCTAClicked(
                  t('CreditRenewals.Eligibility.buttonLabel'),
                  CreditRenewalStepsEnum.ELIGIBILITY,
                )
                checkEligibility()
              }}
              buttonText={t('CreditRenewals.Eligibility.buttonLabel')}
              aria-label={t('CreditRenewals.Eligibility.buttonLabel')}
            />
            <Typography variant="body4">{t('CreditRenewals.Eligibility.notice')}</Typography>
          </Grid>
        </Grid>
      </Grid>
    </DivRoot>
  )
}

export default CreditRenewalEligibility
