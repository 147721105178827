import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import HttpBackend from 'i18next-http-backend'
import { initReactI18next } from 'react-i18next'

import enPortalTranslation from 'src/locales/en/portalTranslation'
import enTransalation from 'src/locales/en/translation'
import frPortalTranslation from 'src/locales/fr/portalTranslation'
import frTransalation from 'src/locales/fr/translation'
import { ENVIRONMENT, EnvEnum, IS_PRODUCTION } from 'src/utils/constants'
export const defaultNS = 'translation'
export const resources = {
  en: {
    translation: { ...enTransalation, ...enPortalTranslation },
  },
  fr: {
    translation: { ...frTransalation, ...frPortalTranslation },
  },
} as const
export type SupportedLanguage = keyof typeof resources
export const supportedLanguages = Object.keys(resources) as SupportedLanguage[]

export const EnglishLanguageKey: SupportedLanguage = 'en'
export const FrenchLanguageKey: SupportedLanguage = 'fr'

i18n
  .use(LanguageDetector)
  .use(HttpBackend)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    debug: !(IS_PRODUCTION || ENVIRONMENT === EnvEnum.testing),
    fallbackLng: 'en',
    nonExplicitSupportedLngs: true,
    interpolation: { escapeValue: false },
    supportedLngs: supportedLanguages,
    defaultNS,
    resources,
  })

export default i18n
