import { Grid, Typography } from '@mui/material'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { useContext } from 'react'
import { useTranslation } from 'react-i18next'

import { LandingPromoBanner } from 'src/components/EndToEnd/landing/PromoBanner/LandingPromoBanner'
import { InfoTile } from 'src/components/EndToEnd/landing/shared/InfoTile'
import {
  LandingTemplate,
  LandingTemplateDivider,
} from 'src/components/EndToEnd/landing/shared/LandingTemplate'
import { PrequalificationContext } from 'src/contexts'
import { useConfiguration } from 'src/hooks'
import { useLocalizedFormatters } from 'src/hooks/useLocalizedFormatters'
import {
  LFLGroupIconsDesktop,
  LFLGroupIconsMobile,
  LFLGroupLogo,
  borrowellLogo,
  divider,
  landingDome,
  landingLeaf,
  landingPill,
  landingTwoLeaf,
  logoImgSvg,
  perkopolisLogo,
} from 'src/images'
import theme from 'src/themes'
import { FeatureFlags, HeaderType, PartnersEnum, StepsEnum } from 'src/types'
import { capitalize } from 'src/utils'
import { isPartnerPromoActive, isfairstoneLandingPromoActive } from 'src/utils/core'

/**
 * Header config for RootLayout.
 * @returns {HeaderType} The header configuration.
 */
export const useGenericLandingHeaderConfig = (): HeaderType => {
  const { t } = useTranslation()
  const { percentFormat, currencyFormat } = useLocalizedFormatters()
  const { loanLimits } = useConfiguration()
  const { partnerConfig } = useContext(PrequalificationContext)
  const { enableCreditKarmaLandingPage } = useFlags<FeatureFlags>()
  const { isFigLandingPromo } = useFlags<FeatureFlags>()

  const { setStepE2E } = useContext(PrequalificationContext)
  const partnerName = partnerConfig?.name as string
  const isPromoActive = isPartnerPromoActive(isFigLandingPromo, partnerName)
  const isFairstoneCashContest = isfairstoneLandingPromoActive(isFigLandingPromo, partnerName)

  /**
   * Handles the click event for the landing page CTA button.
   */
  const handleClick = () => {
    setStepE2E(StepsEnum.CONSENTS)
  }

  /**
   * Gets the subtitle based on the partner configuration.
   * @returns {string} The subtitle.
   */
  const getSubtitle = () => {
    if (enableCreditKarmaLandingPage) {
      if (partnerConfig?.name == PartnersEnum.CREDIT_KARMA) {
        return 'e2e.Landing.creditKarma.subtitle'
      }
    }
    if (isPromoActive || isFairstoneCashContest) {
      return 'e2e.Landing.FigPromo.subtitle'
    }
    if (partnerConfig?.name == PartnersEnum.PERKOPOLIS) {
      return 'e2e.Landing.perkopolis.subtitle'
    }
    if (partnerConfig?.name == PartnersEnum.LFLGROUP) {
      return 'e2e.Landing.LFLGroup.subtitle'
    }
    if (partnerConfig?.name == PartnersEnum.CREDIT_KARMA_PROMO) {
      return 'e2e.Landing.creditKarmaPromo.subtitle'
    }
    if (partnerConfig?.name == PartnersEnum.FAIRSTONE) {
      return 'e2e.Landing.fairstone.subtitle'
    }
    if (partnerConfig?.is_referral) {
      return 'e2e.Landing.figFinancialReferral.subtitle'
    }
    return 'e2e.Landing.generic.subtitle'
  }

  const getLandingPromoBanner = () => {
    if (isPromoActive || isFairstoneCashContest) {
      return <LandingPromoBanner partner={partnerConfig!.name} handleClick={handleClick} />
    }
    if (partnerConfig?.name == PartnersEnum.PERKOPOLIS) {
      return <LandingPromoBanner partner={partnerConfig!.name} handleClick={handleClick} />
    }
    if (partnerConfig?.name == PartnersEnum.LFLGROUP) {
      return <LandingPromoBanner partner={partnerConfig!.name} handleClick={handleClick} />
    }
    if (partnerConfig?.name == PartnersEnum.CREDIT_KARMA_PROMO) {
      return <LandingPromoBanner partner={partnerConfig!.name} handleClick={handleClick} />
    }
    if (partnerConfig?.name == PartnersEnum.FAIRSTONE) {
      return <LandingPromoBanner partner={partnerConfig!.name} handleClick={handleClick} />
    }
    if (partnerConfig?.is_referral) {
      return <LandingPromoBanner partner={partnerConfig!.name} handleClick={handleClick} />
    }

    return null
  }

  const getIcon = () => {
    if (partnerConfig?.name == PartnersEnum.PERKOPOLIS) {
      return (
        <Grid container justifyContent="center" marginBottom="12px">
          <img src={logoImgSvg} alt="Fig Financial Logo" height="31px" />
          <img
            src={divider}
            alt="Divider"
            height="31px"
            style={{ paddingLeft: '1%', paddingRight: '1%' }}
          />
          <img src={perkopolisLogo} alt="Fig Financial Logo" height="31px" />
        </Grid>
      )
    }
    if (partnerConfig?.name == PartnersEnum.BORROWELL) {
      return (
        <Grid container justifyContent="center" marginBottom="12px">
          <img src={borrowellLogo} alt="Borrowell Logo" height="70px" width="207px" />
        </Grid>
      )
    }
    if (partnerConfig?.name == PartnersEnum.LFLGROUP) {
      return (
        <Grid container justifyContent="center" marginBottom="12px">
          <img src={logoImgSvg} alt="Fig Financial Logo" height="31px" />
          <img
            src={divider}
            alt="Divider"
            height="31px"
            style={{ paddingLeft: '1%', paddingRight: '1%' }}
          />
          <img src={LFLGroupLogo} alt="LFL Group Logo" height="31px" />
        </Grid>
      )
    }
    return (
      <Grid container justifyContent="center" marginBottom="12px">
        <img src={logoImgSvg} alt="Fig Financial Logo" height="31px" />
      </Grid>
    )
  }

  const getTitle = () => {
    if (
      isPromoActive ||
      partnerConfig?.name == PartnersEnum.CREDIT_KARMA_PROMO ||
      isFairstoneCashContest
    ) {
      return 'e2e.Landing.FigPromo.title'
    }
    if (partnerConfig?.name == PartnersEnum.PERKOPOLIS) {
      return 'e2e.Landing.perkopolis.title'
    }
    if (partnerConfig?.name == PartnersEnum.LFLGROUP) {
      return 'e2e.Landing.LFLGroup.title'
    }
    if (partnerConfig?.is_referral) {
      return 'e2e.Landing.figFinancialReferral.title'
    }
    return 'e2e.Landing.generic.title'
  }

  return {
    sx: {
      [theme.breakpoints.up('md')]: { marginTop: '48px', width: 604, fontSize: '16px' },
      [theme.breakpoints.down('md')]: {
        padding: '24px 16px 0 16px',
        fontSize: '12px',
        borderTop: `1px solid ${theme.color.grey4}`,
      },
    },
    icon: getIcon(),
    title: t(getTitle(), {
      apr: percentFormat(loanLimits?.minApr, 2),
      referrer_name: capitalize(partnerConfig?.referrer_name),
    }),
    content: t(getSubtitle(), {
      min_amt: currencyFormat(loanLimits?.minLoanAmount, 0),
      max_amt: currencyFormat(loanLimits?.maxLoanAmount, 0),
    }),
    promoBanner: getLandingPromoBanner() || undefined,
  }
}

export const useGenericLandingIconConfig = () => {
  const { partnerConfig } = useContext(PrequalificationContext)
  if (partnerConfig?.name == PartnersEnum.LFLGROUP) {
    return {
      partner: PartnersEnum.LFLGROUP,
      mobileIcon: LFLGroupIconsMobile,
      desktopIcon: LFLGroupIconsDesktop,
    }
  }
  return null
}

/*
 * Generic landing page body.
 */
export const GenericLandingBody = () => {
  const { t } = useTranslation()
  const { percentFormat } = useLocalizedFormatters()

  return (
    <>
      <Grid item xs={12}>
        <Typography variant="body2">{t('e2e.Landing.generic.figDescription')}</Typography>
      </Grid>
      <LandingTemplateDivider />
      <Grid item xs={12}>
        <Typography variant="h3">{t('e2e.Landing.generic.benefits.title')}</Typography>
      </Grid>

      <InfoTile
        title={t('e2e.Landing.generic.benefits.convenienceTitle')}
        text={t('e2e.Landing.generic.benefits.convenienceText', { pct: percentFormat(100, 0) })}
        icon={<img src={landingTwoLeaf} alt="" />}
      />
      <InfoTile
        title={t('e2e.Landing.generic.benefits.feesTitle')}
        text={t('e2e.Landing.generic.benefits.feesText')}
        icon={<img src={landingLeaf} alt="" />}
      />
      <InfoTile
        title={t('e2e.Landing.generic.benefits.seamlessTitle')}
        text={t('e2e.Landing.generic.benefits.seamlessText')}
        icon={<img src={landingDome} alt="" />}
      />
      <InfoTile
        title={t('e2e.Landing.generic.benefits.trustTitle')}
        text={t('e2e.Landing.generic.benefits.trustText')}
        icon={<img src={landingPill} alt="" />}
      />
    </>
  )
}

/*
 * Generic landing page, using Fig-only branding (no co-branding).
 */
export const GenericLanding = () => {
  const genericLandingHeaderConfig = useGenericLandingHeaderConfig()
  const iconConfig = useGenericLandingIconConfig() || {} // Ensure iconConfig is not null
  return (
    <LandingTemplate
      headerConfig={genericLandingHeaderConfig}
      iconConfig={iconConfig}
      body={<GenericLandingBody />}
    />
  )
}
