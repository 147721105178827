import { BoxProps } from '@mui/material/Box'

import { CreditRenewalStepsEnum, StepsEnum } from 'src/types'

export interface BulletListItemProps {
  text?: string
  title?: string
  content?: string
  isMobile?: boolean
  content1?: string
  content2?: string
}

export interface PaymentProtectionProps {
  previousStep: StepsEnum
  nextStep: StepsEnum
}

export interface CreditRenewalPaymentProtectionProps {
  previousStep: CreditRenewalStepsEnum
  nextStep: CreditRenewalStepsEnum
}

export enum PaymentProtectionEnum {
  PROTECTION_ACCEPTED = 'protection_accepted',
  PROTECTION_DECLINED = 'protection_declined',
  PROTECTION_NOT_SELECTED = 'not_selected',
}

export interface BoxModalProps extends BoxProps {
  isMobile: boolean
}

export interface TermsPageProps {
  handleClose: () => void
}
