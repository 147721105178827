import { Grid, InputAdornment } from '@mui/material'
import { CalendarIcon } from '@mui/x-date-pickers'
import { t } from 'i18next'
import { useState } from 'react'

import FigTextField from 'src/components/common/FigTextField'

export const DateOfBirthInput = ({ dateOfBirth }: { dateOfBirth: string }) => {
  const [errorState, setErrorState] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string>('')

  return (
    <Grid item xs={12} my="16px" textAlign={'left'}>
      <FigTextField
        data-testid="confirm-dob-input"
        autoComplete="bday"
        value={dateOfBirth}
        label={t('CreditRenewals.ConfirmInformation.dateOfBirthInput.label')}
        onClick={() => {
          setErrorState(true)
          setErrorMessage(t('CreditRenewals.ConfirmInformation.dateOfBirthInput.errorMessage'))
        }}
        error={errorState}
        helperText={errorState ? errorMessage : ''}
        InputProps={{
          readOnly: true,
          endAdornment: (
            <InputAdornment position="end">
              <CalendarIcon />
            </InputAdornment>
          ),
        }}
        sx={{
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: errorState ? 'grey.400' : 'grey.400',
            },
            '& .MuiInputBase-input': {
              color: errorState ? 'grey.600' : 'grey.600',
            },
          },
          '& .MuiFormLabel-root.Mui-error': {
            color: 'grey.600',
          },
          '& .MuiFormHelperText-root': {
            color: errorState ? 'error.main' : 'grey.600',
          },
        }}
      />
    </Grid>
  )
}
