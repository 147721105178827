import { styled, Typography, Box } from '@mui/material'
import { useLocation } from 'react-router-dom'

import { TrustpilotMicroCombo } from 'src/components/Trustpilot'
import theme from 'src/themes'
import { HeaderType, StepsEnum } from 'src/types'

const FigHeaderContainer = styled('div')(({ theme }) => ({
  width: '100%',
  height: '100%',
  position: 'relative',
  [theme.breakpoints.up('md')]: {
    marginTop: '20px',
    width: 594,
  },
}))

export default FigHeader
/**
 *
 */
function FigHeader({ title, icon, content, sx, promoBanner }: HeaderType) {
  const location = useLocation()

  const isLanding = location?.pathname?.split('/')?.pop() === StepsEnum.LANDING

  return (
    <FigHeaderContainer sx={sx}>
      {icon}
      <Typography
        variant="h1"
        textAlign="center"
        sx={{ lineHeight: 1.5, fontSize: '2em' }}
        marginBottom="10px"
      >
        {title}
      </Typography>
      <Typography variant="body2" textAlign="center" color={theme.color.grey9}>
        {content}
      </Typography>
      {isLanding && (
        <Box marginTop="48px">
          <TrustpilotMicroCombo />
        </Box>
      )}
      {promoBanner ? promoBanner : null}
    </FigHeaderContainer>
  )
}
