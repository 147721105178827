import { ThemeProvider } from '@emotion/react'
import Grid from '@mui/material/Grid2'

import WalmartLandingTheme from 'src/themes/WalmartLandingTheme'

export const WalmartLandingWrapper = ({ children }: { children: React.ReactNode }) => {
  return (
    <ThemeProvider theme={WalmartLandingTheme}>
      <Grid container justifyContent="center" alignItems="center" flexDirection="row">
        {children}
      </Grid>
    </ThemeProvider>
  )
}
