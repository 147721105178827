import { useEffect } from 'react'
import { useMutation } from 'react-query'

import { saveQuebecConsents } from 'src/hooks/useApi'
import { captureExceptionHelper } from 'src/utils'

/*
 *
 */
export const useSaveQCConsents = ({
  jwtOrigination,
  autoTrigger = false,
}: {
  jwtOrigination: string | undefined
  autoTrigger?: boolean
}) => {
  const {
    mutate: saveQuebecConsentsMutation,
    isSuccess,
    isLoading,
    reset,
  } = useMutation(
    ({ jwtOrigination }: { jwtOrigination: string }) => saveQuebecConsents(jwtOrigination),
    {
      onError: (e: any) => {
        captureExceptionHelper('Error saving Quebec consents', e)
        reset()
      },
    },
  )

  useEffect(() => {
    if (!jwtOrigination || !autoTrigger) {
      return
    }
    saveQuebecConsentsMutation({ jwtOrigination })
  }, [jwtOrigination, autoTrigger])

  return { saveQuebecConsentsMutation, isSuccess, isLoading, reset }
}
