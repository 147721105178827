import { useContext } from 'react'

import type { PortalContextProps } from 'src/portal/contexts/portal'
import { PortalContext } from 'src/portal/contexts/portal'

export default function usePortalContext(): PortalContextProps {
  const context = useContext(PortalContext)
  if (context === undefined) {
    throw new Error('usePortalContext must be used within a PortalProvider')
  }
  return context
}
